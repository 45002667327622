import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const PeaceOfMindIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.8 20C6.3 20 5.875 19.825 5.525 19.475C5.175 19.125 5 18.7 5 18.2C5 17.85 5.1 17.5208 5.3 17.2125C5.5 16.9042 5.76667 16.6833 6.1 16.55L10 15V12.75C9.1 13.8 8.05417 14.6042 6.8625 15.1625C5.67083 15.7208 4.38333 16 3 16V14C4.13333 14 5.1625 13.7667 6.0875 13.3C7.0125 12.8333 7.85 12.1667 8.6 11.3L9.95 9.7C10.15 9.46667 10.3833 9.29167 10.65 9.175C10.9167 9.05833 11.2 9 11.5 9H12.5C12.8 9 13.0833 9.05833 13.35 9.175C13.6167 9.29167 13.85 9.46667 14.05 9.7L15.4 11.3C16.15 12.1667 16.9875 12.8333 17.9125 13.3C18.8375 13.7667 19.8667 14 21 14V16C19.6167 16 18.3292 15.7208 17.1375 15.1625C15.9458 14.6042 14.9 13.8 14 12.75V15L17.9 16.55C18.2333 16.6833 18.5 16.9042 18.7 17.2125C18.9 17.5208 19 17.85 19 18.2C19 18.7 18.825 19.125 18.475 19.475C18.125 19.825 17.7 20 17.2 20H10V19.5C10 19.0667 10.1417 18.7083 10.425 18.425C10.7083 18.1417 11.0667 18 11.5 18H14.5C14.65 18 14.7708 17.9542 14.8625 17.8625C14.9542 17.7708 15 17.65 15 17.5C15 17.35 14.9542 17.2292 14.8625 17.1375C14.7708 17.0458 14.65 17 14.5 17H11.5C10.8 17 10.2083 17.2417 9.725 17.725C9.24167 18.2083 9 18.8 9 19.5V20H6.8ZM12 8C11.45 8 10.9792 7.80417 10.5875 7.4125C10.1958 7.02083 10 6.55 10 6C10 5.45 10.1958 4.97917 10.5875 4.5875C10.9792 4.19583 11.45 4 12 4C12.55 4 13.0208 4.19583 13.4125 4.5875C13.8042 4.97917 14 5.45 14 6C14 6.55 13.8042 7.02083 13.4125 7.4125C13.0208 7.80417 12.55 8 12 8Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
