import { Text } from 'components/text/text'
import { Spacer } from 'components/spacer'
import { Divider } from 'components/divider/divider'
import { ChevronDown } from '@assets/icons'
import { ReactNode, useState } from 'react'
import { ChevronIconUp } from '@assets/svg'

export const AccordionAdvance = ({
  items,
}: {
  items: Array<{
    id: string
    header: string | JSX.Element
    subTitle?: string
    content: string | JSX.Element
    icon?: ReactNode
  }>
}): JSX.Element => {
  const [active, setActive] = useState<string>(null)

  const isString = (item: string | JSX.Element): boolean => {
    return typeof item === 'string'
  }

  return (
    <div className='accordion'>
      {items?.map((i, idx) => (
        <div key={`accordion_${i?.id}_${idx}`}>
          <div className='accordion__item'>
            <Spacer preset='tiny' />
            <div
              className='accordion__header cursor-pointer'
              onClick={() =>
                setActive((prev) => (prev === i?.id ? null : i?.id))
              }
              aria-hidden
            >
              <Spacer preset='tiny' />
              <div className='d-flex justify-space-between align-center gap-12'>
                {i.icon}
                <div className='flex d-flex-col justify-space-between align-center'>
                  {isString(i?.header) ? (
                    <Text text={i?.header as string} preset='bodyBig' />
                  ) : (
                    i?.header
                  )}

                  <Spacer preset='tiny' />
                  <Text
                    text={i.subTitle}
                    preset='headline3'
                    color='iconPrimaryInitial'
                  />
                </div>
                {active === i?.id ? <ChevronIconUp /> : <ChevronDown />}
              </div>
              <Spacer preset='tiny' />
            </div>
            {active === i?.id ? (
              <div className='accordion__content'>
                <Spacer preset='tiny' />
                {isString(i?.content) ? (
                  <Text
                    text={i?.content as string}
                    preset='paragraph2'
                    color='secondary'
                  />
                ) : (
                  i?.content
                )}
                <Spacer preset='tiny' />
              </div>
            ) : null}
          </div>
          <Spacer preset='tiny' />
          <Divider />
        </div>
      ))}
    </div>
  )
}
