import { CloseIcon } from '@assets/svg'
import { Button } from 'components'
import { BuySellTransaction, OrderTransactionStatusEnum } from 'interfaces'
import { t } from 'i18next'

export function useCancelButton(
  transaction: BuySellTransaction,
  handleOnClick: () => void,
  isCancellable: boolean,
): JSX.Element {
  return transaction?.status === OrderTransactionStatusEnum.pending ? (
    <Button
      text={t('common.cancel')}
      buttonType='ghost--negative'
      textPreset='paragraph2'
      disabled={!isCancellable}
      onClick={() => handleOnClick()}
      autoWidth={true}
      right={<CloseIcon className='negative' />}
    />
  ) : null
}
