/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import './transactions.styles.scss'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useTranslation } from '@hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState, setMarketType } from 'store'
import { isEqual } from 'lodash'
import {
  BigSpinner,
  Divider,
  IntersectionObserverComp,
  Show,
  Spacer,
  StickyElementsWrapper,
  TabBar,
  Text,
} from '@components'
import {
  OrderTransactionStatusEnum,
  SelectedFilters,
  TransactionsTab,
  TransactionState,
  TransactionType,
} from '@interfaces'
import {
  clearTransactionsList,
  fetchOrderTransactions,
  fetchTransactions,
  setTransactionsScrollPosition,
  setTransactionsTab,
} from './thunk'
import { trackingService } from '@services'
import {
  TabClickedEvents,
  TransactionsEvents,
} from '@utils/eventTracker/eventKeys'
import { getTransactionStatusForTab } from './utils/getTransactionStatusForTab'
import {
  clearTransactionsFilters,
  createTransactionsFiltersSelection,
  fetchTransactionsFilters,
  setTransactionsFilterFocused,
  updateTransactionsFilter,
} from './filters.thunk'
import { FiltersContainer } from '@shared/components/filters/filtersContainer/filtersContainer'
import { Container } from '@shared/components'
import {
  BalancesSummary,
  ClosedTab,
  ExecutedTab,
  PendingTab,
} from './components'
import { SpinnerIcon } from '@assets/svg'
import { FiltersDateOptions } from '@shared/components/filters/filterDropdownContent/filtersDateOptions'
import { subDays, subMonths } from 'date-fns'
import { formatDateLong } from '@utils/helpers'
import { clearFiltersSelection } from 'features/discover/filters.thunk'

const ITEMS_PER_PAGE = 20

export default function Transactions(): JSX.Element {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const dispatch = useDispatch()
  const initialTab = useParams()?.mode

  const { selectedTab, filters, byStatus }: TransactionState = useSelector(
    (state: RootState) => state?.transactions,
  )

  const [dateSelection, setDateSelection] = useState<{
    from: string
    to: string
  }>({
    from: new Date(subMonths(new Date(), 1))?.toISOString()?.substring(0, 10),
    to: new Date()?.toISOString()?.substring(0, 10),
  })

  const [finalDates, setDateFinalDates] = useState<{
    from: string
    to: string
  }>({ from: null, to: null })

  const [animatedItems, setAnimatedItems] = useState(false)

  const [prevFiltersSelection, setPrevFiltersSelection] = useState<{
    [key: string]: string
  }>({})

  const getTabFromInitialTab = (initialTab: string): TransactionsTab => {
    switch (initialTab) {
      case 'pending':
        return TransactionsTab.pending
      case 'closed':
        return TransactionsTab.closed
      case 'executed':
        return TransactionsTab.executed
      default:
        return TransactionsTab.executed
    }
  }

  useEffect(() => {
    if (
      initialTab &&
      initialTab !== selectedTab &&
      !loading &&
      !isLoadingNextPage
    ) {
      dispatch(
        setTransactionsTab({
          tab: getTabFromInitialTab(initialTab),
        }),
      )
    }
    // Only on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTabPressed = (tabPressed: TransactionsTab): void => {
    if (loading && isLoadingNextPage) return
    // Scroll to top on tab change
    window.scrollTo({ top: 0, behavior: 'smooth' })

    // This allows to change the url without reloading the discover component
    window.history.replaceState(
      null,
      null,
      `/transactions/${tabPressed.toLowerCase()}`,
    )

    handleResetAllFilters()

    // Change tab
    dispatch(setTransactionsTab({ tab: tabPressed }))

    // Track tab change
    trackingService.trackEvent({
      event: TabClickedEvents.transactions,
      props: {
        tab: tabPressed,
        origin: selectedTab,
      },
    })
  }

  // On tab change
  useEffect(() => {
    // Reset date
    resetDateSelection()

    // Load filters on tab change if not loaded yet
    const status = getTransactionStatusForTab(selectedTab)

    const alreadyLoadedFilters =
      !filters?.byStatus || filters?.byStatus?.[status]?.length > 0

    if (!status || alreadyLoadedFilters) return

    dispatch(
      fetchTransactionsFilters({
        status,
      }),
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  const tabs = [
    {
      title: t('transactions.pending.tabName'),
      id: TransactionsTab.pending,
    },
    {
      title: t('transactions.cancelled.tabName'),
      id: TransactionsTab.closed,
    },
    {
      title: t('transactions.completed.tabName'),
      id: TransactionsTab.executed,
    },
  ]

  const currentFilters = useMemo(() => {
    const status = getTransactionStatusForTab(selectedTab)
    if (!status) return
    return filters?.byStatus?.[status] || []
  }, [filters?.byStatus, selectedTab])

  const handleFilterClear = (id: string): void => {
    dispatch(updateTransactionsFilter({ id, type: 'reset' }))
    dispatch(createTransactionsFiltersSelection())

    if (id === 'date') resetDateSelection()
  }

  const handleResetAllFilters = (): void => {
    currentFilters?.forEach((f) => {
      dispatch(updateTransactionsFilter({ id: f?.id, type: 'reset' }))
    })
    dispatch(clearTransactionsFilters())
    dispatch(createTransactionsFiltersSelection())
    resetDateSelection()
  }

  const currentStatus = getTransactionStatusForTab(selectedTab)
  const currentState = byStatus[currentStatus]

  const pendingState = byStatus['PENDING']
  const closedState = byStatus['CANCELLED']
  const executedState = byStatus['COMPLETED']

  const isPendingTab = selectedTab === TransactionsTab.pending
  const isClosedTab = selectedTab === TransactionsTab.closed
  const isExecutedTab = selectedTab === TransactionsTab.executed

  const itsEmpty = !currentState.loading && !currentState?.data?.length

  const loading = currentState?.loading
  const isLoadingNextPage = currentState?.nextPageLoading

  const currentFiltersWithDate = useMemo(() => {
    let currentSelection = { ...(filters?.selected || {}) }

    if (finalDates?.from && finalDates?.to) {
      currentSelection = {
        ...currentSelection,
        date: `${finalDates.from},${finalDates.to}`,
      }
    }

    return currentSelection || {}
  }, [finalDates, filters?.selected])

  const thereAreActiveFilters: boolean =
    Object.keys(currentFiltersWithDate).length > 0

  const getPendingTransactions = useCallback(
    (page: number, size?: number, filters?: SelectedFilters) => {
      dispatch(
        fetchOrderTransactions({
          page,
          size,
          statuses: [OrderTransactionStatusEnum.pending],
          selectedFilters: filters,
          date: null,
        }),
      )
    },
    [dispatch],
  )

  const getExecutedTransactions = useCallback(
    (page: number, size?: number, filters?: SelectedFilters) => {
      dispatch(
        fetchTransactions({
          page,
          size,
          selectedFilters: filters,
          date: null,
        }),
      )
    },
    [dispatch],
  )

  const getClosedTransactions = useCallback(
    (page: number, size?: number, filters?: SelectedFilters) => {
      dispatch(
        fetchOrderTransactions({
          page,
          size,
          statuses: [OrderTransactionStatusEnum.cancelled],
          selectedFilters: filters,
          date: null,
        }),
      )
    },
    [dispatch],
  )

  const load = ({
    page,
    size,
    filters,
  }: {
    page: number
    size: number
    filters?: SelectedFilters
  }): void => {
    setAnimatedItems(true)

    Object.keys(filters || {}).forEach((k) => {
      trackingService.trackEvent({
        event: TransactionsEvents.filterApplied,
        props: {
          tab: selectedTab,
          type: k,
          value: filters[k],
        },
      })
    })

    setPrevFiltersSelection(filters)

    if (selectedTab === TransactionsTab.executed) {
      getExecutedTransactions(page, size, filters)
    } else if (selectedTab === TransactionsTab.closed) {
      getClosedTransactions(page, size, filters)
    } else if (selectedTab === TransactionsTab.pending) {
      getPendingTransactions(page, size, filters)
    }
  }

  useEffect(() => {
    if (!currentState.data) {
      load({
        page: 0,
        size: ITEMS_PER_PAGE,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  const handleLoadNextPage = (): void => {
    if (isLoadingNextPage || loading) return
    const currentPage = currentState.page

    load({
      page: currentPage + 1,
      size: ITEMS_PER_PAGE,
      filters: {
        ...currentFiltersWithDate,
      },
    })
  }

  const resetDateSelection = (): void => {
    setDateSelection({
      from: new Date(subMonths(new Date(), 1))?.toISOString()?.substring(0, 10),
      to: new Date()?.toISOString()?.substring(0, 10),
    })
    setDateFinalDates({
      from: null,
      to: null,
    })
  }

  useEffect(() => {
    const status = getTransactionStatusForTab(selectedTab)
    const position = byStatus?.[status]?.scrollPosition || 0
    if (position > 0) {
      window?.scrollTo({ top: position, behavior: 'instant' })
      dispatch(setTransactionsScrollPosition({ position: 0 }))
    }
    dispatch(clearFiltersSelection())
    dispatch(clearTransactionsList())
    setDateSelection({ from: null, to: null })
    handleResetAllFilters()
    load({ page: 0, size: ITEMS_PER_PAGE, filters: currentFiltersWithDate })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loading || isLoadingNextPage) return

    const previousFiltersKeys = Object.keys(prevFiltersSelection || {})
    const currentSelKeys = Object.keys(currentFiltersWithDate || {})

    const justCleanedFilters =
      previousFiltersKeys?.length > 0 && currentSelKeys?.length === 0

    const filtersAreDifferent = !isEqual(
      prevFiltersSelection,
      currentFiltersWithDate,
    )

    if (justCleanedFilters || filtersAreDifferent) {
      load({
        page: 0,
        size: 20,
        filters: currentFiltersWithDate,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFiltersWithDate])

  const todayDayDate = new Date(new Date().toISOString().substring(0, 10))
  const todayDay = todayDayDate.toISOString().substring(0, 10)

  const yyyymmdd = (date: Date): string => date.toISOString().substring(0, 10)

  const lastNdays = 'common.lastNdays'
  const lastNmonths = 'common.lastNmonths'

  const dates: Array<{
    from: string
    to: string
    title: string
  }> = [
    {
      from: yyyymmdd(subDays(todayDayDate, 7)),
      to: todayDay,
      title: t(lastNdays, { n: 7 }),
    },
    {
      from: yyyymmdd(subDays(todayDayDate, 15)),
      to: todayDay,
      title: t(lastNdays, { n: 15 }),
    },
    {
      from: yyyymmdd(subDays(todayDayDate, 30)),
      to: todayDay,
      title: t(lastNdays, { n: 30 }),
    },
    {
      from: yyyymmdd(subDays(todayDayDate, 90)),
      to: todayDay,
      title: t(lastNdays, { n: 90 }),
    },
    {
      from: yyyymmdd(subMonths(todayDayDate, 6)),
      to: todayDay,
      title: t(lastNmonths, { n: 5 }),
    },
    {
      from: yyyymmdd(subMonths(todayDayDate, 12)),
      to: todayDay,
      title: t(lastNmonths, { n: 12 }),
    },
    {
      from: yyyymmdd(
        new Date(Date.UTC(new Date().getFullYear(), 0, 1, 0, 0, 0, 0)),
      ),
      to: yyyymmdd(new Date()),
      title: 'YTD',
    },
  ]

  const formattedDates: Array<{
    from: string
    to: string
    title: string
    subtitle: string
  }> = dates?.map((i) => {
    return {
      from: i?.from,
      to: i?.to,
      title: i?.title,
      subtitle: `${formatDateLong(i?.from)} - ${formatDateLong(i?.to)}`,
    }
  })

  const selectedDateFormatted = useMemo(() => {
    return finalDates?.from && finalDates?.to
      ? `${formatDateLong(finalDates?.from)} - ${formatDateLong(
          finalDates?.to,
        )}`
      : null
  }, [finalDates?.from, finalDates?.to])

  // Hide filters when there is no active filters and there is no data
  const hideFilters = !thereAreActiveFilters && itsEmpty

  const handleNavigation = (
    id: string,
    type: TransactionType,
    financialEvent: string,
  ): void => {
    dispatch(setTransactionsScrollPosition({ position: window?.scrollY }))
    navigate(`/transactions/${id}/${type}?financialEvent=${financialEvent}`)
  }

  const handleOnClose = (): void => {
    dispatch(createTransactionsFiltersSelection())
    dispatch(setTransactionsFilterFocused(null))
  }
  useEffect(() => {
    dispatch(setMarketType(null))
  }, [dispatch])
  return (
    <div className='h-100 --fade-in-animation'>
      <StickyElementsWrapper startPoint={56} zIndex={'3'}>
        <Container size='normal'>
          <>
            <Spacer preset='mediumPlus' />
            <Text preset='hero' text={t('transactions.title')} />
            <Spacer preset='smallPlus' />
          </>
        </Container>
        <div className='transactions__tabs' data-sticky>
          <Container size='normal'>
            <TabBar
              extraMarginOnSides
              tabs={tabs}
              activeTab={selectedTab}
              setActiveTab={handleTabPressed}
              loading={loading}
            />
          </Container>
          <Divider />
        </div>
        <div className='d-flex d-flex-col'>
          <div
            className={`transactions__filters ${
              animatedItems ? '--fade-in-animation' : ''
            }`}
            data-sticky
          >
            <Show when={!hideFilters}>
              <Container size='normal'>
                <div className='d-flex align-center flex-wrap-wrap'>
                  <FiltersContainer
                    filters={currentFilters}
                    handleOnPress={(filter) => {
                      dispatch(setTransactionsFilterFocused(filter.id))
                    }}
                    handleOnClearFilter={handleFilterClear}
                    handleResetAllFilters={handleResetAllFilters}
                    disabled={loading}
                    isLoading={loading}
                    focusedFilter={filters?.focusedFilter}
                    onClose={handleOnClose}
                    onGoBack={() => {
                      dispatch(
                        setTransactionsFilterFocused(
                          filters?.focusedFilter?.parentId,
                        ),
                      )
                    }}
                    focusFilter={(id) => {
                      dispatch(setTransactionsFilterFocused(id))
                    }}
                    toggleFilter={(id) =>
                      dispatch(
                        updateTransactionsFilter({
                          id,
                          type: 'toggle',
                        }),
                      )
                    }
                    filtersCustomizations={{
                      date: {
                        title: selectedDateFormatted,
                        active: !!selectedDateFormatted,
                        component: (
                          <FiltersDateOptions
                            onSelect={(s) => {
                              setDateSelection({
                                from: s?.from,
                                to: s?.to,
                              })
                            }}
                            dateSelection={dateSelection}
                            dateValues={formattedDates}
                            onReset={() => {
                              setDateSelection({ from: null, to: null })
                            }}
                            onClose={() => {
                              setDateFinalDates(dateSelection)
                              dispatch(setTransactionsFilterFocused(null))
                            }}
                          />
                        ),
                      },
                    }}
                  />
                  {!loading && currentState?.totalItems ? (
                    <>
                      <Spacer preset='small' />
                      <div className='fade-in-animation-normal'>
                        <Spacer preset='tiny' />
                        <Text
                          preset='paragraph2'
                          text={`${currentState?.totalItems} ${t(
                            'search.results',
                          )}`}
                          color='secondary'
                        />
                        <Spacer preset='tiny' />
                      </div>
                    </>
                  ) : null}
                </div>
              </Container>
            </Show>
          </div>
          <Show when={loading && currentState?.page === 0}>
            <div className='container home__loader'>
              <BigSpinner />
            </div>
          </Show>
          <Container size='normal'>
            <>
              <Show when={!loading && !itsEmpty}>
                <BalancesSummary />
              </Show>
              {/* Pending tab*/}
              <Show when={!loading && isPendingTab}>
                <PendingTab
                  animated={animatedItems}
                  thereAreActiveFilters={thereAreActiveFilters}
                  groups={pendingState?.data}
                  onTransactionClick={handleNavigation}
                />
              </Show>
              {/* Closed/Cancelled tab*/}
              <Show when={!loading && isClosedTab}>
                <ClosedTab
                  animated={animatedItems}
                  groups={closedState?.data}
                  thereAreActiveFilters={thereAreActiveFilters}
                  onTransactionClick={handleNavigation}
                />
              </Show>
              {/* Executed/Completed tab*/}
              <Show when={!loading && isExecutedTab}>
                <ExecutedTab
                  animated={animatedItems}
                  thereAreActiveFilters={thereAreActiveFilters}
                  groups={executedState?.data}
                  onTransactionClick={handleNavigation}
                />
              </Show>
              <Show
                when={
                  !itsEmpty &&
                  !loading &&
                  !!currentState?.data?.length &&
                  currentState?.page < currentState.totalPages - 1
                }
              >
                <IntersectionObserverComp
                  handler={handleLoadNextPage}
                  options={{
                    rootMargin: '24px',
                    threshold: 1.0,
                  }}
                >
                  <div className='text-center'>
                    <Spacer preset='small' />
                    <SpinnerIcon className='spinner spinner--orange' />
                    <Spacer preset='small' />
                  </div>
                </IntersectionObserverComp>
              </Show>
              <Spacer preset='enormous' />
            </>
          </Container>
        </div>
      </StickyElementsWrapper>
    </div>
  )
}
