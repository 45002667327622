import { useTranslation } from '@hooks'
import { useNavigate } from '@hooks'
import { ReactComponent as RightIcon } from '@assets/svg/next.svg'
import { ReactComponent as LeftIcon } from '@assets/svg/back.svg'
import { ScrollingCarousel } from '@trendyol-js/react-carousel'
import { ProductCard } from 'components/productCard'
import { AssetType, DiscoverTab } from '@interfaces'
import { t } from 'i18next'
import { useDispatch } from 'react-redux'
import { setMarketType } from 'store'

const productTitles = [
  { id: DiscoverTab.stocks, title: t('common.stocks') },
  { id: DiscoverTab.etfs, title: t('common.etfs') },
]

export default function ProductCarrouselHome(): JSX.Element {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const dispatch = useDispatch()
  const handleOnClick = (id: string): void => {
    dispatch(setMarketType(id as AssetType))
    navigate(`/discover/${id.toLowerCase()}`)
  }
  return (
    <div className='home__productsCatalog'>
      <div className='home__productsCatalog__title d-flex justify-space-between align-center pt-3 pb-3'>
        {t('home.explore')}
      </div>
      <ScrollingCarousel
        className={`carousel`}
        rightIcon={
          <RightIcon fill='var(--textNeutralStrong)' className='rightIcon' />
        }
        leftIcon={<LeftIcon fill='var(--textNeutralStrong)' />}
      >
        {productTitles?.map((item) => (
          <ProductCard
            key={item.id}
            id={item.id}
            title={item.title}
            onClick={() => handleOnClick(item?.id)}
          />
        ))}
      </ScrollingCarousel>
    </div>
  )
}
