import { UIIcon, UIIconColorProps } from 'components/utils'

export const LockCloseRoundIcon = ({
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x={0.25}
        y={0.25}
        width={39.5}
        height={39.5}
        rx={19.75}
        fill='white'
      />
      <rect
        x={0.25}
        y={0.25}
        width={39.5}
        height={39.5}
        rx={19.75}
        stroke='#595B67'
        strokeWidth={0.5}
      />
      <path
        d='M17 16H23V14C23 13.1667 22.7083 12.4583 22.125 11.875C21.5417 11.2917 20.8333 11 20 11C19.1667 11 18.4583 11.2917 17.875 11.875C17.2917 12.4583 17 13.1667 17 14V16ZM20.25 30H14C13.45 30 12.9792 29.8042 12.5875 29.4125C12.1958 29.0208 12 28.55 12 28V18C12 17.45 12.1958 16.9792 12.5875 16.5875C12.9792 16.1958 13.45 16 14 16H15V14C15 12.6167 15.4875 11.4375 16.4625 10.4625C17.4375 9.4875 18.6167 9 20 9C21.3833 9 22.5625 9.4875 23.5375 10.4625C24.5125 11.4375 25 12.6167 25 14V16H26C26.55 16 27.0208 16.1958 27.4125 16.5875C27.8042 16.9792 28 17.45 28 18V19.3C27.7 19.2 27.3875 19.125 27.0625 19.075C26.7375 19.025 26.3833 19 26 19V18H14V28H19.3C19.4333 28.4 19.5667 28.7458 19.7 29.0375C19.8333 29.3292 20.0167 29.65 20.25 30ZM26 31C24.6167 31 23.4375 30.5125 22.4625 29.5375C21.4875 28.5625 21 27.3833 21 26C21 24.6167 21.4875 23.4375 22.4625 22.4625C23.4375 21.4875 24.6167 21 26 21C27.3833 21 28.5625 21.4875 29.5375 22.4625C30.5125 23.4375 31 24.6167 31 26C31 27.3833 30.5125 28.5625 29.5375 29.5375C28.5625 30.5125 27.3833 31 26 31ZM27.65 28.35L28.35 27.65L26.5 25.8V23H25.5V26.2L27.65 28.35Z'
        fill='#23282A'
      />
    </svg>
  )
}
