import { Condition, PreorderCondition } from '@interfaces'

export const isFixedStopLossOrTakeProfit = (
  conditionInfo: PreorderCondition | Condition,
): boolean => {
  const onlyStopLoss =
    conditionInfo?.stopPrice > 0 &&
    !conditionInfo?.stopPercentage &&
    !conditionInfo?.trailAmount &&
    !conditionInfo?.trailPercentage
  return !!(onlyStopLoss || conditionInfo?.stopPercentage > 0)
}
