import {
  ChevronLeft,
  LockCloseRoundIcon,
  RemoveIcon,
  PlusIcon,
  TrendingUpIcon,
  WithdrawalIcon,
} from '@assets/icons'
import { Button, CustomModal, Spacer, Text } from '@components'
import { AccordionAdvance } from 'components/accordionAdvance/accordionAdvance'
import { useAddFundsAndCashout, useTranslation } from '@hooks'
import { ButtonProps } from 'components/button/button.props'
import { useMemo } from 'react'
import { formatCurrency } from '@utils/helpers'
import { useSelector } from 'react-redux'
import { RootState, useAppSelector } from 'store'

export const WalletDetails = ({
  close,
  sourceBalance,
  onHold,
  withdrawlMoney,
  sourceCurrency,
  transactionCurrency,
}: {
  close: () => void
  sourceBalance: number
  onHold: number
  withdrawlMoney: number
  sourceCurrency: string
  transactionCurrency?: string
}): JSX.Element => {
  const { t } = useTranslation()
  const { addFunds, cashout } = useAddFundsAndCashout({
    origin: 'Profile',
  })
  const exchangeBalanceState = useSelector(
    (state: RootState) => state.exchangeBalance,
  )
  const userBalanceState = useAppSelector((state) => state.userBalance)
  const actions = useMemo(() => {
    const commonBtnProps: ButtonProps = {
      autoWidth: true,
      size: 'medium',
      buttonType: 'secondary',
      textPreset: 'paragraph2',
    }
    // const originWallets = userBalanceState?.wallet?.filter(
    //   (val) =>
    //     val?.maxWithdrawal?.currency !== transactionCurrency &&
    //     val?.maxWithdrawal?.amount > 0,
    // )

    const buttons = {
      addMoney: (
        <Button
          {...commonBtnProps}
          left={<PlusIcon size={24} />}
          text={t('balance.addMoney')}
          buttonType='primary'
          onClick={() => {
            close()
            addFunds()
          }}
          autoWidth={false}
        />
      ),
      // exchange: (
      //   <Button
      //     {...commonBtnProps}
      //     left={<CurrencyExchange size={24} />}
      //     text={t('balance.exchange')}
      //     buttonType='secondary'
      //     disabled={withdrawlMoney <= 0 || originWallets.length === 0}
      //     onClick={() => {
      //       if (exchangeBalanceState?.totalBalanceMode) {
      //         dispatch(
      //           setExchangeBalanceStep(ExchangeBalanceSteps.originWalletList),
      //         )
      //       } else {
      //         dispatch(setExchangeBalanceStep(ExchangeBalanceSteps.walletList))
      //       }
      //     }}
      //     autoWidth={false}
      //   />
      // ),
      withdraw: (
        <Button
          {...commonBtnProps}
          left={<RemoveIcon size={24} />}
          text={t('balance.withdraw')}
          disabled={withdrawlMoney <= 0}
          onClick={() => {
            close()
            cashout()
          }}
          buttonType='secondary'
          autoWidth={false}
        />
      ),
    }
    if (userBalanceState?.wallet?.length <= 1) {
      return [buttons.addMoney, buttons.withdraw]
    }
    if (
      exchangeBalanceState?.fromTransaction &&
      exchangeBalanceState?.totalBalanceMode
    ) {
      return [
        buttons.addMoney,
        // buttons.exchange
      ]
    }
    if (
      exchangeBalanceState?.fromTransaction &&
      transactionCurrency === sourceCurrency
    ) {
      return [buttons.addMoney]
    }
    // if (
    //   exchangeBalanceState?.fromTransaction &&
    //   transactionCurrency !== sourceCurrency
    // ) {
    //   return [buttons.exchange]
    // }
    return [
      buttons.addMoney,
      buttons.withdraw,
      // buttons.exchange
    ]
  }, [
    t,
    addFunds,
    cashout,
    close,
    exchangeBalanceState?.totalBalanceMode,
    withdrawlMoney,
    exchangeBalanceState?.fromTransaction,
    sourceCurrency,
    transactionCurrency,
    userBalanceState?.wallet,
  ])

  return (
    <>
      <CustomModal.Header
        text={
          <Text
            preset='subtitle2'
            text={
              exchangeBalanceState?.totalBalanceMode
                ? t('exchangeBalance.totalBalance')
                : t('balance.currencyBalance', {
                    currency: sourceCurrency,
                  })
            }
          />
        }
        className='w-100 d-flex align-center justify-space-between'
      >
        <Button
          onClick={close}
          buttonType='link'
          autoWidth
          content={<ChevronLeft color='iconPrimaryInitial' size={36} />}
          shape='square'
        />
      </CustomModal.Header>
      <CustomModal.Content>
        <Spacer preset='medium' />

        <div className='d-flex d-flex-col justify-center align-center'>
          <Text
            preset='bodyRegular'
            text={
              exchangeBalanceState?.totalBalanceMode
                ? t('balance.totalBalanceModal.totalBalanceDescription')
                : t('balance.totalBalanceModal.description', {
                    currency: sourceCurrency,
                  })
            }
          ></Text>
        </div>
        <Spacer preset='medium' />

        <AccordionAdvance
          items={[
            {
              id: 'howToQualify',
              header: t('balance.totalBalanceModal.availableInvesting'),
              subTitle: formatCurrency({
                amount: sourceBalance,
                currency: sourceCurrency,
              }),
              content: t(
                'balance.totalBalanceModal.availableInvestingDescription',
              ),
              icon: <TrendingUpIcon size={40} />,
            },
            {
              id: 'howWillIknowIf',
              header: t('balance.totalBalanceModal.onHold'),
              subTitle: formatCurrency({
                amount: onHold,
                currency: sourceCurrency,
              }),
              content: t(
                'balance.totalBalanceModal.availableInvestingDescription',
              ),
              icon: <LockCloseRoundIcon size={40} />,
            },
            {
              id: 'howCanIavoid',
              header: t('balance.totalBalanceModal.availableWithdrawal'),
              subTitle: formatCurrency({
                amount: withdrawlMoney,
                currency: sourceCurrency,
              }),
              content: t('balance.totalBalanceModal.description'),
              icon: <WithdrawalIcon size={40} />,
            },
          ]}
        />
      </CustomModal.Content>
      <CustomModal.Footer>
        {actions.length === 3 ? (
          <div className='d-flex-col w-100 gap-16'>
            <div className='w-100'>{actions[0]}</div>
            <Spacer preset='tinyPlus' />
            <div className='d-flex w-100 gap-16'>
              <div className='w-100'>{actions[1]}</div>
              <div className='w-100'>{actions[2]}</div>
            </div>
          </div>
        ) : (
          <div className='d-flex w-100 gap-16'>{actions}</div>
        )}
      </CustomModal.Footer>
    </>
  )
}
