import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { CompanyPositionData, ReducerState } from '@interfaces'
import {
  clearAssetPosition,
  companyInvestmentsHasBeenUpdated,
  fetchCompanyInvestments,
} from './thunk'

const initialState: CompanyPositionData & ReducerState = {
  shares: null,
  lockedShares: null,
  id: null,
  ticker: null,
  portfolioPercentage: null,
  loading: false,
  error: null,
  logoUrl: null,
  name: null,
  monetaryStatsInAssetCurrency: null,
  monetaryStatsInPortfolioBaseCurrency: null,
  monetaryStatsInSettlementCurrency: null,
  type: null,
  totalPerformance: null,
  totalPerformanceToday: null,
  lastModified: null,
}

export const reducers = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchCompanyInvestments.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(
      fetchCompanyInvestments.fulfilled,
      (acc, { payload }: PayloadAction<CompanyPositionData>) => {
        const {
          id,
          ticker,
          portfolioPercentage,
          shares,
          lockedShares,
          conditionalOrders,
          logoUrl,
          name,
          monetaryStatsInAssetCurrency,
          monetaryStatsInPortfolioBaseCurrency,
          monetaryStatsInSettlementCurrency,
          type,
          totalPerformance,
          totalPerformanceToday,
        } = payload
        acc.shares = shares
        acc.lockedShares = lockedShares
        acc.portfolioPercentage = portfolioPercentage
        acc.ticker = ticker
        acc.id = id
        acc.loading = false
        acc.logoUrl = logoUrl
        acc.conditionalOrders = conditionalOrders
        acc.name = name
        acc.monetaryStatsInAssetCurrency = monetaryStatsInAssetCurrency
        acc.monetaryStatsInPortfolioBaseCurrency =
          monetaryStatsInPortfolioBaseCurrency
        acc.monetaryStatsInSettlementCurrency =
          monetaryStatsInSettlementCurrency
        acc.type = type
        acc.totalPerformance = totalPerformance
        acc.totalPerformanceToday = totalPerformanceToday
      },
    )
    .addCase(fetchCompanyInvestments.rejected, () => initialState)
    .addCase(companyInvestmentsHasBeenUpdated, (acc) => {
      acc.lastModified = new Date().toISOString()
    })
    .addCase(clearAssetPosition, () => initialState),
)

export default reducers
