import { Button } from 'components'
import { BuySellTransaction, OrderTransactionStatusEnum } from 'interfaces'
import { t } from 'i18next'
import { ChevronRight } from '@assets/icons'

export function useCompanyInfoButton(
  transaction: BuySellTransaction,
  handleOnClick: () => void,
): JSX.Element {
  return transaction?.status !== OrderTransactionStatusEnum.cancelled &&
    transaction?.condition ? (
    <Button
      text={t('common.companyInfo')}
      buttonType='secondary'
      textPreset='paragraph2'
      onClick={() => handleOnClick()}
      autoWidth={true}
      right={<ChevronRight />}
    />
  ) : null
}
