import { ChevronLeft, ImportantIcon } from '@assets/icons'
import { formatCurrency, formatCurrencyToSymbol } from '@utils/helpers'
import {
  Button,
  Card,
  CustomModal,
  Divider,
  Spacer,
  SummaryCard,
  Text,
} from 'components'
import { ExchangeBalanceSteps } from 'features/exchangeBalance/exchangeBalance.props'
import {
  getTransferOrder,
  setExchangeBalanceStep,
} from 'features/exchangeBalance/thunk'
import { useTranslation } from 'hooks/useTranslation'
import { CurrencyCode } from 'interfaces'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { walletTransferLogo } from 'screens'
import { RootState } from 'store'

const ConfirmOrder = ({
  onPrevious,
}: {
  onPrevious: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const exchangeBalanceState = useSelector(
    (state: RootState) => state.exchangeBalance,
  )
  const { wallet } = useSelector((state: RootState) => state.userBalance)
  const dispatch = useDispatch()
  const sections = {
    id: 'reviewOrder',
    overTitle: t('exchangeBalance.ExchangeOrderDetails'),
    data: [
      {
        label: t('exchangeBalance.exchangeSuccess.amount'),
        value: formatCurrency(exchangeBalanceState?.preOrder?.sourceAmount),
      },
      {
        label: t('exchangeBalance.exchangeSuccess.fxRate'),
        value: `${formatCurrencyToSymbol(
          exchangeBalanceState?.preOrder?.exchangeRate?.from,
        )} 1 = ${formatCurrencyToSymbol(
          exchangeBalanceState?.preOrder?.exchangeRate?.to,
        )} ${exchangeBalanceState?.preOrder?.exchangeRate?.value}`,
      },
      {
        label: t('exchangeBalance.exchangeSuccess.estimatedExchange'),
        value: formatCurrency(exchangeBalanceState?.preOrder?.targetNetAmount),
      },
      {
        label: t('exchangeBalance.exchangeSuccess.fees'),
        value: formatCurrency(exchangeBalanceState?.preOrder?.fee),
      },
      {
        label: t('exchangeBalance.exchangeSuccess.totalCost'),
        value: formatCurrency(exchangeBalanceState?.preOrder?.sourceAmount),
        className: 'headline3 text-right',
      },
    ],
  }
  const handelConfirmOrder = (): void => {
    const data = {
      preOrderId: exchangeBalanceState?.preOrder?.id,
    }
    dispatch(getTransferOrder(data))
  }

  useEffect(() => {
    if (
      !exchangeBalanceState.orderError &&
      !exchangeBalanceState.orderLoading &&
      exchangeBalanceState.orderSuccess
    ) {
      dispatch(setExchangeBalanceStep(ExchangeBalanceSteps.exchangeSuccess))
    }
  }, [
    dispatch,
    exchangeBalanceState.orderError,
    exchangeBalanceState.orderSuccess,
    exchangeBalanceState.orderLoading,
  ])
  return (
    <>
      <CustomModal.Header
        text={
          <Text
            preset='subtitle2'
            text={t('exchangeBalance.destinationWalletTitle')}
          />
        }
        className='w-100 d-flex align-center justify-space-between'
      >
        <Button
          onClick={onPrevious}
          buttonType='link'
          autoWidth
          content={<ChevronLeft color='iconPrimaryInitial' size={36} />}
          shape='square'
        />
      </CustomModal.Header>
      <CustomModal.Content>
        <Card bordered width='full' padding='tiny'>
          <Text
            preset='headline2'
            text={t('exchangeBalance.exchangeConvertionTitle', {
              source: exchangeBalanceState?.preOrder?.exchangeRate?.from,
              destination: exchangeBalanceState?.preOrder?.exchangeRate?.to,
            })}
          />
          <Spacer preset='tiny' />
          <div className='w-100 d-flex align-center gap-16'>
            {walletTransferLogo(
              exchangeBalanceState?.preOrder?.exchangeRate
                ?.from as CurrencyCode,
              exchangeBalanceState?.preOrder?.exchangeRate?.to as CurrencyCode,
            )}
            <div className='w-80'>
              <Text
                preset='headline2'
                text={`-${formatCurrency(
                  exchangeBalanceState?.preOrder?.sourceAmount,
                )}`}
              />
              <Text
                preset='headline1'
                text={`+${formatCurrency(
                  exchangeBalanceState?.preOrder?.targetNetAmount,
                )}`}
              />
            </div>
          </div>
          <Spacer preset='tiny' />
          <Divider />
          <Spacer preset='tiny' />
          <div className='d-flex align-end justify-end'>
            <Text
              preset='caption'
              text={t('exchangeBalance.balanceAfterExchange', {
                source: exchangeBalanceState?.preOrder?.exchangeRate?.to,
                amount: wallet.find(
                  (wallet) =>
                    wallet.maxWithdrawal.currency ===
                    exchangeBalanceState?.preOrder?.exchangeRate?.to,
                )?.cashAvailable?.amount,
              })}
            />
          </div>
        </Card>
        <Spacer preset='small' />
        {/* Summery card Table */}
        {sections && (
          <SummaryCard
            id={sections.id}
            overTitle={sections.overTitle}
            data={sections.data}
            key={sections.id}
            className='mb-3'
          />
        )}
        {/* <Spacer preset='small' /> */}
        <Card width='full'>
          <div className='d-flex d-flex-row align-center'>
            <ImportantIcon color='secondary' />
            <Spacer preset='tiny' />
            <Text
              text={t('exchangeBalance.note')}
              preset='paragraph2'
              color='secondary'
            />
          </div>
          <Spacer preset='smaller' />
          <Divider />
          <Spacer preset='smaller' />
          <Text
            text={t('exchangeBalance.transactionExecutedImmediately')}
            preset='paragraph3'
            color='tertiary'
          />
          <Spacer preset='smaller' />
          <Divider />
          <Spacer preset='smaller' />
          <Text
            text={t('exchangeBalance.followingTransaction')}
            preset='paragraph3'
            color='tertiary'
          />
        </Card>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          text={t('exchangeBalance.confirmOrder')}
          size='big'
          loading={exchangeBalanceState.orderLoading}
          onClick={() => {
            handelConfirmOrder()
          }}
        />
      </CustomModal.Footer>
    </>
  )
}

export default ConfirmOrder
