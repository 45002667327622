import { Button, CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'

export const CompanyPerformanceInfo = ({
  title,
  text,
  close,
}: {
  close: () => void
  title: string
  text: string
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CustomModal show={true} theme='lighter' size='small'>
      <CustomModal.Header text={title} />
      <CustomModal.Content>
        <Spacer preset='tiny' />
        <Text preset='paragraph1' text={text} align='center' />
        <Spacer preset='tiny' />
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.ok')} onClick={close} buttonType='primary' />
      </CustomModal.Footer>
    </CustomModal>
  )
}
