import { t } from 'i18next'
import { AssetType, Money } from '@interfaces'
import { getFormatLang, getLang } from './langs'

interface FormatLargeAmount {
  value: number
  unit: string
}
export const formatLargeAmount = (
  amount: number,
  isLargeAmount: boolean,
): FormatLargeAmount => {
  if (!isLargeAmount) {
    return { value: amount, unit: '' }
  }

  if (Math.abs(amount) >= 1.0e9) {
    return { value: amount / 1.0e9, unit: 'B' }
  } else if (Math.abs(amount) >= 1.0e6) {
    return { value: amount / 1.0e6, unit: 'M' }
  } else if (Math.abs(amount) >= 1.0e3) {
    return { value: amount / 1.0e3, unit: 'K' }
  } else {
    return { value: amount, unit: '' }
  }
}

export const formatMoney = (
  amount = 0,
  currency = 'USD',
  maximumFractionDigits = 2,
  locale = getFormatLang(),
  isLargeAmount = false,
): string => {
  const { value, unit } = formatLargeAmount(amount, isLargeAmount)
  return (
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits,
    }).format(value) + unit
  )
}

export const formatCurrency = (
  money: Money,
  extraDigitsValue = 2,
  locale = getFormatLang(),
): string => {
  return formatMoney(money?.amount, money?.currency, extraDigitsValue, locale)
}

export const formatCurrencyToSymbol = (currency: string): string => {
  return (0)
    .toLocaleString(getFormatLang(), {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
}

export const formatDate = (
  date: string,
  options?: Intl.DateTimeFormatOptions,
): string => {
  if (!date) {
    return null
  }

  const locale = getLang()

  return new Date(date).toLocaleString(locale, options)
}

export const formatDateShortWeekDay = (date: string): string => {
  return new Date(date).toLocaleDateString('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
}

export const formatDateLong = (date: string): string => {
  if (!date) {
    return null
  }

  const locale = getLang()

  return new Date(date).toLocaleString(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
}

export const formatEndDateRemaining = (today: Date, expDate: Date): string => {
  if (!expDate) {
    return null
  }
  const locale = getLang()
  const daysBetween = numOfDaysBetween(today, expDate)

  const dateText = expDate.toLocaleString(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })

  return daysBetween
    ? dateText.concat(` (${String(daysBetween)} ${t('common.days')})`)
    : dateText.concat(t('date.today'))
}

export const capitalize = (str: string): string => {
  if (!str) {
    return str
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const cleanType = (type: string): string => {
  const underscore = type.indexOf('_')
  return underscore !== -1 ? type.substring(0, underscore) : type
}

export const symbolPrice = (type: string | null): string => {
  const plus = ['SELL', 'DIVIDEND_SHARES', 'DEPOSIT', 'DIVIDEND_CASH']
  const minus = ['WITHDRAWAL', 'BUY']
  return !type
    ? ''
    : plus.includes(type)
    ? '+'
    : minus.includes(type)
    ? '-'
    : ''
}

export const formatNumber = (
  number = 0,
  minimumFractionDigits?: number,
  locale = getFormatLang(),
): string => {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits,
  }).format(number)
}

export const formatPercentage = (
  value: number,
  minimumFractionDigits?: number,
  locale = getFormatLang(),
): string => {
  return `${formatNumber(value, minimumFractionDigits, locale)}%`
}

export const extraDigits = 4

export const formatNumberIntl = (
  number = 0,
  options?: Intl.NumberFormatOptions,
  locale = getFormatLang(),
): string => {
  return new Intl.NumberFormat(locale, options || {}).format(number)
}

export const formatSeconds = (seconds = 0): string => {
  const minutes = ~~(seconds / 60)
  const secondsOutput = ~~(seconds % 60)
  const secondsString =
    secondsOutput.toString().length == 1
      ? `0${(secondsOutput % 60).toString()}`
      : secondsOutput.toString()
  return `${minutes}:${secondsString}`
}

export const maskEmail = (email: string): string => {
  const charactersToShow = 4
  if (!email || !email.includes('@')) return ''

  const hidden = '****'

  const [user, rest] = email.split('@')

  let start: string
  const ending = `@${rest}`

  if (user && user.length > charactersToShow) {
    start = `${hidden}${user
      .toString()
      .slice(user.length - charactersToShow, user.length)}`
  } else {
    start = `${hidden.slice(0, Math.ceil(user.length / 2))}${user
      .toString()
      .slice(Math.ceil(user.length / 2), user.length)}`
  }

  return `${start}${ending}`
}

export const randomId = (): string => {
  return Math.random().toString(36).substring(2, 36)
}

export const getFormattedShares = (
  executed: number,
  requested: number,
): string => {
  return executed === requested
    ? executed?.toString()
    : `${executed}/${requested}`
}

export const whatDecimalSeparator = (lang = getFormatLang()): string => {
  return Intl.NumberFormat(lang, {
    notation: 'standard',
  })
    .format(1.1)
    .substring(1, 2)
}

export const whatThousandsSeparator = (lang = getFormatLang()): string => {
  return Intl.NumberFormat(lang, {
    notation: 'standard',
  })
    .format(10000.1)
    .substring(2, 3)
}

export const sanitizeNumber = (
  number: string,
  thousandsSeparator = whatThousandsSeparator(),
  decimalSeparator = whatDecimalSeparator(),
): number => {
  return Number(
    parseFloat(
      number
        ?.replace(new RegExp(`\\${thousandsSeparator}`, 'g'), '')
        ?.replace(decimalSeparator, '.'),
    ),
  )
}

export const numOfDaysBetween = (
  earlierDate: Date,
  laterDate: Date,
): number => {
  const difference = laterDate.getTime() - earlierDate.getTime()
  return Math.ceil(difference / (1000 * 3600 * 24))
}

export const isLeapYear = (y: number): boolean =>
  !(y % 4) && (!(y % 400) || !!(y % 100))

export const getDaysPerMonth = (year: number): number[] => {
  return [
    31,
    isLeapYear(year) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ]
}

export const backEndFormat = 'yyyy-MM-dd'
export const frontEndFormat = 'dd/MM/yyyy'

export const shortenEnvironment = (env: string): 'dev' | 'pre' | 'pro' => {
  switch (env) {
    case 'development':
      return 'dev'
    case 'preproduction':
      return 'pre'
    case 'production':
      return 'pro'
    default:
      return 'dev'
  }
}

export const buildQueryParams = (params: { [key: string]: string }): string => {
  params = Object.keys(params)?.reduce((acc, i) => {
    return params[i]
      ? {
          ...acc,
          [i]: params[i],
        }
      : acc
  }, {})

  return Object.keys(params).reduce(
    (acc: string, item: string, idx: number) => {
      return `${acc}${idx === 0 ? '?' : '&'}${item}=${params[item]}`
    },
    '',
  )
}

export const promiseWrapper = <T>(fn: () => T): Promise<T> => {
  return new Promise((resolve) => resolve(fn()))
}

export const isSavingAsset = (type: AssetType): boolean => {
  return type === AssetType.savingPlan
}

export const formatNameInTwoCharacters = (name: string): string => {
  const splitted = name?.split(' ')
  return `${(splitted[0] || '').charAt(0)}${(splitted[1] || '').charAt(0)}`
}

export const isDateValid = (value: string): boolean => {
  return !isNaN(Date.parse(value))
}

export const createUTCDateFromISOString = (ISODate: string): Date => {
  const dateParts = ISODate?.slice(0, 19)?.split('T')
  const dateSegments = dateParts?.[0]?.split('-')
  const timeSegments = dateParts?.[1]?.split(':')

  if (dateSegments?.length !== 3) return null

  const year = Number(dateSegments?.[0])
  const month = Number(dateSegments?.[1]) - 1 // Note: JavaScript counts months from 0
  const date = Number(dateSegments?.[2])

  const hours = timeSegments?.[0] ? Number(timeSegments[0]) : 0
  const minutes = timeSegments?.[1] ? Number(timeSegments[1]) : 0
  const seconds = timeSegments?.[2] ? Number(timeSegments[2]) : 0

  return new Date(Date.UTC(year, month, date, hours, minutes, seconds))
}
