import './header.styles.scss'
import { HeaderProps } from './header.props'
import Langs from '../langs/langs'
import { ReactComponent as MoonIcon } from '@assets/svg/moon.svg'
import { ReactComponent as SunIcon } from '@assets/svg/sun.svg'
import { fetchUserProfile } from '@screens/profile/thunk'
import { useSelector, useDispatch } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { formatMoney } from '@utils/helpers'
import { changeTheme, getTheme } from '@utils/theme'
import { OnboardingStatus } from '@interfaces'
import { MoneyOperations } from '@features'
import { useAddFundsAndCashout, useTranslation } from '@hooks'
import { Button, Show, SideMenu, Spacer, Text } from '@components'
import { doLogout } from '@screens/login/thunk'
import { useLocation } from 'react-router-dom'
import {
  fetchOnboardingStatus,
  fetchCurrenciesExchanges,
  resetStoreState,
  fetchUserBalances,
  RootState,
} from '@store'
import {
  CompleteProfileQRModal,
  FxRateModal,
  InactiveSessionModal,
} from '@shared/modals'
import { AddCircle, CurrencyExchange, WalletIcon } from '@assets/icons'
import { MultiAssetsSearch } from 'features/search/components/multiAssetsSearch/multiAssetsSearch'
import { useInitialSearchLoad } from 'hooks/useInitialSearchLoad'
import MainLogo from 'components/mainLogo/mainLogo'
import { setSelectedCurrency } from 'features/exchangeBalance/thunk'

const timer = 300 // 5 min with the warning
const timeToIDLE = 1800 // 30 min until we show the warning
const timeoutInMilliseconds = timeToIDLE * 1000

export function Header({ simple }: HeaderProps): JSX.Element {
  const params = useLocation().search
  const { t } = useTranslation()
  const headerStyle = 'header header--'.concat(simple ? 'simple' : 'menu')
  const dispatch = useDispatch()
  const [theme, setTheme] = useState(getTheme())

  const [inactive, setInactive] = useState(false)
  const [timerTo, setTimerTo] = useState(timer)

  const moneyOperationsState = useSelector(
    (state: RootState) => state.moneyOperations,
  )

  const changingTheme = (t: string): void => {
    changeTheme(t)
    setTheme(getTheme())
  }

  useInitialSearchLoad()

  const [modalOnboarding, setModalOnboarding] = useState(false)
  const [modalForexFees, setModalForexFees] = useState(false)

  const { cashAvailable } = useSelector((state: RootState) => state.userBalance)
  const { profile, onboardingStatus, login, userSettings } = useSelector(
    (state: RootState) => state,
  )

  const onBoardingCompleted =
    onboardingStatus.onboarding?.onboardingStatus ==
    OnboardingStatus.onboardingCompleted

  const realCashAvailable = formatMoney(
    cashAvailable?.amount || 0,
    cashAvailable?.currency || 'USD',
  )

  const loadCurrenciesExchanges = useCallback(() => {
    if (login.token && cashAvailable?.currency) {
      dispatch(fetchCurrenciesExchanges({ target: cashAvailable?.currency }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login.token, cashAvailable?.currency])

  useEffect(() => {
    if (!login.token) return

    loadCurrenciesExchanges()
  }, [loadCurrenciesExchanges, login.token])

  const logOut = (): void => {
    dispatch(doLogout())
    dispatch(resetStoreState())
  }

  const closeInactive = (): void => {
    setInactive(false)
    setTimerTo(timer)
  }

  useEffect(() => {
    if (!inactive) return
    if (timerTo === 0) {
      dispatch(doLogout())
      dispatch(resetStoreState())
      return
    }

    const interval = setInterval(() => {
      setTimerTo((timerTo) => timerTo - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [inactive, timerTo, dispatch, params])

  useEffect(() => {
    let timeoutId: number
    const cleaner = (): void => {
      window.clearTimeout(timeoutId)
      document.removeEventListener('mousemove', resetTimer)
      document.removeEventListener('mousedown', resetTimer)
      document.removeEventListener('keypress', resetTimer)
      document.removeEventListener('touchmove', resetTimer)
    }

    const startTimer = (): void => {
      timeoutId = window.setTimeout(doInactive, timeoutInMilliseconds)
    }

    const doInactive = (): (() => void) => {
      let settingInactive = true
      if (settingInactive) {
        setInactive(true)
      }
      return () => {
        settingInactive = false
      }
    }

    function resetTimer(): void {
      window.clearTimeout(timeoutId)
      startTimer()
    }

    if (login.id && !timeoutId) {
      document.addEventListener('mousemove', resetTimer)
      document.addEventListener('mousedown', resetTimer)
      document.addEventListener('keypress', resetTimer)
      document.addEventListener('touchmove', resetTimer)
      document.addEventListener('scroll', resetTimer)

      startTimer()
    }

    return () => cleaner()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // This will run on every page change as the header component is re-rendered
  useEffect(() => {
    if (!login.token) return

    dispatch(fetchUserBalances())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!login.token) return

    if (!profile.userProfile.customerId && !profile.loading) {
      dispatch(fetchUserProfile())
    }

    if (
      !onboardingStatus?.onboarding?.onboardingStatus &&
      !onboardingStatus?.isLoading
    ) {
      dispatch(fetchOnboardingStatus())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login.token])

  const { addFunds } = useAddFundsAndCashout({ origin: 'Header' })

  return (
    <>
      {inactive && (
        <InactiveSessionModal
          closeInactive={closeInactive}
          logOut={logOut}
          timerTo={timerTo}
        />
      )}
      <header className={headerStyle}>
        {!simple && (
          <div className='header__wrapper'>
            <div className='header__wrapper__menuLogo w-20'>
              <SideMenu />
              <MainLogo className='header__logo' />
            </div>
            <div className='header__wrapper__search-wrapper pr-2 py-1 w-50'>
              <div className='header__wrapper__search-box'>
                <MultiAssetsSearch />
              </div>
            </div>
            <div className='d-flex w-25 justify-end'>
              <Button
                autoWidth={true}
                buttonType='menu'
                onClick={() => setModalForexFees(true)}
                text={t('forexFees.headerTitle')}
                textPreset='paragraph2'
                left={<CurrencyExchange size={24} color='iconNeutralMedium' />}
                size='small'
              />
              <Spacer preset='tiny' />
              <Button
                testId='wallet-button'
                autoWidth={true}
                buttonType='menu'
                onClick={
                  onBoardingCompleted
                    ? () => {
                        dispatch(setSelectedCurrency(null))
                        addFunds()
                      }
                    : () => setModalOnboarding(true)
                }
                left={<WalletIcon color='iconNeutralMedium' />}
                right={<AddCircle color='iconPrimaryInitial' />}
                size='small'
                content={
                  <Text
                    text={realCashAvailable}
                    preset='paragraph2'
                    hiddenText={userSettings?.hideAmounts}
                  />
                }
              />
            </div>
          </div>
        )}
        {simple && (
          <div className='header__basic'>
            <MainLogo className='header__logo' />
            <div className='header__basic__right'>
              <Langs />
              <div className='header__wrapper__theme'>
                {['light', 'default'].includes(theme) ? (
                  <MoonIcon
                    className='cursor-pointer'
                    onClick={() => changingTheme('dark')}
                  />
                ) : (
                  <SunIcon
                    className='cursor-pointer'
                    onClick={() => changingTheme('light')}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {!!moneyOperationsState?.stepsHistory?.length &&
          moneyOperationsState?.currentStep && <MoneyOperations />}
        {modalOnboarding && (
          <CompleteProfileQRModal close={() => setModalOnboarding(false)} />
        )}
        <Show when={modalForexFees}>
          <FxRateModal close={() => setModalForexFees(false)}></FxRateModal>
        </Show>
      </header>
    </>
  )
}
