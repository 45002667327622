import { TimeInForceOption, TimeInForceType } from '@interfaces'
import { addDays, addMonths, addYears } from 'date-fns'

export const timeInForceModalConfig = (
  locale: string,
): Array<TimeInForceOption<Date | null>> => {
  const today = new Date().setHours(0, 0, 0)
  const toLongStyle = (date: Date, locale: string): string => {
    return date?.toLocaleDateString(locale, {
      dateStyle: 'long',
    })
  }

  const options: Array<TimeInForceOption<Date | null>> = []
  const customUntilDayOpt: TimeInForceOption<null> = {
    title: 'stopLossTakeProfit.timeInForce.options.goodTilDay',
    description: 'stopLossTakeProfit.timeInForce.options.tildayDescription',
    value: null,
    type: TimeInForceType.TIL_DAY,
  }
  const customUntilCancelOpt: TimeInForceOption<null> = {
    title: 'stopLossTakeProfit.timeInForce.options.goodTilCancel',
    description: 'stopLossTakeProfit.timeInForce.options.tilCancelDescription',
    value: null,
    type: TimeInForceType.TIL_CANCEL,
  }

  const translations = {
    days: 'stopLossTakeProfit.timeInForce.options.days',
    months: 'stopLossTakeProfit.timeInForce.options.months',
    year: 'stopLossTakeProfit.timeInForce.options.year',
  }

  const datesConfiguration = [
    {
      quantity: 30,
      periodicity: translations.days,
      dateValue: addDays(today, 30),
    },
    {
      quantity: 90,
      periodicity: translations.days,
      dateValue: addDays(today, 90),
    },
    {
      quantity: 6,
      periodicity: translations.months,
      dateValue: addMonths(today, 6),
    },
    {
      quantity: 1,
      periodicity: translations.year,
      dateValue: addYears(today, 1),
    },
  ]

  datesConfiguration.forEach((config) => {
    options.push({
      title: `${config.quantity} ${config.periodicity}`,
      description: toLongStyle(config.dateValue, locale),
      value: config.dateValue,
      type: TimeInForceType.CUSTOM,
    })
  })
  options.unshift(customUntilDayOpt)
  options.push(customUntilCancelOpt)
  return options
}
