import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const DollarIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.025 21V18.85C10.1417 18.65 9.37933 18.2667 8.738 17.7C8.096 17.1333 7.625 16.3333 7.325 15.3L9.175 14.55C9.425 15.35 9.796 15.9583 10.288 16.375C10.7793 16.7917 11.425 17 12.225 17C12.9083 17 13.4877 16.846 13.963 16.538C14.4377 16.2293 14.675 15.75 14.675 15.1C14.675 14.5167 14.4917 14.054 14.125 13.712C13.7583 13.3707 12.9083 12.9833 11.575 12.55C10.1417 12.1 9.15833 11.5627 8.625 10.938C8.09167 10.3127 7.825 9.55 7.825 8.65C7.825 7.56667 8.175 6.725 8.875 6.125C9.575 5.525 10.2917 5.18333 11.025 5.1V3H13.025V5.1C13.8583 5.23333 14.546 5.53733 15.088 6.012C15.6293 6.48733 16.025 7.06667 16.275 7.75L14.425 8.55C14.225 8.01667 13.9417 7.61667 13.575 7.35C13.2083 7.08333 12.7083 6.95 12.075 6.95C11.3417 6.95 10.7833 7.11267 10.4 7.438C10.0167 7.76267 9.825 8.16667 9.825 8.65C9.825 9.2 10.075 9.63333 10.575 9.95C11.075 10.2667 11.9417 10.6 13.175 10.95C14.325 11.2833 15.196 11.8123 15.788 12.537C16.3793 13.2623 16.675 14.1 16.675 15.05C16.675 16.2333 16.325 17.1333 15.625 17.75C14.925 18.3667 14.0583 18.75 13.025 18.9V21H11.025Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
