import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const RemoveIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 25 24'
      fill='none'
    >
      <path
        d='M5.6665 13V11H19.6665V13H5.6665Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
