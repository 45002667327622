export interface TimeInForceOption<K> {
  title: string
  description: string
  value: K
  type?: TimeInForceType
}

export enum TimeInForceType {
  TIL_DAY = 'TIL_DAY',
  TIL_CANCEL = 'TIL_CANCEL',
  CUSTOM = 'CUSTOM',
  CALENDAR_CUSTOM = 'CALENDAR_CUSTOM',
}
