import { Button, InfoCards } from 'components'
import { useBuyAndSellActions } from 'hooks/useBuyAndSellActions'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setMarketType } from 'store'

export const CompanyRightPanel = (): JSX.Element => {
  const dispatch = useDispatch()
  const { companyInfo, companyPosition, companyStocks } = useSelector(
    (state: RootState) => state,
  )
  const { buy, sell } = useBuyAndSellActions()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(setMarketType(companyStocks?.type))
  }, [dispatch, companyStocks?.type])
  return (
    <div className='company-details__widget__info'>
      <div className={`company-details__widget__info__cards mb-2`}>
        <InfoCards
          currentValue={companyStocks?.price}
          data={companyInfo?.data}
          companyPosition={companyPosition.id ? companyPosition : null}
        />
      </div>
      <div className='company-details__after-widget__button buttons__cards'>
        <div className='w-100 pr-1'>
          <Button
            text={`${t('home.sell')}`}
            loading={false}
            disabled={!companyPosition?.id}
            type='button'
            buttonType='ghost'
            size='big'
            onClick={() => {
              if (companyPosition?.id) {
                sell({
                  id: companyStocks?.id,
                  type: companyStocks?.type,
                  origin,
                })
              }
            }}
          />
        </div>
        <div className='w-100 pl-1'>
          <Button
            text={`${t('home.buy')}`}
            loading={false}
            type='button'
            disabled={!companyStocks?.isEnabled}
            size='big'
            onClick={() => {
              buy({
                id: companyStocks?.id,
                type: companyStocks?.type,
                origin,
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}
