import { SuccessIcon } from '@assets/icons'
import { formatCurrency } from '@utils/helpers'
import { AlertMessage, Card, Spacer, Text } from 'components'
import { SLTPSummaryCard, SLTPSummaryRowProps } from 'components/summaryCard'
import { t } from 'i18next'
import { useMemo } from 'react'
import { SLTPSummaryOrderDetailProps } from './SLTPSummaryOrderDetail.props'
import { getSLTPOrderConfig } from '@utils/stopLossTakeProfitOrderConfig'

export function SLTPSummaryOrderDetail({
  header,
  ordersData,
  errorsData,
}: SLTPSummaryOrderDetailProps): JSX.Element {
  const firstOrder = ordersData?.[0]
  const SLTPOrderRows: SLTPSummaryRowProps[] = useMemo(() => {
    return ordersData?.map((orderData) => {
      const config = getSLTPOrderConfig(
        orderData.condition,
        orderData.asset.currency,
      )

      return {
        firstLabelChildren: (
          <Text
            preset='paragraph2'
            text={t(config.name)}
            color={config.color}
          ></Text>
        ),
        firstValue: t('buySellFlow.estimatedPnlAbbr'),
        firstValueChildren: (
          <Text
            preset='paragraph2'
            text={`${
              formatCurrency({
                amount: orderData?.calculations?.estimatedPnL?.amount,
                currency: orderData?.calculations?.estimatedPnL?.currency,
              }) ?? ''
            }`}
            color={config.color}
            inline={true}
          />
        ),
        secondLabel: `${orderData.shares} ${orderData.asset.ticker}`,
        secondValue: <Text preset='paragraph2' text={config.value} />,
      }
    })
  }, [ordersData])

  return (
    <>
      <div className='d-flex d-flex-col align-center'>
        <SuccessIcon
          size={64}
          color='positive'
          secondaryColor='iconNeutralStrongInverted'
        />
        <Spacer preset='small' />
        <Text preset='paragraph5' text={header} align='center' />
        <Spacer preset='medium' />
      </div>
      <Card padding='smaller' width='full'>
        <SLTPSummaryCard
          id={`SLTPOrderData-${firstOrder?.id}`}
          data={SLTPOrderRows}
        />
      </Card>

      {!!errorsData?.length && (
        <AlertMessage
          message={t('buySellFlow.confirmationModal.errorOrders')}
        />
      )}
    </>
  )
}
