import { TextColorsEnum } from './textColorPresets'

export enum IconColorsEnum {
  iconNeutralStrong = 'iconNeutralStrong',
  iconNeutralMedium = 'iconNeutralMedium',
  iconNeutralSoft = 'iconNeutralSoft',
  actionPrimaryInitial = 'actionPrimaryInitial',
  transparent = 'transparent',
  iconPrimaryInitial = 'iconPrimaryInitial',
  iconNeutralStrongInverted = 'iconNeutralStrongInverted',
}

export const AllIconColorEnums = { ...TextColorsEnum, ...IconColorsEnum }

type IconColorPresets = keyof typeof AllIconColorEnums

export interface UIIcon {
  size?: number
}

export interface UIIconColorProps {
  color?: IconColorPresets
}
export interface UISecondaryColorProp extends UIIconColorProps {
  secondaryColor?: IconColorPresets
}
export interface UITertiaryColorProp extends UISecondaryColorProp {
  tertiaryColor?: IconColorPresets
}

export interface UIQuaternaryColorProp extends UITertiaryColorProp {
  quaternaryColor?: IconColorPresets
}

export const getIconColorSVGFill = (color: IconColorPresets): string => {
  switch (color) {
    case 'primary':
      return `var(--iconLabelPrimaryInitial)`
    case 'secondary':
      return `var(--textNeutralMedium)`
    case 'tertiary':
      return `var(--textNeutralSoft)`
    case 'positive':
      return `var(--textPositiveInitial)`
    case 'negative':
      return `var(--textNegativeInitial)`
    case 'iconPrimaryInitial':
      return `var(--iconPrimaryInitial)`
    case 'iconPositiveInitial':
      return `var(--iconPositiveInitial)`
    case 'warning':
      return `var(--surfaceWarningInitial)`
    case 'surfaceCanvasPrimaryInitial':
      return `var(--surfaceCanvasPrimaryInitial)`
    case 'surfacePositiveSubtleInitial':
      return `var(--surfacePositiveSubtleInitial)`
    case 'labelNavigationTitle':
      return `var(--textNeutralSoft)`
    case 'iconNeutralStrong':
      return `var(--iconNeutralStrong)`
    case 'iconNeutralMedium':
      return `var(--iconNeutralMedium)`
    case 'iconNeutralSoft':
      return `var(--iconNeutralSoft)`
    case 'actionPrimaryInitial':
      return `var(--actionPrimaryInitial)`
    case 'iconNeutralStrongInverted':
      return `var(--iconNeutralStrongInverted)`
    case 'transparent':
      return ``
    default:
      return `var(--iconNeutralSoft)`
  }
}
