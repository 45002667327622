import './moneyOperations.styles.scss'
import './../buy/buy.styles.scss'
import { isNil } from 'lodash'
import {
  SelectBankAccount,
  Summary,
  SelectAmount,
  TopUpLegalReminder,
  TopUpTransferInfo,
  TruelayerPermissions,
  SelectNewBank,
  CompletedCashout,
  CashoutTransferEmail,
  CashoutTransferInstructions,
  BankConnectionResult,
  PaymentReview,
} from './steps'
import { MoneyOperationsSteps } from './moneyOperations.props'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  RootState,
  clearLimitOrderDetails,
  clearMarketOrderDetails,
  clearOrderOperationType,
  clearOrderType,
  fetchUserBalances,
  resetOrder,
} from '@store'
import { clearSepaConditions } from './banktransfer/thunk'
import {
  ToAmount,
  PaymentMethod,
  OperationTypeEnum,
  BankAggregationItem,
  MoneyOperationTypeEnum,
  CardFlowType,
  OperationConfirmationStatus,
  PaymentMethodTypeEnum,
} from '@interfaces'
import { useTranslation } from 'react-i18next'
import { FailedCashoutModal, PaymentTypeSelector } from '@shared/modals'
import { CardPaymentSummary } from './steps/cardPaymentSummary'
import {
  clearCreditCardPayment,
  clearRecurringPaymentOptions,
  clearTopUpInfo,
  clearTopUpType,
  setCardFlowType,
} from './topup/thunk'
import {
  clearMoneyOperationsState,
  goToPrevStep,
  setMoneyOperationStep,
  setOperationType,
} from './thunk'
import { CompletedCardPayment } from './steps/completedCardPayment'
import { CustomModal } from '@components'
import { clearCashoutResult, fetchCashout } from './cashout/thunk'
import { AssetOperation } from './buySellOperation/assetOperation'
import { AssetOperationReview, AssetOperationSummary } from 'features/buy/steps'
import { fetchInvestments } from '@store/commonReducers/portfolio/thunk'
import { clearLimitOrdersForm } from '@store/commonReducers/limitOrderPriceLimits/thunk'
import {
  fetchBrokerTier,
  fetchBrokerTierUserData,
} from '@store/commonReducers/brokerTier'
import ManualTransferNewAccount from './manualTransferNewAccount/manualTransferNewAccount'
import { clearManualTransfer } from './manualTransferNewAccount/thunk'
import ManualTransferWithdraw from './manualTransferWithdraw/manualTransferWithdraw'
import { ManualTransferWithdrawSuccess } from './banktransfer/steps/manualTransferWithdrawSuccess'
import { clearSelectedCurrency } from 'features/exchangeBalance/thunk'
import { companyInvestmentsHasBeenUpdated } from '@screens/company/companyPosition/thunk'

export function MoneyOperations(): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onCloseReset = (): void => {
    // Clearing state
    dispatch(clearSepaConditions())
    dispatch(clearCreditCardPayment())
    dispatch(clearRecurringPaymentOptions())
    dispatch(clearTopUpInfo())
    dispatch(clearTopUpType())
    dispatch(clearMoneyOperationsState())
    dispatch(clearCashoutResult())
    dispatch(clearMarketOrderDetails())
    dispatch(clearLimitOrderDetails())
    dispatch(clearOrderType())
    dispatch(clearOrderOperationType())
    dispatch(resetOrder())
    dispatch(clearLimitOrdersForm())
    dispatch(clearManualTransfer())
    // Updating sensitive data
    dispatch(fetchInvestments())
    dispatch(fetchBrokerTier())
    dispatch(fetchBrokerTierUserData())
    dispatch(fetchUserBalances())
    dispatch(clearSelectedCurrency())
    dispatch(companyInvestmentsHasBeenUpdated())
  }

  const [bankTo, setBankTo] = useState({} as BankAggregationItem)

  const { addMoney, paymentMethods, userBalance, cashout } = useSelector(
    (state: RootState) => state,
  )

  const [toTop, setToTop] = useState({
    paymentMethod: {} as PaymentMethod,
    amount: 0,
    operationType: OperationTypeEnum.addMoney,
    currency: userBalance?.cashAvailable?.currency,
  } as ToAmount)

  const moneyOperationsState = useSelector(
    (state: RootState) => state.moneyOperations,
  )

  const operationType = moneyOperationsState?.operationType

  useEffect(() => {
    if (
      !paymentMethods?.loading &&
      paymentMethods?.paymentMethods?.length > 0
    ) {
      const defaultPaymentMethod: PaymentMethod =
        paymentMethods?.paymentMethods?.find((pm) => pm?.default === true)

      setToTop({
        ...toTop,
        paymentMethod: defaultPaymentMethod,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods?.loading, paymentMethods?.paymentMethods])

  const setBank = (bankSelected: BankAggregationItem): void => {
    setBankTo(bankSelected)
    dispatch(setMoneyOperationStep(MoneyOperationsSteps.truelayerPermissions))
  }

  // When the cashout is completed we need to show completed cashout screen
  useEffect(() => {
    if (!addMoney?.loading && !cashout.loading && cashout.isSuccess) {
      dispatch(setMoneyOperationStep(MoneyOperationsSteps.completedCashout))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashout.isSuccess, cashout.loading])

  useEffect(() => {
    if (!cashout.loading && cashout.error) {
      dispatch(setMoneyOperationStep(MoneyOperationsSteps.failedCashout))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashout.isSuccess, cashout.loading])

  const [accountSelected, setAccountSelected] = useState<{
    accountId: string
    amount: number
    currency: string
    redirectUrl: string // only for top up, no needed for cashout
  }>()

  const goToTopUpConfirmation = (): void => {
    dispatch(setMoneyOperationStep(MoneyOperationsSteps.paymentConfirmation))
  }

  const setAccount = (summary: {
    accountId: string
    amount: number
    currency: string
    redirectUrl: string
  }): void => {
    setAccountSelected(summary)

    if (operationType === MoneyOperationTypeEnum.addMoney) {
      goToTopUpConfirmation()
    }

    if (
      operationType === MoneyOperationTypeEnum.cashout &&
      !cashout.loading &&
      isNil(cashout.isSuccess) // Check that cashout has not been performed yet
    ) {
      dispatch(
        fetchCashout({
          accountId: summary?.accountId,
          amount: summary?.amount,
          currency: summary?.currency,
        }),
      )
    }
  }

  const onBankTransfer = (): void => {
    dispatch(
      setMoneyOperationStep(
        operationType === MoneyOperationTypeEnum.cashout
          ? MoneyOperationsSteps.manualBankWithdraw
          : MoneyOperationsSteps.manualBankTransfer,
      ),
    )
  }
  // MoneyOperationsSteps.topUpLegalReminder,

  const handlePrevStep = (): void => {
    dispatch(clearManualTransfer())
    dispatch(goToPrevStep())
  }

  const renderSteps = (step: MoneyOperationsSteps): JSX.Element => {
    switch (step) {
      // Top up payment amount selection

      case MoneyOperationsSteps.selectAmount:
        return (
          <SelectAmount
            type={operationType}
            nextStep={(amount, paymentMethodType) => {
              setToTop({
                ...toTop,
                amount: amount,
              })
              if (paymentMethodType === PaymentMethodTypeEnum.MANUAL_TRANSFER) {
                if (operationType === MoneyOperationTypeEnum.addMoney) {
                  dispatch(
                    setMoneyOperationStep(
                      MoneyOperationsSteps.topUpTransferInfo,
                    ),
                  )
                } else {
                  dispatch(
                    setMoneyOperationStep(
                      MoneyOperationsSteps.manualBankWithdrawSuccess,
                    ),
                  )
                }
              }

              // No other payment method type is supported for now
              // The only exception is a bank transfer but it is handled with the onTransfer props below
            }}
            values={toTop}
            onTransfer={() => {
              dispatch(
                setMoneyOperationStep(MoneyOperationsSteps.selectPaymentMethod),
              )
            }}
            onCancel={onCloseReset}
          />
        )

      // Bank account selection

      case MoneyOperationsSteps.selectBankAccount:
        return (
          <SelectBankAccount
            prevStep={handlePrevStep}
            nextStep={setAccount}
            type={operationType}
            values={toTop}
            onClose={onCloseReset}
          />
        )

      // Select payment method (both for top up and cash out)

      case MoneyOperationsSteps.selectPaymentMethod:
        return (
          <PaymentTypeSelector
            type={operationType}
            title={
              operationType === MoneyOperationTypeEnum.cashout
                ? t('withdrawMoney.header')
                : t('addMoney.selectPaymentMethod')
            }
            prevStep={handlePrevStep}
            onConnection={() =>
              dispatch(
                setMoneyOperationStep(MoneyOperationsSteps.selectNewBank),
              )
            }
            onTransfer={onBankTransfer}
            onCreditCard={() => {
              dispatch(setCardFlowType(CardFlowType.NEWCARD))
              dispatch(
                setMoneyOperationStep(
                  MoneyOperationsSteps.newCreditCardPayment,
                ),
              )
            }}
            onClose={onCloseReset}
          />
        )

      // Top up cases

      case MoneyOperationsSteps.topUpLegalReminder:
        return (
          <TopUpLegalReminder
            prevStep={handlePrevStep}
            nextStep={() =>
              dispatch(
                setMoneyOperationStep(MoneyOperationsSteps.topUpTransferInfo),
              )
            }
            onClose={onCloseReset}
          />
        )
      case MoneyOperationsSteps.topUpTransferInfo:
        return (
          <TopUpTransferInfo prevStep={handlePrevStep} onClose={onCloseReset} />
        )

      // Cashout cases

      case MoneyOperationsSteps.cashoutTransferInstructions:
        return (
          <CashoutTransferInstructions
            prevStep={handlePrevStep}
            nextStep={() =>
              dispatch(
                setMoneyOperationStep(
                  MoneyOperationsSteps.cashoutTransferEmail,
                ),
              )
            }
            onClose={onCloseReset}
          />
        )
      case MoneyOperationsSteps.cashoutTransferEmail:
        return (
          <CashoutTransferEmail
            values={toTop}
            prevStep={() =>
              dispatch(
                setMoneyOperationStep(
                  MoneyOperationsSteps.cashoutTransferInstructions,
                ),
              )
            }
            onClose={onCloseReset}
          />
        )
      case MoneyOperationsSteps.completedCashout:
        return (
          <CompletedCashout values={accountSelected} onClose={onCloseReset} />
        )
      case MoneyOperationsSteps.failedCashout:
        return <FailedCashoutModal onCloseReset={onCloseReset} />

      case MoneyOperationsSteps.paymentConfirmation:
        return (
          <Summary
            prevStep={() =>
              dispatch(
                setMoneyOperationStep(MoneyOperationsSteps.selectBankAccount),
              )
            }
            nextStep={() =>
              dispatch(
                setMoneyOperationStep(MoneyOperationsSteps.paymentSubmitted),
              )
            }
            values={accountSelected}
            show
            onClose={onCloseReset}
          />
        )
      case MoneyOperationsSteps.cardPaymentSummary:
        return (
          <CardPaymentSummary
            toTop={toTop}
            onClose={onCloseReset}
            prevStep={handlePrevStep} // As the user can get here from different paths, this previous step must be dynamic
          />
        )
      case MoneyOperationsSteps.paymentSubmitted:
        return (
          <PaymentReview
            prevStep={handlePrevStep}
            show
            onClose={onCloseReset}
          />
        )
      case MoneyOperationsSteps.truelayerPermissions:
        return (
          <TruelayerPermissions
            prevStep={handlePrevStep}
            bankSelected={bankTo}
            onClose={onCloseReset}
          />
        )
      case MoneyOperationsSteps.bankConnectionResult:
        return (
          <BankConnectionResult
            show
            type={OperationConfirmationStatus.success}
            onClose={() =>
              dispatch(setMoneyOperationStep(MoneyOperationsSteps.selectAmount))
            }
          />
        )
      case MoneyOperationsSteps.selectNewBank:
        return (
          <SelectNewBank
            prevStep={handlePrevStep}
            nextStep={setBank}
            onClose={onCloseReset}
          />
        )
      case MoneyOperationsSteps.completedCardPayment:
        return <CompletedCardPayment onClose={onCloseReset} />

      // Asset operation cases
      case MoneyOperationsSteps.operateAsset:
        return (
          <AssetOperation
            close={onCloseReset}
            onNext={() => {
              dispatch(
                setMoneyOperationStep(
                  MoneyOperationsSteps.reviewAssetOperation,
                ),
              )
            }}
          />
        )
      case MoneyOperationsSteps.reviewAssetOperation:
        return (
          <AssetOperationReview
            nextStep={() => {
              dispatch(
                setMoneyOperationStep(
                  MoneyOperationsSteps.assetOperationSummary,
                ),
              )
            }}
            onTopUp={() => {
              dispatch(setOperationType(MoneyOperationTypeEnum.addMoney))
              dispatch(setMoneyOperationStep(MoneyOperationsSteps.selectAmount))
            }}
            prevStep={handlePrevStep}
            onClose={onCloseReset}
          />
        )
      case MoneyOperationsSteps.assetOperationSummary:
        return <AssetOperationSummary onClose={onCloseReset} />
      case MoneyOperationsSteps.manualBankTransfer:
        return (
          <ManualTransferNewAccount
            prevStep={handlePrevStep}
            nextStep={() => {
              dispatch(
                setMoneyOperationStep(MoneyOperationsSteps.topUpTransferInfo),
              )
            }}
            onClose={onCloseReset}
          />
        )
      case MoneyOperationsSteps.manualBankWithdraw:
        return (
          <ManualTransferWithdraw
            prevStep={handlePrevStep}
            nextStep={() => {
              dispatch(
                setMoneyOperationStep(
                  MoneyOperationsSteps.manualBankWithdrawSuccess,
                ),
              )
            }}
            onClose={onCloseReset}
          />
        )
      case MoneyOperationsSteps.manualBankWithdrawSuccess:
        return (
          <ManualTransferWithdrawSuccess
            prevStep={handlePrevStep}
            onClose={onCloseReset}
          />
        )

      default:
        return null
    }
  }

  return (
    <CustomModal
      placeOn='right'
      show={!!moneyOperationsState?.currentStep}
      height='fullHeight'
      size='big'
      onClose={onCloseReset}
      dismissable={true}
    >
      {renderSteps(moneyOperationsState?.currentStep)}
    </CustomModal>
  )
}
