import { BasicLink, Button, CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'
import { isStopLossOrder } from '@utils/isStopLossOrder'
import { isTakeProfitOrder } from '@utils/isTakeProfitOrder'
import { BuySellTransaction, OrderTransaction } from 'interfaces'

const cancelOrderModalConfig: Record<
  string,
  { header: string; content: string }
> = {
  limit: {
    header: 'transactions.cancelLimitOrder',
    content: 'transactions.confirmCancelLimitOrder',
  },
  stopLoss: {
    header: 'transactions.cancelStopLossOrder',
    content: 'transactions.confirmCancelStopLossOrder',
  },
  takeProfit: {
    header: 'transactions.cancelTakeProfitOrder',
    content: 'transactions.confirmCancelTakeProfitOrder',
  },
}

export const CancelOrderModal = ({
  transaction,
  handleCancelOrder,
  close,
}: {
  transaction: OrderTransaction | BuySellTransaction
  handleCancelOrder: () => void
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  const key = isStopLossOrder(transaction?.condition)
    ? 'stopLoss'
    : isTakeProfitOrder(transaction?.condition)
    ? 'takeProfit'
    : 'limit'

  return (
    <CustomModal show={true} theme='lighter' size='small'>
      <CustomModal.Header text={t(cancelOrderModalConfig[key].header)} />
      <CustomModal.Content>
        <Spacer preset='tiny' />
        <Text
          preset='paragraph1'
          text={t(cancelOrderModalConfig[key].content)}
          align='center'
        />
        <Spacer preset='tiny' />
      </CustomModal.Content>
      <CustomModal.Footer>
        <div className='d-flex d-flex-col w-100'>
          <Button
            text={t('transactions.confirmCancelBtn')}
            onClick={handleCancelOrder}
            buttonType='negative'
          />
          <BasicLink
            text={t('common.no')}
            type='action'
            className='w-100 mt-3'
            onClick={close}
          />
        </div>
      </CustomModal.Footer>
    </CustomModal>
  )
}
