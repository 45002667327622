import { useTranslation } from '@hooks'
import { Spacer, Text } from '@components'
import MainLogo from 'components/mainLogo/mainLogo'
import ExampleTrailingStopLossOne from '../../shared/exampleTrailingStopLossOne'
import ExampleTrailingStopLossTwo from '../../shared/exampleTrailingStopLossTwo'
import ExampleTrailingStopLossThree from '../../shared/exampleTrailingStopLossThree'

export const TrailingStopLossInfoContent = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className='d-flex d-flex-col align-start'>
      <div className='dividerInfo mt-2 pb-2 text-left'>
        <Text
          preset='bodyRegular'
          text={t('stopLossTakeProfit.trailingStopLossInfo.description')}
        />
      </div>
      <div className='mt-2 text-left'>
        <Text
          preset='subtitle3'
          color='secondary'
          text={t('stopLossTakeProfit.trailingStopLossInfo.example')}
        />

        <div className='mt-1 mb-1'>
          <Text
            preset='bodyRegular'
            color='secondary'
            text={t('stopLossTakeProfit.trailingStopLossInfo.explanationOne')}
          />
        </div>
      </div>
      <div className='mt-4 paragraph2'>
        <ExampleTrailingStopLossOne
          currentPriceText={t(
            'stopLossTakeProfit.trailingStopLossInfo.currentPriceText',
          )}
          buyText={t(
            'stopLossTakeProfit.trailingStopLossInfo.trailingDistance',
          )}
        />
      </div>
      <div className='mt-3 text-left'>
        <Text
          preset='bodyRegular'
          color='secondary'
          text={t('stopLossTakeProfit.trailingStopLossInfo.explanationTwo')}
        />
      </div>
      <div className='mt-4 paragraph2'>
        <ExampleTrailingStopLossTwo
          currentPriceText={t(
            'stopLossTakeProfit.trailingStopLossInfo.priceIncrease',
          )}
          stopLossText={t(
            'stopLossTakeProfit.trailingStopLossInfo.stopLossPriceAdjusts',
          )}
        />
      </div>
      <div className='mt-4 pb-2 text-left '>
        <Text
          preset='bodyRegular'
          color='secondary'
          text={t('stopLossTakeProfit.trailingStopLossInfo.explanationThree')}
        />
      </div>
      <div className='mt-4 paragraph2'>
        <ExampleTrailingStopLossThree
          currentPriceText={t(
            'stopLossTakeProfit.trailingStopLossInfo.priceDecrease',
          )}
          stopLossText={t(
            'stopLossTakeProfit.trailingStopLossInfo.stopLossPositionCloses',
          )}
        />
      </div>
      <div className='mt-4 pb-2 text-left '>
        <Text
          preset='bodyRegular'
          color='secondary'
          text={t('stopLossTakeProfit.trailingStopLossInfo.explanationFour')}
        />
      </div>
      <div className='mt-3 text-left infoLogo '>
        <div className='containerLogo mb-2'>
          <MainLogo className='containerLogo__icon' />
        </div>
        <div className='containerLogo__info'>
          <Text
            preset='subtitle2'
            color='primary'
            text={t(
              'stopLossTakeProfit.trailingStopLossInfo.pushNotificationTitle',
            )}
          />
          <Spacer preset='tiny' />
          <Text
            preset='bodyRegular'
            color='secondary'
            text={t(
              'stopLossTakeProfit.trailingStopLossInfo.pushNotificationDescription',
            )}
          />
        </div>
      </div>
    </div>
  )
}
