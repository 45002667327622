import isEmpty from 'lodash/isEmpty'
import { SummaryCardProps, SummaryRowProps } from '@components'
import {
  BuySellTransaction,
  DepositWithdrawalTransaction,
  NoteTransaction,
  OrderTransactionChildStatusEnum,
  SafekeepingTransaction,
  TransactionDetailType,
  TransactionType,
  WalletTransaction,
} from '@interfaces'
import { t } from 'i18next'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import { getOrderSections } from './hooks/orderSections'
import {
  formatCurrencyToSymbol,
  formatMoney,
  formatNumber,
  frontEndFormat,
} from '@utils/helpers'

export interface TransactionsSection {
  id: string
  overTitle: string
  data: SummaryRowProps[]
}

export function getFormattedStatus(
  status: OrderTransactionChildStatusEnum,
): string {
  switch (status) {
    case OrderTransactionChildStatusEnum.cancelled:
      return t('transactions.cancelled')
    case OrderTransactionChildStatusEnum.completed:
      return t('transactions.completed')
    case OrderTransactionChildStatusEnum.pending:
      return t('transactions.pending')
    case OrderTransactionChildStatusEnum.partiallyCompleted:
      return t('transactions.partiallyCompleted')
    case OrderTransactionChildStatusEnum.error:
      return t('transactions.error')
    default:
      return status
  }
}

export function getStatusRow(
  transaction: TransactionDetailType,
): SummaryRowProps {
  const status =
    (transaction as BuySellTransaction)?.statusText ||
    getFormattedStatus(
      transaction?.status as unknown as OrderTransactionChildStatusEnum,
    )

  return {
    label: t('transactions.status'),
    value: status,
  }
}

export function getDefaultSection(
  transaction: TransactionDetailType,
): SummaryCardProps[] {
  const defaultRows = [getStatusRow(transaction)]

  return [
    {
      id: 'defaultSection',
      data: defaultRows.map((r, i) => ({
        ...r,
        showDivider: i !== defaultRows.length - 1,
      })),
    },
  ]
}

export function getDepositOrWithdrawalRows(
  transaction: DepositWithdrawalTransaction,
): SummaryCardProps[] {
  const { type, amount, fees } = transaction

  return [
    {
      id: 'depositOrWithdrawalRows',
      data: [
        getStatusRow(transaction),
        {
          label:
            type === TransactionType.deposit
              ? t('transactions.amountDeposited')
              : t('transactions.amountWithdrawn'),
          value: formatMoney(amount?.amount, amount?.currency),
        },
        {
          label: t('buySellFlow.summary.fees'),
          value: formatMoney(fees?.amount, fees?.currency),
        },
      ],
    },
  ]
}

export function getWalletRows(
  transaction: WalletTransaction,
): Array<SummaryCardProps> {
  const { amount, forex, fees, type, date } = transaction

  const walletTotalCostRow: SummaryRowProps = {
    label: t('transactions.totalCost'),
    value:
      type === TransactionType.walletTransferIncoming
        ? formatMoney(amount?.amount * forex?.value, forex?.to)
        : formatMoney(amount?.amount, amount?.currency),
    className: 'headline3 text-right',
  }
  return [
    {
      id: 'multiWalletTransferOrderDetails',
      overTitle: t('transactions.multiWalletTransferOrderDetails'),
      data: [
        {
          label: t('common.date'),
          value: format(new Date(date), frontEndFormat),
        },
        {
          label: t('transactions.amount'),
          value:
            type === TransactionType.walletTransferIncoming
              ? formatMoney(amount?.amount * forex?.value, forex?.to)
              : formatMoney(amount?.amount, amount?.currency),
        },
        {
          label: t('transactions.fxRate'),
          value: t('transactions.fxRateValue', {
            formCurrency: formatCurrencyToSymbol(forex?.from),
            value: forex?.value,
            toCurrency: formatCurrencyToSymbol(forex?.to),
          }),
        },
        {
          label: t('transactions.exchanged'),
          value:
            type === TransactionType.walletTransferIncoming
              ? formatMoney(amount?.amount, amount?.currency)
              : formatMoney(amount?.amount * forex?.value, forex?.to),
        },
        {
          label: t('transactions.commissions'),
          value: formatMoney(fees?.amount, fees?.currency),
        },
        walletTotalCostRow,
      ],
    },
  ]
}

export function getNotesRows(
  transaction: NoteTransaction,
): Array<TransactionsSection> {
  const { amount, total, date, taxes, concept } = transaction

  return [
    {
      id: 'noteDetails',
      overTitle: t('transactions.orderDetails'),
      data: [
        {
          label: t('transactions.amount'),
          value: formatMoney(amount?.amount, amount?.currency),
        },
        {
          label: t('transactions.concept'),
          value: concept,
        },
        {
          label: t('transactions.date'),
          value: format(new Date(date), frontEndFormat),
        },
        {
          label: t('transactions.taxes'),
          value: formatMoney(taxes?.amount, taxes?.currency),
        },
        {
          label: t('transactions.total'),
          value: formatMoney(total?.amount, total?.currency),
        },
      ],
    },
  ]
}

export function getSafekeeping(
  transaction: SafekeepingTransaction,
): Array<TransactionsSection> {
  const { amount, date, fee } = transaction

  return [
    {
      id: 'safekeeping',
      overTitle: t('transactions.orderDetails'),
      data: [
        {
          label: t('transactions.date'),
          value: format(new Date(date), frontEndFormat),
        },
        {
          label: t('transactions.fee'),
          value: fee
            ? t('transactions.portfolioValue', {
                value: formatNumber(fee),
              })
            : '',
        },
        {
          label: t('transactions.total'),
          value: formatMoney(amount?.amount, amount?.currency),
        },
      ],
    },
  ]
}

export function useSectionRows(
  transaction: TransactionDetailType,
  showInfoModal: (title: string, content: string) => void,
  showTaxesModal: () => void,
): SummaryCardProps[] {
  const { transactionType } = useParams()

  if (!isEmpty(transaction)) {
    const { type } = transaction

    // Buy (limit, order) or Sell orders (limit, market, stop loss, take profit)
    if (type === TransactionType.buy || type === TransactionType.sell) {
      return getOrderSections(
        transaction as BuySellTransaction,
        showInfoModal,
        showTaxesModal,
      )
    }

    // wallet transactions
    if (
      type === TransactionType.walletTransferIncoming ||
      type === TransactionType.walletTransferOutging
    ) {
      return getWalletRows(transaction as WalletTransaction)
    }

    // Deposits or Withdrawal
    if (
      type === TransactionType.deposit ||
      type === TransactionType.withdrawal
    ) {
      return getDepositOrWithdrawalRows(
        transaction as DepositWithdrawalTransaction,
      )
    }

    if (
      type === TransactionType.noteDebit ||
      type === TransactionType.noteCredit
    ) {
      return getNotesRows(transaction as NoteTransaction)
    }
    if ((transactionType as TransactionType) === TransactionType.safekeeping) {
      return getSafekeeping(transaction as SafekeepingTransaction)
    }
    return getDefaultSection(transaction)
  }
  return null
}
