import { Button, CustomModal } from '@components'
import { useState } from 'react'
import { ConditionType } from 'interfaces'
import { ChevronLeft, CloseIcon } from '@assets/icons'
import { StopLossTakeProfitAboutModal } from '../stopLossTakeProfitOperationInfo/stopLossTakeProfitAboutModal/stopLossTakeProfitAboutModal'
import { StopLossTakeProfitFaqs } from '../stopLossTakeProfitFaqs/stopLossTakeProfitFaqs'

export const StopLossTakeProfitHelpCombinedModal = ({
  onClose,
  mode,
}: {
  onClose: () => void
  mode: ConditionType
}): JSX.Element => {
  const handleClose = (): void => {
    onClose()
  }
  const [showFaqModal, setShowFaqModal] = useState(false)
  const [selectedFaqMode, setSelectedFaqMode] = useState<ConditionType>()

  return (
    <CustomModal show size='fullHeight' height='fullHeight'>
      <CustomModal.Header>
        <div className='w-100 d-flex align-start justify-start'>
          {showFaqModal ? (
            <Button
              onClick={() => {
                setShowFaqModal(false)
              }}
              buttonType='link'
              autoWidth
              content={<ChevronLeft color='iconPrimaryInitial' size={30} />}
              shape='square'
            />
          ) : (
            <Button
              onClick={() => handleClose()}
              buttonType='link'
              autoWidth
              content={<CloseIcon color='iconPrimaryInitial' size={20} />}
              shape='square'
            />
          )}
        </div>
      </CustomModal.Header>

      {showFaqModal ? (
        <StopLossTakeProfitFaqs
          mode={selectedFaqMode}
          close={() => {
            setShowFaqModal(false)
          }}
        />
      ) : (
        <StopLossTakeProfitAboutModal
          onClose={() => {
            onClose()
          }}
          mode={mode}
          onShowFaqModal={(faqMode) => {
            setShowFaqModal(true)
            setSelectedFaqMode(faqMode)
          }}
        />
      )}
    </CustomModal>
  )
}
