import { PriceLine } from '@interfaces/priceLine'
import { getStopLossTakeProfitAbbrName } from '@utils/getStopLossTakeProfitName'
import { formatPercentage, formatMoney } from '@utils/helpers'
import { t } from 'i18next'
import {
  ConditionalOrders,
  ConditionType,
  OrderTransactionChildStatusEnum,
} from 'interfaces'
import { useMemo } from 'react'

export function usePriceLines(
  conditionalOrders: ConditionalOrders[],
  currency: string,
  theme,
): PriceLine[] {
  return useMemo(() => {
    if (!conditionalOrders?.length) {
      return null
    }
    return conditionalOrders?.map((order: ConditionalOrders) => {
      const { requestedShares, status } = order
      const { stopType } = order.condition
      const price =
        order?.condition?.activationPrice || order?.condition?.stopPrice
      const isStopLoss = stopType === ConditionType.STOP_LOSS
      const isPercentage =
        !!order?.condition?.trailPercentage ||
        !!order?.condition?.stopPercentage
      const sign = isStopLoss ? '-' : '+'
      const base = 'stopLossTakeProfit.type.'
      const type = getStopLossTakeProfitAbbrName(order?.condition)

      const colorsMap = new Map([
        [
          ConditionType.STOP_LOSS,
          {
            labelTextColor: theme.labelPrimaryInitial,
            labelBackgroundColor: theme.priceStopLossBackgroundColor,
            priceBackgroundColor: theme.priceStopLossBackgroundColor,
            lineColor: theme.priceStopLossLineColor,
          },
        ],
        [
          ConditionType.TAKE_PROFIT,
          {
            labelTextColor: theme.labelPrimaryInitial,
            labelBackgroundColor: theme.priceTakeProfitBackgroundColor,
            priceBackgroundColor: theme.priceTakeProfitBackgroundColor,
            lineColor: theme.priceTakeProfitLineColor,
          },
        ],
      ])
      const lineColors = colorsMap.get(stopType)
      const value = isPercentage
        ? formatPercentage(
            order?.condition?.trailPercentage ||
              order?.condition?.stopPercentage,
          )
        : formatMoney(
            order?.condition?.trailAmount || order?.condition?.stopPrice,
            currency,
          )

      const orderType = t(`${base}${type}`)
      const activeLabel =
        status === OrderTransactionChildStatusEnum.waitingActivation
          ? t('stopLossTakeProfit.activeStatus')
          : ''

      const label = `${t(
        'transactions.sell',
      )} ${requestedShares} · ${orderType} ${sign}${value} ${activeLabel} >`
      return { price, label, ...lineColors }
    })
  }, [conditionalOrders, theme, currency])
}
