import { createReducer } from '@reduxjs/toolkit'
import { setMarketType } from './thunk'

const initialState = {
  type: null,
}

export const marketReducer = createReducer(initialState, (builder) => {
  builder.addCase(setMarketType, (acc, { payload }) => {
    acc.type = payload
  })
})
