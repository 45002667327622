import {
  AlertMessage,
  Avatar,
  CustomModal,
  Modal,
  RoundedSwitchButton,
  Show,
  Spacer,
  Text,
  TextField,
} from '@components'
import { OperationCardBase } from '../operationCardBase'
import {
  ChevronDown,
  InfoIcon,
  StopLossIcon,
  TakeProfitIcon,
} from '@assets/icons'
import { useTranslation } from 'hooks/useTranslation'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { formatCurrency, formatNumber } from '@utils/helpers'
import { getLang } from '@utils/langs'
import {
  ConditionType,
  StopLossTakeProfitOperation,
  StopLossTakeProfitType,
  SLTKInputType,
  OrderTypeEnum,
  orderTypeMap,
} from '@interfaces'
import { format } from 'date-fns'
import { calculateSLAndTPValues } from './utils/calculateSLAndTPValues'
import { BasicCardField } from '../basicFormField/basicFormField'
import { onChangeNumericInput } from '@utils/onChangeNumericInput'
import { SLTKOperationToPreorder } from '../../utils/SLTKOperationToPreorder'
import { TogglePricePercentModal } from './togglePricePercentModal'
import { notDefined } from '@utils/notDefined'
import { SLTKCardParams } from './stopLossTakeProfitCard.props'
import { TimeInForceModal } from './components/timeInForceModal'
import { timeInForceModalConfig } from './components/timeInForceModalConfig'
import {
  TimeInForceOption,
  TimeInForceType,
} from '@interfaces/timeInForceOption'
import { ToggleTypeOfOrderModal } from './components/toggleTypeOfOrderModal'
import { formatInputNumber } from '@utils/formatting'

interface InfoModalContent {
  title: string
  content: string
}

const inputInitiaValue = {
  value: null,
  stringified: '',
}

const locale = getLang()

const initialExpirationDate = timeInForceModalConfig(locale).find(
  (option) => option.type === TimeInForceType.TIL_CANCEL,
)

const emptySummary: StopLossTakeProfitOperation = {
  stopPercentage: null,
  sharesAmount: null,
  activationPrice: null,
  profitsOrLosses: null,
  stopPrice: null,
  limitPrice: null,
  estimatedTotal: null,
  trailAmount: null,
  expirationDate: initialExpirationDate,
  assetId: null,
  type: null,
  trailPercentage: null,
  method: null,
}

const sharesAmountDecimals = 0
const priceDecimals = 2
const percentageDecimals = 2

const initialForm = {
  sharesAmount: inputInitiaValue,
  trailAmount: inputInitiaValue,
  price: inputInitiaValue,
  trailPercentage: inputInitiaValue,
  stopPercentage: inputInitiaValue,
  activationPrice: inputInitiaValue,
  limitPrice: inputInitiaValue,
}

const orderTypeClasses = {
  [StopLossTakeProfitType.TRAILING]: 'dropdown-label-disabled',
  [StopLossTakeProfitType.FIXED]: 'cursor-pointer dropdown-label-enabled',
}

export const StopLossCardTakeProfitCard = ({
  loading,
  showStopLossTakeProfitModal,
  focusOnInit,
  disabled,
  mode,
  currentShares,
  lockedShares,
  assetPrice,
  symbol,
  assetId,
  onChange,
}: SLTKCardParams): JSX.Element => {
  const { t } = useTranslation()
  const [active, setActive] = useState(false)

  const [form, setForm] = useState(initialForm)

  const [infoModalVisible, setInfoModalVisible] = useState(false)
  const [infoModal, setInfoModal] = useState<InfoModalContent>(null)

  const [summary, setSummary] =
    useState<StopLossTakeProfitOperation>(emptySummary)

  const [selectedTimeInForce, setSelectedTimeInForce] = useState<
    TimeInForceOption<Date>
  >(initialExpirationDate)
  const [orderType, setOrderType] = useState(OrderTypeEnum.market)
  const [showInputTypeModal, setShowInputTypeModal] = useState(false)
  const [showOrderTypeModal, setShowOrderTypeModal] = useState(false)
  const [invalidLimitPrice, setInvalidLimitPrice] = useState(false)

  const [tab] = useState(StopLossTakeProfitType.FIXED)

  const [inputType, setInputType] = useState(SLTKInputType.FIXED_PRICE)

  const noCalculationFallback = '-'

  const isTrailing = tab === StopLossTakeProfitType.TRAILING

  const inputDisabled = loading || disabled

  const literals: {
    title: string
    subtitle: string
    inputLiteral: string
    activationPrice: string
    activationPriceLiteral: string
  } = useMemo(() => {
    const literalsKey =
      mode === ConditionType.STOP_LOSS
        ? 'stopLossLiterals'
        : 'takeProfitLiterals'
    const activationKey =
      mode === ConditionType.STOP_LOSS ? 'stopLoss' : 'takeProfit'

    return {
      title: t(`buySellFlow.${literalsKey}.title`),
      subtitle: t(`buySellFlow.${literalsKey}.subtitle`),
      inputLiteral: t(`buySellFlow.${literalsKey}.fields.${tab}.${inputType}`),
      activationPrice: t(`buySellFlow.${literalsKey}.estimatedExecutionPrice`),
      activationPriceLiteral: t(`stopLossTakeProfit.type.${activationKey}`),
    }
  }, [mode, t, tab, inputType])

  /**
   * The maximum amount of shares that can be used for the operation.
   */
  const maxShares = currentShares - lockedShares

  useEffect(() => {
    onChange(active ? SLTKOperationToPreorder(summary) : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, summary])

  useEffect(() => {
    if (!active) {
      setForm(initialForm)
      setSummary(emptySummary)
    }
  }, [active])

  /**
   * Focus first input field.
   */
  useEffect(() => {
    if (focusOnInit) {
      const el: HTMLInputElement = document.querySelector(
        `[name="sharesAmount"]`,
      )
      el?.focus()
    }
  }, [focusOnInit])

  const showInfoModal = ({ title, content }: InfoModalContent): void => {
    setInfoModal({ title, content })
    setInfoModalVisible(true)
  }

  const renderInfoTooltip = ({
    title,
    content,
  }: InfoModalContent): JSX.Element => {
    return (
      <div
        className='cursor-pointer d-flex align-center'
        onClick={() => showInfoModal({ title, content })}
        aria-hidden
      >
        <InfoIcon size={16} color='tertiary' />
      </div>
    )
  }

  const onChangeSharesForm = onChangeNumericInput(
    sharesAmountDecimals,
    (res) => {
      setForm({
        ...form,
        sharesAmount: {
          stringified: res?.asString,
          value: res?.sanitized,
        },
      })
    },
  )

  const onChangePriceForm = onChangeNumericInput(priceDecimals, (res) => {
    setForm({
      ...form,
      price: {
        stringified: res?.asString,
        value: res?.sanitized,
      },
    })
  })

  const onChangeAmountForm = onChangeNumericInput(priceDecimals, (res) => {
    if (isTakeProfit && isTrailing) {
      const activationPrice = assetPrice?.amount + res?.sanitized
      setForm({
        ...form,
        activationPrice: {
          value: activationPrice,
          stringified: activationPrice.toString(),
        },
        trailAmount: {
          stringified: res?.asString,
          value: res?.sanitized,
        },
      })
    } else {
      setForm({
        ...form,
        trailAmount: {
          stringified: res?.asString,
          value: res?.sanitized,
        },
      })
    }
  })

  const onChangePercentage = onChangeNumericInput(percentageDecimals, (res) => {
    const value = {
      stringified: res?.asString,
      value: res?.sanitized,
    }

    if (isTakeProfit && isTrailing) {
      const activationPrice = assetPrice?.amount * (1 + res?.sanitized / 100)
      setForm((prevForm) => ({
        ...prevForm,
        activationPrice: {
          value: activationPrice,
          stringified: formatInputNumber(
            activationPrice.toString(),
            locale,
            percentageDecimals,
          ),
        },
      }))
    }

    if (inputType === SLTKInputType.FIXED_PERCENT) {
      setForm((prevForm) => ({
        ...prevForm,
        stopPercentage: value,
        trailPercentage: null,
      }))
    } else if (inputType === SLTKInputType.TRAIL_PERCENT) {
      setForm((prevForm) => ({
        ...prevForm,
        trailPercentage: value,
        stopPercentage: null,
      }))
    }
  })

  const onChangeActivationPrice = onChangeNumericInput(priceDecimals, (res) => {
    setForm({
      ...form,
      activationPrice: {
        stringified: res?.asString,
        value: res?.sanitized,
      },
    })
  })

  const onChangeLimitPrice = onChangeNumericInput(priceDecimals, (res) => {
    setForm({
      ...form,
      limitPrice: {
        stringified: res?.asString,
        value: res?.sanitized,
      },
    })
  })

  useEffect(() => {
    const calculations = calculateSLAndTPValues({
      assetPrice: assetPrice?.amount,
      type: mode,
      units: form?.sharesAmount?.value,
      stopPrice: form?.price?.value,
      trailPercentage: form?.trailPercentage?.value,
      stopPercentage: form?.stopPercentage?.value,
      trailAmount: form?.trailAmount?.value,
      activationPrice: form?.activationPrice.value,
      limitPrice: form?.limitPrice.value,
    })

    setInvalidLimitPrice(
      form?.limitPrice?.value
        ? isTakeProfit
          ? form?.limitPrice?.value < calculations.stopPrice
          : form?.limitPrice?.value > calculations.stopPrice
        : false,
    )

    setSummary({
      ...summary,
      assetId,
      expirationDate: selectedTimeInForce,
      trailAmount: inputIsAmountType ? form?.trailAmount?.value : null,
      type: mode,
      method: orderType,
      activationPrice:
        inputIsPercentType && !isTrailing
          ? calculations?.stopPrice
          : isTrailing
          ? form?.activationPrice.value ?? calculations?.activationPrice
          : null,
      stopPrice: inputIsPriceType ? form?.price?.value : null,
      trailPercentage:
        isTrailing && inputIsPercentType ? form?.trailPercentage?.value : null,
      stopPercentage:
        !isTrailing && inputIsPercentType ? form?.stopPercentage?.value : null,
      sharesAmount: form?.sharesAmount?.value,
      estimatedTotal: calculations?.estimatedTotal,
      profitsOrLosses: calculations?.profitsOrLosses,
      limitPrice: isLimitOrder ? form?.limitPrice?.value : null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, selectedTimeInForce, orderType])

  const decimalSeparator = getLang() == 'en-US' ? '.' : ','

  const toggleInputType = (): void => {
    setShowInputTypeModal(true)
  }

  const pnlColor = useMemo(() => {
    return summary?.profitsOrLosses < 0 ? 'negative' : 'positive'
  }, [summary?.profitsOrLosses])

  const estimatedProceedsValue = useMemo(() => {
    if (notDefined(summary?.estimatedTotal)) {
      return noCalculationFallback
    }

    return formatCurrency({
      amount: summary?.estimatedTotal,
      currency: assetPrice?.currency,
    })
  }, [summary?.estimatedTotal, assetPrice])

  const inputIsAmountType = useMemo(() => {
    return inputType === SLTKInputType.TRAIL_AMOUNT
  }, [inputType])

  const inputIsPriceType = useMemo(() => {
    return inputType === SLTKInputType.FIXED_PRICE
  }, [inputType])

  const inputIsPercentType = useMemo(() => {
    return (
      inputType === SLTKInputType.FIXED_PERCENT ||
      inputType === SLTKInputType.TRAIL_PERCENT
    )
  }, [inputType])

  const isLimitOrder = useMemo(() => {
    return orderType === OrderTypeEnum.limit
  }, [orderType])

  const isTakeProfit = mode === ConditionType.TAKE_PROFIT

  const showRenderStopPriceAlertMessage = useMemo(() => {
    if (form?.price?.value) {
      return isTakeProfit
        ? form?.price?.value < assetPrice?.amount
        : form?.price?.value > assetPrice?.amount
    }
    return false
  }, [assetPrice?.amount, form?.price?.value, isTakeProfit])

  const dateSelectionText = useMemo(() => {
    if (
      selectedTimeInForce.type === TimeInForceType.CUSTOM ||
      selectedTimeInForce.type === TimeInForceType.CALENDAR_CUSTOM
    ) {
      return format(selectedTimeInForce.value, 'd MMMM yyyy')
    }
    return t(selectedTimeInForce.title)
  }, [selectedTimeInForce, t])

  const onChangeTogglePricePercent = useCallback(
    (type: SLTKInputType) => {
      if (type === inputType) return
      setInputType(type)
      setForm({
        ...initialForm,
        sharesAmount: form?.sharesAmount,
        activationPrice: {
          stringified: formatNumber(assetPrice?.amount),
          value: Number(assetPrice?.amount),
        },
      })
      setShowInputTypeModal(false)
    },
    [assetPrice?.amount, form?.sharesAmount, inputType],
  )

  const onChangeToggleOrderType = useCallback((orderType: OrderTypeEnum) => {
    setOrderType(orderType)
    setForm((form) => ({
      ...form,
      limitPrice: inputInitiaValue,
    }))
    setShowOrderTypeModal(false)
  }, [])

  const [timeInForceVisible, setTimeInForceVisible] = useState(false)

  const closeTimeInForceModal = (): void => setTimeInForceVisible(false)
  const saveTimeInForceDate = (
    updatedTimeInForce: TimeInForceOption<Date>,
  ): void => {
    setSelectedTimeInForce(updatedTimeInForce)
  }

  const activationPriceError: string | null = useMemo(() => {
    const activationPrice = form?.activationPrice?.value
    const trailAmount = form?.trailAmount?.value
    const trailPercentage = form?.trailPercentage?.value
    const currentPrice = assetPrice?.amount

    if (isTakeProfit) {
      if (trailAmount) {
        return activationPrice < currentPrice + trailAmount
          ? 'buySellFlow.activationPriceMustBeHigher'
          : null
      } else if (trailPercentage) {
        return activationPrice < currentPrice * (1 + trailPercentage / 100)
          ? 'buySellFlow.activationPriceMustBeLower'
          : null
      }
      return null
    }

    return activationPrice > currentPrice
      ? 'buySellFlow.activationPriceMustBeLower'
      : null
  }, [
    assetPrice?.amount,
    form?.activationPrice?.value,
    form?.trailAmount?.value,
    form?.trailPercentage?.value,
    isTakeProfit,
  ])

  return (
    <>
      <OperationCardBase
        opened={active}
        image={
          <Avatar
            roundness='full'
            size='mediumPlus'
            bgColor='primaryBase'
            image={
              isTakeProfit ? (
                <TakeProfitIcon color='iconPrimaryInitial' />
              ) : (
                <StopLossIcon color='iconPrimaryInitial' />
              )
            }
          />
        }
        title={
          <div className='d-flex align-center' aria-hidden>
            <Text
              text={literals.title}
              preset='paragraph2'
              textStyle='capitalize'
            />
            <Spacer preset='tiny' />
            <div
              className='cursor-pointer d-flex align-center'
              onClick={() => {
                showStopLossTakeProfitModal(mode)
              }}
              aria-hidden
            >
              <InfoIcon size={16} color='tertiary' />
            </div>
          </div>
        }
        subtitle={literals.subtitle}
        form={
          <>
            <Spacer preset='medium' />
            <div className='d-flex align-center'>
              <div className='w-55'>
                <Text
                  preset='paragraph2'
                  textStyle='uppercase'
                  text={t('common.amount')}
                />
                <Text
                  preset='paragraph3'
                  text={`${t(
                    'buySellFlow.summary.available',
                  )} ${maxShares} ${symbol}`}
                  color={
                    form?.sharesAmount?.value > maxShares
                      ? 'negative'
                      : 'tertiary'
                  }
                />
              </div>
              <div className='d-flex w-45'>
                <TextField
                  type='text'
                  right={symbol}
                  theme='subtle'
                  size='small'
                  style='text-right paragraph5'
                  inputProps={{
                    name: 'sharesAmount',
                    placeholder: '0',
                    disabled: inputDisabled,
                    onChange: onChangeSharesForm,
                    value: form?.sharesAmount?.stringified,
                  }}
                />
              </div>
            </div>
            <Show when={inputIsPriceType}>
              <>
                <BasicCardField
                  title={
                    <button
                      className='cursor-pointer d-flex align-center'
                      onClick={toggleInputType}
                    >
                      <Text
                        preset='paragraph2'
                        textStyle='uppercase'
                        text={literals.inputLiteral}
                        color='labelPrimarySubtleInitial'
                      />
                      <ChevronDown color='iconPrimaryInitial' />
                    </button>
                  }
                  subtitle={
                    inputIsPriceType && (
                      <Text
                        preset='paragraph3'
                        text={`${symbol} ${formatCurrency(assetPrice)}`}
                        color='tertiary'
                      />
                    )
                  }
                  value={
                    <TextField
                      type='text'
                      size='small'
                      theme='subtle'
                      style='text-right paragraph5'
                      right={
                        <Text preset='caption' text={assetPrice?.currency} />
                      }
                      inputProps={{
                        onChange: onChangePriceForm,
                        placeholder: `0${decimalSeparator}00`,
                        disabled: inputDisabled,
                        value: form?.price?.stringified,
                      }}
                    />
                  }
                />
                <Show when={showRenderStopPriceAlertMessage}>
                  <AlertMessage
                    message={
                      isTakeProfit
                        ? t('buySellFlow.stopPriceMustBeHigher')
                        : t('buySellFlow.stopPriceMustBeLower')
                    }
                  />
                </Show>
              </>
            </Show>
            <Show when={inputIsAmountType}>
              <BasicCardField
                title={
                  <button
                    className='cursor-pointer d-flex align-center'
                    onClick={toggleInputType}
                  >
                    <Text
                      preset='paragraph2'
                      textStyle='uppercase'
                      text={literals.inputLiteral}
                      color='labelPrimarySubtleInitial'
                    />
                    <ChevronDown color='actionPrimaryInitial' />
                  </button>
                }
                subtitle={
                  inputIsPriceType && (
                    <Text
                      preset='paragraph3'
                      text={`${symbol} ${formatCurrency(assetPrice)}`}
                      color='labelPrimarySubtleInitial'
                    />
                  )
                }
                value={
                  <TextField
                    type='text'
                    size='small'
                    theme='subtle'
                    style='text-right paragraph5'
                    right={
                      <Text preset='caption' text={assetPrice?.currency} />
                    }
                    inputProps={{
                      onChange: onChangeAmountForm,
                      placeholder: `0${decimalSeparator}00`,
                      disabled: inputDisabled,
                      value: form?.trailAmount?.stringified,
                    }}
                  />
                }
              />
            </Show>
            <Show when={inputIsPercentType}>
              <BasicCardField
                title={
                  <button
                    className='cursor-pointer d-flex align-center'
                    onClick={toggleInputType}
                  >
                    <Text
                      preset='paragraph2'
                      textStyle='uppercase'
                      text={literals.inputLiteral}
                      color='labelPrimarySubtleInitial'
                    />
                    <ChevronDown color='iconPrimaryInitial' />
                  </button>
                }
                value={
                  <TextField
                    size='small'
                    theme='subtle'
                    style='text-right paragraph5'
                    type='text'
                    right={<Text preset='caption' text='%' />}
                    inputProps={{
                      placeholder: `0${decimalSeparator}00`,
                      onChange: onChangePercentage,
                      disabled: inputDisabled,
                      value:
                        inputType === SLTKInputType.FIXED_PERCENT
                          ? form?.stopPercentage?.stringified
                          : form?.trailPercentage?.stringified,
                    }}
                  />
                }
              />
            </Show>
            <Show when={showInputTypeModal}>
              <TogglePricePercentModal
                type={mode}
                inputType={inputType}
                tab={tab}
                onChange={onChangeTogglePricePercent}
              />
            </Show>
            <Show when={showOrderTypeModal}>
              <ToggleTypeOfOrderModal
                type={orderType}
                onChange={onChangeToggleOrderType}
              />
            </Show>
            <Show
              when={
                inputType === SLTKInputType.TRAIL_AMOUNT ||
                inputType === SLTKInputType.TRAIL_PERCENT
              }
            >
              <>
                <BasicCardField
                  title={
                    <div className='d-flex align-center' aria-hidden>
                      <Text
                        preset='paragraph2'
                        textStyle='uppercase'
                        text={t('buySellFlow.activationPrice')}
                      />
                      <Spacer preset='tiny' />
                      {renderInfoTooltip({
                        title: 'buySellFlow.infoActivationPrice.title',
                        content: t('buySellFlow.infoActivationPrice.text', {
                          type: literals.activationPriceLiteral,
                        }),
                      })}
                    </div>
                  }
                  value={
                    <TextField
                      type='text'
                      style='text-right bigText'
                      theme='subtle'
                      right={
                        <Text preset='caption' text={assetPrice?.currency} />
                      }
                      size='small'
                      inputProps={{
                        placeholder: '0',
                        onChange: onChangeActivationPrice,
                        disabled: inputDisabled,
                        value: form?.activationPrice?.stringified,
                      }}
                    />
                  }
                />
                <Show when={!!activationPriceError}>
                  <AlertMessage message={t(activationPriceError)} />
                </Show>
              </>
            </Show>
            <Show when={inputType === SLTKInputType.FIXED_PERCENT}>
              <BasicCardField
                title={literals.activationPrice}
                value={
                  formatCurrency({
                    amount: summary?.activationPrice,
                    currency: assetPrice?.currency,
                  }) || ''
                }
              />
            </Show>
            <Spacer preset='small' />
            <div className='d-flex align-center'>
              <div className='w-55'>
                <Text
                  preset='paragraph2'
                  textStyle='uppercase'
                  text={t('buySellFlow.timeInForce')}
                />
              </div>
              <button
                className='d-flex w-45 justify-end cursor-pointer'
                onClick={() => setTimeInForceVisible(true)}
              >
                <Text
                  text={dateSelectionText}
                  preset='paragraph2'
                  color='iconPrimaryInitial'
                />
                <ChevronDown color='iconPrimaryInitial' />
              </button>
            </div>
            <Spacer preset='small' />
            <div className='d-flex align-center'>
              <div className='w-55'>
                <Text
                  preset='paragraph2'
                  textStyle='uppercase'
                  text={t('buySellFlow.typeOfOrder')}
                />
              </div>
              <button
                className={`d-flex w-45 justify-end ${orderTypeClasses[tab]}`}
                onClick={() => {
                  if (!isTrailing) setShowOrderTypeModal(true)
                }}
              >
                <Text
                  text={t(orderTypeMap.get(orderType))}
                  preset='paragraph2'
                />
                <ChevronDown />
              </button>
            </div>
            <Show when={isLimitOrder}>
              <BasicCardField
                title={
                  <Text
                    preset='paragraph2'
                    textStyle='uppercase'
                    text={t('buySellFlow.limitPrice')}
                  />
                }
                subtitle={
                  <Text
                    preset='paragraph3'
                    text={`${symbol} ${formatCurrency(assetPrice)}`}
                    color='tertiary'
                  />
                }
                value={
                  <TextField
                    type='text'
                    size='small'
                    theme='subtle'
                    style='text-right paragraph5'
                    right={
                      <Text preset='caption' text={assetPrice?.currency} />
                    }
                    inputProps={{
                      onChange: onChangeLimitPrice,
                      placeholder: `0${decimalSeparator}00`,
                      disabled: inputDisabled,
                      value: form?.limitPrice?.stringified,
                    }}
                  />
                }
              />
              <Show when={invalidLimitPrice}>
                <AlertMessage
                  message={
                    isTakeProfit
                      ? t('buySellFlow.limitPriceMustBeHigher')
                      : t('buySellFlow.limitPriceMustBeLower')
                  }
                />
              </Show>
            </Show>
            <BasicCardField
              title={
                isTakeProfit
                  ? t('common.estimatedProfit')
                  : t('common.estimatedProceeds')
              }
              value={estimatedProceedsValue}
            />
            <BasicCardField
              title={
                <div className='d-flex align-center' aria-hidden>
                  <Text
                    preset='paragraph2'
                    textStyle='uppercase'
                    text={`${t('common.estimated')} ${t('common.pnl')}`}
                  />
                  <Spacer preset='tiny' />
                  {renderInfoTooltip({
                    title: 'buySellFlow.infoEstimatedPnL.title',
                    content: t('buySellFlow.infoEstimatedPnL.text'),
                  })}
                </div>
              }
              value={
                <Show
                  when={summary?.profitsOrLosses != null}
                  fallback={<>{noCalculationFallback}</>}
                >
                  <Text
                    preset='paragraph2'
                    align='right'
                    text={
                      formatCurrency({
                        amount: summary?.profitsOrLosses,
                        currency: assetPrice?.currency,
                      }) || ''
                    }
                    color={pnlColor}
                  />
                </Show>
              }
            />
            <Spacer preset='small' />
          </>
        }
        right={
          <RoundedSwitchButton
            size='mediumIntermediate'
            initialState={active}
            onChange={(p) => setActive(p)}
          />
        }
      />
      <Show when={timeInForceVisible}>
        <CustomModal.OverlayContent>
          <TimeInForceModal
            selectedOption={selectedTimeInForce}
            onClose={closeTimeInForceModal}
            onSubmit={saveTimeInForceDate}
          />
        </CustomModal.OverlayContent>
      </Show>

      <Modal
        className='backdrop--none'
        text={t(infoModal?.title)}
        buttonText={t('common.ok')}
        show={infoModalVisible}
        onButton={() => setInfoModalVisible(false)}
      >
        {infoModal?.content}
      </Modal>
    </>
  )
}
