import './filterDropdownContent.styles.scss'
import './filtersDateOptions.styles.scss'
import { CalendarIcon, ChevronLeft, ChevronRight } from '@assets/icons'
import {
  Button,
  Calendar,
  Divider,
  Show,
  Spacer,
  TabBar,
  Text,
  useCalendar,
} from 'components'
import { t } from 'i18next'
import { useState } from 'react'
import { createUTCDateFromISOString, formatDateLong } from '@utils/helpers'

enum FiltersDateOptionsTabs {
  PRESET_DATE = 'PRESET_DATE',
  CUSTOM_DATE = 'CUSTOM_DATE',
}

const getDateMonth = (date?: Date | string): number => {
  const today = new Date(new Date().setHours(0, 0, 0, 0))
  if (!date) return today?.getMonth()
  return (
    typeof date === 'string' ? createUTCDateFromISOString(date) : date
  )?.getMonth()
}

const getDateYear = (date?: Date | string): number => {
  const today = new Date(new Date().setHours(0, 0, 0, 0))
  if (!date) return today?.getFullYear()
  return (
    typeof date === 'string' ? createUTCDateFromISOString(date) : date
  )?.getFullYear()
}

export const FiltersDateOptions = ({
  onSelect,
  onClose,
  onReset,
  dateSelection,
  dateValues,
}: {
  onSelect: (date: { from: string; to: string }) => void
  onClose: () => void
  onReset: () => void
  dateSelection: {
    from: string
    to: string
  }
  dateValues: Array<{
    from: string
    to: string
    title: string
    subtitle: string
  }>
}): JSX.Element => {
  const [activeTab, setActiveTab] = useState(FiltersDateOptionsTabs.CUSTOM_DATE)
  const [fromCalendarYear] = useState(getDateYear(dateSelection?.from))
  const [fromCalendarMonth] = useState(getDateMonth(dateSelection?.from))
  const [toCalendarYear] = useState(getDateYear(dateSelection?.to))
  const [toCalendarMonth] = useState(getDateMonth(dateSelection?.to))

  const tabs = [
    {
      id: FiltersDateOptionsTabs.PRESET_DATE,
      title: t('common.presetDate'),
    },
    {
      id: FiltersDateOptionsTabs.CUSTOM_DATE,
      title: t('common.customDate'),
    },
  ]

  const handleTabPressed = (v: FiltersDateOptionsTabs): void => {
    setActiveTab(v)
  }

  const handleDatesChange = (current: string[], newDate: string): void => {
    current = current
      ?.filter((d) => !!d)
      ?.sort(
        (a: string, b: string) =>
          new Date(a)?.getTime() - new Date(b)?.getTime(),
      )

    const isLowerThanFrom =
      new Date(newDate)?.getTime() < new Date(current[0])?.getTime()

    onSelect(
      isLowerThanFrom
        ? {
            from: newDate,
            to: null,
          }
        : {
            from: current[0],
            to: newDate,
          },
    )
  }

  const fromCalendar = useCalendar({
    onChange: handleDatesChange,
    options: {
      allowSatAndSun: true,
    },
    selectedDates: [dateSelection?.from, dateSelection?.to],
    initialMonth: fromCalendarMonth,
    initialYear: fromCalendarYear,
  })

  const toCalendar = useCalendar({
    onChange: handleDatesChange,
    options: {
      allowSatAndSun: true,
    },
    selectedDates: [dateSelection?.from, dateSelection?.to],
    initialMonth: toCalendarMonth,
    initialYear: toCalendarYear,
  })

  return (
    <>
      <div className='filters-date-options'>
        <Spacer preset='smaller' />
        <TabBar
          extraMarginOnSides
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={handleTabPressed}
          align='center'
          fullWidthTabs
        />
        <Divider />
        <Show when={activeTab === FiltersDateOptionsTabs.PRESET_DATE}>
          <div className='filters-dropdown__grid --three-columns'>
            {dateValues?.map((i) => {
              const isActive =
                dateSelection?.from === i?.from && dateSelection?.to === i?.to
              return (
                <div
                  className={`filters-date-options__option`}
                  key={`date-${i?.from}-${i?.to}`}
                  aria-hidden
                  onClick={() => onSelect({ from: i?.from, to: i?.to })}
                >
                  <Text
                    preset='paragraph2'
                    text={i.title}
                    color={isActive ? 'iconPrimaryInitial' : 'primary'}
                  />
                  <Spacer preset='tinyMinus' />
                  <Text
                    preset='caption'
                    color={isActive ? 'iconPrimaryInitial' : 'secondary'}
                    text={i.subtitle}
                  />
                </div>
              )
            })}
          </div>
        </Show>
        <Show when={activeTab === FiltersDateOptionsTabs.CUSTOM_DATE}>
          <div className='filters-dropdown__padded'>
            <Spacer preset='small' />
            <div className='d-flex align-center'>
              <div className='d-flex w-50 align-center'>
                <Spacer preset='smaller' />
                <div
                  className='w-20 cursor-pointer d-flex align-center'
                  onClick={() => {
                    fromCalendar?.handleMonth('L')
                    toCalendar?.handleMonth('L')
                  }}
                  aria-hidden
                >
                  <ChevronLeft size={32} color='iconPrimaryInitial' />
                </div>
                <div className='w-100 align-center user-select-none'>
                  <Text
                    align='center'
                    preset='paragraph1'
                    text={`${fromCalendar?.month} ${fromCalendar?.selectedYear}`}
                  />
                </div>
                <div className='w-20'></div>
              </div>
              <div className='d-flex w-50 align-center'>
                <div className='w-20'></div>
                <div className='w-100 user-select-none'>
                  <Text
                    align='center'
                    preset='paragraph1'
                    text={`${toCalendar?.month} ${toCalendar?.selectedYear}`}
                  />
                </div>
                <div
                  className='w-20 d-flex justify-end cursor-pointer align-center'
                  onClick={() => {
                    fromCalendar?.handleMonth('R')
                    toCalendar?.handleMonth('R')
                  }}
                  aria-hidden
                >
                  <ChevronRight size={32} color='iconPrimaryInitial' />
                </div>
                <Spacer preset='smaller' />
              </div>
            </div>
            <Spacer preset='small' />
            <div className='d-flex'>
              <Calendar
                id='transctions-from-date'
                withControls={false}
                handleCancel={fromCalendar?.handleCancel}
                handleRestore={fromCalendar?.handleRestore}
                handleSelected={fromCalendar?.handleSelected}
                handleSubmit={fromCalendar?.handleSubmit}
                handleMonth={fromCalendar?.handleMonth}
                getPreviousMonth={fromCalendar?.getPreviousMonth}
                month={fromCalendar?.month}
                selectedYear={fromCalendar?.selectedYear}
                weekDays={fromCalendar?.weekDays}
                submitDisabled={fromCalendar?.submitDisabled}
                monthsMatrix={fromCalendar?.monthsMatrix}
              />
              <Calendar
                id='transctions-to-date'
                withControls={false}
                handleCancel={toCalendar?.handleCancel}
                handleRestore={toCalendar?.handleRestore}
                handleSelected={toCalendar?.handleSelected}
                handleSubmit={toCalendar?.handleSubmit}
                handleMonth={toCalendar?.handleMonth}
                getPreviousMonth={toCalendar?.getPreviousMonth}
                month={toCalendar?.month}
                selectedYear={toCalendar?.selectedYear}
                weekDays={toCalendar?.weekDays}
                submitDisabled={toCalendar?.submitDisabled}
                monthsMatrix={toCalendar?.monthsMatrix}
              />
            </div>
          </div>
        </Show>
      </div>
      <div
        className={`filters-dropdown__filters-footer w-100 d-flex align-center align-center justify-space-between`}
      >
        <div className='filters-date-options__selection-summary d-flex align-center'>
          {dateSelection?.from && (
            <div className='d-flex'>
              <CalendarIcon color='iconPrimaryInitial' />
              <Spacer preset='tiny' />
              <Text
                preset='paragraph2'
                text={`From ${formatDateLong(dateSelection?.from)}`}
                color='iconPrimaryInitial'
              />
            </div>
          )}
          {dateSelection?.to && (
            <>
              <Spacer preset='small' />
              <div className='d-flex align-center'>
                <CalendarIcon color='iconPrimaryInitial' />
                <Spacer preset='tiny' />
                <Text
                  preset='paragraph2'
                  text={`To ${formatDateLong(dateSelection?.to)}`}
                  color='iconPrimaryInitial'
                />
              </div>
            </>
          )}
        </div>
        <Spacer preset='small' />
        <div className='filters-dropdown__filters-footer-buttons d-flex'>
          <Button
            autoWidth
            text={t('common.reset')}
            size='tiny'
            textPreset='paragraph2'
            buttonType='ghost'
            onClick={onReset}
          />
          <Spacer preset='smaller' />
          <Button
            autoWidth
            text={t('common.showResults')}
            size='tiny'
            textPreset='paragraph2'
            onClick={onClose}
          />
        </div>
      </div>
    </>
  )
}
