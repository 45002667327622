import { CloseIcon, PlusIcon } from '@assets/icons'
import { Button, CustomModal, CustomModalProps, Text } from '@components'
import { useAddFundsAndCashout, useTranslation } from '@hooks'
import CardList from '@screens/profile/components/cardList'
import { formatCurrency } from '@utils/helpers'
import { ButtonProps } from 'components/button/button.props'
import { CurrencyFlag } from 'components/currencyFlag/currencyFlag'
import { CurrencyCode, CurrencyName, Money } from 'interfaces'
import React, { useMemo } from 'react'
import { useAppSelector } from 'store'

export const BalanceWalletModal = ({
  opts = {},
  close,
}: {
  opts?: CustomModalProps
  close: () => void
  destinationWallet: Money
}): JSX.Element => {
  const { t } = useTranslation()

  const { balance, wallet } = useAppSelector((state) => state.userBalance)
  const { currenciesExchanges } = useAppSelector(
    (state) => state.currenciesExchanges,
  )
  // const exchangeBalanceState = useAppSelector(
  //   (state: RootState) => state.exchangeBalance,
  // )
  // const [balanceModalValue, setBalanceModalValue] = useState<BalanceModal>({
  //   availableBalance: 0,
  //   onHold: 0,
  //   withdrawlMoney: 0,
  //   currency: CurrencyCode.USD,
  // })
  const { addFunds } = useAddFundsAndCashout({
    origin: 'Profile',
  })
  const actions = useMemo(() => {
    const commonBtnProps: ButtonProps = {
      autoWidth: true,
      size: 'medium',
      buttonType: 'secondary',
      textPreset: 'paragraph2',
    }
    // const originWallets = wallet?.filter(
    //   (W) =>
    //     W.maxWithdrawal?.currency !== destinationWallet?.currency &&
    //     W.maxWithdrawal?.amount > 0,
    // )

    const visibleActions: (typeof Button)[] = []

    const buttons = {
      addMoney: (
        <Button
          {...commonBtnProps}
          left={<PlusIcon size={24} />}
          text={t('balance.addMoney')}
          onClick={addFunds}
          buttonType='primary'
          autoWidth={false}
        />
      ),
    }
    // exchange: (
    //   <Button
    //     {...commonBtnProps}
    //     left={<CurrencyExchange size={24} />}
    //     text={t('balance.exchange')}
    //     buttonType='secondary'
    //     disabled={originWallets.length == 0}
    //     onClick={() => {
    //       dispatch(
    //         setExchangeBalanceStep(ExchangeBalanceSteps.originWalletList),
    //       )
    //     }}
    //     autoWidth={false}
    //   />
    // ),
    return [...visibleActions, buttons.addMoney]
  }, [addFunds, t])

  return (
    <>
      <CustomModal
        show={true}
        theme='lighter'
        size='big'
        placeOn='right'
        height='big'
        dismissable
        onClose={close}
        {...opts}
      >
        <CustomModal.Header
          text={
            <Text preset='caption2' text={t('buySellFlow.accountBalances')} />
          }
        >
          <Button
            onClick={close}
            buttonType='link'
            autoWidth
            content={<CloseIcon color='iconPrimaryInitial' size={24} />}
            shape='square'
          />
        </CustomModal.Header>
        <CustomModal.Content>
          <div className='w-100 d-flex d-flex-col gap-16'>
            <CardList
              avatar={
                <CurrencyFlag currency={balance?.currency as CurrencyCode} />
              }
              title={t('balance.totalBalance')}
              subtitle={t(CurrencyName[balance?.currency])}
              endText={formatCurrency({
                amount: balance?.amount,
                currency: balance?.currency,
              })}
            />
            {wallet?.map((walletItem, walletIndex) => {
              const wCurrency = walletItem?.cashAvailable?.currency
              const exchanges = currenciesExchanges.exchanges.find(
                (e) => e.source === wCurrency,
              )
              return (
                <CardList
                  avatar={<CurrencyFlag currency={wCurrency as CurrencyCode} />}
                  title={wCurrency}
                  subtitle={
                    exchanges &&
                    `1 ${wCurrency} = ${Number(exchanges?.rate)} ${
                      currenciesExchanges.target
                    }`
                  }
                  endText={formatCurrency({
                    amount: walletItem?.cashAvailable?.amount,
                    currency: walletItem?.cashAvailable?.currency,
                  })}
                  endSubText={
                    exchanges?.rate &&
                    formatCurrency({
                      amount:
                        walletItem.cashAvailable?.amount *
                        Number(exchanges?.rate),
                      currency: currenciesExchanges?.target,
                    })
                  }
                  key={`${walletItem?.cashActive?.amount}-${walletIndex}`}
                />
              )
            })}
          </div>
        </CustomModal.Content>
        <CustomModal.Footer>
          <div className='d-flex w-100 gap-24'>
            {actions?.map((item, idx) => {
              return (
                <React.Fragment key={`button-${idx}`}>{item}</React.Fragment>
              )
            })}
          </div>
        </CustomModal.Footer>
      </CustomModal>
      {/* {!!exchangeBalanceState?.stepsHistory?.length &&
        exchangeBalanceState?.currentStep && (
          <ExchangeBalance
            destination={destinationWallet}
            transactionCurrency={buySell?.multiCheckout[0]?.asset?.currency}
            setBalanceModalValue={setBalanceModalValue}
            availableBalance={balanceModalValue?.availableBalance}
            currency={balanceModalValue?.currency}
            onHold={balanceModalValue?.onHold}
            withdrawlMoney={balanceModalValue?.withdrawlMoney}
          />
        )} */}
    </>
  )
}
