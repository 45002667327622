import { MouseEvent, useEffect, useRef, useState } from 'react'
import './sliderInput.styles.scss'
import { isNil } from 'components/utils'

interface SliderInputProps {
  min: number
  max: number
  value: number
  steps: number
  onChange: (value: number) => void
  labelFormatter?: (v: string) => string
  behaviour: 'snap' | 'free'
  disabled?: boolean
}

export const SliderInput = ({
  behaviour = 'snap',
  max = 100,
  min = 0,
  value = 0,
  onChange,
  steps,
  labelFormatter,
  disabled = false,
}: SliderInputProps): JSX.Element => {
  const bar = useRef<HTMLDivElement>()

  const [points, setPoints] = useState<number[]>([])

  useEffect(() => {
    if (bar?.current) {
      const pointsArr = Array.from({ length: steps + 1 }, (_, idx) => {
        return ((max - min) / steps) * idx + min
      })
      setPoints(pointsArr)
    }
  }, [steps, min, max])

  const handleBtnMouseDown = (): void => {
    window?.addEventListener('mousemove', handleMouseMove)
    window?.addEventListener('mouseup', () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseMove)
    })
  }

  const getPositionForValue = (value: number): number => {
    let parsedValue = value
    if (value < min) {
      parsedValue = min
    }
    if (value > max) {
      parsedValue = max
    }
    if (!bar.current) return 0
    const barWidth = bar?.current?.getBoundingClientRect().width
    return ((parsedValue - min) / (max - min)) * barWidth
  }

  const handleMoveToPoint = (value: number): void => {
    if (!bar.current) return
    onChange(value)
  }

  const customRoundPositionValue = (value: number): number => {
    const decimalPart = value % 1
    const threshold = 0.05
    return decimalPart < threshold ? Math.floor(value) : value
  }

  const handleMouseMove = (e: globalThis.MouseEvent): void => {
    if (!bar.current) return

    const barRect = bar.current.getBoundingClientRect()
    let newValue = customRoundPositionValue(
      ((e.clientX - barRect.left) / barRect.width) * (max - min) + min,
    )

    if (newValue > max || newValue < min) {
      return
    }
    if (behaviour === 'snap') {
      const stepIndex = Math.round(
        ((e.clientX - barRect.left) / barRect.width) * steps,
      )
      newValue = points[stepIndex]
    }

    onChange(newValue)
  }
  const handleClick = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ): void => {
    handleMouseMove(e.nativeEvent)
  }
  useEffect(() => {
    return () => {
      window?.removeEventListener('mousemove', handleMouseMove)
      window?.removeEventListener('mouseup', handleMouseMove)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`slider-input ${disabled ? '--disabled' : ''}`} aria-hidden>
      <div
        className='slider-input__bar'
        ref={bar}
        aria-hidden
        onClick={handleClick}
      >
        <div className='slider-input__bar-base' aria-hidden></div>
        {!isNil(value) && (
          <div
            className='slider-input__bar-fill'
            style={{ width: `${getPositionForValue(value)}px` }}
          ></div>
        )}
        {points?.map((_value, idx) => (
          <div
            key={idx}
            aria-hidden
            onClick={(e) => {
              handleMoveToPoint(_value)
              e.stopPropagation()
            }}
            className={`slider-input__point ${idx === 0 ? '--first' : ''} ${
              idx === points?.length - 1 ? '--last' : ''
            } ${_value <= value ? '--active' : ''}`}
            style={{ left: `${getPositionForValue(_value)}px` }}
          ></div>
        ))}

        <div
          className={`slider-input__thumb-btn ${
            value >= max
              ? '--on-last-position'
              : value <= min
              ? '--on-first-position'
              : ''
          }`}
          role='button'
          aria-hidden
          onMouseDown={() => {
            handleBtnMouseDown()
          }}
          style={{ left: getPositionForValue(value) }}
        ></div>
      </div>
      <div className='slider-input__label-container'>
        <span
          className='slider-input__point-label'
          style={{ left: `${getPositionForValue(value)}px` }}
          aria-hidden
          onClick={() => handleMoveToPoint(value)}
        >
          {labelFormatter
            ? labelFormatter(((value / max) * 100).toString())
            : ((value / max) * 100).toFixed(0)}
          %
        </span>
      </div>
    </div>
  )
}
