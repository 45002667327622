import { createReducer } from '@reduxjs/toolkit'
import { OrderTypeEnum, PurchaseState } from '@interfaces'
import {
  clearLimitOrderDetails,
  clearMarketOrderDetails,
  clearOrderOperationType,
  clearOrderType,
  clearPreorderError,
  fetchMultiOrderCheckout,
  fetchOrderTypes,
  resetError,
  resetOrder,
  sendOrderData,
  setLimitOrderDetails,
  setMarketOrderDetails,
  setOperationStockId,
  setOrderOperationType,
  setOrderType,
} from './thunk'

const initialState: PurchaseState = {
  multiCheckout: null,
  isLoading: false,
  isOrderSuccess: false,
  error: null,
  sendOrderError: null,
  sendPreOrderError: null,
  userBankInfo: null,
  ordersData: null,
  orderTypes: [OrderTypeEnum.market],
  tifTypes: [],
  maxTif: null,
  stockId: null,
  orderUserInput: {
    orderOperationType: null,
    orderType: null,
    marketOrder: {
      shares: null,
    },
    limitOrder: {
      expirationDate: null,
      shares: null,
      price: null,
    },
  },
  errorsData: [],
}

export const buySellReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchOrderTypes.pending, (acc: PurchaseState) => {
      acc.isLoading = true
      acc.error = null
    })
    .addCase(fetchOrderTypes.fulfilled, (acc: PurchaseState, action) => {
      const { payload } = action

      acc.orderTypes = payload?.allowedOrderMethods || [OrderTypeEnum.market]
      acc.tifTypes = payload?.tifTypes
      acc.maxTif = payload?.maxTif
      acc.isLoading = false
    })
    .addCase(fetchOrderTypes.rejected, (acc: PurchaseState, { error }) => {
      acc.isLoading = false
      acc.error = error.message
    })
    .addCase(sendOrderData.pending, (acc: PurchaseState) => {
      acc.isLoading = true
      acc.error = null
      acc.isOrderSuccess = false
    })
    .addCase(sendOrderData.fulfilled, (acc: PurchaseState, action) => {
      const { payload } = action
      acc.isLoading = false
      acc.isOrderSuccess = true
      const { errorDetails, successDetails, summary } = payload
      acc.ordersData = successDetails
      acc.errorsData = errorDetails
      acc.sendOrderError =
        !summary.success && !!summary.errors && !!errorDetails?.length
          ? errorDetails[0]?.apiError?.message
          : null
    })
    .addCase(sendOrderData.rejected, (acc: PurchaseState, { error }) => {
      acc.isLoading = false
      acc.isOrderSuccess = false
      acc.sendOrderError = error.message || ''
    })
    .addCase(fetchMultiOrderCheckout.pending, (acc: PurchaseState) => {
      acc.isLoading = true
      acc.sendPreOrderError = null
      acc.error = null
      acc.multiCheckout = null
    })
    .addCase(
      fetchMultiOrderCheckout.fulfilled,
      (acc: PurchaseState, action) => {
        const { payload } = action
        acc.isLoading = false
        acc.sendPreOrderError = null
        acc.multiCheckout = payload
      },
    )
    .addCase(
      fetchMultiOrderCheckout.rejected,
      (acc: PurchaseState, { error }) => {
        acc.isLoading = false
        acc.sendPreOrderError = error.message
        acc.error = error.message || ''
        acc.multiCheckout = null
      },
    )
    .addCase(setOperationStockId, (acc: PurchaseState, { payload }) => {
      acc.stockId = payload
    })
    .addCase(resetOrder, (acc: PurchaseState) => {
      acc.isOrderSuccess = false
      acc.sendOrderError = null
      acc.multiCheckout = null
      acc.ordersData = null
      acc.error = null
    })
    .addCase(resetError, (acc: PurchaseState) => {
      acc.error = null
    })
    .addCase(setOrderOperationType, (acc: PurchaseState, { payload }) => {
      acc.orderUserInput.orderOperationType = payload
    })
    .addCase(setOrderType, (acc: PurchaseState, { payload }) => {
      acc.orderUserInput.orderType = payload
    })
    .addCase(clearOrderType, (acc: PurchaseState) => {
      acc.orderUserInput.orderType = null
    })
    .addCase(clearOrderOperationType, (acc: PurchaseState) => {
      acc.orderUserInput.orderOperationType = null
    })
    .addCase(setMarketOrderDetails, (acc: PurchaseState, { payload }) => {
      acc.orderUserInput.marketOrder = payload
    })
    .addCase(clearMarketOrderDetails, (acc: PurchaseState) => {
      acc.orderUserInput.marketOrder = initialState.orderUserInput.marketOrder
    })
    .addCase(setLimitOrderDetails, (acc: PurchaseState, { payload }) => {
      acc.orderUserInput.limitOrder = payload
    })
    .addCase(clearLimitOrderDetails, (acc: PurchaseState) => {
      acc.orderUserInput.limitOrder = initialState.orderUserInput.limitOrder
    })
    .addCase(clearPreorderError, (acc: PurchaseState) => {
      acc.sendPreOrderError = null
    })
})
