import { getIconColorSVGFill, UIIcon, UIIconColorProps } from 'components/utils'

export const TakeProfitIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1'
        y='5'
        width='22'
        height='13'
        fill={getIconColorSVGFill('transparent')}
      />
      <path
        d='M1.5 17.2002L22.5 17.2002'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeDasharray='2 2'
      />
      <path
        d='M1.5 10L22.5 10'
        stroke='#127F42'
        strokeWidth='2'
        strokeLinecap='round'
        strokeDasharray='2 2'
      />
      <path
        d='M3.09998 19.6001L7.63832 14.2366C7.88943 13.9398 8.28977 13.8155 8.66482 13.9178L12.2828 14.9045C12.7086 15.0206 13.1601 14.844 13.394 14.4698L19.0004 5.5'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M18 10C18 8.89543 17.1046 8 16 8C14.8954 8 14 8.89543 14 10C14 11.1046 14.8954 12 16 12C17.1046 12 18 11.1046 18 10Z'
        fill='white'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
      />
    </svg>
  )
}
