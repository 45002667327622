import { useTranslation } from '@hooks'
import { Spacer, Text } from '@components'
import MainLogo from 'components/mainLogo/mainLogo'
import ExampleFixedTakeProfitOne from '../../shared/exampleFixedTakeProfitOne'
import ExampleFixedTakeProfitTwo from '../../shared/exampleFixedTakeProfitTwo'

export const FixedTakeProfitInfoContent = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className='d-flex d-flex-col align-start'>
      <div className='dividerInfo mt-2 pb-2 text-left'>
        <Text
          preset='bodyRegular'
          text={t('stopLossTakeProfit.fixedTakeProfitInfo.description')}
        />
      </div>
      <div className='mt-2 text-left'>
        <Text
          preset='subtitle3'
          color='secondary'
          text={t('stopLossTakeProfit.fixedTakeProfitInfo.example')}
        />

        <div className='mt-1 mb-1'>
          <Text
            preset='bodyRegular'
            color='secondary'
            text={t('stopLossTakeProfit.fixedTakeProfitInfo.explanationOne')}
          />
        </div>
      </div>
      <div className='mt-4 paragraph2'>
        <ExampleFixedTakeProfitOne
          currentPriceText={t(
            'stopLossTakeProfit.fixedTakeProfitInfo.currentPriceText',
          )}
          takeProfitText={t(
            'stopLossTakeProfit.fixedTakeProfitInfo.takeProfitPrice',
          )}
        />
      </div>
      <div className='mt-3 text-left'>
        <Text
          preset='bodyRegular'
          color='secondary'
          text={t('stopLossTakeProfit.fixedTakeProfitInfo.explanationTwo')}
        />
      </div>
      <div className='mt-4 paragraph2'>
        <ExampleFixedTakeProfitTwo
          currentPriceText={t(
            'stopLossTakeProfit.fixedTakeProfitInfo.closePosition',
          )}
        />
      </div>
      <div className='mt-4 pb-2 text-left '>
        <Text
          preset='bodyRegular'
          color='secondary'
          text={t('stopLossTakeProfit.fixedTakeProfitInfo.explanationThree')}
        />
      </div>

      <div className='mt-3 text-left infoLogo '>
        <div className='containerLogo mb-2'>
          <MainLogo className='containerLogo__icon' />
        </div>
        <div className='containerLogo__info'>
          <Text
            preset='subtitle2'
            color='primary'
            text={t(
              'stopLossTakeProfit.fixedTakeProfitInfo.pushNotificationTitle',
            )}
          />
          <Spacer preset='tiny' />
          <Text
            preset='bodyRegular'
            color='secondary'
            text={t(
              'stopLossTakeProfit.fixedTakeProfitInfo.pushNotificationDescription',
            )}
          />
        </div>
      </div>
    </div>
  )
}
