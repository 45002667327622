import {
  DepositWithdrawalTransaction,
  DividendTransaction,
  NoteTransaction,
  OrderTransaction,
  OrderTransactions,
  OrderTransactionStatusEnum,
  SafekeepingTransaction,
  SelectedFilters,
  TransactionFiltersStatusParam,
  Transactions,
  TransactionType,
  WalletTransaction,
} from '@interfaces'
import { FiltersResponse } from '@interfaces/searchFilters'
import { api } from '@utils/api/api'
import { buildQueryParams } from '@utils/helpers'

export const getTransactions = (
  page = 0,
  size = 20,
  filters: SelectedFilters = {},
): Promise<Transactions> => {
  const params = buildQueryParams({
    page: page?.toString(),
    size: size?.toString(),
    ...filters,
  })
  return api.get(`/api/v2/transactions${params}`)
}

export const getOrderTransactions = (
  page = 0,
  size = 20,
  statuses?: OrderTransactionStatusEnum[],
  filters: SelectedFilters = {},
): Promise<OrderTransactions> => {
  const params = buildQueryParams({
    page: page?.toString(),
    size: size?.toString(),
    statuses: statuses?.join(','),
    ...filters,
  })

  return api.get(`/api/v2/transactions/orders${params}`)
}

export const getOrderDetail = (id: string): Promise<OrderTransaction> => {
  return api.get(`/api/v2/transactions/orders/${id}`)
}

export const getDepositTransaction = (
  id: string,
): Promise<DepositWithdrawalTransaction> => {
  return api.get(`/api/v1/transactions/deposit/${id}`)
}

export const getWithdrawalTransaction = (
  id: string,
): Promise<DepositWithdrawalTransaction> => {
  return api.get(`/api/v1/transactions/withdrawal/${id}`)
}

export const getDividendTransaction = (
  id: string,
  type: TransactionType,
): Promise<DividendTransaction> => {
  return api.get(`/api/v1/transactions/dividend/${id}?dividendType=${type}`)
}

export const cancelOrder = (id: string): Promise<OrderTransaction> => {
  return api.post(`/api/v1/orders/${id}/cancel`)
}

export const getTransactionsFilters = (
  transactionStatus: TransactionFiltersStatusParam,
): Promise<FiltersResponse> => {
  return api.get(
    `/api/v1/transactions/filters?transactionStatus=${transactionStatus}`,
  )
}

export const getWalletTransaction = (
  id: string,
): Promise<WalletTransaction> => {
  return api.get(`/api/v1/transactions/wallets/${id}`)
}
export const getNoteTransaction = (id: string): Promise<NoteTransaction> => {
  return api.get(`/api/v1/transactions/notes/${id}`)
}

export const getSafekeeping = (id: string): Promise<SafekeepingTransaction> => {
  return api.get(`/api/v1/transactions/safekeepingfees/${id}`)
}
