import { UIIcon, UIIconColorProps } from 'components/utils'

export const GbpFlag = ({
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_21595_53894)'>
        <rect width={40} height={40} rx={20} fill='white' />
        <g clipPath='url(#clip1_21595_53894)'>
          <path d='M-7 0H46.3333V40H-7V0Z' fill='#012169' />
          <path
            d='M-0.75 0L19.5833 15.0833L39.8333 0H46.3333V5.16667L26.3333 20.0833L46.3333 34.9167V40H39.6667L19.6667 25.0833L-0.25 40H-7V35L12.9167 20.1667L-7 5.33333V0H-0.75Z'
            fill='white'
          />
          <path
            d='M28.3333 23.4167L46.3333 36.6667V40L23.75 23.4167H28.3333ZM13 25.0833L13.5 28L-2.5 40H-7L13 25.0833ZM46.3333 0V0.25L25.5833 15.9167L25.75 12.25L42.1667 0H46.3333ZM-7 0L12.9167 14.6667H7.91667L-7 3.5V0Z'
            fill='#C8102E'
          />
          <path
            d='M13.0833 0V40H26.4167V0H13.0833ZM-7 13.3333V26.6667H46.3333V13.3333H-7Z'
            fill='white'
          />
          <path
            d='M-7 16.0833V24.0833H46.3333V16.0833H-7ZM15.75 0V40H23.75V0H15.75Z'
            fill='#C8102E'
          />
        </g>
      </g>
      <rect
        x={0.25}
        y={0.25}
        width={39.5}
        height={39.5}
        rx={19.75}
        stroke='#595B67'
        strokeWidth={0.5}
      />
      <defs>
        <clipPath id='clip0_21595_53894'>
          <rect width={40} height={40} rx={20} fill='white' />
        </clipPath>
        <clipPath id='clip1_21595_53894'>
          <rect
            width={53.3333}
            height={40}
            fill='white'
            transform='translate(-7)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
