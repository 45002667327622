/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { VisaCard } from '@assets/illustrations'
import { BankIcon } from '@assets/svg'
import { Avatar, Button, Spacer, Text } from '@components'
import { RootState } from '@store'
import { PaymentMethodTypeEnum } from 'interfaces'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export const SelectedPaymentMethodItem = ({
  onChangeClick,
}: {
  onChangeClick: () => void
}): JSX.Element => {
  const { addMoney } = useSelector((state: RootState) => state)

  const { t } = useTranslation()

  const selected = addMoney?.selectedPaymentMethod

  const title = useMemo(() => {
    switch (selected.type) {
      case 'CARD':
        return `${selected?.cardDetails?.scheme} ****${selected?.cardDetails?.last4}`
      case 'BANK':
        return selected?.name
      case PaymentMethodTypeEnum.MANUAL_TRANSFER:
        return selected?.manualTransferDetails.bankName
      default:
        return null
    }
  }, [selected])

  const avatar = useMemo(() => {
    let image: JSX.Element | string

    switch (selected.type) {
      case 'CARD':
        image = <VisaCard size={24} />
        break
      case 'BANK':
        image = selected?.iconUrl
        break
    }

    return <Avatar image={image} size='small' roundness='full' />
  }, [selected])

  return (
    <>
      <div className='d-flex d-flex-row justify-space-between w-100 align-center --fade-in-animation-fast'>
        <div className='d-flex d-flex-row align-center'>
          {addMoney.selectedPaymentMethod?.type !==
            PaymentMethodTypeEnum.MANUAL_TRANSFER && (
            <>
              <div>{avatar}</div>
              <Spacer preset='small' />
            </>
          )}

          {addMoney.selectedPaymentMethod?.type == 'CARD' && (
            <div className='w-100 d-flex justify-center'>
              {title && (
                <Text preset='paragraph2' color='primary' text={title} />
              )}
            </div>
          )}

          {addMoney.selectedPaymentMethod?.type ==
            PaymentMethodTypeEnum.MANUAL_TRANSFER && (
            <div className='w-100 d-flex justify-center'>
              <label
                htmlFor={`bank_${addMoney.selectedPaymentMethod.id}`}
                className='d-flex align-center'
              >
                <div className='logo mr-2'>
                  <BankIcon className='black' />
                </div>
                <div className='payments__screen__item__content__titles text-left'>
                  <p>
                    {addMoney.selectedPaymentMethod.type ===
                      PaymentMethodTypeEnum.MANUAL_TRANSFER &&
                      t('paymentMethods.manualTransfer')}
                  </p>
                  <small>
                    {`${addMoney.selectedPaymentMethod.manualTransferDetails?.bankName} ${addMoney.selectedPaymentMethod.manualTransferDetails.accountNumber}`}
                  </small>
                </div>
              </label>
            </div>
          )}

          {addMoney.selectedPaymentMethod?.type == 'BANK' && (
            <div className='w-100 d-flex d-flex-col'>
              <Spacer preset='tiny' />
              {title && (
                <Text preset='paragraph2' color='primary' text={title} />
              )}
              <Text
                preset='paragraph2'
                color='secondary'
                text={t(`paymentMethods.bank`)}
              />
            </div>
          )}
        </div>
        <Button
          testId='change-btn'
          buttonType='secondary'
          text={t('common.change')}
          onClick={onChangeClick}
          autoWidth
          textPreset='paragraph2'
        />
      </div>
    </>
  )
}
