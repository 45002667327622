import { Button, CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'
import { ReactComponent as QRIcon } from '@assets/svg/qr_link.svg'

export const CompleteProfileQRModal = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CustomModal show={true} theme='lighter' size='small'>
      <CustomModal.Header text={t('portfolio.complete_qr_title')} />
      <CustomModal.Content>
        <div className='d-flex d-flex-col align-center'>
          <Text
            preset='paragraph1'
            text={t('portfolio.complete_qr_link_text')}
            align='center'
          />
          <Spacer preset='medium' />
          <div className='qr__code__wrapper qr__code__wrapper--small'>
            <QRIcon className='qr__code' />
          </div>
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.ok')} onClick={close} buttonType='primary' />
      </CustomModal.Footer>
    </CustomModal>
  )
}
