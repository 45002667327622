import { createReducer } from '@reduxjs/toolkit'
import {
  clearCashoutResult,
  fetchCashout,
  fetchPortfolioBalance,
} from './thunk'
import { ReducerState, Money } from '@interfaces'

export interface CashoutInterface {
  isSuccess: boolean
  cashAvailable: Money
  cashActive: Money
  maxWithdrawal: Money
}

const initialState: ReducerState & CashoutInterface = {
  loading: false,
  error: null,
  isSuccess: null,
  cashAvailable: { amount: 0, currency: null },
  cashActive: { amount: 0, currency: null },
  maxWithdrawal: { amount: 0, currency: null },
}

const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPortfolioBalance.pending, (acc) => {
      acc.loading = true
      acc.error = null
    })
    .addCase(fetchPortfolioBalance.fulfilled, (acc, { payload }) => {
      acc.loading = false
      acc.error = null
      acc.cashAvailable = payload.cashAvailable
      acc.cashActive = payload.cashActive
      acc.maxWithdrawal = payload.maxWithdrawal
    })
    .addCase(fetchPortfolioBalance.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
    })
    .addCase(fetchCashout.pending, (acc) => {
      acc.loading = true
      acc.error = null
      acc.isSuccess = null
    })
    .addCase(fetchCashout.fulfilled, (acc) => {
      acc.loading = false
      acc.error = null
      acc.isSuccess = true
    })
    .addCase(fetchCashout.rejected, (acc, { error }) => {
      acc.loading = false
      acc.error = error.message || ''
      acc.isSuccess = false
    })
    .addCase(clearCashoutResult, (acc) => {
      acc.error = null
      acc.isSuccess = null
    })
})

export default reducers
