import { Condition, ConditionType, PreorderCondition } from 'interfaces'
import { isTrailingStopLossOrTakeProfit } from './isTrailingStopLossOrTakeProfit'
import { isStopLossOrder } from './isStopLossOrder'

export const getStopLossTakeProfitAbbrName = (
  conditionInfo: PreorderCondition | Condition,
): string => {
  const isTrailing = isTrailingStopLossOrTakeProfit(conditionInfo)
  const isStopLoss = conditionInfo?.stopType === ConditionType?.STOP_LOSS

  return !isTrailing
    ? isStopLoss
      ? 'fixedStopLossAbbr'
      : 'fixedTakeProfitAbbr'
    : isStopLoss
    ? 'trailingStopLossAbbr'
    : 'trailingTakeProfitAbbr'
}

export const getStopLossTakeProfitName = (
  conditionInfo: PreorderCondition | Condition,
): string => {
  const isTrailing = isTrailingStopLossOrTakeProfit(conditionInfo)
  const isStopLoss = isStopLossOrder(conditionInfo)

  return !isTrailing
    ? `buySellFlow.${
        isStopLoss ? 'fixedStopLossPrice' : 'fixedTakeProfitPrice'
      }`
    : `buySellFlow.${
        isStopLoss ? 'trailingStopLossPrice' : 'trailingTakeProfitPrice'
      }`
}
