import { formatNumber, formatNumberIntl } from '@utils/helpers'
import { PieChart, Spacer, Text } from 'components'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const PortfolioValueChartComp = (): JSX.Element => {
  const { investments } = useSelector((state: RootState) => state)

  const portfolioDistribution: Array<{
    id: string
    label: string
    value: number
  }> = investments?.portfolios?.map((p) => {
    const portfolioValue = p?.positions?.reduce(
      (acc, p) =>
        acc + Number(p?.monetaryStatsInPortfolioBaseCurrency?.value?.amount),
      0,
    )

    return {
      id: p?.id,
      label: p?.title,
      value: portfolioValue,
    }
  })

  const colors = [
    'var(--primary500)',
    'var(--neutral100)',
    'var(--success500)',
    'var(--warning400)',
    'var(--destructive500)',
    'var(--tertiary400)',
    'var(--tertiary300)',
  ]

  const allValuesAbove1k = investments?.portfolios?.every(
    (p) => p?.currentValue?.amount >= 1000,
  )

  const formatPortfolioValue = (value: number): string => {
    return formatNumber(allValuesAbove1k ? value / 1000 : value)
  }

  return (
    <>
      <PieChart
        innerElem={
          <div className='d-flex d-flex-col align-center justify-center'>
            <Text
              preset='hero'
              text={formatNumber(investments?.currentValue?.amount)}
            />
            <Spacer preset='extraTiny' />
            <Text
              preset='subtitle3'
              text={`${investments?.currentValue?.currency}`}
            />
          </div>
        }
        innerRadius={0.72}
        padAngle={0.015}
        data={portfolioDistribution?.map((p) => ({
          label: p.label,
          value: p.value,
        }))}
      />
      <Spacer preset='huge' />
      {portfolioDistribution && portfolioDistribution?.length > 0
        ? portfolioDistribution?.map((p, idx) => {
            return (
              <div key={`positions-summary-II-${p?.id}-${idx}`}>
                <div className='d-flex justify-space-between'>
                  <div className='d-flex align-center'>
                    <div
                      className='chart-dot'
                      style={{ backgroundColor: colors[idx] }}
                    ></div>
                    <Spacer preset='tinyPlus' />
                    <Text preset='paragraph2' text={p.label} />
                  </div>
                  <div>
                    <Text
                      preset='paragraph2'
                      text={`(${formatPortfolioValue(
                        p?.value,
                      )}) ${formatNumberIntl(
                        (p?.value / investments?.currentValue?.amount) * 100,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      )}%`}
                    />
                  </div>
                </div>
                <Spacer preset='smallMinus' />
              </div>
            )
          })
        : null}
    </>
  )
}

export const PortfolioValueChart = memo(PortfolioValueChartComp)
