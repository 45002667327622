import { ChevronLeft } from '@assets/icons'
import CardList from '@screens/profile/components/cardList'
import { formatCurrency } from '@utils/helpers'
import { Button, CustomModal, Spacer, Text } from 'components'
import { CurrencyFlag } from 'components/currencyFlag/currencyFlag'
import { ExchangeBalanceSteps } from 'features/exchangeBalance/exchangeBalance.props'
import { setExchangeBalanceStep } from 'features/exchangeBalance/thunk'
import { BalanceModal, CurrencyCode, CurrencyName, Money } from 'interfaces'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Fragment } from 'react/jsx-runtime'
import { useAppSelector } from 'store'

const OriginWalletList = ({
  onPrevious,
  setOriginWallet,
  destinationWallet,
}: {
  onPrevious: () => void
  setOriginWallet: (value: BalanceModal) => void
  destinationWallet: Money
}): JSX.Element => {
  const { t } = useTranslation()
  const userBalanceState = useAppSelector((state) => state.userBalance)
  const dispatch = useDispatch()

  useEffect(() => {
    const originWallets = userBalanceState?.wallet?.filter(
      (val) =>
        val?.maxWithdrawal?.currency !== destinationWallet?.currency &&
        val?.maxWithdrawal?.amount > 0,
    )
    if (originWallets?.length === 1) {
      setOriginWallet({
        availableBalance: originWallets[0]?.cashAvailable?.amount,
        currency: originWallets[0]?.cashAvailable?.currency,
        onHold: originWallets[0]?.lockedCash?.amount,
        withdrawlMoney: originWallets[0]?.maxWithdrawal?.amount,
      })
      dispatch(setExchangeBalanceStep(ExchangeBalanceSteps.walletList))
    }
  }, [
    userBalanceState?.wallet,
    setOriginWallet,
    dispatch,
    destinationWallet?.currency,
  ])
  return (
    <>
      <CustomModal.Header
        text={
          <Text
            preset='subtitle2'
            text={t('exchangeBalance.selectOriginWallet')}
          />
        }
        className='w-100 d-flex align-center justify-space-between'
      >
        <Button
          onClick={onPrevious}
          buttonType='link'
          autoWidth
          content={<ChevronLeft color='iconPrimaryInitial' size={36} />}
          shape='square'
        />
      </CustomModal.Header>
      <CustomModal.Content>
        <div className='w-100'>
          {userBalanceState?.wallet
            ?.filter(
              (val) =>
                val?.maxWithdrawal?.currency !== destinationWallet?.currency &&
                val?.maxWithdrawal?.amount > 0,
            )
            ?.map((walletItem, walletIndex) => {
              return (
                <Fragment key={walletIndex}>
                  {walletItem?.cashAvailable?.amount > 0 && (
                    <CardList
                      onClick={() => {
                        setOriginWallet({
                          availableBalance: walletItem?.cashAvailable?.amount,
                          currency: walletItem?.cashAvailable?.currency,
                          onHold: walletItem?.lockedCash?.amount,
                          withdrawlMoney: walletItem?.maxWithdrawal?.amount,
                        })
                        dispatch(
                          setExchangeBalanceStep(
                            ExchangeBalanceSteps.walletList,
                          ),
                        )
                      }}
                      avatar={
                        <CurrencyFlag
                          currency={
                            walletItem?.cashAvailable?.currency as CurrencyCode
                          }
                        />
                      }
                      title={
                        walletItem?.cashAvailable?.currency as CurrencyCode
                      }
                      subtitle={t(
                        CurrencyName[
                          walletItem?.cashAvailable?.currency as CurrencyCode
                        ],
                      )}
                      endText={formatCurrency({
                        amount: walletItem?.cashAvailable?.amount,
                        currency: walletItem?.cashAvailable
                          ?.currency as CurrencyCode,
                      })}
                    />
                  )}

                  <Spacer preset='tiny' />
                </Fragment>
              )
            })}
        </div>
      </CustomModal.Content>
    </>
  )
}

export default OriginWalletList
