import { Spacer, Text } from 'components'

export const BasicCardField = ({
  title,
  value,
  subtitle,
}: {
  title: JSX.Element | string
  value: JSX.Element | string
  subtitle?: JSX.Element | string
}): JSX.Element => {
  return (
    <>
      <Spacer preset='small' />
      <div className='d-flex align-center'>
        <div className='w-55'>
          {typeof title === 'string' ? (
            <Text preset='paragraph2' textStyle='uppercase' text={title} />
          ) : (
            title
          )}
          {typeof subtitle === 'string' ? (
            <Text preset='paragraph2' textStyle='uppercase' text={subtitle} />
          ) : (
            subtitle
          )}
        </div>
        <div className='d-flex w-45 justify-end align-end'>
          {typeof value === 'string' ? (
            <Text preset='bigText' align='right' text={value} />
          ) : (
            value
          )}
        </div>
      </div>
    </>
  )
}
