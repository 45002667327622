import { ReceiptIcon } from '@assets/svg'
import {
  fetchDocument,
  cleanPdf,
} from '@screens/transactions/transactionPDF/thunk'
import { Button } from 'components'
import { t } from 'i18next'
import { TransactionDetailType, TransactionType } from 'interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'

export function useTransactionDocButton(
  transactionDetail: TransactionDetailType,
  financialEvent: string,
): JSX.Element {
  const dispatch = useDispatch()
  const transactionPdf = useSelector((state: RootState) => state.transactionPdf)
  const pdfTo = (id: string): void => {
    dispatch(fetchDocument(id))
  }

  const base64ToArrayBuffer = (base64: string): Uint8Array => {
    const binaryString = window.atob(base64)
    const binaryLen = binaryString.length
    const bytes = new Uint8Array(binaryLen)
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i)
      bytes[i] = ascii
    }
    return bytes
  }

  if (transactionPdf.file) {
    const bytesBlob = base64ToArrayBuffer(transactionPdf.file)
    const newBlob = new Blob([bytesBlob], { type: 'application/pdf' })
    const urlBlob = URL.createObjectURL(newBlob)

    window.open(urlBlob)
    dispatch(cleanPdf())
  }

  const isDividend =
    transactionDetail?.type === TransactionType.dividendCash ||
    transactionDetail?.type === TransactionType.dividendShares

  let buttonText: string = t('transactions.operationConfirmation')

  if (isDividend) {
    buttonText = financialEvent
      ? t('transactions.financialEventConfirmation')
      : t('transactions.dividendConfirmation')
  }
  if (transactionDetail?.type === TransactionType.safekeeping) {
    buttonText = t('transactions.paymentConfirmation')
  }
  return [
    TransactionType.dividendCash,
    TransactionType.dividendShares,
    TransactionType.buy,
    TransactionType.sell,
    TransactionType.safekeeping,
  ].includes(transactionDetail?.type as TransactionType) &&
    transactionDetail?.documentId ? (
    <Button
      autoWidth={true}
      text={buttonText}
      buttonType='ghost'
      textPreset='paragraph2'
      loading={transactionPdf.loading}
      onClick={() => pdfTo(transactionDetail?.documentId)}
      right={<ReceiptIcon />}
    />
  ) : null
}
