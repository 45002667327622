import { ChangeEvent } from 'react'
import { formatInputNumber } from './formatting'
import { sanitizeNumber } from './helpers'
import { getFormatLang } from './langs'

/**
 * Improved version of useOnChangeMoneyForm:
 * returns both number and string in a callback function.
 */
export const onChangeNumericInput = (
  decimals = 2,
  fn: (res: { asString: string; sanitized: number }) => void,
) => {
  return (e: ChangeEvent<HTMLInputElement>) => {
    const lang = getFormatLang()
    const sanitized = sanitizeNumber(e?.target?.value)
    const validValue = !isNaN(Number(sanitized))

    const val = formatInputNumber(e?.target?.value, lang, decimals)

    fn({
      asString: validValue ? val : '',
      sanitized: validValue ? sanitized : 0,
    })
  }
}
