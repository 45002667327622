import './transactionItem.style.scss'
import {
  Condition,
  Money,
  CurrencyCode,
  OrderCancellationReason,
  OrderTransactionChildStatusEnum,
  OrderTransactionStatusEnum,
  OrderTypeEnum,
  Transaction,
  TransactionType,
} from '@interfaces'
import { formatMoney, getFormattedShares } from '@utils/helpers'
import { t } from 'i18next'
import { useMemo } from 'react'
import {
  BuyLimitOrder,
  Cashout,
  Dividend,
  MarketOrder,
  SellLimitOrder,
  StopLossTransaction,
  TakeProfitTransaction,
  TopUp,
  TransferIncoming,
  TransferOutgoing,
} from '@assets/svg'
import { isTakeProfitOrder } from '@utils/isTakeProfitOrder'
import { isStopLossOrder } from '@utils/isStopLossOrder'
import { isFixedStopLossOrTakeProfit } from '@utils/isFixedStopLossOrTakeProfit'
import {
  EurToGbp,
  EurToUsd,
  ExchangeIcon,
  GbpToEur,
  GbpToUsd,
  UsdToGbp,
  UsdToEur,
  NoteCreditIcon,
  NoteDebitIcon,
} from '@assets/icons'
import { stringBuilder } from 'components/utils'
import { Avatar, Spacer } from 'components'
import { getStopLossTakeProfitName } from '@utils/getStopLossTakeProfitName'

export const transactionIconSelector = (type): JSX.Element | null => {
  switch (type) {
    case TransactionType.safekeeping:
      return <NoteDebitIcon color='surfaceCanvasPrimaryInitial' size={40} />
    case TransactionType.noteCredit:
      return <NoteCreditIcon color='surfaceCanvasPrimaryInitial' size={40} />
    case TransactionType.noteDebit:
      return <NoteDebitIcon color='surfaceCanvasPrimaryInitial' size={40} />
    case TransactionType.withdrawal:
      return <Cashout />
    case TransactionType.deposit:
      return <TopUp />

    default:
      return null
  }
}

const positiveText = 'positive--text'
const negativeText = 'negative--text'
const hyphen = '-'
export interface TransactionItemInterface {
  handleOnClick: () => void
  data: Transaction
  animated?: boolean
}

export const walletTransferLogo = (
  to: CurrencyCode,
  from: CurrencyCode,
): JSX.Element => {
  if (to === CurrencyCode.EUR && from === CurrencyCode.GBP)
    return <GbpToEur size={40} />
  if (to === CurrencyCode.EUR && from === CurrencyCode.USD)
    return <UsdToEur size={40} />
  if (to === CurrencyCode.GBP && from === CurrencyCode.EUR)
    return <EurToGbp size={40} />
  if (to === CurrencyCode.GBP && from === CurrencyCode.USD)
    return <UsdToGbp size={40} />
  if (to === CurrencyCode.USD && from === CurrencyCode.EUR)
    return <EurToUsd size={40} />
  if (to === CurrencyCode.USD && from === CurrencyCode.GBP)
    return <GbpToUsd size={40} />
  return null
}

export function TransactionItem({
  handleOnClick,
  data,
  animated = false,
}: TransactionItemInterface): JSX.Element {
  const {
    amount,
    logo,
    title,
    type,
    orderDetails,
    ticker,
    dividendSharesDetails,
  } = data

  const isCancelled =
    orderDetails?.status === OrderTransactionStatusEnum.cancelled

  const isCompleted =
    orderDetails?.status === OrderTransactionStatusEnum.completed

  const condition = orderDetails?.condition
  const colorClass = isTakeProfitOrder(condition) ? positiveText : negativeText

  const iconProps = { className: colorClass }
  const SLTPIconMap: Record<string, JSX.Element> = {
    takeProfit: <TakeProfitTransaction {...iconProps} />,
    stopLoss: <StopLossTransaction {...iconProps} />,
    sellLimit: <SellLimitOrder {...iconProps} />,
    market: <MarketOrder {...iconProps} />,
  }

  const isFixedSLTP = isFixedStopLossOrTakeProfit(condition)

  const SLTPTextMap: Record<string, string> = {
    takeProfit: `transactions.${
      isFixedSLTP ? 'fixedTakeProfit' : 'trailingTakeProfit'
    }`,
    stopLoss: `transactions.${
      isFixedSLTP ? 'fixedStopLoss' : 'trailingStopLoss'
    }`,
    sellLimit: 'transactions.sellLimitOrder',
    sell: 'transactions.sell',
  }

  const itemTitle = useMemo(() => {
    if (type === TransactionType.walletTransferIncoming) {
      return t('transactions.incommingWalletTransfer')
    }
    if (type === TransactionType.walletTransferOutging) {
      return t('transactions.outgoingWalletTransfer')
    }
    return title
  }, [title, type])

  const config = useMemo(() => {
    return {
      [TransactionType.walletTransferIncoming]: {
        signSymbol: '+',
        sharesSymbol: '',
      },
      [TransactionType.walletTransferOutging]: {
        signSymbol: '-',
        sharesSymbol: '',
      },
      [TransactionType.noteDebit]: {
        signSymbol: '-',
        sharesSymbol: '',
      },
      [TransactionType.noteCredit]: {
        signSymbol: '+',
        sharesSymbol: '',
      },
      [TransactionType.safekeeping]: {
        signSymbol: '-',
        sharesSymbol: '',
      },
      [TransactionType.deposit]: {
        signSymbol: '+',
        sharesSymbol: '',
      },
      [TransactionType.withdrawal]: {
        signSymbol: '-',
        sharesSymbol: '',
      },
      [TransactionType.dividendCash]: {
        signSymbol: '+',
        sharesSymbol: '',
      },
      [TransactionType.dividendShares]: {
        signSymbol: '+',
        sharesSymbol: '+',
      },
      [TransactionType.buy]: {
        signSymbol: '-',
        sharesSymbol: '+',
      },
      [TransactionType.sell]: {
        signSymbol: '+',
        sharesSymbol: '-',
      },
    }
  }, [])

  const isOrder = useMemo(() => {
    return type === TransactionType.buy || type === TransactionType.sell
  }, [type])

  const isLimitOrder = useMemo(() => {
    return orderDetails?.method === OrderTypeEnum.limit
  }, [orderDetails?.method])

  const isStopLossOrTakeProfit = useMemo(() => !!condition, [condition])
  const isWalletTransfer = useMemo(() => {
    return (
      type === TransactionType.walletTransferIncoming ||
      type === TransactionType.walletTransferOutging
    )
  }, [type])

  const topRightInfo = useMemo(() => {
    if (!type) return
    const transactionConfig = config[type]
    if (type === TransactionType.dividendShares) {
      return `${transactionConfig?.signSymbol}${dividendSharesDetails?.shares} ${ticker}`
    }

    const isBuy = type === TransactionType.buy

    const isNotCompleted =
      orderDetails?.status !== OrderTransactionStatusEnum.completed

    const total =
      isBuy && isNotCompleted && orderDetails?.lockedCash
        ? formatMoney(
            orderDetails?.lockedCash?.amount,
            orderDetails?.lockedCash?.currency,
          )
        : formatMoney(amount?.amount, amount?.currency)

    const amountAsString = total.toString()

    const alreadyWithSymbol =
      amountAsString?.includes('+') || amountAsString?.includes('-')

    const symbol = alreadyWithSymbol ? '' : transactionConfig?.signSymbol

    return `${symbol}${total}`
  }, [config, type, orderDetails, amount, dividendSharesDetails, ticker])

  const topRightInfoClasses = useMemo(() => {
    return stringBuilder([
      ['transaction-item__amount', true],
      ['paragraph2', true],
      [
        'transaction-item__amount--crossed',
        isCancelled && isStopLossOrTakeProfit,
      ],
    ])
  }, [isCancelled, isStopLossOrTakeProfit])

  const getStopLossTakeProfitLiteral = (
    isCompleted: boolean,
    condition: Condition,
  ): string => {
    if (isCompleted) return 'transactions.executionPrice'
    return getStopLossTakeProfitName(condition)
  }

  const getPriceLiteral = (
    isStopLossOrTakeProfit: boolean,
    isCompleted: boolean,
    condition: Condition,
    method?: OrderTypeEnum,
  ): string => {
    if (isStopLossOrTakeProfit) {
      return getStopLossTakeProfitLiteral(isCompleted, condition)
    }

    return method === OrderTypeEnum.limit
      ? 'transactions.limitPrice'
      : 'transactions.marketPrice'
  }

  const getMoneyForStopLossOrTakeProfit = (
    condition?: Condition,
    orderDetails?,
  ): Money | '-' => {
    const isCompleted =
      orderDetails?.status === OrderTransactionStatusEnum.completed

    if (isCompleted) {
      return orderDetails?.sharePrice ? orderDetails?.sharePrice : hyphen
    }

    return condition?.stopPrice
      ? {
          amount: condition.stopPrice,
          currency: orderDetails?.currency,
        }
      : hyphen
  }

  const getMoneyForOrder = (orderDetails?): Money => {
    return {
      amount: orderDetails?.sharePrice?.amount,
      currency: orderDetails?.sharePrice?.currency,
    }
  }

  const bottomRightPriceInfo = useMemo(() => {
    if (!isOrder) return null

    const literal = getPriceLiteral(
      isStopLossOrTakeProfit,
      isCompleted,
      condition,
      orderDetails?.method,
    )

    const money = isStopLossOrTakeProfit
      ? getMoneyForStopLossOrTakeProfit(condition, orderDetails)
      : getMoneyForOrder(orderDetails)

    return `${t(literal)}: ${
      money === hyphen ? money : formatMoney(money?.amount, money?.currency)
    }`
  }, [isCompleted, isOrder, isStopLossOrTakeProfit, orderDetails, condition])

  const bottomLeftSharesInfo = useMemo(() => {
    if (isOrder) {
      let transactionConfig = config[type]

      if (isStopLossOrTakeProfit) {
        transactionConfig = { ...transactionConfig, sharesSymbol: '' }
      }

      const shares = `${getFormattedShares(
        orderDetails?.shares?.executed,
        orderDetails?.shares?.requested,
      )} `

      return orderDetails?.shares?.executed
        ? `${transactionConfig?.sharesSymbol}${shares}${ticker}`
        : `${shares}${ticker}`
    }
    return null
  }, [
    isOrder,
    config,
    type,
    isStopLossOrTakeProfit,
    orderDetails?.shares?.executed,
    orderDetails?.shares?.requested,
    ticker,
  ])

  const extraStatus = useMemo(() => {
    if (!orderDetails) {
      return null
    }

    const { status, childStatus, childStatusText, reason, reasonText } =
      orderDetails

    const isPartiallyCompleted =
      status === OrderTransactionStatusEnum.completed &&
      childStatus === OrderTransactionChildStatusEnum.partiallyCompleted

    const isCancelled =
      status === OrderTransactionStatusEnum.cancelled &&
      childStatus === OrderTransactionChildStatusEnum.cancelled

    const isExpired =
      status === OrderTransactionStatusEnum.cancelled &&
      reason === OrderCancellationReason.expired

    let displayedStatus: string

    if (isPartiallyCompleted || isCancelled) {
      displayedStatus = childStatusText
    }

    if (isExpired) {
      displayedStatus = reasonText
    }

    return displayedStatus ? (
      <p className={`secondary--text paragraph3`}>{displayedStatus}</p>
    ) : null
  }, [orderDetails])

  const getSellInfoIcon = (condition: Condition): JSX.Element => {
    if (isTakeProfitOrder(condition)) return SLTPIconMap.takeProfit
    if (isStopLossOrder(condition)) return SLTPIconMap.stopLoss
    return isLimitOrder ? SLTPIconMap.sellLimit : SLTPIconMap.market
  }

  const getSellInfoText = (condition: Condition): string => {
    if (isTakeProfitOrder(condition)) return SLTPTextMap.takeProfit
    if (isStopLossOrder(condition)) return SLTPTextMap.stopLoss
    return isLimitOrder ? SLTPTextMap.sellLimit : SLTPTextMap.sell
  }

  const sellInfo = useMemo(() => {
    return {
      icon: getSellInfoIcon(condition),
      text: getSellInfoText(condition),
      textClass: colorClass,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLimitOrder, condition])

  const transferText = 'transactions.transfer'
  const transferInfo = useMemo(() => {
    switch (type) {
      case TransactionType.walletTransferIncoming:
      case TransactionType.walletTransferOutging:
        return (
          <div className='transaction-item__transferInfo'>
            <ExchangeIcon />
            <p
              className={
                'tertiaryInitial--text caption transaction-item__label'
              }
            >
              {title}
            </p>
          </div>
        )
      case TransactionType.safekeeping:
        return (
          <div className='transaction-item__transferInfo'>
            <TransferOutgoing />
            <p className={`${negativeText} caption`}>&nbsp;{t(transferText)}</p>
          </div>
        )

      case TransactionType.noteCredit:
        return (
          <div className='transaction-item__transferInfo'>
            <TransferIncoming />
            <p className={`${positiveText} caption`}>
              &nbsp;{t('transactions.incomingPayment')}
            </p>
          </div>
        )
      case TransactionType.noteDebit:
        return (
          <div className='transaction-item__transferInfo'>
            <TransferOutgoing />
            <p className={`${negativeText} caption`}>
              &nbsp;{t('transactions.outgoingPayment')}
            </p>
          </div>
        )
      case TransactionType.deposit:
        return (
          <div className='transaction-item__transferInfo'>
            <TransferIncoming />
            <p className={`${positiveText} caption`}>&nbsp;{t(transferText)}</p>
          </div>
        )
      case TransactionType.withdrawal:
        return (
          <div className='transaction-item__transferInfo'>
            <TransferOutgoing />
            <p className={`${negativeText} caption transaction-item__label`}>
              {t(transferText)}
            </p>
          </div>
        )
      case TransactionType.dividendCash:
        return (
          <div className='transaction-item__transferInfo'>
            <Dividend />
            <p className={`caption ${positiveText} transaction-item__label`}>
              {t('transactions.dividend')}
            </p>
          </div>
        )
      case TransactionType.dividendShares:
        return (
          <div className='transaction-item__transferInfo'>
            <Dividend />
            <p className={`caption ${positiveText} transaction-item__label`}>
              {dividendSharesDetails?.financialEvent ||
                t('transactions.financialEvent')}
            </p>
          </div>
        )
      case TransactionType.buy:
        return (
          <div className='transaction-item__transferInfo'>
            {isLimitOrder ? (
              <BuyLimitOrder className={positiveText} />
            ) : (
              <MarketOrder className={positiveText} />
            )}
            <p className={`${positiveText} caption transaction-item__label`}>
              {t(
                isLimitOrder
                  ? 'transactions.buyLimitOrder'
                  : 'transactions.buy',
              )}
            </p>
          </div>
        )
      case TransactionType.sell:
        return (
          <div className='transaction-item__transferInfo'>
            {sellInfo.icon}
            <p
              className={`${sellInfo.textClass} paragraph3 transaction-item__label`}
            >
              {t(sellInfo.text)}
            </p>
          </div>
        )
      default:
        return null
    }
  }, [
    type,
    title,
    dividendSharesDetails?.financialEvent,
    isLimitOrder,
    sellInfo,
  ])

  return (
    <div
      className={`transaction-item cursor-pointer ${
        animated ? '--fade-in-animation' : ''
      }`}
      onClick={handleOnClick}
      aria-hidden
    >
      <div className='transaction-item__topSection'>
        {isOrder || isWalletTransfer ? (
          <Avatar
            bordered={false}
            objectFit='cover'
            roundness={isWalletTransfer ? 'none' : 'full'}
            size='large'
            image={logo}
          />
        ) : (
          <Avatar
            roundness='full'
            size='large'
            image={transactionIconSelector(type)}
          />
        )}

        <div className='transaction-item__titleSection'>
          <p
            className='transaction-item__title bodyBig'
            data-testid='item-title'
          >
            {itemTitle}
          </p>
          <Spacer preset='tiny' />
          {transferInfo}
        </div>
        <p className={topRightInfoClasses} data-testid='item-amount'>
          {topRightInfo}
        </p>
      </div>
      {isOrder ? (
        <div className='transaction-item__bottomSection'>
          <div
            className='transaction-item__tradedAmount paragraph2'
            data-testid='item-traded-amount'
          >
            {bottomLeftSharesInfo}
            {extraStatus}
          </div>
          <div className='transaction-item__limitPrice paragraph2'>
            {bottomRightPriceInfo}
          </div>
        </div>
      ) : null}
    </div>
  )
}
