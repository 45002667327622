import './home.styles.scss'
import '../../components/button/button.styles.scss'
import { BigSpinner, Spacer, Show, Text } from '@components'
import { useTranslation } from '@hooks'
import { useEffect, useState } from 'react'
import { fetchInvestments } from '../../store/commonReducers/portfolio/thunk'
import { fetchCollections } from '../collections/thunk'
import { fetchPromotions } from './promotions/thunk'
import { useDispatch, useSelector } from 'react-redux'
import {
  RootState,
  fetchAssessmentEvaluation,
  fetchProfileValuation,
} from '@store'
import { fetchWatchlistStocks } from '@screens/watchlist'
import WatchlistHome from './watchlist/watchlistHome'
import { ClaimEnum } from '@services'
import { Promotions } from './promotions/promotions'
import CollectionsHome from './collections/collectionsHome'
import { NoInvestments } from '@assets/illustrations'
import ProductCarrouselHome from './products/productsHomeCarrousel'
import { useOnboardingCompleted } from 'hooks/useOnboardingCompleted'
import { OnBoardingNotCompleted } from '@screens/onBoardingNotCompleted/onBoardingNotCompleted'
import { PortfolioSection } from './portfolioSection/portfolioSection'
import { BrokerTier } from './brokerTier/brokerTier'
import {
  BrokerTierInfoModal,
  PromotionVisibilityConfigModal,
} from '@shared/modals'
import { BrokerTierModalSteps } from '@shared/modals/brokerTierInfoModal/types'
import {
  setMoneyOperationStep,
  setOperationType,
} from 'features/moneyOperations/thunk'
import { MoneyOperationTypeEnum, OrderTransactionStatusEnum } from 'interfaces'
import { MoneyOperationsSteps } from 'features'
import {
  fetchBrokerTier,
  setLiteAndProInfoInactive,
  setLiteAndProInfoNotInterested,
} from '@store/commonReducers/brokerTier'
import { fetchHomePendingTransactions } from '@screens/transactions/thunk'
import { PendingTransactions } from './pendingTransactions/pendingTransactions'
import { usePortfolioValuationRange } from 'hooks/usePortfolioValuationRange'
import BalancesSection from './balancesSection/balancesSection'

export default function Home(): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dateRage = usePortfolioValuationRange()
  const [showHeytradeProConfig, setShowHeytradeProConfig] = useState(null)
  const [showBrokerTierInfomodal, setShowBrokerTierInfomodal] = useState(false)
  const [brokerTierInfoStep, setBrokerTierInfoStep] = useState(
    BrokerTierModalSteps.initial,
  )

  const {
    login,
    promotions,
    investments,
    onboardingStatus,
    watchlist,
    brokerTier,
    profile,
    portfolioValuation,
  } = useSelector((state: RootState) => state)

  const isTester = login?.claims?.some((c) => c === ClaimEnum.isTester)

  const onboardingCompleted = useOnboardingCompleted()

  useEffect(() => {
    dispatch(fetchCollections())
    dispatch(fetchWatchlistStocks(0))
    dispatch(fetchPromotions(isTester))
    dispatch(fetchAssessmentEvaluation())
    dispatch(fetchBrokerTier())
    dispatch(
      fetchHomePendingTransactions({
        page: 0,
        size: 20,
        statuses: [OrderTransactionStatusEnum.pending],
        date: null,
        selectedFilters: {},
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTester])

  useEffect(() => {
    if (onboardingCompleted) {
      dispatch(fetchInvestments())
    }
    if (profile.userProfile?.portfolioId)
      dispatch(
        fetchProfileValuation({
          portfolioId: profile.userProfile.portfolioId,
          portfolioValuationDate: dateRage,
        }),
      )
  }, [
    onboardingCompleted,
    dispatch,
    profile.userProfile?.portfolioId,
    dateRage,
  ])

  const isLoading =
    onboardingStatus.hardLoading ||
    watchlist.hardLoading ||
    portfolioValuation.loading ||
    (onboardingCompleted && investments.hardLoading)

  return isLoading ? (
    <div className='home__loader --fade-in-animation'>
      <BigSpinner />
    </div>
  ) : (
    <div className='home__wrapper --fade-in-animation'>
      <Show when={onboardingCompleted && investments?.portfolios?.length > 0}>
        <PortfolioSection />
      </Show>
      <Spacer preset='medium' />
      <div className='home__widget__promotions'>
        <BalancesSection />
      </div>
      <div className='home__widget__promotions py-3'>
        <Promotions promotionsData={promotions} />
      </div>
      <Show when={onboardingCompleted && investments?.portfolios?.length == 0}>
        <div className='home__widget'>
          <div className='home__widget__portfolio'>
            <div className='onboarding__widget__wrapper'>
              <div className='onboarding__widget__left'>
                <NoInvestments />
              </div>
              <div className='onboarding__widget__right'>
                <Text
                  preset='headline1'
                  text={t('portfolio.welcome_tbcc')}
                  color='primary'
                />
                <Spacer preset='smallMinus' />
                <Text
                  preset='bodyRegular'
                  text={t('portfolio.first_investment')}
                  color='primary'
                />
              </div>
            </div>
          </div>
        </div>
      </Show>
      <Show
        when={
          onboardingCompleted &&
          brokerTier?.infoActive &&
          !brokerTier?.infoNotInterested
        }
      >
        <BrokerTier
          openConfig={() => setShowHeytradeProConfig(true)}
          openMoreInfo={() => {
            setBrokerTierInfoStep(BrokerTierModalSteps.learnMore)
            setShowBrokerTierInfomodal(true)
          }}
        />
      </Show>
      <Show when={!onboardingCompleted}>
        <OnBoardingNotCompleted />
      </Show>
      <PendingTransactions />
      <WatchlistHome />
      <ProductCarrouselHome />
      <CollectionsHome />
      <Spacer preset='enormous' />
      <Show when={showBrokerTierInfomodal}>
        <BrokerTierInfoModal
          initialStep={brokerTierInfoStep}
          addFunds={() => {
            setShowBrokerTierInfomodal(false)
            dispatch(setOperationType(MoneyOperationTypeEnum.addMoney))
            dispatch(setMoneyOperationStep(MoneyOperationsSteps.selectAmount))
          }}
          close={() => {
            setBrokerTierInfoStep(BrokerTierModalSteps.initial)
            setShowBrokerTierInfomodal(false)
          }}
          openVisibilityConfig={() => setShowHeytradeProConfig(true)}
        />
      </Show>
      <Show when={showHeytradeProConfig}>
        <PromotionVisibilityConfigModal
          close={() => setShowHeytradeProConfig(false)}
          title='Heytrade Pro'
          onLearnMore={() => {
            setShowHeytradeProConfig(false)
            setBrokerTierInfoStep(BrokerTierModalSteps.learnMore)
            setShowBrokerTierInfomodal(true)
          }}
          onNotInterested={() => {
            dispatch(setLiteAndProInfoNotInterested())
            setShowHeytradeProConfig(false)
          }}
          onRemindMeLater={() => {
            dispatch(setLiteAndProInfoInactive())
            setShowHeytradeProConfig(false)
          }}
        />
      </Show>
    </div>
  )
}
