import { useTranslation } from '@hooks'
import { Button, CustomModal, Show, Spacer, TabBar } from '@components'
import { useState } from 'react'
import { FixedStopLossInfoContent } from '../tabs/fixedStopLossInfoContent/fixedStopLossInfoContent'
import { TrailingStopLossInfoContent } from '../tabs/trailingStopLossInfoContent/trailingStopLossInfoContent'
import { TrailingTakeProfitInfoContent } from '../tabs/trailingTakeProfitInfoContent/trailingTakeProfitInfoContent'
import { ConditionType } from 'interfaces'
import { FixedTakeProfitInfoContent } from '../tabs/fixedTakeProfitInfoContent/fixedTakeProfitInfoContent'
import { StopLossInfoFooter } from '../StopLossInfoFooter/stopLossInfoFooter'
import { StopLossOperationTypeEnum } from '../types'

export const StopLossTakeProfitAboutModal = ({
  onClose,
  mode,
  onShowFaqModal,
}: {
  onClose: () => void
  mode: ConditionType
  onShowFaqModal: (faqMode: ConditionType) => void
}): JSX.Element => {
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState(
    mode == ConditionType.TAKE_PROFIT
      ? StopLossOperationTypeEnum.fixedTakeProfit
      : StopLossOperationTypeEnum.fixedStopLoss,
  )

  const tabFooterMode: ConditionType =
    activeTab === StopLossOperationTypeEnum.fixedStopLoss ||
    activeTab === StopLossOperationTypeEnum.trailingStopLoss
      ? ConditionType.STOP_LOSS
      : ConditionType.TAKE_PROFIT
  const tabs = [
    {
      id: StopLossOperationTypeEnum.fixedStopLoss,
      title: t('stopLossTakeProfit.fixedStopLossInfo.title'),
    },
    {
      id: StopLossOperationTypeEnum.fixedTakeProfit,
      title: t('stopLossTakeProfit.fixedTakeProfitInfo.title'),
    },
  ]

  const handleTab = (id: string): void => {
    setActiveTab(id as StopLossOperationTypeEnum)
  }

  return (
    <>
      <div className='d-flex justify-center border-bottom-default w-100'>
        <TabBar
          tabs={tabs}
          extraMarginOnSides
          activeTab={activeTab}
          loading={false}
          setActiveTab={handleTab}
          fullWidthTabs
        />
      </div>

      <CustomModal.Content>
        <Show when={activeTab === StopLossOperationTypeEnum.fixedStopLoss}>
          <FixedStopLossInfoContent />
        </Show>
        <Show when={activeTab === StopLossOperationTypeEnum.trailingStopLoss}>
          <TrailingStopLossInfoContent />
        </Show>
        <Show when={activeTab === StopLossOperationTypeEnum.fixedTakeProfit}>
          <FixedTakeProfitInfoContent />
        </Show>
        <Show when={activeTab === StopLossOperationTypeEnum.trailingTakeProfit}>
          <TrailingTakeProfitInfoContent />
        </Show>

        <StopLossInfoFooter
          showStopLossFaq={() => {
            onShowFaqModal(tabFooterMode)
          }}
          mode={tabFooterMode}
        />
        <Spacer preset='medium' />
        <Button onClick={onClose} text={t('common.continue')} />
        <Spacer preset='small' />
      </CustomModal.Content>
    </>
  )
}
