import { t } from 'i18next'
import { Condition } from 'interfaces'
import { formatMoney } from './helpers'
import { isStopLossOrder } from './isStopLossOrder'
import { isTrailingStopLossOrTakeProfit } from './isTrailingStopLossOrTakeProfit'
import { TextColorPresets } from 'components'
import { TextColorsEnum } from 'components/utils/textColorPresets'
import { getStopLossTakeProfitName } from './getStopLossTakeProfitName'

export const getSLTPOrderConfig = (
  condition: Condition,
  currency: string,
): { name: string; value: string; color: TextColorPresets } => {
  const colors = {
    positive: TextColorsEnum.positive,
    negative: TextColorsEnum.negative,
  }
  const title = getStopLossTakeProfitName(condition)

  if (isStopLossOrder(condition)) {
    if (isTrailingStopLossOrTakeProfit(condition)) {
      return {
        name: 'transactions.trailingStopLoss',
        value: `${t(title)}: ${formatMoney(
          condition.activationPrice,
          currency,
        )}`,
        color: colors.negative,
      }
    }
    return {
      name: 'transactions.fixedStopLoss',
      value: `${t(title)}: ${formatMoney(condition.stopPrice, currency)}`,
      color: colors.negative,
    }
  } else {
    if (isTrailingStopLossOrTakeProfit(condition)) {
      return {
        name: 'transactions.trailingTakeProfit',
        value: `${t(title)}: ${formatMoney(
          condition.activationPrice,
          currency,
        )}`,
        color: colors.positive,
      }
    }
    return {
      name: 'transactions.fixedTakeProfit',
      value: `${t(title)}: ${formatMoney(condition.stopPrice, currency)}`,
      color: colors.positive,
    }
  }
}
