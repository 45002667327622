import { getIconColorSVGFill, UIIcon, UIIconColorProps } from 'components/utils'

export const StopLossIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1'
        y='7'
        width='24'
        height='24'
        fill={getIconColorSVGFill('transparent')}
      />
      <path
        d='M1.5 6.7998L22.5 6.7998'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeDasharray='2 2'
      />
      <path
        d='M1.5 14L22.5 14'
        stroke='#F7034D'
        strokeWidth='2'
        strokeLinecap='round'
        strokeDasharray='2 2'
      />
      <path
        d='M3.09998 4.39991L7.63832 9.7634C7.88943 10.0602 8.28977 10.1845 8.66482 10.0822L12.2828 9.09548C12.7086 8.97938 13.1601 9.15604 13.394 9.53023L19.0004 18.5'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <circle
        cx='16'
        cy='14'
        r='2'
        fill='white'
        stroke={color ? getIconColorSVGFill(color) : 'currentColor'}
        strokeWidth='2'
      />
    </svg>
  )
}
