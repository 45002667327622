import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const NoteCreditIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width={size}
        height={size}
        rx={size / 2}
        fill='var(--surfacePositiveInitial)'
      />
      <path
        d='M20 15C19.45 15 19 15.45 19 16V19H16C15.45 19 15 19.45 15 20C15 20.55 15.45 21 16 21H19V24C19 24.55 19.45 25 20 25C20.55 25 21 24.55 21 24V21H24C24.55 21 25 20.55 25 20C25 19.45 24.55 19 24 19H21V16C21 15.45 20.55 15 20 15ZM20 10C14.48 10 10 14.48 10 20C10 25.52 14.48 30 20 30C25.52 30 30 25.52 30 20C30 14.48 25.52 10 20 10ZM20 28C15.59 28 12 24.41 12 20C12 15.59 15.59 12 20 12C24.41 12 28 15.59 28 20C28 24.41 24.41 28 20 28Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
