import './text.styles.scss'
import { TextColorsEnum, UITextColorProps, stringBuilder } from '../utils'
import { ReactNode, useMemo } from 'react'
import DOMPurify from 'dompurify'

export enum TextAlignEnum {
  right = 'right',
  center = 'center',
}

export enum TextPresetsEnum {
  tab = 'tab',
  mainButton = 'mainButton',
  tinyButton = 'tinyButton',
  linkText = 'linkText',
  caption = 'caption',
  caption2 = 'caption2',
  hero = 'hero',
  headline1 = 'headline1',
  headline2 = 'headline2',
  headline3 = 'headline3',
  headline4 = 'headline4',
  subtitle1 = 'subtitle1',
  subtitle2 = 'subtitle2',
  subtitle3 = 'subtitle3',
  smallText = 'smallText',
  bigText = 'bigText',
  paragraph1 = 'paragraph1',
  paragraph2 = 'paragraph2',
  paragraph3 = 'paragraph3',
  paragraph4 = 'paragraph4',
  paragraph5 = 'paragraph5',
  bodyRegular = 'bodyRegular',
  bodyBig = 'bodyBig',
}
export type TextPresets = keyof typeof TextPresetsEnum
export type TextColorPresets = keyof typeof TextColorsEnum
export type TextAlignPresets = keyof typeof TextAlignEnum

export interface PropsText {
  preset: TextPresets
  text?: string
  align?: TextAlignPresets
  textStyle?: 'underline' | 'capitalize' | 'uppercase'
  children?: ReactNode
  hiddenText?: boolean
  inline?: boolean
  testId?: string
}

export const Text = ({
  preset,
  text,
  color,
  align,
  textStyle,
  children,
  inline = false,
  hiddenText = false,
  testId = '',
}: PropsText & UITextColorProps): JSX.Element => {
  const classes = useMemo(() => {
    return stringBuilder([
      ['text', true],
      ['--text-primary', color === 'primary'],
      ['--text-secondary', color === 'secondary'],
      ['--text-tertiary', color === 'tertiary'],
      ['--text-positive', color === 'positive'],
      ['--text-negative', color === 'negative'],
      ['--text-icon-nav-and-controls', color === 'iconPrimaryInitial'],
      ['--text-primary-base', color === 'surfaceCanvasPrimaryInitial'],
      ['--text-primary-subtleInitial', color === 'primarySubtleInitial'],
      ['--text-warning', color === 'warning'],
      [
        '--text-label-primary-subtle-initial',
        color === 'labelPrimarySubtleInitial',
      ],
      ['--label-navigation-title', color === 'labelNavigationTitle'],
      ['--text-inherit-color', color === 'inherit' || !color],
      ['--text-center', align === 'center'],
      ['--text-right', align === 'right'],
      ['--text-hidden-correction', !!hiddenText],
      [preset, !!preset],
      [color, !!color],
      ['text-decoration-underline', textStyle === 'underline'],
      ['--capitalize', textStyle === 'capitalize'],
      ['--uppercase', textStyle === 'uppercase'],
      ['--text-inline', !!inline],
    ])
  }, [align, color, preset, textStyle, hiddenText])

  if (hiddenText) {
    return (
      <p className={classes}>
        <span>∗∗∗∗∗</span>
      </p>
    )
  }

  return (
    <p data-testid={testId ? `text-${testId}` : 'text'} className={classes}>
      {text}
      {children &&
        (typeof children === 'string' ? (
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(children),
            }}
          ></span>
        ) : (
          children
        ))}
    </p>
  )
}
