import { useTranslation, useNavigate } from '@hooks'
import { CustomModal, GoBack, Button, Show, BigSpinner } from '@components'
import { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { resetOrder, sendOrderData } from '@store'
import { OrderTypeEnum } from '@interfaces'
import { trackingService } from '@services'
import { ReactComponent as ImportantIcon } from '@assets/svg/important.svg'
import {
  BuySellEvents,
  LimitOrderEvents,
  TopUpEvents,
} from '@utils/eventTracker/eventKeys'
import { PreorderDetail } from './preorderDetail'

export function AssetOperationReview(props: {
  nextStep: () => void
  prevStep: () => void
  onClose?: () => void
  onTopUp?: () => void
}): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { navigate } = useNavigate()
  const [selected, setSelected] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isConfirmed, setConfirmed] = useState(false)

  const { buySell, companyPosition, userBalance, companyStocks, investments } =
    useSelector((state: RootState) => state)
  const realAbbreviation = companyStocks?.symbol

  const checkouts = buySell?.multiCheckout

  const cancelActions = (): void => {
    dispatch(resetOrder())
    props.prevStep()
  }

  const checkout = checkouts?.[0]

  const readCarefullyRows = [
    t('buySellFlow.limitReadCarefully.firstRow', {
      contingenciesBuffer: checkout?.contingenciesBuffer * 100,
    }),
    t('buySellFlow.limitReadCarefully.secondRow'),
    t('buySellFlow.limitReadCarefully.thirdRow'),
    t('buySellFlow.limitReadCarefully.forthRow'),
  ]

  const isMarketOpen = companyStocks?.marketStatus?.status === 'OPEN'

  if (!isMarketOpen) {
    readCarefullyRows.splice(0, 0, t('buySellFlow.marketClosedReadCarefully'))
  }

  const confirmOrderText = t('buySellFlow.confirmOrder')

  const sendOrder = useCallback(() => {
    if (checkout?.method === OrderTypeEnum.limit) {
      trackingService.trackEvent({
        event: LimitOrderEvents.confirmedPreorder,
      })
    }
    dispatch(
      sendOrderData({
        preOrdersId: checkouts?.map((c) => c?.id),
      }),
    )
  }, [checkout?.method, dispatch, checkouts])

  const handleTopUp = (): void => {
    props?.onTopUp && props.onTopUp()
    trackingService.trackEvent({
      event: TopUpEvents.modalOpened,
      props: {
        origin: 'buySellFlow',
        currentBalance: userBalance?.cashActive?.amount,
        totalCost: checkout?.totalCost?.amount,
      },
    })
  }

  const handleSelect = (): void => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setSelected(true)
      trackingService.trackEvent({
        event: BuySellEvents.confirmOrder,
        props: {
          type: checkout?.type,
          method: checkout?.method,
          shares: checkout?.shares,
          ticker: realAbbreviation,
          id: companyStocks?.id,
          assetGroup: companyStocks?.type,
          totalCost: checkout?.totalCost?.amount,
        },
      })
    }, 1000)
  }

  const handleConfirmation = (): void => {
    setConfirmed(true)
    trackingService.trackEvent({
      event: BuySellEvents.sendOrder,
      props: {
        type: checkout?.type,
        method: checkout?.method,
        shares: checkout?.shares,
        ticker: realAbbreviation,
        id: companyStocks?.id,
        assetGroup: companyStocks?.type,
        totalCost: checkout?.totalCost?.amount,
      },
    })
  }

  useEffect(() => {
    if (!buySell.isOrderSuccess && isConfirmed === true) {
      sendOrder()
    }
    // In this case I don't want  react-hooks/exhaustive-deps
    // because I just need to check the isOrderSuccess variable
    // so that the useEffect isn't be fired more than once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buySell.isOrderSuccess, isConfirmed])

  useEffect(() => {
    if (isConfirmed && buySell.sendOrderError) {
      props.nextStep()
    }
  }, [buySell.sendOrderError, isConfirmed, props])

  useEffect(() => {
    if (isConfirmed && buySell.isOrderSuccess) {
      props.nextStep()
    }
  }, [
    buySell.isOrderSuccess,
    navigate,
    buySell.sendOrderError,
    buySell.isLoading,
    props,
    buySell.error,
    isConfirmed,
  ])

  return (
    <Show
      when={!buySell.isLoading}
      fallback={
        <div className='d-flex h-100 align-center'>
          <BigSpinner>
            <div className='headline4 text--primary mt-3'>
              {t('buySellFlow.sendingOrder')}
            </div>
          </BigSpinner>
        </div>
      }
    >
      <>
        <CustomModal.Header
          text={t('buySellFlow.reviewTransaction')}
          linkText={t('common.cancel')}
          className='subtitle3 dark--label-title'
          onClick={props.onClose}
        >
          <GoBack
            goBack={() => {
              cancelActions()
            }}
          />
        </CustomModal.Header>
        <CustomModal.Content className='d-flex-col'>
          {checkouts?.map((c) => (
            <PreorderDetail
              companyPosition={companyPosition}
              investments={investments}
              preorder={c}
              togglable={checkouts?.length > 1}
              key={`preorder-${c?.id}`}
            />
          ))}

          <div className='bg-secondary-base text-left secondary--text bradius-2'>
            <div className='d-flex align-center paragraph2 py-2 mx-2'>
              <ImportantIcon className='mr-1' />
              <p>{t('bankTransfer.cashout.followSteps')}</p>
            </div>
            {readCarefullyRows.map((e: string) => {
              return (
                <p
                  key={e}
                  className='text-secondary paragraph3 py-2 mx-2 border-top-default'
                >
                  {e}
                </p>
              )
            })}
          </div>
        </CustomModal.Content>
        <CustomModal.Footer className='d-flex d-flex-col'>
          {checkout?.enoughCashForOrder ? (
            selected ? (
              <div className='d-flex d-flex-row w-100'>
                <div className='w-70 mr-1'>
                  <Button
                    text={confirmOrderText}
                    type='submit'
                    size='big'
                    onClick={handleConfirmation}
                  />
                </div>
                <div className='w-30 ml-1'>
                  <Button
                    text={t('common.cancel')}
                    type='submit'
                    size='big'
                    onClick={cancelActions}
                    buttonType='ghost--negative'
                  />
                </div>
              </div>
            ) : (
              <div className='d-flex d-flex-col w-100'>
                <Button
                  text={confirmOrderText}
                  type='submit'
                  size='big'
                  loading={loading}
                  onClick={handleSelect}
                />
              </div>
            )
          ) : (
            <div className='d-flex d-flex-row w-100'>
              <div className='w-40 mr-2'>
                <Button
                  text={t('buySellFlow.topUp')}
                  type='submit'
                  size='big'
                  onClick={handleTopUp}
                />
              </div>
              <div className='w-100'>
                <Button
                  text={t('buySellFlow.confirmPurchase')}
                  type='submit'
                  size='big'
                  disabled={true}
                />
              </div>
            </div>
          )}
        </CustomModal.Footer>
      </>
    </Show>
  )
}
