import { useTranslation } from '@hooks'
import { BasicLink, Spacer, Text } from '@components'
import { ConditionType } from 'interfaces'
export const StopLossInfoFooter = ({
  showStopLossFaq,
  mode,
}: {
  showStopLossFaq: () => void
  mode: ConditionType
}): JSX.Element => {
  const { t } = useTranslation()

  const translationKeyMap: Record<ConditionType, string> = {
    [ConditionType.TAKE_PROFIT]:
      'stopLossTakeProfit.footerInfoContent.footerTakeProfit',
    [ConditionType.STOP_LOSS]:
      'stopLossTakeProfit.footerInfoContent.footerStopLoss',
  }

  return (
    <div className='d-flex d-flex-col mt-4 mb-2 text-left '>
      <Text
        preset='bodyRegular'
        color='secondary'
        text={t('stopLossTakeProfit.footerInfoContent.footerText1')}
      />
      <Spacer preset='smaller' />
      <Text
        preset='bodyRegular'
        color='secondary'
        text={t('stopLossTakeProfit.footerInfoContent.footerText2', {
          mode: t(translationKeyMap[mode])?.toLocaleLowerCase(),
        })}
      />
      <Spacer preset='smaller' />
      <Text
        preset='bodyRegular'
        color='secondary'
        text={t('stopLossTakeProfit.footerInfoContent.footerText3')}
      />

      <Spacer preset='smaller' />
      <div className='d-flex align-center'>
        <Text
          preset='bodyRegular'
          color='secondary'
          text={t('stopLossTakeProfit.footerInfoContent.footerText4')}
        />
        <Spacer preset='tiny' />
        <BasicLink
          onClick={showStopLossFaq}
          text={t('stopLossTakeProfit.footerInfoContent.footerText5', {
            mode: t(translationKeyMap[mode]),
          })}
          className='paragraph2 text-right'
        />
      </div>
    </div>
  )
}
