import { Avatar, Card, Spacer, Text } from 'components'
import { BalanceCardProps } from './balanceCardItem.props'

const BalanceCard = ({
  avatar,
  title,
  subtitle,
  onClick,
}: BalanceCardProps): JSX.Element => {
  return (
    <Card
      onClick={onClick}
      key='balance-card'
      aria-hidden
      padding='smaller'
      width='full'
    >
      <div className='w-100 d-flex align-center justify-space-between'>
        <div className='d-flex-col align-center'>
          <Text preset='subtitle2' text={title} color='secondary' />
          <Spacer preset='tinyMedium' />
          <Text preset='headline2' text={subtitle} />
        </div>
        <Spacer preset='medium' />
        <Avatar image={avatar} roundness='full' size='large' />
      </div>
    </Card>
  )
}

export default BalanceCard
