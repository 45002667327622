import { useTranslation } from '@hooks'
import ExampleFixedStopLossTwo from '../../shared/exampleFixedStopLossTwo'
import { Spacer, Text } from '@components'
import MainLogo from 'components/mainLogo/mainLogo'
import ExampleFixedStopLossOne from '../../shared/exampleFixedStopLossOne'

export const FixedStopLossInfoContent = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className='d-flex d-flex-col align-start'>
      <div className='dividerInfo mt-2 pb-2 text-left'>
        <Text
          preset='bodyRegular'
          text={t('stopLossTakeProfit.fixedStopLossInfo.description')}
        />
      </div>
      <div className='mt-2 text-left'>
        <Text
          preset='subtitle3'
          color='secondary'
          text={t('limitOrders.limitBuyInfo.example')}
        />

        <div className='mt-1 mb-1'>
          <Text
            preset='bodyRegular'
            color='secondary'
            text={t('stopLossTakeProfit.fixedStopLossInfo.explanationOne')}
          />
        </div>
      </div>
      <div className='mt-4 paragraph2'>
        <ExampleFixedStopLossOne
          currentPriceText={t(
            'stopLossTakeProfit.fixedStopLossInfo.currentPriceText',
          )}
          buyText={t('stopLossTakeProfit.fixedStopLossInfo.buyText')}
        />
      </div>
      <div className='mt-3 text-left'>
        <Text
          preset='bodyRegular'
          color='secondary'
          text={t('stopLossTakeProfit.fixedStopLossInfo.explanationTwo')}
        />
      </div>
      <div className='mt-4 paragraph2'>
        <ExampleFixedStopLossTwo
          currentPriceText={t(
            'stopLossTakeProfit.fixedStopLossInfo.closePosition',
          )}
          buyText={t('stopLossTakeProfit.fixedStopLossInfo.buyText')}
        />
      </div>
      <div className='mt-4 pb-2 text-left '>
        <Text
          preset='bodyRegular'
          color='secondary'
          text={t('stopLossTakeProfit.fixedStopLossInfo.explanationThree')}
        />
      </div>
      <div className='mt-3 text-left infoLogo '>
        <div className='containerLogo mb-2'>
          <MainLogo className='containerLogo__icon' />
        </div>
        <div className='containerLogo__info'>
          <Text
            preset='subtitle2'
            color='primary'
            text={t(
              'stopLossTakeProfit.fixedStopLossInfo.pushNotificationTitle',
            )}
          />
          <Spacer preset='tiny' />
          <Text
            preset='bodyRegular'
            color='secondary'
            text={t(
              'stopLossTakeProfit.fixedStopLossInfo.pushNotificationDescription',
            )}
          />
        </div>
      </div>
    </div>
  )
}
