import { CloseIcon, SuccessIcon } from '@assets/icons'
import { formatCurrency, formatCurrencyToSymbol } from '@utils/helpers'
import { Button, CustomModal, Spacer, SummaryCard, Text } from 'components'
import { useNavigate } from 'hooks/useNavigation'
import { useTranslation } from 'hooks/useTranslation'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const ExchangeSuccess = ({ onClose }: { onClose: () => void }): JSX.Element => {
  const { t } = useTranslation()
  const exchangeBalanceState = useSelector(
    (state: RootState) => state.exchangeBalance,
  )
  const { navigate } = useNavigate()
  const sections = {
    id: 'exchangeBalance',
    overTitle: '',
    data: [
      {
        label: t('exchangeBalance.exchangeSuccess.fxRate'),
        value: `${formatCurrencyToSymbol(
          exchangeBalanceState?.order?.exchangeRate?.from,
        )} 1 = ${formatCurrencyToSymbol(
          exchangeBalanceState?.order?.exchangeRate?.to,
        )} ${exchangeBalanceState?.order?.exchangeRate?.value}`,
      },
      {
        label: t('exchangeBalance.exchangeSuccess.totalCost'),
        value: formatCurrency(exchangeBalanceState?.order?.sourceAmount),
        className: 'headline3 text-right',
      },
    ],
  }
  return (
    <>
      <CustomModal.Header
        text={<Text preset='subtitle2' text='' />}
        className='w-100 d-flex align-center justify-space-between'
      >
        <Button
          onClick={onClose}
          buttonType='link'
          autoWidth
          content={<CloseIcon color='iconPrimaryInitial' size={36} />}
          shape='square'
        />
      </CustomModal.Header>
      <CustomModal.Content>
        <div className='d-flex d-flex-col justify-center align-center w-65 ml-auto mr-auto'>
          <div className='d-flex-col d-flex justify-center align-center'>
            <SuccessIcon
              color='iconPositiveInitial'
              secondaryColor='iconNeutralSoft'
              size={64}
            />
          </div>
          <Spacer preset='small' />
          <Text preset='headline3' color='primary' align='center'>
            {t('exchangeBalance.exchangeSuccess.title', {
              source: exchangeBalanceState?.order?.exchangeRate?.from,
              destination: exchangeBalanceState?.order?.exchangeRate?.to,
            })}
          </Text>
          <Spacer preset='smaller' />

          <Text preset='headline3' color='positive' align='center'>
            {`+${formatCurrency(exchangeBalanceState?.order?.targetNetAmount)}`}
          </Text>
        </div>
        <Spacer preset='medium' />

        <div className='w-100'>
          {sections && (
            <SummaryCard
              id={sections.id}
              overTitle={sections.overTitle}
              data={sections.data}
              key={sections.id}
              className='mb-3'
            />
          )}
        </div>
        {!exchangeBalanceState?.fromTransaction && (
          <div
            className='w-100 mt-1 cursor-pointer'
            role='button'
            onClick={() => {
              onClose()
              navigate('/transactions/executed')
            }}
            aria-hidden
          >
            <Text
              preset='bodyRegular'
              color='primarySubtleInitial'
              align='center'
              text={t('buySellFlow.confirmationModal.viewInformation')}
            />
          </div>
        )}
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.close')} size='big' onClick={onClose} />
      </CustomModal.Footer>
    </>
  )
}

export default ExchangeSuccess
