import { AlertIcon } from '@assets/icons'
import { Card, Spacer, Text } from 'components'

export function AlertMessage({ message }: { message: string }): JSX.Element {
  return (
    <>
      <Spacer preset='smaller' />
      <Card bgColor='banner6' width='full' padding='smaller'>
        <div className='d-flex'>
          <AlertIcon color='negative' />
          <Spacer preset='tiny' />
          <Text preset='caption' color='negative' text={message} />
        </div>
      </Card>
    </>
  )
}
