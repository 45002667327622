import { InfoIcon } from '@assets/svg'
import {
  formatCurrency,
  formatDate,
  formatMoney,
  formatPercentage,
  frontEndFormat,
  getFormattedShares,
} from '@utils/helpers'
import { isStopLossOrder } from '@utils/isStopLossOrder'
import { isTakeProfitOrder } from '@utils/isTakeProfitOrder'
import { SummaryCardProps, SummaryRowProps } from 'components'
import { stringBuilder } from 'components/utils'
import { t } from 'i18next'
import {
  BuySellTransaction,
  Money,
  OrderCancellationReason,
  OrderTransactionStatusEnum,
  OrderTypeEnum,
  TransactionType,
} from 'interfaces'
import { isNil } from 'lodash'
import { format } from 'date-fns'
import { getStopLossTakeProfitName } from '@utils/getStopLossTakeProfitName'

const getNumberOfSharesRow = (
  transaction: BuySellTransaction,
): SummaryRowProps => {
  const { type, shares } = transaction

  const isStopLossOrTakeProfit =
    isStopLossOrder(transaction.condition) ||
    isTakeProfitOrder(transaction.condition)

  const transactionSigns = {
    [TransactionType.buy]: '+',
    [TransactionType.sell]: '-',
  }

  const sharesSign = isStopLossOrTakeProfit ? '' : transactionSigns[type]
  const formattedShares = `${sharesSign}${getFormattedShares(
    shares?.executed,
    shares?.requested,
  )}`

  return {
    label: t('transactions.amountOfShares'),
    value: formattedShares,
  }
}

const getExecutionPriceRow = (
  transaction: BuySellTransaction,
): SummaryRowProps => {
  return {
    label: t('transactions.executionPrice'),
    value: formatCurrency(transaction?.executedPrice),
  }
}

const getLimitPriceRow = (transaction: BuySellTransaction): SummaryRowProps => {
  return {
    label: t('transactions.limitPrice'),
    value: formatCurrency(transaction?.estimatedPrice),
  }
}

const getActivationPrice = (
  transaction: BuySellTransaction,
): SummaryRowProps => {
  return {
    label: t('buySellFlow.activationPrice'),
    value: formatMoney(
      transaction?.condition?.activationPrice,
      transaction?.assetCurrency,
    ),
  }
}

const showBuyCommissionsRows = (
  isBuy: boolean,
  transaction: BuySellTransaction,
): boolean =>
  isBuy ||
  isStopLossOrder(transaction?.condition) ||
  isTakeProfitOrder(transaction?.condition)

const getSLTPExpirationDateRow = (
  transaction: BuySellTransaction,
): SummaryRowProps => {
  const value = transaction?.expirationDate
    ? format(new Date(transaction?.expirationDate), frontEndFormat)
    : t('stopLossTakeProfit.timeInForce.options.goodTilCancel')
  return {
    label: t('transactions.expirationDate'),
    value,
  }
}

const getConditionRows = (
  transaction: BuySellTransaction,
  isCompleted: boolean,
  isStopLoss: boolean,
  isLimit: boolean,
): SummaryRowProps[] => {
  const { condition } = transaction
  const stopPriceRow = {
    label: t(getStopLossTakeProfitName(condition)),
    value: formatMoney(
      transaction?.condition?.stopPrice,
      transaction?.assetCurrency,
    ),
  }

  const conditionRows = []
  if (condition?.trailAmount) {
    conditionRows.push({
      label: t('buySellFlow.trailAmount'),
      value: formatMoney(
        transaction?.condition?.trailAmount,
        transaction?.assetCurrency,
      ),
    })
  }
  if (condition?.trailPercentage) {
    conditionRows.push({
      label: t('buySellFlow.trailPercentage'),
      value: formatPercentage(transaction?.condition?.trailPercentage, 0),
    })
  }

  if (condition?.stopPercentage) {
    conditionRows.push({
      label: t(
        `buySellFlow.${
          isStopLoss || isCompleted
            ? 'pricePercentageStop'
            : 'pricePercentageLimit'
        }`,
      ),
      value: formatPercentage(transaction?.condition?.stopPercentage, 0),
    })
  }

  if (
    !!condition?.stopPrice &&
    !condition?.trailAmount &&
    !condition?.trailPercentage &&
    !isCompleted
  ) {
    conditionRows.push(stopPriceRow)
  }

  if (isLimit) {
    conditionRows.push(getLimitPriceRow(transaction))
  }

  return conditionRows
}

const getFeeRate = (commission: { amount: Money; rate: number }): string => {
  return !isNil(commission?.rate)
    ? `(${(commission?.rate * 100).toFixed(2)}%)`
    : ''
}

function getFormattedNetAmount(transaction: BuySellTransaction): string {
  const { netAmount, netAmountInPortfoliosCurrency } = transaction

  return `${formatMoney(
    netAmount?.amount,
    netAmount?.currency,
  )} = ${formatMoney(
    netAmountInPortfoliosCurrency?.amount,
    netAmountInPortfoliosCurrency?.currency,
  )}`
}

function getFormattedGrossAmount(transaction: BuySellTransaction): string {
  const { grossAmount } = transaction

  return formatMoney(grossAmount?.amount, grossAmount?.currency)
}

const getLabelForStopLossOrTakeProfit = (
  isCompleted: boolean,
  isStopLoss: boolean,
): string => {
  if (!isCompleted) {
    return isStopLoss ? 'common.estimatedProceeds' : 'common.estimatedProfit'
  }
  return isStopLoss ? 'buySellFlow.totalEarned' : 'buySellFlow.totalProfit'
}

const getTotalLabelExplanation = (
  transaction: BuySellTransaction,
  isStopLoss: boolean,
  isTakeProfit: boolean,
): string => {
  const isBuy = transaction?.type === TransactionType.buy
  const isPending = transaction?.status === OrderTransactionStatusEnum.pending
  const isStopLossTakeProfit = isStopLoss || isTakeProfit

  const mode = isStopLoss ? 'stopLossLiterals' : 'takeProfitLiterals'

  if (isStopLossTakeProfit) {
    return t('buySellFlow.summary.totalEarnedOrProfitExplanation', {
      type: t(`buySellFlow.${mode}.title`),
    })
  }

  if (isPending) {
    return isBuy
      ? t('buySellFlow.summary.totalToWithholdExplanation')
      : t('buySellFlow.summary.estimatedEarningsExplanation')
  }
}

export const getTotalLabel = (
  transaction: BuySellTransaction,
  isCompleted: boolean,
  isStopLoss: boolean,
  isTakeProfit: boolean,
): string => {
  const isBuy = transaction?.type === TransactionType.buy
  const isPending = transaction?.status === OrderTransactionStatusEnum.pending

  const isCancelled =
    transaction?.status === OrderTransactionStatusEnum.cancelled

  if (isStopLoss || isTakeProfit) {
    return t(getLabelForStopLossOrTakeProfit(isCompleted, isStopLoss))
  }

  if (isPending) {
    return isBuy
      ? t('buySellFlow.totalOnHold')
      : t('buySellFlow.estimatedEarnings')
  }

  if (isCancelled) {
    return t('buySellFlow.releasedFromHold')
  }

  return isBuy
    ? t('buySellFlow.summary.totalCost')
    : t('buySellFlow.totalEarned')
}

const getFormattedFxRate = (transaction: BuySellTransaction): string => {
  return `${
    formatMoney(
      transaction?.exchangeRateAverage,
      transaction?.totalCost?.currency,
      4,
    ) as string
  } = ${formatMoney(1, transaction?.assetCurrency) as string}`
}

function getOrderStatusRow(transaction: BuySellTransaction): SummaryRowProps {
  const isExpired =
    transaction?.status === OrderTransactionStatusEnum.cancelled &&
    transaction?.reason === OrderCancellationReason.expired

  const status = isExpired
    ? transaction?.reasonText
    : transaction?.childStatusText || transaction?.statusText

  return {
    label: t('transactions.status'),
    value: status,
  }
}

const getDateCreatedRow = (date: string): SummaryRowProps => ({
  label: t('transactions.dateCreated'),
  value: formatDate(date),
})

const getExpirationDateRow = (expirationDate: string): SummaryRowProps => ({
  label: t('transactions.expirationDate'),
  value: formatDate(expirationDate),
})

const getEstimatedNetAmountRow = (
  transaction: BuySellTransaction,
  isCompleted: boolean,
): SummaryRowProps => ({
  label: !isCompleted
    ? t('transactions.estimatedNetAmount')
    : t('transactions.netAmount'),
  value: getFormattedNetAmount(transaction),
})

const getRequiredNetAmountRow = (
  transaction: BuySellTransaction,
): SummaryRowProps => ({
  label: t('buySellFlow.requiredNetAmount'),
  value: formatCurrency(transaction?.requiredNetAmount),
})

const getFXRateRow = (transaction: BuySellTransaction): SummaryRowProps => ({
  label: t('transactions.fxRate'),
  value: getFormattedFxRate(transaction),
})

const getTaxesRow = (taxes: Money, showTaxesModal): SummaryRowProps => ({
  label: t('buySellFlow.summary.taxes'),
  value: formatCurrency(taxes),
  labelChildren: (
    <InfoIcon className='ml-1 cursor-pointer' onClick={showTaxesModal} />
  ),
})

const getEstimatedGrossAmount = (
  transaction: BuySellTransaction,
): SummaryRowProps => ({
  label: t('transactions.estimatedGrossAmount'),
  value: getFormattedGrossAmount(transaction),
})

const getGrossAmountRow = (
  transaction: BuySellTransaction,
): SummaryRowProps => ({
  label: t('transactions.grossAmount'),
  value: getFormattedGrossAmount(transaction),
})

const bufferTranslationOption = (transaction) => ({
  percent: transaction?.contingenciesBuffer * 100,
})

const getBufferIncludedRow = (
  transaction: BuySellTransaction,
  showInfoModal,
): SummaryRowProps => ({
  label: t('transactions.bufferIncluded', bufferTranslationOption(transaction)),
  labelChildren: (
    <InfoIcon
      className='ml-1 cursor-pointer'
      onClick={() =>
        showInfoModal(
          t(
            'transactions.bufferIncluded',
            bufferTranslationOption(transaction),
          ),
          t(
            'transactions.bufferExplanation',
            bufferTranslationOption(transaction),
          ),
        )
      }
    />
  ),
  value: formatCurrency(transaction?.contingenciesBufferAmount),
})

const getStopLossTakeProfitOrderDetailsRows = ({
  transaction,
  isPending,
  isCompleted,
  isStopLoss,
  showTaxesModal,
  isLimit,
}: {
  transaction: BuySellTransaction
  isPending: boolean
  isCompleted: boolean
  isStopLoss: boolean
  showTaxesModal
  isLimit: boolean
}): SummaryRowProps[] => {
  const { taxes } = transaction
  const commonRows = [
    ...getConditionRows(transaction, isCompleted, isStopLoss, isLimit),
  ]
  if (transaction?.condition?.activationPrice) {
    commonRows.push(getActivationPrice(transaction))
  }
  if (isCompleted) {
    commonRows.push(getExecutionPriceRow(transaction))
  }
  if (isPending) {
    commonRows.push(getSLTPExpirationDateRow(transaction))
  }
  commonRows.push(
    getNumberOfSharesRow(transaction),
    getGrossAmountRow(transaction),
    getTaxesRow(taxes, showTaxesModal),
    getFXRateRow(transaction),
    getEstimatedNetAmountRow(transaction, isCompleted),
  )

  return commonRows
}

function getCommonTransactionAndTotalRows({
  transaction,
  isBuy,
  isPending,
  isCompleted,
  isStopLoss,
  isTakeProfit,
  isCancelled,
  showInfoModal,
}: {
  transaction: BuySellTransaction
  isBuy: boolean
  isPending: boolean
  isCompleted: boolean
  isCancelled: boolean
  isStopLoss: boolean
  isTakeProfit: boolean
  showInfoModal: (title: string, content: string) => void
}): SummaryRowProps[] {
  const { commissions, totalCost, lockedCash } = transaction

  const executionFeeRow = {
    label: t('common.commissions.executionFees'),
    value: formatCurrency(transaction?.commissions?.executionFees?.amount),
  }

  const exchangeFeeRow = {
    label: `${t('common.commissions.exchangeFee')} ${getFeeRate(
      commissions?.forexFees,
    )}`,
    value: formatCurrency(commissions?.forexFees?.amount),
  }

  const totalCommissionsRow = {
    label: `${t('common.commissions.totalCommissions')}`,
    value: formatCurrency(commissions?.totalCommission),
  }

  const buyCommissionsRows = showBuyCommissionsRows(isBuy, transaction)
    ? [
        {
          label: `${t('common.commissions.custodyFees')} ${getFeeRate(
            commissions?.custodyFees,
          )}`,
          value: formatCurrency(commissions?.custodyFees?.amount),
        },
        {
          label: `${t(
            'common.commissions.financialInstrumentCost',
          )} ${getFeeRate(commissions?.financialInstrumentFees)}`,
          value: formatCurrency(commissions?.financialInstrumentFees?.amount),
        },
        {
          label: `${t(
            'common.commissions.paymentsReceivedFromThirdParties',
          )} ${getFeeRate(commissions?.thirdPartyPayments)}`,
          value: formatCurrency(commissions?.thirdPartyPayments?.amount),
        },
      ]
    : []

  const firstTotalValue = formatCurrency(
    isBuy && !isCompleted && !isNil(lockedCash) ? lockedCash : totalCost,
  )

  const firstTotalValueClasses = stringBuilder([
    ['headline4', true],
    [
      'transactionDetails__amount--crossed',
      isCancelled && (isStopLoss || isTakeProfit),
    ],
  ])

  const totalLabel = getTotalLabel(
    transaction,
    isCompleted,
    isStopLoss,
    isTakeProfit,
  )
  const totalLabelExplanation = getTotalLabelExplanation(
    transaction,
    isStopLoss,
    isTakeProfit,
  )

  const totalCostRow = {
    labelChildren: (
      <div className='totalRow w-100'>
        <div className='justify-space-between d-flex'>
          <div className='d-flex align-center'>
            <p className='headline4'>{totalLabel}</p>
            {totalLabelExplanation ? (
              <InfoIcon
                className='ml-1 cursor-pointer'
                onClick={() => showInfoModal(totalLabel, totalLabelExplanation)}
              />
            ) : null}
          </div>
          <p className={firstTotalValueClasses}>{firstTotalValue}</p>
        </div>
        {isPending && isBuy ? (
          <div className='mt-1 justify-space-between d-flex'>
            <div className='d-flex align-center'>
              <p className='paragraph2'>{t('transactions.estimatedTotal')}</p>
              <InfoIcon
                className='ml-1 cursor-pointer'
                onClick={() =>
                  showInfoModal(
                    t('transactions.estimatedTotal'),
                    t('buySellFlow.summary.estimatedTotalExplanation'),
                  )
                }
              />
            </div>
            <p className='paragraph2'>{formatCurrency(totalCost)}</p>
          </div>
        ) : null}
      </div>
    ),
  }

  return [
    executionFeeRow,
    exchangeFeeRow,
    ...buyCommissionsRows,
    totalCommissionsRow,
    totalCostRow,
  ]
}

export function getOrderSections(
  transaction: BuySellTransaction,
  showInfoModal: (title: string, content: string) => void,
  showTaxesModal: () => void,
): SummaryCardProps[] {
  const { date, executedPrice, status, expirationDate, taxes, condition } =
    transaction

  const commissionsTitle = 'transactions.executionCommissions'

  const executedPriceRow = {
    label: t('transactions.executedPrice'),
    value: formatCurrency(executedPrice),
  }

  const isBuy = transaction?.type === TransactionType.buy

  const isLimit = transaction?.orderMethod === OrderTypeEnum?.limit

  const isStopLoss = isStopLossOrder(condition)
  const isTakeProfit = isTakeProfitOrder(condition)

  const isCompleted = status === OrderTransactionStatusEnum.completed
  const isCancelled = status === OrderTransactionStatusEnum.cancelled
  const isPending = status === OrderTransactionStatusEnum.pending

  const statusSection = {
    id: 'orderStatusSection',
    overTitle: t('transactions.orderStatus'),
    data: [getOrderStatusRow(transaction), getDateCreatedRow(date)],
  }

  const commonTransactionAndTotalRows = getCommonTransactionAndTotalRows({
    transaction,
    isBuy,
    isCancelled,
    isPending,
    isCompleted,
    isStopLoss,
    isTakeProfit,
    showInfoModal,
  })

  if (isStopLoss || isTakeProfit) {
    return [
      statusSection,
      {
        id: 'stopLossTakeProfitOrderDetailsSection',
        overTitle: isStopLoss
          ? t('transactions.stopLossOrderDetails')
          : t('transactions.takeProfitOrderDetails'),
        data: getStopLossTakeProfitOrderDetailsRows({
          transaction,
          isPending,
          isCompleted,
          isStopLoss,
          showTaxesModal,
          isLimit,
        }),
      },
      {
        id: 'totalAndCommissions',
        overTitle: t('buySellFlow.investmentServicesCommissionsAndTotal'),
        data: commonTransactionAndTotalRows,
      },
    ]
  }

  if ((isCancelled || isPending) && isLimit) {
    return [
      statusSection,
      {
        id: 'orderDetailsSection',
        overTitle: t('transactions.limitOrderDetailedInfo'),
        data: [
          getLimitPriceRow(transaction),
          getExpirationDateRow(expirationDate),
          getNumberOfSharesRow(transaction),
          getEstimatedGrossAmount(transaction),
          getTaxesRow(taxes, showTaxesModal),
          getFXRateRow(transaction),
          getEstimatedNetAmountRow(transaction, isCompleted),
          ...(isBuy
            ? [
                getBufferIncludedRow(transaction, showInfoModal),
                getRequiredNetAmountRow(transaction),
              ]
            : []),
        ],
      },
      {
        id: 'totalAndCommissions',
        overTitle: t(commissionsTitle),
        data: commonTransactionAndTotalRows,
      },
    ]
  }

  return [
    statusSection,
    {
      id: 'orderDetailsSection',
      overTitle: isLimit
        ? t('transactions.limitOrderDetailedInfo')
        : t('transactions.marketOrderDetailedInfo'),
      data: [
        executedPriceRow,
        getNumberOfSharesRow(transaction),
        getEstimatedGrossAmount(transaction),
        getTaxesRow(taxes, showTaxesModal),
        getFXRateRow(transaction),
        getEstimatedNetAmountRow(transaction, isCompleted),
      ],
    },
    {
      id: 'totalAndCommissions',
      overTitle: t(commissionsTitle),
      data: commonTransactionAndTotalRows,
    },
  ]
}
