import { Investments, Stock } from 'interfaces'
import { useMemo } from 'react'

export const useGetAssetFromPortfolio = ({
  investments,
  id,
}: {
  investments: Investments
  id: string
}): { asset: Stock | undefined } => {
  const asset: Stock | undefined = useMemo(() => {
    return investments?.portfolios
      ?.flatMap((p) => p?.positions ?? [])
      .find((a) => a?.id === id)
  }, [id, investments?.portfolios])

  return { asset }
}
