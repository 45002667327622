import { AssetIcon, InfoIcon } from '@assets/icons'
import { formatCurrency } from '@utils/helpers'
import { Avatar, Card, Divider, Modal, Spacer, Text } from 'components'
import { useGetAssetFromPortfolio } from 'hooks/useGetAssetFromPortfolio'
import { useTranslation } from '@hooks/useTranslation'
import {
  CompanyPositionData,
  ConditionType,
  Investments,
  OrderTypeEnum,
  PreOrderData,
} from '@interfaces'
import { useMemo, useState } from 'react'
import { getStopLossTakeProfitType } from '@utils/getStopLossTakeProfitType'
import { getStopLossTakeProfitName } from '@utils/getStopLossTakeProfitName'

interface InfoModalContent {
  title: string
  content: string
}

export interface OverviewSLTKCardProps {
  checkout: PreOrderData
  investments: Investments
  companyPosition: CompanyPositionData
}

export const OverviewSLTKCard = ({
  checkout,
  investments,
  companyPosition,
}: OverviewSLTKCardProps): JSX.Element => {
  const { t } = useTranslation()
  const { asset } = useGetAssetFromPortfolio({
    id: companyPosition?.id,
    investments: investments,
  })

  const [infoModalVisible, setInfoModalVisible] = useState(false)
  const [infoModal, setInfoModal] = useState<InfoModalContent>(null)

  const availableShares = asset?.shares - asset?.lockedShares

  const stopLossTakeProfitType = getStopLossTakeProfitType(checkout?.condition)

  const isTakeProfit = useMemo(
    () => checkout?.condition.stopType === ConditionType.TAKE_PROFIT,
    [checkout?.condition.stopType],
  )

  const operationTitle = t(
    `buySellFlow.preorder.details.${checkout?.condition?.stopType}.${stopLossTakeProfitType}.title`,
  )

  const pnlColor = useMemo(() => {
    return checkout?.estimatedPnL?.amount < 0 ? 'negative' : 'positive'
  }, [checkout?.estimatedPnL?.amount])

  const profitOrProceeds = formatCurrency(checkout?.totalCost)

  const showInfoModal = ({ title, content }: InfoModalContent): void => {
    setInfoModal({ title, content })
    setInfoModalVisible(true)
  }

  const renderDetail = (
    label: string,
    value: string,
    testId: string,
  ): JSX.Element => {
    return (
      <>
        <div className='d-flex w-100 justify-space-between'>
          <Text preset='paragraph2' text={label} testId={`${testId}-label`} />
          <Text preset='paragraph2' text={value} testId={`${testId}-value`} />
        </div>
        <Spacer preset='tiny' />
      </>
    )
  }

  const renderInfoTooltip = ({
    title,
    content,
  }: InfoModalContent): JSX.Element => {
    return (
      <div
        className='cursor-pointer d-flex align-center'
        onClick={() => {
          showInfoModal({ title, content })
        }}
        aria-hidden
      >
        <InfoIcon size={16} color='tertiary' />
      </div>
    )
  }

  return (
    <>
      <Card width='full' padding='smaller'>
        <div className='d-flex align-center w-100'>
          <Avatar
            roundness='full'
            size='massive'
            image={asset?.logoUrl}
            fallback={<AssetIcon size={24} />}
          />
          <Spacer preset='small' />
          <div className='d-flex d-flex-col'>
            <Text preset='paragraph5' color='primary' text={operationTitle} />
            <div className='d-flex'>
              <Text
                preset='headline1'
                color='iconPrimaryInitial'
                text={`${checkout?.shares}/${availableShares}`}
              />
              <Spacer preset='tiny' />
              <Text preset='headline1' color='primary' text={asset?.ticker} />
              <Spacer preset='tiny' />
              {renderInfoTooltip({
                title: 'buySellFlow.infoShares.title',
                content: 'buySellFlow.infoShares.text',
              })}
            </div>
          </div>
        </div>
      </Card>
      <Divider />
      <Card width='full' padding='smaller'>
        <Spacer preset='tiny' />
        <div className='d-flex w-100 justify-space-between'>
          <div className='d-flex'>
            <Text
              preset='headline3'
              text={
                isTakeProfit
                  ? t('common.estimatedProfit')
                  : t('common.estimatedProceeds')
              }
              testId={
                isTakeProfit
                  ? 'estimated-profit-label'
                  : 'estimated-proceeds-label'
              }
            />
            <Spacer preset='tiny' />
            {renderInfoTooltip({
              title: isTakeProfit
                ? 'common.estimatedProfit'
                : 'common.estimatedProceeds',
              content: isTakeProfit
                ? 'buySellFlow.infoEstimatedProfitOrProceed.profitText'
                : 'buySellFlow.infoEstimatedProfitOrProceed.proceedText',
            })}
          </div>

          <Text
            preset='headline3'
            text={profitOrProceeds}
            testId='profit-or-proceeds'
          />
        </div>
        <Spacer preset='tiny' />
        {!!checkout?.condition?.stopPercentage &&
          renderDetail(
            t('buySellFlow.pricePercentageLimit'),
            `${checkout?.condition?.stopPercentage}%`,
            'price-percentage-limit',
          )}
        {!!checkout?.condition?.stopPrice &&
          !checkout?.condition.activationPrice &&
          renderDetail(
            t(getStopLossTakeProfitName(checkout?.condition)),
            formatCurrency({
              amount: checkout?.condition?.stopPrice,
              currency: checkout?.price?.currency,
            }),
            'fixed-stop-loss-price',
          )}
        {!!checkout?.condition?.trailAmount &&
          renderDetail(
            t('buySellFlow.trailAmount'),
            formatCurrency({
              amount: checkout?.condition?.trailAmount,
              currency: checkout?.price?.currency,
            }),
            'trail-amount',
          )}
        {!!checkout?.condition?.trailPercentage &&
          renderDetail(
            t('buySellFlow.trailPercentage'),
            `${checkout?.condition?.trailPercentage}%`,
            'trail-percentage',
          )}
        {!!checkout?.condition.activationPrice &&
          renderDetail(
            t('buySellFlow.activationPrice'),
            formatCurrency({
              amount: checkout?.condition?.activationPrice,
              currency: checkout?.price?.currency,
            }),
            'activation-price',
          )}
        {checkout?.method === OrderTypeEnum.limit &&
          !!checkout?.price &&
          renderDetail(
            t('buySellFlow.limitPrice'),
            formatCurrency({
              amount: checkout?.price?.amount,
              currency: checkout?.price?.currency,
            }),
            'limit-price',
          )}
        <div className='d-flex w-100 justify-space-between'>
          <div className='d-flex'>
            <Text
              preset='paragraph2'
              text={t('buySellFlow.estimatedPnL')}
              testId='estimated-pnl-label'
            />
            <Spacer preset='tiny' />
            {renderInfoTooltip({
              title: 'buySellFlow.infoEstimatedPnL.title',
              content: 'buySellFlow.infoEstimatedPnL.text',
            })}
          </div>
          <Text
            preset='paragraph2'
            text={
              formatCurrency({
                amount: checkout?.estimatedPnL?.amount,
                currency: checkout?.estimatedPnL?.currency,
              }) || ''
            }
            color={pnlColor}
            testId='estimated-pnl-value'
          />
        </div>
      </Card>
      <Modal
        className='backdrop--none'
        text={t(infoModal?.title)}
        buttonText={t('common.ok')}
        show={infoModalVisible}
        onButton={() => setInfoModalVisible(false)}
      >
        {t(infoModal?.content)}
      </Modal>
    </>
  )
}
