import { UIIcon, UIIconColorProps } from 'components/utils'

export const ExchangeIcon = ({
  size = 16,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 17 16'
      fill='none'
    >
      <g clipPath='url(#clip0_22883_160070)'>
        <path
          d='M4.4339 12.6174H4.4839V12.5674V11.3667C5.51011 12.7395 7.15134 13.6322 9.00011 13.6322C12.1124 13.6322 14.631 11.1136 14.631 8.00124V7.95124H14.581H13.5663H13.5163V8.00124C13.5163 10.4952 11.4941 12.5174 9.00011 12.5174C7.46836 12.5174 6.1143 11.7538 5.2998 10.588H6.46333H6.51333V10.538V9.52331V9.47331H6.46333H3.41919H3.36919V9.52331V12.5674V12.6174H3.41919H4.4339ZM3.36919 7.59069V7.64069H3.41919H4.4339H4.4839V7.59069C4.4839 5.09674 6.50616 3.07448 9.00011 3.07448C10.5319 3.07448 11.8859 3.83817 12.7004 5.0039H11.5369H11.4869V5.0539V6.06862V6.11862H11.5369H14.581H14.631V6.06862V3.02448V2.97448H14.581H13.5663H13.5163V3.02448V4.22522C12.4901 2.8524 10.8489 1.95977 9.00011 1.95977C5.88777 1.95977 3.36919 4.47834 3.36919 7.59069Z'
          fill='var(--textTertiaryInitial)'
          stroke='#3449E6'
          strokeWidth='0.1'
        />
      </g>
      <defs>
        <clipPath id='clip0_22883_160070'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
