import { CustomModal } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { ExchangeBalanceSteps } from './exchangeBalance.props'
import { WalletDetails } from './steps/walletDetails/walletDetails'
import {
  clearExchangeBalanceState,
  clearFromTransaction,
  clearOrders,
  clearPreOrders,
  clearSelectedCurrency,
  goToPrevStep,
  resetIsTotalBalance,
  setExchangeBalanceStep,
} from './thunk'
import WalletList from './steps/walletList/walletList'
import ReviewOrder from './steps/reviewOrder/reviewOrder'
import ConfirmOrder from './steps/confirmOrder/confirmOrder'
import ExchangeSuccess from './steps/exchangeSuccess/exchangeSuccess'
import { useState } from 'react'
import { BalanceModal, CurrencyCode, Money } from 'interfaces'
import OriginWalletList from './steps/originWalletList/originWalletList'

interface ExchangeBalanceProps {
  availableBalance: number
  onHold: number
  withdrawlMoney: number
  currency: string
  setBalanceModalValue: (value: BalanceModal) => void
  transactionCurrency?: string
  destination?: Money
}
const ExchangeBalance = ({
  availableBalance,
  currency,
  onHold,
  withdrawlMoney,
  setBalanceModalValue,
  transactionCurrency,
  destination,
}: ExchangeBalanceProps): JSX.Element => {
  const dispatch = useDispatch()
  const [destinationWallet, setDestinationWallet] = useState<Money>(
    destination || null,
  )

  const onCloseReset = (isAddMoney?: boolean): void => {
    dispatch(clearExchangeBalanceState())
    dispatch(clearPreOrders())
    dispatch(clearOrders())
    dispatch(clearFromTransaction())
    dispatch(resetIsTotalBalance())
    if (!isAddMoney) {
      dispatch(clearSelectedCurrency())
    }
  }
  const exchangeBalanceState = useSelector(
    (state: RootState) => state.exchangeBalance,
  )

  const renderSteps = (step: ExchangeBalanceSteps): JSX.Element => {
    switch (step) {
      // Top up payment amount selection
      case ExchangeBalanceSteps.walletDetails:
        return (
          <WalletDetails
            transactionCurrency={transactionCurrency}
            sourceBalance={availableBalance}
            sourceCurrency={currency}
            withdrawlMoney={withdrawlMoney}
            close={() => onCloseReset(true)}
            onHold={onHold}
          />
        )
      case ExchangeBalanceSteps.originWalletList:
        return (
          <OriginWalletList
            destinationWallet={destinationWallet}
            setOriginWallet={setBalanceModalValue}
            onPrevious={() => {
              dispatch(goToPrevStep())
            }}
          />
        )
      case ExchangeBalanceSteps.walletList:
        return (
          <WalletList
            destinationWallet={destinationWallet}
            setDestinationWallet={setDestinationWallet}
            sourceCurrency={currency as CurrencyCode}
            onPrevious={() => {
              dispatch(goToPrevStep())
            }}
          />
        )
      case ExchangeBalanceSteps.reviewOrder:
        return (
          <ReviewOrder
            sourceCurrency={currency as CurrencyCode}
            sourceBalance={availableBalance}
            destinationBalance={destinationWallet?.amount}
            desinationCurrency={destinationWallet?.currency as CurrencyCode}
            onPrevious={() => {
              setDestinationWallet(destination || null)
              if (exchangeBalanceState?.selectedCurrency) {
                dispatch(
                  setExchangeBalanceStep(ExchangeBalanceSteps.walletDetails),
                )
              } else if (destination) {
                onCloseReset()
              } else {
                dispatch(goToPrevStep())
              }
            }}
          />
        )
      case ExchangeBalanceSteps.confirmOrder:
        return (
          <ConfirmOrder
            onPrevious={() => {
              dispatch(clearPreOrders())
              dispatch(goToPrevStep())
            }}
          />
        )
      case ExchangeBalanceSteps.exchangeSuccess:
        return <ExchangeSuccess onClose={onCloseReset} />
      default:
        return null
    }
  }
  return (
    <CustomModal
      theme='lighter'
      size='big'
      show={!!exchangeBalanceState?.currentStep}
      height='fullHeight'
      onClose={onCloseReset}
      dismissable={true}
      placeOn='right'
    >
      {renderSteps(exchangeBalanceState?.currentStep)}
    </CustomModal>
  )
}

export default ExchangeBalance
