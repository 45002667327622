import { BasicLink, Button, Show, Spacer } from 'components'
import { formatCurrency } from '@utils/helpers'
import BalanceCard from '@shared/components/balanceCard/balanceCardItem'
import { RootState, useAppSelector } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks/useNavigation'
import { BalanceModal, CurrencyCode } from 'interfaces'
import React, { useMemo, useState } from 'react'
import {
  setExchangeBalanceStep,
  setSelectedCurrency,
  totalBalanceMode,
} from 'features/exchangeBalance/thunk'
import { ExchangeBalanceSteps } from 'features/exchangeBalance/exchangeBalance.props'
import ExchangeBalance from 'features/exchangeBalance/exchangeBalance'
import { ButtonProps } from 'components/button/button.props'
import { Dollar, InvestArrow, PlusIcon, RemoveIcon } from '@assets/icons'
import { useAddFundsAndCashout } from 'hooks/useAddFundsAndCashout'
import { FxRateModal } from '@shared/modals'
import { CurrencyFlag } from 'components/currencyFlag/currencyFlag'

const BalancesSection = (): JSX.Element => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const dispatch = useDispatch()
  const [modalForexFees, setModalForexFees] = useState(false)
  const [balanceModalValue, setBalanceModalValue] = useState<BalanceModal>({
    availableBalance: 0,
    onHold: 0,
    withdrawlMoney: 0,
    currency: CurrencyCode.USD,
  })
  const exchangeBalanceState = useAppSelector(
    (state: RootState) => state.exchangeBalance,
  )
  const { userBalance } = useSelector((state: RootState) => state)
  const { cashout, modalOnboarding, addFunds } = useAddFundsAndCashout({
    origin: 'Home Portfolio',
  })
  const actions = useMemo(() => {
    const commonBtnProps: ButtonProps = {
      autoWidth: true,
      size: 'small',
      buttonType: 'secondary',
      textPreset: 'paragraph2',
    }

    const visibleActions: (typeof Button)[] = []

    const buttons = {
      invest: (
        <Button
          {...commonBtnProps}
          left={<InvestArrow size={24} />}
          text={t('common.invest')}
          onClick={() => navigate('/discover/all')}
          buttonType='primary'
          autoWidth={false}
        />
      ),
      fxRate: (
        <Button
          {...commonBtnProps}
          left={<Dollar size={24} />}
          text={t('transactions.fxRate')}
          buttonType='secondary'
          onClick={() => {
            setModalForexFees(true)
          }}
          autoWidth={false}
        />
      ),
      addMoney: (
        <Button
          {...commonBtnProps}
          left={<PlusIcon size={24} />}
          text={t('balance.addMoney')}
          onClick={() => {
            dispatch(setSelectedCurrency(null))
            addFunds()
          }}
          buttonType='secondary'
          autoWidth={false}
        />
      ),
      withdraw: (
        <Button
          {...commonBtnProps}
          left={<RemoveIcon size={24} />}
          text={t('balance.withdraw')}
          buttonType='secondary'
          onClick={() => {
            dispatch(setSelectedCurrency(null))
            cashout()
          }}
          autoWidth={false}
        />
      ),
    }

    if (userBalance.wallet?.length <= 1) {
      return [
        ...visibleActions,
        buttons.invest,
        buttons.addMoney,
        buttons.fxRate,
        buttons.withdraw,
      ]
    }

    if (userBalance?.balance?.amount > 0) {
      return [
        ...visibleActions,
        buttons.invest,
        buttons.addMoney,
        buttons.fxRate,
        buttons.withdraw,
      ]
    }
    return [...visibleActions, buttons.invest, buttons.addMoney, buttons.fxRate]
  }, [
    cashout,
    navigate,
    t,
    addFunds,
    userBalance?.balance?.amount,
    dispatch,
    userBalance.wallet?.length,
  ])
  return (
    <>
      <div className='w-100'>
        <div className='d-flex justify-space-between align-center'>
          {t('balance.balances')}
          <BasicLink
            onClick={() => {
              navigate(`/profile/balances`)
            }}
            className='text-right d-flex align-center paragraph2'
          >
            {t('common.viewAll')}
          </BasicLink>
        </div>
        <Spacer preset='smallMinus' />
        <div className='d-flex align-center justify-space-between gap-24'>
          <BalanceCard
            onClick={() => {
              setBalanceModalValue({
                availableBalance: userBalance?.cashActive?.amount,
                currency: userBalance?.cashActive?.currency,
                onHold: userBalance?.lockedCash?.amount,
                withdrawlMoney: userBalance?.maxWithdrawal?.amount,
              })
              dispatch(totalBalanceMode())
              dispatch(
                setExchangeBalanceStep(ExchangeBalanceSteps.walletDetails),
              )
            }}
            avatar={
              <CurrencyFlag
                currency={userBalance?.cashActive?.currency as CurrencyCode}
              />
            }
            title={t('balance.totalBalance')}
            subtitle={formatCurrency({
              amount: userBalance?.cashActive?.amount,
              currency: userBalance?.cashActive?.currency,
            })}
          />
          {userBalance.wallet?.map((walletItem, walletIndex) => {
            const wCurrency = walletItem?.cashAvailable?.currency
            return (
              <BalanceCard
                onClick={() => {
                  setBalanceModalValue({
                    availableBalance: walletItem?.cashAvailable?.amount,
                    currency: walletItem?.cashAvailable?.currency,
                    onHold: walletItem?.lockedCash?.amount,
                    withdrawlMoney: walletItem?.maxWithdrawal?.amount,
                  })
                  dispatch(
                    setSelectedCurrency(walletItem?.cashAvailable?.currency),
                  )
                  dispatch(
                    setExchangeBalanceStep(ExchangeBalanceSteps.walletDetails),
                  )
                }}
                key={walletIndex}
                avatar={<CurrencyFlag currency={wCurrency as CurrencyCode} />}
                title={wCurrency}
                subtitle={formatCurrency({
                  amount: walletItem?.cashAvailable?.amount,
                  currency: walletItem?.cashAvailable?.currency,
                })}
              />
            )
          })}
        </div>
      </div>
      <Spacer preset='medium' />
      <div className='w-100'>
        <div className='d-flex'>
          {actions?.map((item, idx) => {
            return (
              <React.Fragment key={`button-${idx}`}>
                {item}
                <Spacer preset='tiny' />
              </React.Fragment>
            )
          })}
        </div>
      </div>
      {modalOnboarding}
      <Show when={modalForexFees}>
        <FxRateModal close={() => setModalForexFees(false)}></FxRateModal>
      </Show>
      {!!exchangeBalanceState?.stepsHistory?.length &&
        exchangeBalanceState?.currentStep && (
          <ExchangeBalance
            setBalanceModalValue={setBalanceModalValue}
            availableBalance={balanceModalValue?.availableBalance}
            currency={balanceModalValue?.currency}
            onHold={balanceModalValue?.onHold}
            withdrawlMoney={balanceModalValue?.withdrawlMoney}
          />
        )}
    </>
  )
}

export default BalancesSection
