import './orderSummaryCard.styles.scss'
import { useMemo, useState } from 'react'
import { OrderOperationTypeEnum, OrderTypeEnum } from '@interfaces'
import { InfoIcon } from '@assets/svg'
import { t } from 'i18next'
import { Avatar, Card, Divider, Modal, Spacer, Text } from '@components'
import { formatCurrency } from '@utils/helpers'
import { OrderSummaryCardProps } from './orderSummaryCard.props'
import { useGetAssetFromPortfolio } from 'hooks/useGetAssetFromPortfolio'
import { AssetIcon } from '@assets/icons'
import PreOrderExchange from './preOrderExchange'

const totalRequiredKey = 'buySellFlow.summary.totalRequired'

export function OrderSummaryCard({
  checkout,
  investments,
  companyPosition,
}: OrderSummaryCardProps): JSX.Element {
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false)
  const [infoTitle, setInfoTitle] = useState<string>()
  const [infoContent, setInfoContent] = useState<string>()

  const isBuy = checkout?.type === OrderOperationTypeEnum.buy

  const { asset } = useGetAssetFromPortfolio({
    id: companyPosition?.id,
    investments: investments,
  })

  const total = formatCurrency(
    isBuy ? checkout?.lockedCash : checkout?.totalCost,
  )

  const availableShares = asset?.shares - asset?.lockedShares

  const estimatedTotal = isBuy ? formatCurrency(checkout?.totalCost) : null
  const available = isBuy
    ? formatCurrency(checkout?.cashAvailableForInvesting)
    : String(availableShares)

  const balanceInfoModalHandler = (): void => {
    setInfoTitle(t('buySellFlow.availableForSaleModalTitle'))
    setInfoContent(t('buySellFlow.sharesAvailableForSaleExplanation'))
    setShowInfoModal(true)
  }

  const showEstimatedTotalModal = (): void => {
    setInfoTitle(t('buySellFlow.summary.estimatedTotal'))
    setInfoContent(t('buySellFlow.summary.estimatedTotalExplanation'))
    setShowInfoModal(true)
  }

  const showTotalModal = (): void => {
    let infoTitle: string
    let infoContent: string

    if (checkout?.type === OrderOperationTypeEnum.sell) {
      infoTitle = t('buySellFlow.estimatedEarnings')
      infoContent = t('buySellFlow.summary.estimatedEarningsExplanation')
    } else if (checkout?.method === OrderTypeEnum.limit) {
      infoTitle = t('buySellFlow.summary.totalToWithhold')
      infoContent = t('buySellFlow.summary.totalToWithholdExplanation')
    } else {
      infoTitle = t(totalRequiredKey)
      infoContent = t('buySellFlow.summary.totalToWithholdExplanation')
    }

    setInfoTitle(infoTitle)
    setInfoContent(infoContent)
    setShowInfoModal(true)
  }

  const title = useMemo(() => {
    switch (checkout?.method) {
      case OrderTypeEnum.market:
        if (checkout?.type === OrderOperationTypeEnum.buy) {
          return t('buySellFlow.limitOrders.buyMarket')
        }
        return t('buySellFlow.limitOrders.sellMarket')
      case OrderTypeEnum.limit:
        if (checkout?.type === OrderOperationTypeEnum.buy) {
          return t('buySellFlow.limitOrders.buyLimit')
        }
        return t('buySellFlow.limitOrders.sellLimit')
      default:
        return ''
    }
  }, [checkout?.type, checkout?.method])

  const totalLabel = useMemo(() => {
    switch (checkout?.type) {
      case OrderOperationTypeEnum.buy:
        if (checkout?.method === OrderTypeEnum.limit) {
          return t('buySellFlow.summary.totalToWithhold')
        }
        return t(totalRequiredKey)
      case OrderOperationTypeEnum.sell:
        return t('buySellFlow.estimatedEarnings')
      default:
        return ''
    }
  }, [checkout?.type, checkout?.method])

  const estimatedTotalLabel = useMemo(() => {
    if (checkout?.type === OrderOperationTypeEnum.buy) {
      return t('buySellFlow.summary.estimatedTotal')
    }
    return ''
  }, [checkout?.type])

  const availableLabel = useMemo(() => {
    if (checkout?.type === OrderOperationTypeEnum.buy) {
      return t('buySellFlow.balanceAvailableToInvest', {
        cashAvailable: available,
      })
    }
    return `${t('buySellFlow.infoAssets.title')}: ${available} ${
      checkout?.asset?.ticker
    }`
  }, [checkout?.type, checkout?.asset?.ticker, available])

  return (
    <>
      <Card width='full' padding='smaller'>
        <div className='d-flex align-center w-100'>
          <Avatar
            roundness='full'
            size='massive'
            image={asset?.logoUrl}
            fallback={<AssetIcon size={24} />}
          />
          <Spacer preset='small' />
          <div className='d-flex d-flex-col'>
            <Text preset='paragraph5' color='primary' text={title} />
            <div className='d-flex headline1'>
              <p className='orange--text'>{checkout?.shares}&nbsp;</p>
              <p className='primary--text'>{asset?.ticker}</p>
            </div>
          </div>
        </div>
      </Card>
      <Divider />
      <Card width='full' padding='smaller'>
        <div className='d-flex justify-space-between'>
          <div className='d-flex align-center'>
            <Text text={totalLabel} preset='headline3' />
            <InfoIcon
              className='ml-1 cursor-pointer'
              onClick={showTotalModal}
              aria-hidden
            />
          </div>
          <Text preset='headline3'>{total}</Text>
        </div>
        {estimatedTotal ? (
          <div className='d-flex justify-space-between mt-1 paragraph2'>
            <div className='d-flex align-center'>
              <p>{estimatedTotalLabel}</p>
              <InfoIcon
                className='ml-1 cursor-pointer'
                onClick={showEstimatedTotalModal}
                aria-hidden
              />
            </div>
            <p>{estimatedTotal}</p>
          </div>
        ) : null}
        <Spacer preset='tiny' />
        {checkout?.type === OrderOperationTypeEnum.buy && (
          <div className='w-100 d-flex align-end justify-end'>
            <PreOrderExchange available={available} />
          </div>
        )}
        {checkout?.type === OrderOperationTypeEnum.sell && (
          <div
            className='mt-1 cursor-pointer'
            role='button'
            onClick={balanceInfoModalHandler}
            aria-hidden
          >
            <p className='text-right orange--text paragraph3'>
              {availableLabel}
            </p>
          </div>
        )}
      </Card>
      {showInfoModal ? (
        <Modal
          className='backdrop--none'
          text={infoTitle}
          show={showInfoModal}
          buttonText={t('common.ok')}
          onButton={() => setShowInfoModal(false)}
        >
          {infoContent}
        </Modal>
      ) : null}
    </>
  )
}
