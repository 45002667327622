import { ChevronLeft } from '@assets/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  formatCurrency,
  formatCurrencyToSymbol,
  sanitizeNumber,
  whatDecimalSeparator,
} from '@utils/helpers'
import { BigSpinner, Button, Card, CustomModal, Spacer, Text } from 'components'
import { SliderInput } from 'components/sliderInput/sliderInput'
import { WalletInputForm } from 'components/walletInputForm/walletInputForm'
import { ExchangeBalanceSteps } from 'features/exchangeBalance/exchangeBalance.props'
import {
  fetchExchangeRates,
  getTransferPreOrder,
  setExchangeBalanceStep,
} from 'features/exchangeBalance/thunk'
import { useOnChangeMoneyForm } from 'hooks/useOnChangeMoneyForm'
import { useTranslation } from 'hooks/useTranslation'
import { CurrencyCode, Forex } from 'interfaces'
import { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import * as yup from 'yup'

const ReviewOrder = ({
  onPrevious,
  sourceCurrency,
  sourceBalance,
  desinationCurrency,
  destinationBalance,
}: {
  onPrevious: () => void
  sourceCurrency: CurrencyCode
  sourceBalance: number
  desinationCurrency: CurrencyCode
  destinationBalance: number
}): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [value, setValue] = useState(0)
  const decimalSeparator = whatDecimalSeparator()

  const schemaReset = yup.object().shape({
    sourceAmount: yup.string().required(t('common.fieldRequired')),
    destinationAmount: yup.string().required(t('common.fieldRequired')),
  })
  const chooseAmountForm = useForm({
    resolver: yupResolver(schemaReset),
    criteriaMode: 'all',
    mode: 'onChange',
  })

  const onChangeAmount = useOnChangeMoneyForm(
    chooseAmountForm,
    'sourceAmount',
    2,
  )
  const onChangeDestinationAmount = useOnChangeMoneyForm(
    chooseAmountForm,
    'destinationAmount',
    2,
  )
  const exchangeBalanceState = useSelector(
    (state: RootState) => state.exchangeBalance,
  )
  // Watch both fields for changes
  const sourceAmount: string = chooseAmountForm.watch('sourceAmount')
  const { portfolioId } = useSelector(
    (state: RootState) => state.profile?.userProfile,
  )
  const findExchangeBySource = (source: string): Forex | undefined => {
    return exchangeBalanceState?.exchanges.find(
      (exchange) => exchange?.source === source,
    )
  }

  // Find the object where source is
  const Exchange = findExchangeBySource(sourceCurrency)
  const reviewOrderHandler = (): void => {
    const data = {
      portfolioId,
      sourceAmount: {
        amount: sanitizeNumber(String(sourceAmount)),
        currency: sourceCurrency,
      },
      targetCurrency: desinationCurrency,
    }
    dispatch(getTransferPreOrder(data))
  }
  const minimumNotPass = (value: number): boolean => {
    if (isNaN(value)) return true
    const numberTo = value
    return numberTo > (sourceBalance || 0) || isNaN(numberTo) || numberTo == 0
  }

  useEffect(() => {
    if (desinationCurrency) {
      dispatch(
        fetchExchangeRates({
          target: desinationCurrency,
        }),
      )
    }
  }, [desinationCurrency, dispatch])

  useEffect(() => {
    if (
      !exchangeBalanceState?.preOrderError &&
      !exchangeBalanceState?.preOrderLoading &&
      exchangeBalanceState?.preOrderSuccess
    ) {
      dispatch(setExchangeBalanceStep(ExchangeBalanceSteps.confirmOrder))
    }
  }, [
    dispatch,
    exchangeBalanceState?.preOrderError,
    exchangeBalanceState?.preOrderSuccess,
    exchangeBalanceState?.preOrderLoading,
  ])

  return (
    <>
      <CustomModal.Header
        text={
          <Text
            preset='subtitle2'
            text={t('exchangeBalance.destinationWalletTitle')}
          />
        }
        className='w-100 d-flex align-center justify-space-between'
      >
        <Button
          onClick={onPrevious}
          buttonType='link'
          autoWidth
          content={<ChevronLeft color='iconPrimaryInitial' size={36} />}
          shape='square'
        />
      </CustomModal.Header>

      <CustomModal.Content>
        <div className='w-100'>
          {exchangeBalanceState?.loading ? (
            <div className='d-flex justify-center align-center w-100 h-100'>
              <BigSpinner />
            </div>
          ) : (
            <>
              <Text preset='headline1' text='Exchange balance' />
              <Text
                preset='bodyRegular'
                text={`${formatCurrencyToSymbol(
                  sourceCurrency,
                )} 1 = ${formatCurrencyToSymbol(desinationCurrency)} ${Number(
                  Exchange?.rate,
                )}`}
              />

              <Spacer preset='medium' />
              <Card>
                <div className='d-flex d-flex-col'>
                  <Text
                    preset='bodyBig'
                    text={t('exchangeBalance.originWallet')}
                  />
                  <Spacer preset='tiny' />
                  <WalletInputForm
                    disableDropDown={true}
                    defaultCurrency={
                      (exchangeBalanceState.selectedCurrency as CurrencyCode) ||
                      sourceCurrency
                    }
                    subTitle={
                      <div className='d-flex align-center'>
                        <Text
                          preset='caption'
                          color='secondary'
                          text={`${t('common.balance')} : ${formatCurrency({
                            amount: sourceBalance,
                            currency: sourceCurrency,
                          })}`}
                        />
                      </div>
                    }
                    register={chooseAmountForm.register}
                    inputProps={{
                      placeholder: `0${decimalSeparator}00`,
                      name: 'sourceAmount',
                      onChange: async (e: ChangeEvent<HTMLInputElement>) => {
                        const modifitedDestinationAmount =
                          (
                            sanitizeNumber(e.target.value) *
                            Number(Exchange.rate)
                          ).toFixed(2) || 0

                        chooseAmountForm.setValue(
                          'destinationAmount',
                          !isNaN(Number(modifitedDestinationAmount))
                            ? modifitedDestinationAmount
                            : '',
                        )
                        const ModifitedSliderValue = sanitizeNumber(
                          e.target.value,
                        )
                        setValue(
                          !isNaN(ModifitedSliderValue)
                            ? ModifitedSliderValue
                            : 0,
                        )
                        await onChangeAmount(e)
                      },
                      value: chooseAmountForm?.getValues()?.sourceAmount,
                    }}
                  />
                </div>
                <Spacer preset='medium' />
                <div className='w-100'>
                  <SliderInput
                    behaviour='free'
                    steps={4}
                    min={0}
                    max={sourceBalance}
                    onChange={(newValue) => {
                      chooseAmountForm.setValue(
                        'sourceAmount',
                        newValue.toFixed(2),
                      )
                      chooseAmountForm.setValue(
                        'destinationAmount',
                        (newValue / Number(Exchange?.rate)).toFixed(2),
                      )
                      setValue(newValue)
                    }}
                    value={value}
                  />
                </div>

                <Spacer preset='medium' />

                <div className='d-flex d-flex-col'>
                  <Text
                    preset='bodyBig'
                    text={t('exchangeBalance.destinationWallet')}
                  />
                  <Spacer preset='tiny' />
                  <WalletInputForm
                    disableDropDown={true}
                    defaultCurrency={desinationCurrency}
                    subTitle={
                      <div className='d-flex align-center'>
                        <Text
                          preset='caption'
                          color='secondary'
                          text={`${t('common.balance')} : ${formatCurrency({
                            amount: destinationBalance,
                            currency: desinationCurrency,
                          })}`}
                        />
                      </div>
                    }
                    register={chooseAmountForm.register}
                    inputProps={{
                      placeholder: `0${decimalSeparator}00`,
                      name: 'destinationAmount',
                      onChange: async (e: ChangeEvent<HTMLInputElement>) => {
                        const modifitedSourceAmount = (
                          sanitizeNumber(e.target.value) /
                          Number(Exchange?.rate)
                        ).toFixed(2)
                        chooseAmountForm.setValue(
                          'sourceAmount',
                          !isNaN(Number(modifitedSourceAmount))
                            ? modifitedSourceAmount
                            : '',
                        )
                        const ModifitedSliderValue =
                          sanitizeNumber(e.target.value) *
                          Number(Exchange?.rate)
                        setValue(
                          !isNaN(ModifitedSliderValue)
                            ? ModifitedSliderValue
                            : 0,
                        )

                        await onChangeDestinationAmount(e)
                      },
                      value: chooseAmountForm?.getValues()?.destinationAmount,
                    }}
                  />
                </div>
              </Card>
            </>
          )}
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          text='Review order'
          size='big'
          loading={exchangeBalanceState.preOrderLoading}
          disabled={minimumNotPass(
            sanitizeNumber(
              chooseAmountForm.getValues()?.sourceAmount as string,
            ),
          )}
          onClick={() => {
            reviewOrderHandler()
          }}
        />
      </CustomModal.Footer>
    </>
  )
}

export default ReviewOrder
