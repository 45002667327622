import { useEffect, useState } from 'react'
import { useTranslation } from '@hooks'
import { CustomModal, Button, Spacer } from '@components'
import { BankPaymentReviewDetails, CompanyInfoCard } from '@interfaces'
import { formatMoney } from '@utils/helpers'
import { SecurePaymentIcon } from '@assets/svg'
import { trackingService } from '@services'
import { useDispatch } from 'react-redux'
import { clearTopUpPaymentUrl } from '../topup/thunk'
import { TopUpEvents } from '@utils/eventTracker/eventKeys'
import { BankIcon, ReceiptIcon, TimerIcon, UndoIcon } from '@assets/icons'

export function PaymentReview(props: {
  onClose: () => void
  prevStep: () => void
  show?: boolean
  companyData?: CompanyInfoCard[]
}): JSX.Element {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const onClosing = (): void => {
    localStorage.removeItem('summaryFlow')
    trackingService.trackEvent({ event: TopUpEvents.doneClicked })
    props.onClose()
  }

  const [summary, setSummary] = useState<BankPaymentReviewDetails>({
    amount: null,
    currency: null,
    concept: null,
  })

  const summaryAmount =
    summary?.amount && summary?.currency
      ? formatMoney(summary?.amount, summary?.currency)
      : null

  useEffect(() => {
    try {
      const summaryFlow = JSON.parse(localStorage.getItem('summaryFlow') || ' ')

      if (summaryFlow) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setSummary(summaryFlow)
        trackingService.trackEvent({ event: TopUpEvents.paymentSubmitted })
      }
    } catch (_) {
      // Do nothing
    }
    return () => {
      dispatch(clearTopUpPaymentUrl())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <CustomModal.Header
        linkText={t('common.close')}
        className='subtitle3 dark--label-title'
        onClick={onClosing}
      />
      <CustomModal.Content className='d-flex d-flex-col align-center justify-start'>
        <div className='connected__banks__icons'>
          <SecurePaymentIcon />
        </div>

        <div className='connected__banks__header mt-3'>
          <div className='headline1 mb-1'>
            {t('submittedBankPayment.paymentSubmitted')}
          </div>
          <div className='headline1 positive--text my-3'>{summaryAmount}</div>
          <div className='paragraph2 secondary--text'>
            {t('submittedBankPayment.willBeSent')}
          </div>
          <div className='paragraph2 secondary--text'>
            {t('submittedBankPayment.concept', { code: summary.concept })}
          </div>
        </div>

        <div className='infoCard__content mt-5 mb-3'>
          <div className='infoCard__content__item paragraph2'>
            <div className='mr-1 d-flex align-self-center'>
              <TimerIcon color='iconNeutralSoft' />
            </div>
            <Spacer preset='smaller' />
            <div className='text-left secondary--text'>
              {t('submittedBankPayment.processingTime')}
            </div>
          </div>
          <div className='infoCard__content__item paragraph2'>
            <div className='mr-1 d-flex align-self-center'>
              <UndoIcon color='iconNeutralSoft' />
            </div>
            <Spacer preset='smaller' />
            <div className='text-left secondary--text'>
              {t('submittedBankPayment.cancelPayment')}
            </div>
          </div>
          <div className='infoCard__content__item paragraph2'>
            <div className='mr-1 d-flex align-self-center'>
              <BankIcon color='iconNeutralSoft' />
            </div>
            <Spacer preset='smaller' />
            <div className='text-left secondary--text'>
              {t('submittedBankPayment.weArePerforming')}
            </div>
          </div>
          <div className='infoCard__content__item paragraph2'>
            <div className='mr-1 d-flex align-self-center'>
              <ReceiptIcon color='iconNeutralSoft' />
            </div>
            <Spacer preset='smaller' />
            <div className='text-left secondary--text'>
              {t('submittedBankPayment.findYourTopUp')}
            </div>
          </div>
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          type='submit'
          size='big'
          text={t('common.done')}
          onClick={onClosing}
        />
      </CustomModal.Footer>
    </>
  )
}
