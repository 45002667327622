import { CheckIcon, DollarIcon, PercentageIcon } from '@assets/icons'
import { Avatar, CustomModal, Divider, Spacer, Text } from '@components'
import { useTranslation } from '@hooks/useTranslation'
import {
  ConditionType,
  StopLossTakeProfitType,
  SLTKInputType,
} from '@interfaces'

export const TogglePricePercentModal = ({
  type,
  tab,
  inputType,
  onChange,
}: {
  type: ConditionType
  tab: StopLossTakeProfitType
  inputType: SLTKInputType
  onChange: (type: SLTKInputType) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const path = 'buySellFlow.priceAmountPercentSelection'

  const inputIsPriceType =
    inputType === SLTKInputType.FIXED_PRICE ||
    inputType === SLTKInputType.TRAIL_AMOUNT

  const title = t(`${path}.${type}.${tab}.title`)

  const options = [
    {
      icon: <DollarIcon color='iconNeutralStrong' />,
      title: t(`${path}.${type}.${tab}.priceOrAmount.title`),
      subtitle: t(`${path}.${type}.${tab}.priceOrAmount.subtitle`),
      checked: inputIsPriceType,
      value:
        tab === StopLossTakeProfitType.FIXED
          ? SLTKInputType.FIXED_PRICE
          : SLTKInputType.TRAIL_AMOUNT,
    },
    {
      icon: <PercentageIcon color='iconNeutralStrong' />,
      title: t(`${path}.${type}.${tab}.percentOption.title`),
      subtitle: t(`${path}.${type}.${tab}.percentOption.subtitle`),
      checked: !inputIsPriceType,
      value:
        tab === StopLossTakeProfitType.TRAILING
          ? SLTKInputType.TRAIL_PERCENT
          : SLTKInputType.FIXED_PERCENT,
    },
  ]

  return (
    <CustomModal.OverlayContent>
      <CustomModal show dismissable>
        <CustomModal.Header text={title} />
        <CustomModal.Content>
          {options?.map((o, idx) => (
            <div key={`${o?.value}-${idx}`}>
              <button
                className='d-flex align-center w-100 align-items cursor-pointer'
                onClick={() => onChange(o?.value)}
                tabIndex={idx}
              >
                <Avatar
                  image={o?.icon}
                  bordered
                  roundness='full'
                  size='large'
                />
                <Spacer preset='smaller' />
                <div>
                  <Text preset='paragraph2' text={o?.title} color='primary' />
                  <Spacer preset='extraTiny' />
                  <Text
                    preset='paragraph2'
                    color='tertiary'
                    text={o?.subtitle}
                  />
                </div>
                <Spacer preset='small' />
                <Avatar
                  bgColor={o?.checked ? 'banner1' : 'transparent'}
                  bordered
                  image={o?.checked ? <CheckIcon size={16} /> : null}
                  roundness='full'
                  size='small'
                />
              </button>
              {idx < options?.length - 1 ? (
                <>
                  <Spacer preset='small' />
                  <Divider />
                  <Spacer preset='small' />
                </>
              ) : null}
            </div>
          ))}
          <Spacer preset='small' />
        </CustomModal.Content>
      </CustomModal>
    </CustomModal.OverlayContent>
  )
}
