export interface LimitPriceDetail {
  rate: number
  lowerLimit: number
  upperLimit: number
}

export interface CompanyOperationsAllowed {
  assetId: string
  market: string
  allowedOrderMethods: OrderTypeEnum[]
  priceLimit: {
    aggressive: LimitPriceDetail[]
    passive: LimitPriceDetail[]
  }
  tifTypes: string[]
  maxTif: string
}

export enum OrderTypeEnum {
  market = 'MARKET',
  limit = 'LIMIT',
}

export const orderTypeMap = new Map<OrderTypeEnum, string>([
  [OrderTypeEnum.market, 'stopLossTakeProfit.orderTypes.market'],
  [OrderTypeEnum.limit, 'stopLossTakeProfit.orderTypes.limit'],
])
