import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { IManualTransfer, ManualBankTransferInfo } from '@interfaces'
import { manualTransfer, manualTransferWithdrawApi } from '@services'

export const manualTransferDeposit = createAsyncThunk<
  ManualBankTransferInfo,
  IManualTransfer
>('manualTransferDeposit', (body) => {
  return manualTransfer(body)
})

export const manualTransferWithdraw = createAsyncThunk<
  ManualBankTransferInfo,
  IManualTransfer
>('manualTransferWithdraw', (body) => {
  return manualTransferWithdrawApi(body)
})

export const clearManualTransfer = createAction('clearManualTransfer')
export const addInputAmount = createAction(
  'addInputAmount',
  (amount: number) => ({
    payload: amount,
  }),
)
export const addInputCurrency = createAction(
  'addInputCurrency',
  (currency: string) => ({
    payload: currency,
  }),
)
