import { useTranslation } from '@hooks'
import { Spacer, Text } from '@components'
import MainLogo from 'components/mainLogo/mainLogo'

import ExampleTrailingTakeProfitOne from '../../shared/exampleTrailingTakeProfitOne'
import ExampleTrailingTakeProfitTwo from '../../shared/exampleTrailingTakeProfitTwo'
import ExampleTrailingTakeProfitThree from '../../shared/exampleTrailingTakeProfitThree'
import ExampleTrailingTakeProfitFour from '../../shared/exampleTrailingTakeProfitFour'

export const TrailingTakeProfitInfoContent = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className='d-flex d-flex-col align-start'>
      <div className='dividerInfo mt-2 pb-2 text-left'>
        <Text
          preset='bodyRegular'
          text={t('stopLossTakeProfit.trailingTakeProfitInfo.description')}
        />
      </div>
      <div className='mt-2 text-left'>
        <Text
          preset='subtitle3'
          color='secondary'
          text={t('stopLossTakeProfit.example')}
        />

        <div className='mt-1 mb-1'>
          <Text
            preset='bodyRegular'
            color='secondary'
            text={t('stopLossTakeProfit.trailingTakeProfitInfo.explanationOne')}
          />
        </div>
      </div>
      <div className='mt-4 paragraph2'>
        <ExampleTrailingTakeProfitOne
          takeProfitInactiveText={t(
            'stopLossTakeProfit.trailingTakeProfitInfo.takeProfitInactive',
          )}
          currentPriceText={t(
            'stopLossTakeProfit.trailingTakeProfitInfo.currentPriceText',
          )}
        />
      </div>
      <div className='mt-3 text-left'>
        <Text
          preset='bodyRegular'
          color='secondary'
          text={t('stopLossTakeProfit.trailingTakeProfitInfo.explanationTwo')}
        />
      </div>
      <div className='mt-4 paragraph2'>
        <ExampleTrailingTakeProfitTwo
          activationPriceText={t(
            'stopLossTakeProfit.trailingTakeProfitInfo.activationPriceReached',
          )}
          takeProfitText={t(
            'stopLossTakeProfit.trailingTakeProfitInfo.takeProfitTrailActive',
          )}
        />
      </div>
      <div className='mt-3 text-left infoLogo '>
        <div className='containerLogo mb-2'>
          <MainLogo className='containerLogo__icon' />
        </div>
        <div className='containerLogo__info'>
          <Text
            preset='subtitle2'
            color='primary'
            text={t(
              'stopLossTakeProfit.trailingTakeProfitInfo.pushNotificationActivatedTitle',
            )}
          />
          <Spacer preset='tiny' />
          <Text
            preset='bodyRegular'
            color='secondary'
            text={t(
              'stopLossTakeProfit.trailingTakeProfitInfo.pushNotificationActivatedDescription',
            )}
          />
        </div>
      </div>
      <div className='mt-4 pb-2 text-left '>
        <Text
          preset='bodyRegular'
          color='secondary'
          text={t('stopLossTakeProfit.trailingTakeProfitInfo.explanationThree')}
        />
      </div>
      <div className='mt-4 paragraph2'>
        <ExampleTrailingTakeProfitThree
          priceIncreasesText={t('stopLossTakeProfit.priceIncrease')}
          takeProfitAdjusts={t(
            'stopLossTakeProfit.trailingTakeProfitInfo.takeProfitAdjust',
          )}
        />
      </div>
      <div className='mt-4 pb-2 text-left '>
        <Text
          preset='bodyRegular'
          color='secondary'
          text={t('stopLossTakeProfit.trailingTakeProfitInfo.explanationFour')}
        />
      </div>
      <div className='mt-4 paragraph2'>
        <ExampleTrailingTakeProfitFour
          positionClosedText={t(
            'stopLossTakeProfit.trailingTakeProfitInfo.currentPositionClosed',
          )}
          priceDecreaseText={t('stopLossTakeProfit.priceDecreases')}
        />
      </div>
      <div className='mt-4 pb-2 text-left '>
        <Text
          preset='bodyRegular'
          color='secondary'
          text={t('stopLossTakeProfit.trailingTakeProfitInfo.explanationFive')}
        />

        <div className='mt-3 text-left infoLogo '>
          <div className='containerLogo mb-2'>
            <MainLogo className='containerLogo__icon' />
          </div>
          <div className='containerLogo__info'>
            <Text
              preset='subtitle2'
              color='primary'
              text={t(
                'stopLossTakeProfit.trailingTakeProfitInfo.pushNotificationTitle',
              )}
            />
            <Spacer preset='tiny' />
            <Text
              preset='bodyRegular'
              color='secondary'
              text={t(
                'stopLossTakeProfit.trailingTakeProfitInfo.pushNotificationDescription',
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
