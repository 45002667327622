import { BalanceInfoModal } from '@shared/modals'
import { Modal, SummaryCard, SummaryCardProps } from '@components'
import { usePreOrderSections, useTranslation } from '@hooks'
import {
  CompanyPositionData,
  PreOrderData,
  PreOrderModalType,
  ReducerState,
} from '@interfaces'
import { useState } from 'react'

export const PreorderSections = ({
  checkout,
  companyPosition,
}: {
  checkout: PreOrderData
  companyPosition: CompanyPositionData & ReducerState
}): JSX.Element => {
  const [modalType, setModalType] = useState<PreOrderModalType>()
  const [infoModalTitle, setInfoModalTitle] = useState<string>()
  const [infoModalContent, setInfoModalContent] = useState<string>()
  const [isShownModal, setIsShownModal] = useState<boolean>(false)
  const { t } = useTranslation()

  const availableForSaleShares =
    companyPosition?.shares - companyPosition?.lockedShares

  const showBalanceModal = (): void => {
    setModalType(PreOrderModalType.balance)
    setIsShownModal(true)
  }

  const showTaxesModal = (): void => {
    setModalType(PreOrderModalType.taxes)
    setIsShownModal(true)
  }

  const showInfoModal = (title: string, content: string): void => {
    setModalType(PreOrderModalType.info)
    setInfoModalTitle(title)
    setInfoModalContent(content)
    setIsShownModal(true)
  }

  const renderSection = (
    section: SummaryCardProps,
    idx: number,
  ): JSX.Element => {
    return (
      <div key={`${section?.id}-${idx}`} className='--fade-in-animation'>
        <SummaryCard
          {...section}
          className='mb-3'
          bgColor='canvasTertiaryInitial'
        />
      </div>
    )
  }

  const sections = usePreOrderSections(
    checkout,
    availableForSaleShares,
    showBalanceModal,
    showTaxesModal,
    showInfoModal,
  )

  const closeModal = (): void => {
    setIsShownModal(false)
  }

  const renderPreOrderModal = (): JSX.Element => {
    switch (modalType) {
      case PreOrderModalType.taxes:
        return (
          <Modal
            className='mb-4'
            show={isShownModal}
            type='invalid'
            onButton={closeModal}
            text={t('buySellFlow.summary.taxesInfo.title')}
            buttonText={t('common.ok')}
          >
            <div className='d-flex d-flex-col align-center w-100'>
              <object
                data={checkout?.marketTaxesInfoUrl}
                height='200'
                width='100%'
                title='taxes'
              ></object>
            </div>
          </Modal>
        )
      case PreOrderModalType.balance:
        return (
          <BalanceInfoModal
            opts={{ showBackgrop: false }}
            close={() => setIsShownModal(false)}
          />
        )
      case PreOrderModalType.info:
        return (
          <Modal
            className='backdrop--none'
            text={infoModalTitle}
            show={isShownModal}
            buttonText={t('common.ok')}
            onButton={closeModal}
          >
            {infoModalContent}
          </Modal>
        )
      default:
        return (
          <Modal
            className='mb-4'
            show={isShownModal}
            type='error'
            onButton={closeModal}
          />
        )
    }
  }

  return (
    <>
      <div className='text-left'>
        {sections?.map((s: SummaryCardProps, idx: number) =>
          renderSection(s, idx),
        )}
      </div>
      {isShownModal && renderPreOrderModal()}
    </>
  )
}
