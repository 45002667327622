import { Money } from './money'
export interface CurrenciesExchanges {
  target: string
  exchanges: Forex[]
  cacheDurationInSeconds: number
}

export interface ExchangeRatesItem {
  source: string
  rate: number
}

export interface Forex {
  source: string
  rate: Money
}

export enum CurrencyCode {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
}

export enum CurrencyName {
  USD = 'balance.usdFull',
  EUR = 'balance.eurFull',
  GBP = 'balance.gbpFull',
}
