import './../../moneyOperations.styles.scss'
import { useTranslation } from '@hooks'
import { CustomModal, Spacer, Text, Button } from '@components'

import { SuccessIcon } from '@assets/icons'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { formatMoney } from '@utils/helpers'
import { CurrencyName } from 'interfaces'

interface ManualTransferWithdrawSuccessProps {
  prevStep?: () => void
  onClose?: () => void
}

export function ManualTransferWithdrawSuccess({
  onClose,
}: ManualTransferWithdrawSuccessProps): JSX.Element {
  const { t } = useTranslation()
  const manualBankTransfer = useSelector(
    (state: RootState) => state.manualBankTransfer,
  )
  const formateAmount = formatMoney(
    manualBankTransfer?.inputAmount,
    manualBankTransfer?.inputCurrency,
  )

  return (
    <>
      <CustomModal.Header></CustomModal.Header>
      <CustomModal.Content className='d-flex-col'>
        <div className='d-flex d-flex-col w-100 align-self-start'>
          <div className='d-flex d-flex-col justify-center align-center w-65 ml-auto mr-auto'>
            <div className='d-flex-col d-flex justify-center align-center'>
              <SuccessIcon
                color='iconPositiveInitial'
                secondaryColor='iconNeutralSoft'
                size={64}
              />
            </div>
            <Spacer preset='small' />
            <Text preset='headline3' color='primary' align='center'>
              {t('withdrawMoney.success.title')}
            </Text>
            <Spacer preset='smaller' />

            <Text preset='headline3' color='positive' align='center'>
              {formateAmount}{' '}
              <span className='--text-primary'>
                {t(CurrencyName[manualBankTransfer?.amount?.currency])}
              </span>
            </Text>
          </div>
          <Spacer preset='large' />
          <div className='w-100'>
            <Text preset='subtitle2' color='primary'>
              {t('addMoney.pleaseNote.pleaseNote')}
            </Text>
            <ul className='default_list'>
              <li>
                <Text preset='bodyRegular'>
                  {t('withdrawMoney.success.time')}
                </Text>
              </li>
              <li>
                <Text preset='bodyRegular'>
                  {t('withdrawMoney.success.soleHolder')}
                </Text>
              </li>
              <li>
                <Text preset='bodyRegular'>
                  {t('withdrawMoney.success.notified')}
                </Text>
              </li>
            </ul>
          </div>
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.close')} onClick={onClose} />
      </CustomModal.Footer>
    </>
  )
}
