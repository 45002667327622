import {
  BasicLink,
  Button,
  Calendar,
  CustomModal,
  Divider,
  InputRadio,
  Spacer,
  Text,
  useCalendar,
} from '@components'
import { useTranslation } from '@hooks/useTranslation'
import { getLang } from '@utils/langs'
import { useCallback, useMemo, useState } from 'react'
import { ChevronRight } from '@assets/icons'
import { formatDateShortWeekDay } from '@utils/helpers'
import { timeInForceModalConfig } from './timeInForceModalConfig'
import {
  TimeInForceOption,
  TimeInForceType,
} from '@interfaces/timeInForceOption'

export const TimeInForceModal = ({
  selectedOption,
  onClose,
  onSubmit,
}: {
  selectedOption: TimeInForceOption<Date>
  onClose: () => void
  onSubmit: (selectedOption?: TimeInForceOption<Date>) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const locale = getLang()
  const title = t('stopLossTakeProfit.timeInForce.title')

  const dateOptions = useMemo((): TimeInForceOption<Date>[] => {
    return timeInForceModalConfig(locale)
  }, [locale])

  const calendarMinDate = new Date()?.toISOString()?.substring(0, 10)
  const [currentOption, setCurrentOption] = useState(
    selectedOption || dateOptions[1],
  )
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [calendarSelectedDate, setCalendarSelectedDate] = useState<string>(
    selectedOption.type === TimeInForceType.CALENDAR_CUSTOM
      ? selectedOption.title
      : '',
  )

  const getOptionLabel = (option: TimeInForceOption<Date>): string => {
    if (option.type === TimeInForceType.CUSTOM) {
      const [quantity, key] = option.title.split(' ')
      return t(key, { quantity })
    }
    return t(option.title)
  }

  const closeCalendar = (): void => setIsCalendarOpen(false)
  const saveCalendarDate = (newDate: string): void => {
    setIsCalendarOpen(false)
    if (newDate) {
      setCurrentOption({
        title: newDate,
        description: null,
        value: new Date(newDate),
        type: TimeInForceType.CALENDAR_CUSTOM,
      })
    }
  }

  const {
    month,
    weekDays,
    getPreviousMonth,
    selectedYear,
    handleMonth,
    handleSelected,
    handleCancel,
    handleSubmit,
    handleRestore,
    monthsMatrix,
    submitDisabled,
  } = useCalendar({
    onChange: (_: string[], newDate: string) => {
      setCalendarSelectedDate(newDate)
    },
    options: {
      allowSatAndSun: false,
    },
    selectedDates: [calendarSelectedDate],
    minDate: calendarMinDate,
    onCancel: closeCalendar,
    onSubmit: (newDates: string[]) => saveCalendarDate(newDates[0]),
  })

  const selectedHandler = useCallback(
    (option: TimeInForceOption<Date>): void => {
      setCurrentOption(option)
    },
    [],
  )

  const handleTimeInForceClose = useCallback((): void => {
    onClose()
  }, [onClose])

  const handleTimeInForceSubmit = useCallback((): void => {
    onSubmit(currentOption)
    onClose()
  }, [onSubmit, currentOption, onClose])

  return (
    <CustomModal size='big' show dismissable>
      <CustomModal.Header text={title} />
      <CustomModal.Content>
        {dateOptions?.map((option, idx) => (
          <div key={`options-${title}-idx-${idx}`}>
            <div
              onClick={() => selectedHandler(option)}
              aria-hidden
              className='w-100 d-flex justify-between align-items-center cursor-pointer'
            >
              <div className='w-100 d-flex d-flex-col --capitalize'>
                <Text
                  text={getOptionLabel(option)}
                  preset='paragraph2'
                  color='primary'
                />
                <Text
                  text={t(option?.description)}
                  preset='paragraph2'
                  color='secondary'
                />
              </div>
              <InputRadio
                containerStyle='d-flex align-items-center'
                checked={
                  option?.type === currentOption?.type &&
                  option?.value?.toDateString() ===
                    currentOption?.value?.toDateString()
                }
                id={`${title}-idx-${idx}-option`}
                value='option'
                name='options-selector'
              />
            </div>
            {idx != dateOptions.length - 1 ? <Spacer preset='small' /> : null}
          </div>
        ))}
        <>
          <Spacer preset='medium' />
          <div>
            <div className='--no-modal-padding'>
              <Divider />
            </div>
            <Spacer preset='small' />
            <div
              className='d-flex align-center cursor-pointer'
              onClick={() => setIsCalendarOpen(true)}
              aria-hidden='true'
            >
              <div className='w-100 d-flex d-flex-col'>
                <Text
                  text={t('stopLossTakeProfit.timeInForce.options.custom')}
                  preset='paragraph2'
                  color='primary'
                />
                <Text
                  color='iconPrimaryInitial'
                  text={
                    calendarSelectedDate
                      ? formatDateShortWeekDay(calendarSelectedDate)
                      : t(
                          'stopLossTakeProfit.timeInForce.options.customDescription',
                        )
                  }
                  preset='paragraph2'
                />
              </div>
              <ChevronRight color='iconPrimaryInitial' />
            </div>
            <CustomModal
              show={isCalendarOpen}
              dismissable
              height='fitContent'
              theme='lighter'
              size='small'
              onClose={() => setIsCalendarOpen(false)}
            >
              <Calendar
                monthsMatrix={monthsMatrix}
                id='asset-operation-calendar'
                headerTitle={t('limitOrders.form.expirationDate')}
                withHeader
                withFooter
                handleCancel={handleCancel}
                handleRestore={handleRestore}
                handleSelected={handleSelected}
                handleSubmit={handleSubmit}
                handleMonth={handleMonth}
                getPreviousMonth={getPreviousMonth}
                month={month}
                selectedYear={selectedYear}
                weekDays={weekDays}
                submitDisabled={submitDisabled}
              />
            </CustomModal>
          </div>
        </>
      </CustomModal.Content>
      <CustomModal.Footer>
        <div className='w-100 d-flex d-flex-col'>
          <Button
            onClick={handleTimeInForceSubmit}
            buttonType='primary'
            text={t('stopLossTakeProfit.timeInForce.selectOption')}
            disabled={!currentOption}
          />
          <Spacer preset='small' />
          <BasicLink
            text={t('common.cancel')}
            onClick={handleTimeInForceClose}
          />
        </div>
      </CustomModal.Footer>
    </CustomModal>
  )
}
