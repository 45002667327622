// eslint-disable no-unused-vars
import {
  capitalize,
  extraDigits,
  formatCurrency,
  formatEndDateRemaining,
  formatMoney,
} from '@utils/helpers'
import {
  ConditionType,
  OrderOperationTypeEnum,
  OrderTypeEnum,
  orderTypeMap,
  PreOrderData,
  SLTKInputType,
} from '@interfaces'
import { SummaryCardProps, SummaryRowProps, Text } from '@components'
import { t, TFunction } from 'i18next'
import { InfoIcon } from '@assets/svg'
import { trackingService } from '@services'
import { LimitOrderEvents } from '@utils/eventTracker/eventKeys'
import { getStopLossTakeProfitType } from '@utils/getStopLossTakeProfitType'
import { isStopLossOrTakeProfitPreorder } from '@utils/isStopLossOrTakeProfitPreorder'
import { isTrailingStopLossOrTakeProfit } from '@utils/isTrailingStopLossOrTakeProfit'
import { isStopLossOrder } from '@utils/isStopLossOrder'

const estimatedTotalLabel = 'buySellFlow.summary.estimatedTotal'
const estimatedEarningsLabel = 'buySellFlow.estimatedEarnings'

const priceTitle = {
  [ConditionType.STOP_LOSS]: {
    [SLTKInputType.FIXED_PRICE]: t('buySellFlow.fixedStopLossPrice'),
    [SLTKInputType.FIXED_PERCENT]: t('buySellFlow.estimatedStopLossPrice'),
  },
  [ConditionType.TAKE_PROFIT]: {
    [SLTKInputType.FIXED_PRICE]: t('buySellFlow.fixedTakeProfitPrice'),
    [SLTKInputType.FIXED_PERCENT]: t('buySellFlow.estimatedTakeProfitPrice'),
  },
}

export function getPreOrderFormattedFxShares(checkout: PreOrderData): string {
  return `${formatMoney(
    checkout?.forexRate,
    checkout?.totalCost?.currency,
    4,
  )} = ${formatMoney(1, checkout?.asset?.currency)}`
}

export function getOperationTitle(
  isBuy: boolean,
  orderType: OrderTypeEnum,
): string {
  if (isBuy && orderType === OrderTypeEnum.market) {
    return 'buySellFlow.orderTypes.buyMarketOrder'
  }

  if (isBuy && orderType === OrderTypeEnum.limit) {
    return 'buySellFlow.orderTypes.buyLimitOrder'
  }

  if (!isBuy && orderType === OrderTypeEnum.market) {
    return 'buySellFlow.orderTypes.sellMarketOrder'
  }

  if (!isBuy && orderType === OrderTypeEnum.limit) {
    return 'buySellFlow.orderTypes.sellLimitOrder'
  }

  return ''
}

export function isLimitOrder(preorder: PreOrderData): boolean {
  return preorder?.method === OrderTypeEnum.limit
}

export function isMarketOrder(preorder: PreOrderData): boolean {
  return preorder?.method === OrderTypeEnum.market
}

export function getBalanceCashColor(checkout: PreOrderData): string {
  return checkout?.enoughCashForOrder ? 'orange--text' : 'negative--text'
}

export function getSharesCashColor(availableForSaleShares: number): string {
  return availableForSaleShares ? 'orange--text' : 'negative--text'
}

const showTotalToWithholdExplanation = (showInfoModal): void => {
  showInfoModal(
    t('buySellFlow.summary.totalToWithhold'),
    t('buySellFlow.summary.totalToWithholdExplanation'),
  )
}

const showTotalRequiredExplanation = (showInfoModal): void => {
  showInfoModal(
    t('buySellFlow.summary.totalRequired'),
    t('buySellFlow.summary.totalToWithholdExplanation'),
  )
}
const showEstimatedTotalExplanation = (showInfoModal): void => {
  showInfoModal(
    t(estimatedTotalLabel),
    t('buySellFlow.summary.estimatedTotalExplanation'),
  )
}

const showEstimatedEarningsExplanation = (showInfoModal): void => {
  showInfoModal(
    t(estimatedEarningsLabel),
    t('buySellFlow.summary.estimatedEarningsExplanation'),
  )
}

const balanceInfoNote = (
  checkout: PreOrderData,
  showBalanceModal: () => void,
): JSX.Element => {
  return (
    <div
      onClick={showBalanceModal}
      className={`d-flex paragraph3 mt-1 cursor-pointer ${getBalanceCashColor(
        checkout,
      )}`}
      role='button'
      aria-hidden
    >
      {t('buySellFlow.balanceAvailableToInvest', {
        cashAvailable: formatCurrency(checkout?.cashAvailableForInvesting),
      })}
    </div>
  )
}

const availableSharesNote = (
  checkout: PreOrderData,
  availableForSaleShares: number,
  showInfoModal: (title: string, content: string) => void,
): JSX.Element => {
  const showAvailableSharesModal = (): void => {
    showInfoModal(
      t('buySellFlow.availableForSaleModalTitle'),
      t('buySellFlow.sharesAvailableForSaleExplanation'),
    )
  }

  return (
    <div
      onClick={showAvailableSharesModal}
      className={`d-flex paragraph3 mt-1 cursor-pointer ${getSharesCashColor(
        availableForSaleShares,
      )}`}
      role='button'
      aria-hidden
    >
      {t('buySellFlow.sharesAvailableForSale', {
        maxShares: availableForSaleShares,
        symbol: checkout?.asset?.ticker,
      })}
    </div>
  )
}

export function getMarketOrderTotalRow(
  checkout: PreOrderData,
  showBalanceModal: () => void,
  showInfoModal: (title: string, content: string) => void,
  availableForSaleShares: number,
): SummaryRowProps {
  const isBuy = checkout?.type === OrderOperationTypeEnum.buy

  const firstTotalValue = formatCurrency(
    isBuy ? checkout?.lockedCash : checkout?.totalCost,
  )

  const firstRowTotalHandler = (): void => {
    isBuy
      ? showTotalRequiredExplanation(showInfoModal)
      : showEstimatedEarningsExplanation(showInfoModal)
  }

  return {
    labelChildren: (
      <div className='totalRow w-100'>
        <div className='mb-1 justify-space-between d-flex'>
          <div className='d-flex align-center'>
            <p className='headline4'>
              {isBuy
                ? t('buySellFlow.summary.totalRequired')
                : t(estimatedEarningsLabel)}
            </p>
            <InfoIcon
              className='ml-1 cursor-pointer'
              onClick={firstRowTotalHandler}
            />
          </div>
          <p className='headline4'>{firstTotalValue}</p>
        </div>
        {isBuy ? (
          <div className='justify-space-between d-flex'>
            <div className='d-flex align-center'>
              <p className='paragraph2'>{t(estimatedTotalLabel)}</p>
              <InfoIcon
                className='ml-1 cursor-pointer'
                onClick={() => showEstimatedTotalExplanation(showInfoModal)}
              />
            </div>
            <p className='paragraph2'>{formatCurrency(checkout?.totalCost)}</p>
          </div>
        ) : null}
        <div className='d-flex justify-end'>
          {isBuy
            ? balanceInfoNote(checkout, showBalanceModal)
            : availableSharesNote(
                checkout,
                availableForSaleShares,
                showInfoModal,
              )}
        </div>
      </div>
    ),
  }
}

export function getLimitOrderTotalRow(
  checkout: PreOrderData,
  showBalanceModal: () => void,
  showInfoModal: (title: string, content: string) => void,
  availableForSaleShares: number,
): SummaryRowProps {
  const isBuy = checkout?.type === OrderOperationTypeEnum.buy

  const firstTotalValue = formatCurrency(
    isBuy ? checkout?.lockedCash : checkout?.totalCost,
  )

  const firstRowTotalHandler = (): void => {
    isBuy
      ? showTotalToWithholdExplanation(showInfoModal)
      : showEstimatedTotalExplanation(showInfoModal)
  }

  return {
    labelChildren: (
      <div className='totalRow w-100'>
        <div className='mb-1 justify-space-between d-flex'>
          <div className='d-flex align-center'>
            <p className='headline4'>
              {isBuy
                ? t('buySellFlow.summary.totalToWithhold')
                : t(estimatedEarningsLabel)}
            </p>
            <InfoIcon
              className='ml-1 cursor-pointer'
              onClick={firstRowTotalHandler}
            />
          </div>
          <p className='headline4'>{firstTotalValue}</p>
        </div>
        {isBuy ? (
          <div className='justify-space-between d-flex'>
            <div className='d-flex align-center'>
              <p className='paragraph2'>{t(estimatedTotalLabel)}</p>
              <InfoIcon
                className='ml-1 cursor-pointer'
                onClick={() => showEstimatedTotalExplanation(showInfoModal)}
              />
            </div>
            <p className='paragraph2'>{formatCurrency(checkout?.totalCost)}</p>
          </div>
        ) : null}
        <div className='d-flex justify-end'>
          {isBuy
            ? balanceInfoNote(checkout, showBalanceModal)
            : availableSharesNote(
                checkout,
                availableForSaleShares,
                showInfoModal,
              )}
        </div>
      </div>
    ),
  }
}

export const getNumberOfSharesRow = (
  preorder: PreOrderData,
  t: TFunction,
): SummaryRowProps => {
  return {
    label: t('buySellFlow.numberOfShares'),
    value: preorder?.shares?.toString(),
  }
}

export const getAmountRow = (
  preorder: PreOrderData,
  t: TFunction,
): SummaryRowProps => {
  const isLimit = isLimitOrder(preorder)

  const amountLabel = isLimit
    ? t('transactions.estimatedGrossAmount')
    : t('buySellFlow.summary.amount')

  return {
    label: amountLabel,
    value: formatCurrency(preorder?.grossAmount),
  }
}

export const getTaxesRow = (
  preorder: PreOrderData,
  t: TFunction,
  showTaxesModal: () => void,
): SummaryRowProps => {
  return {
    label: t('buySellFlow.summary.taxes'),
    value: formatCurrency(preorder?.marketTaxes),
    labelChildren: (
      <InfoIcon className='ml-1 cursor-pointer' onClick={showTaxesModal} />
    ),
  }
}

export const getFxRateRow = (
  preorder: PreOrderData,
  t: TFunction,
): SummaryRowProps => {
  const exchangeRateText = getPreOrderFormattedFxShares(preorder)
  return {
    label: t('buySellFlow.summary.fxRate'),
    value: exchangeRateText,
  }
}

export const getEstimatedNetAmountRow = (
  preorder: PreOrderData,
  t: TFunction,
): SummaryRowProps => {
  return {
    label: t('transactions.estimatedNetAmount'),

    value: `${formatCurrency(
      preorder?.netAmountInPortfoliosCurrency,
    )} = ${formatCurrency(preorder?.netAmount)}`,
  }
}

export const getActivationPriceOrStopPriceRow = (
  preorder: PreOrderData,
): SummaryRowProps => {
  const subtype = getStopLossTakeProfitSubType(preorder)

  // TBD if activation price show activation price instead of stop price
  const stopPriceRow = {
    label: priceTitle?.[preorder?.condition?.stopType]?.[subtype],
    value: formatCurrency({
      amount: preorder?.condition?.stopPrice,
      currency: preorder?.price?.currency,
    }),
  }

  return isTrailingStopLossOrTakeProfit(preorder?.condition)
    ? {
        label: t('buySellFlow.activationPrice'),
        value: formatCurrency({
          amount: preorder?.condition?.activationPrice,
          currency: preorder?.price?.currency,
        }),
      }
    : stopPriceRow
}

export const getStopLossTakeProfitSubType = (
  preorder: PreOrderData,
): SLTKInputType => {
  if (preorder?.condition?.trailPercentage > 0) {
    return SLTKInputType.TRAIL_PERCENT
  }
  if (preorder?.condition?.trailAmount > 0) {
    return SLTKInputType.TRAIL_AMOUNT
  }
  if (preorder?.condition?.stopPercentage > 0) {
    return SLTKInputType.FIXED_PERCENT
  }
  return SLTKInputType.FIXED_PRICE
}

export const pnlColor = (preorder: PreOrderData): 'negative' | 'positive' => {
  return preorder?.estimatedPnL?.amount < 0 ? 'negative' : 'positive'
}

const getEstimatedExplanation = (isStopLoss: boolean) => {
  const literal = isStopLoss ? 'proceedText' : 'profitText'

  const title = isStopLoss
    ? t('common.estimatedProceeds')
    : t('common.estimatedProfit')

  const content = t(`buySellFlow.infoEstimatedProfitOrProceed.${literal}`)

  return { title, content }
}

export const getStopLossTakeProfitSections = (
  preorder: PreOrderData,
  availableForSaleShares: number,
  showTaxesModal: () => void,
  showInfoModal: (title: string, content: string) => void,
): SummaryCardProps[] => {
  const stopLossTakeProfitType = getStopLossTakeProfitType(preorder?.condition)
  const { title, content } = getEstimatedExplanation(
    isStopLossOrder(preorder?.condition),
  )

  const sectionTitle = t(
    `buySellFlow.preorder.details.${preorder?.condition?.stopType}.${stopLossTakeProfitType}.title`,
  )

  const stopPercentageRow = preorder?.condition?.stopPercentage
    ? [
        {
          label: isStopLossOrder(preorder?.condition)
            ? t('buySellFlow.pricePercentageStop')
            : t('buySellFlow.pricePercentageLimit'),
          value: `${preorder?.condition?.stopPercentage}%`,
        },
      ]
    : []

  const trailAmountRow = preorder?.condition?.trailAmount
    ? [
        {
          label: t('buySellFlow.trailAmount'),
          value: formatCurrency(
            {
              amount: preorder?.condition?.trailAmount,
              currency: preorder?.price?.currency,
            },
            extraDigits,
          ),
        },
      ]
    : []

  const trailPercentage = preorder?.condition?.trailPercentage
    ? [
        {
          label: t('buySellFlow.trailPercentage'),
          value: `${preorder?.condition?.trailPercentage}%`,
        },
      ]
    : []

  const timeInForceRow = [
    {
      label: capitalize(t('buySellFlow.timeInForce')),
      value: preorder?.expirationDate
        ? formatEndDateRemaining(new Date(), new Date(preorder?.expirationDate))
        : t('stopLossTakeProfit.timeInForce.options.goodTilCancel'),
    },
  ]

  const orderTypeRow = [
    {
      label: capitalize(t('buySellFlow.orderType')),
      value: t(orderTypeMap.get(preorder?.method)),
    },
  ]

  const limitPriceRow =
    preorder?.method === OrderTypeEnum.limit
      ? [
          {
            label: capitalize(t('buySellFlow.limitPrice')),
            value: formatCurrency(preorder?.price, extraDigits),
          },
        ]
      : []

  return [
    {
      id: preorder?.id?.concat('SLTPInfo'),
      overTitle: sectionTitle,
      data: [
        ...trailAmountRow,
        ...trailPercentage,
        ...stopPercentageRow,
        getActivationPriceOrStopPriceRow(preorder),
        ...timeInForceRow,
        ...orderTypeRow,
        ...limitPriceRow,
        getNumberOfSharesRow(preorder, t),
        getAmountRow(preorder, t),
        getTaxesRow(preorder, t, showTaxesModal),
        getFxRateRow(preorder, t),
        getEstimatedNetAmountRow(preorder, t),
      ],
    },
    {
      id: preorder?.id?.concat('Commisions'),
      overTitle: t('buySellFlow.investmentServicesCommissionsAndTotal'),
      data: [
        {
          label: t('buySellFlow.executionFeesPercent', {
            percentage: preorder?.commissions?.executionFees?.rate * 100,
          }),
          value:
            formatCurrency(preorder?.commissions?.executionFees?.amount) || '',
        },
        {
          label: t('buySellFlow.exchangeFeePercent', {
            percentage: preorder?.commissions?.forexFees?.rate * 100,
          }),
          value: formatCurrency(preorder?.commissions?.forexFees?.amount) || '',
        },
        {
          label: t('buySellFlow.custodyFeesPercentage', {
            percentage: preorder?.commissions?.custodyFees?.rate * 100,
          }),
          value:
            formatCurrency(preorder?.commissions?.custodyFees?.amount) || '',
        },
        {
          label: t('buySellFlow.financialInstrumentCostAndFeesPercent', {
            percentage:
              preorder?.commissions?.financialInstrumentFees?.rate * 100,
          }),
          value:
            formatCurrency(
              preorder?.commissions?.financialInstrumentFees?.amount,
            ) || '',
        },
        {
          label: t('buySellFlow.thirdPartyPaymentsPercentage', {
            percentage: preorder?.commissions?.thirdPartyPayments?.rate * 100,
          }),
          value:
            formatCurrency(preorder?.commissions?.thirdPartyPayments?.amount) ||
            '',
        },
        {
          label: t('buySellFlow.totalCommissions'),
          value: formatCurrency(preorder?.commissions?.totalCommission) || '',
        },
        {
          labelChildren: (
            <div className='totalRow w-100'>
              <div className='mb-1 justify-space-between d-flex'>
                <div className='d-flex align-center'>
                  <Text preset='headline4' text={title} />
                  <InfoIcon
                    className='ml-1 cursor-pointer'
                    onClick={() => {
                      showInfoModal(title, content)
                    }}
                  />
                </div>
                <Text
                  preset='headline4'
                  text={formatCurrency(preorder?.totalCost) || ''}
                />
              </div>
              {
                <div className='justify-space-between d-flex'>
                  <div className='d-flex align-center'>
                    <Text
                      preset='paragraph2'
                      text={t('buySellFlow.estimatedPnL')}
                    />
                    <InfoIcon
                      className='ml-1 cursor-pointer'
                      onClick={() => {
                        showInfoModal(
                          t('buySellFlow.estimatedPnL'),
                          t('buySellFlow.infoEstimatedPnL.text'),
                        )
                      }}
                    />
                  </div>
                  <Text
                    preset='paragraph2'
                    text={formatCurrency(preorder?.estimatedPnL) || ''}
                    color={pnlColor(preorder)}
                  />
                </div>
              }
              <div className='d-flex justify-end'>
                {availableSharesNote(
                  preorder,
                  availableForSaleShares,
                  showInfoModal,
                )}
              </div>
            </div>
          ),
        },
      ],
    },
  ]
}

export function usePreOrderSections(
  checkout: PreOrderData,
  availableForSaleShares: number,
  showBalanceModal: () => void,
  showTaxesModal: () => void,
  showInfoModal: (title: string, content: string) => void,
): SummaryCardProps[] {
  const isLimit = isLimitOrder(checkout)

  const isBuy = checkout?.type === OrderOperationTypeEnum.buy

  const bufferIncludedTxOptions = {
    percent: checkout?.contingenciesBuffer * 100,
  }

  const bufferRow = {
    label: t('transactions.bufferIncluded', bufferIncludedTxOptions),
    value: formatCurrency(checkout?.contingenciesBufferAmount),
    labelChildren: (
      <InfoIcon
        className='ml-1 cursor-pointer'
        onClick={() => {
          trackingService.trackEvent({ event: LimitOrderEvents.bufferModal })
          showInfoModal(
            t('transactions.bufferIncluded', bufferIncludedTxOptions),
            t('transactions.bufferExplanation', bufferIncludedTxOptions),
          )
        }}
      />
    ),
  }

  const commonCommissions = [
    {
      label: t('common.commissions.executionFees'),
      value: formatCurrency(checkout?.commissions?.executionFees?.amount),
    },
    {
      label: t('common.commissions.exchangeFee'),
      value: formatCurrency(checkout?.commissions?.forexFees?.amount),
    },
  ]

  const totalCommissions = {
    label: t('common.commissions.totalCommissions'),
    value: formatCurrency(checkout?.commissions?.totalCommission),
  }

  const buyBufferRows = isBuy
    ? [
        bufferRow,
        {
          label: t('buySellFlow.requiredNetAmount'),
          value: formatCurrency(checkout?.requiredNetAmount),
        },
      ]
    : []

  if (isStopLossOrTakeProfitPreorder(checkout?.condition)) {
    return getStopLossTakeProfitSections(
      checkout,
      availableForSaleShares,
      showTaxesModal,
      showInfoModal,
    )
  }

  if (checkout?.method === OrderTypeEnum.market) {
    return [
      {
        id: checkout.id.concat('MarketPrice'),
        overTitle: t('buySellFlow.marketOrderDetails'),
        data: [
          {
            label: t('buySellFlow.unitPrice'),
            value: formatCurrency(checkout?.price, extraDigits),
          },
          getNumberOfSharesRow(checkout, t),
          getAmountRow(checkout, t),
          getTaxesRow(checkout, t, showTaxesModal),
          getFxRateRow(checkout, t),
          getEstimatedNetAmountRow(checkout, t),
          ...buyBufferRows,
        ],
      },
      {
        id: checkout.id.concat('OperationPrice'),
        overTitle: t('buySellFlow.operationPrice'),
        data: [
          ...commonCommissions,
          // ...buyCommissionsRows,
          totalCommissions,
          getMarketOrderTotalRow(
            checkout,
            showBalanceModal,
            showInfoModal,
            availableForSaleShares,
          ),
        ],
      },
    ]
  }

  if (isLimit) {
    const limitPriceTotalRow: SummaryRowProps = getLimitOrderTotalRow(
      checkout,
      showBalanceModal,
      showInfoModal,
      availableForSaleShares,
    )

    const expirationDate = checkout?.expirationDate
      ? formatEndDateRemaining(new Date(), new Date(checkout?.expirationDate))
      : null

    return [
      {
        id: checkout.id.concat('LimitOrderDetails'),
        overTitle: t('buySellFlow.limitOrderDetails'),
        data: [
          {
            label: t('buySellFlow.limitPrice'),
            value: formatCurrency(checkout?.price, extraDigits),
          },
          {
            label: t('transactions.expirationDate'),
            value: expirationDate,
          },
          getNumberOfSharesRow(checkout, t),
          getAmountRow(checkout, t),
          getTaxesRow(checkout, t, showTaxesModal),
          getFxRateRow(checkout, t),
          getEstimatedNetAmountRow(checkout, t),
          ...buyBufferRows,
        ],
      },
      {
        id: checkout.id.concat('ExecutionCommissions'),
        overTitle: t('transactions.executionCommissions'),
        data: [
          ...commonCommissions,
          // ...buyCommissionsRows,
          totalCommissions,
          limitPriceTotalRow,
        ],
      },
    ]
  }

  return []
}
