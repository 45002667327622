import { Button, CustomModal, Spacer, Text, ExpandableCard } from '@components'
import { useTranslation } from '@hooks'
import { ConditionType } from 'interfaces'
import { JSX } from 'react'

export const StopLossTakeProfitFaqs = ({
  close,
  mode,
}: {
  close: () => void
  mode: ConditionType
}): JSX.Element => {
  const { t } = useTranslation()

  const translationKeyMap: Record<ConditionType, string> = {
    [ConditionType.TAKE_PROFIT]:
      'promotions.stopLossTakeProfit.faqs.takeProfit',
    [ConditionType.STOP_LOSS]: 'promotions.stopLossTakeProfit.faqs.stopLoss',
  }

  const rootTranslation: string = translationKeyMap[mode]

  const faqCategories = t(`${rootTranslation}.sections`, {
    returnObjects: true,
  })

  const renderSections = (section, index): JSX.Element => {
    return (
      <div key={`section-${section.title || index}`} className='faq-section'>
        {section?.title && (
          <>
            <Spacer preset='small' />
            <Text preset='subtitle2'>{section.title} </Text>
          </>
        )}
        {section?.text1 && (
          <>
            <Spacer preset='tinyPlus' />
            <Text
              preset={'paragraph2'}
              color='secondary'
              text={section.text1}
            />
          </>
        )}
        {section?.text2 && (
          <Text preset={'paragraph2'} color='secondary' text={section.text2} />
        )}
      </div>
    )
  }

  const renderCategoryContent = (items: object): JSX.Element => {
    const sections = []

    Object.keys(items).forEach((key) => {
      const item = items[key]
      sections.push(item)
    })

    return (
      <>
        {sections.map((section, index) => {
          return renderSections(section, index)
        })}
      </>
    )
  }
  const renderFAQCategory = (categoryKey, index): JSX.Element => {
    const category: object = t(`${rootTranslation}.sections.${categoryKey}`, {
      returnObjects: true,
    })

    return (
      typeof category === 'object' && (
        <div key={`${categoryKey}-${index}`}>
          <Spacer preset='smaller' />
          <ExpandableCard
            header={
              <div className='d-flex w-100 align-center '>
                <Text
                  preset='headline3'
                  color='secondary'
                  text={`${index + 1}`}
                />
                <Spacer preset='smaller' />
                <Text
                  preset='headline3'
                  color='secondary'
                  text={category['title']}
                />
              </div>
            }
          >
            {renderCategoryContent(category)}
          </ExpandableCard>
        </div>
      )
    )
  }

  const renderCards = (): JSX.Element[] => {
    return Object.keys(faqCategories).map((faqCategory, index) =>
      renderFAQCategory(faqCategory, index),
    )
  }

  return (
    <CustomModal.Content>
      <Text preset='headline1' text={t(`${rootTranslation}.title`)} />
      <Spacer preset='smaller' />
      <Text
        preset='paragraph2'
        color='secondary'
        text={t(`${rootTranslation}.subtitle`)}
      />

      <Spacer preset='medium' />
      {renderCards()}

      <Spacer preset='huge' />
      <Button onClick={close} text={t('common.ok')} />
      <Spacer preset='small' />
    </CustomModal.Content>
  )
}
