import { UIIcon, UIIconColorProps } from 'components/utils'

export const TrendingUpIcon = ({
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x={0.25}
        y={0.25}
        width={39.5}
        height={39.5}
        rx={19.75}
        fill='white'
      />
      <rect
        x={0.25}
        y={0.25}
        width={39.5}
        height={39.5}
        rx={19.75}
        stroke='#595B67'
        strokeWidth={0.5}
      />
      <g clipPath='url(#clip0_21601_182194)'>
        <path
          d='M24.85 14.85L26.29 16.29L21.41 21.17L18.12 17.88C17.73 17.49 17.1 17.49 16.71 17.88L10.71 23.89C10.32 24.28 10.32 24.91 10.71 25.3C11.1 25.69 11.73 25.69 12.12 25.3L17.41 20L20.7 23.29C21.09 23.68 21.72 23.68 22.11 23.29L27.7 17.71L29.14 19.15C29.45 19.46 29.99 19.24 29.99 18.8V14.5C30 14.22 29.78 14 29.5 14H25.21C24.76 14 24.54 14.54 24.85 14.85Z'
          fill='#23282A'
        />
      </g>
      <defs>
        <clipPath id='clip0_21601_182194'>
          <rect
            width={24}
            height={24}
            fill='white'
            transform='translate(8 8)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
