import { getFormatLang } from '@utils/langs'
import {
  LanguageCode,
  TickMarkType,
} from '../../../charting_library/charting_library'

const locale = getFormatLang()

export const getLanguageFromURL = (): LanguageCode | null => {
  const regex = new RegExp('[\\?&]lang=([^&#]*)')
  const results = regex.exec(location.search)
  return results === null
    ? null
    : (decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode)
}

export const priceFormatterFactory = (): {
  format: (price: number) => string
} => {
  return {
    format: (price: number | bigint): string => {
      return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 4,
      }).format(price)
    },
  }
}

export const customFormatter = (
  date: Date,
  tickMarkType: TickMarkType,
): string => {
  switch (tickMarkType) {
    case 'Year':
      return date.toLocaleDateString('default', {
        year: 'numeric',
      })

    case 'Month':
    case 'DayOfMonth':
      return `${date.toLocaleString('default', {
        day: '2-digit',
      })} ${date.toLocaleString('default', {
        month: 'short',
      })} ${date.toLocaleString('default', { year: '2-digit' })}`

    case 'Time':
      return date.getUTCHours() + ':' + date.getUTCMinutes()

    case 'TimeWithSeconds':
      return (
        date.getUTCHours() +
        ':' +
        date.getUTCMinutes() +
        ':' +
        date.getUTCSeconds()
      )
  }

  throw new Error('unhandled tick mark type ' + tickMarkType)
}
