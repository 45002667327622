import { Balance, CurrencyCode } from 'interfaces'
import { Spacer } from 'components/spacer'
import { Text } from 'components/text/text'
import { Button } from 'components/button/button'
import { ChevronDown } from '@assets/icons'
import { formatCurrencyToSymbol } from '@utils/helpers'
import { TextField } from 'components/textField/textField'
import { useState } from 'react'
import { TextFieldProps } from 'components/textField/textField.props'
import { CustomModal } from 'components/customModal'
import { ChangeCurrency } from '@shared/modals/changeCurrency/changeCurreny'
import { CurrencyFlag } from 'components/currencyFlag/currencyFlag'

interface WalletInput extends TextFieldProps {
  subTitle?: JSX.Element
  onChangeCurrency?: (value: Balance) => void
  disableDropDown?: boolean
  defaultCurrency?: CurrencyCode
}

export const WalletInputForm = ({
  register,
  inputProps,
  subTitle,
  onChangeCurrency,
  disableDropDown = false,
  defaultCurrency = CurrencyCode.USD,
}: WalletInput): JSX.Element => {
  const [isCurrencyListShown, setCurrencyListShown] = useState(false)

  const [currencyDetails, setCurrenyDetails] =
    useState<CurrencyCode>(defaultCurrency)
  return (
    <>
      <div className='d-flex align-center justify-space-between w-100'>
        <div className='d-flex-col'>
          <div className='d-flex align-center gap-4'>
            {
              <CurrencyFlag
                currency={currencyDetails as CurrencyCode}
                size={24}
              />
            }
            <Text preset='bodyBig' text={currencyDetails} />
            <Button
              onClick={() => {
                !disableDropDown && setCurrencyListShown(true)
              }}
              buttonType='link'
              autoWidth
              content={<ChevronDown color='iconPrimaryInitial' size={24} />}
              shape='square'
            />
          </div>
          <Spacer preset='tiny' />
          {subTitle}
        </div>
        <div className='w-50'>
          <TextField
            size='normal'
            theme={'subtle'}
            right={
              <Text
                preset='subtitle2'
                text={formatCurrencyToSymbol(currencyDetails as string)}
              />
            }
            style={'text-right headline1 bradius-2'}
            register={register}
            inputProps={inputProps}
          ></TextField>
        </div>
      </div>
      {isCurrencyListShown && (
        <CustomModal.OverlayContent>
          <ChangeCurrency
            onClose={() => setCurrencyListShown(false)}
            show={isCurrencyListShown}
            activeCurrencyDetails={currencyDetails}
            setCurrencyDetails={(e) => {
              setCurrenyDetails(e.cashAvailable.currency as CurrencyCode)
              onChangeCurrency(e)
            }}
          />
        </CustomModal.OverlayContent>
      )}
    </>
  )
}
