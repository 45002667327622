import { PortfolioSliderProps } from './portfolioSlider.props'
import { ReactComponent as RightIcon } from '@assets/svg/next.svg'
import { ReactComponent as LeftIcon } from '@assets/svg/back.svg'
import { ScrollingCarousel } from '@trendyol-js/react-carousel'
import { PortfolioItem } from './portfolioItem/portfolioItem'
import { Spacer } from 'components'
import React from 'react'

export function PortfolioSlider({
  className,
  shownPortfolio,
  handlePortfolioPressed,
  investments,
}: PortfolioSliderProps): JSX.Element {
  return (
    <ScrollingCarousel
      key={investments.id}
      className={`carousel d-flex-row ${className}`}
      rightIcon={
        <RightIcon fill='var(--textNeutralStrong)' className='rightIcon' />
      }
      leftIcon={<LeftIcon fill='var(--textNeutralStrong)' />}
    >
      {investments.portfolios.map((portfolio, idx) => {
        return portfolio.positions?.length ? (
          <React.Fragment key={`${portfolio.id}-${idx}`}>
            <PortfolioItem
              {...portfolio}
              key={`${portfolio.id}-${idx}`}
              length={portfolio.positions?.length}
              focused={idx == shownPortfolio}
              onPress={() => handlePortfolioPressed(idx)}
              togglable
            />
            {idx < investments.portfolios?.length - 1 ? (
              <Spacer preset='smaller' />
            ) : null}
          </React.Fragment>
        ) : null
      })}
    </ScrollingCarousel>
  )
}
