import { BasicLink, ButtonedTabs, Card, Spacer, Text } from 'components'
import BarChart from '../../../../components/advanceBarChart/barChart'
import { EarningTab } from '@interfaces'
import { useState } from 'react'
import AboutEarning from '@shared/modals/aboutEarning/aboutEarning'
import { useTranslation } from 'hooks/useTranslation'
import { useAppSelector } from 'store'
import _ from 'lodash'

interface Result {
  quarter: number
  year: number
  estimated: number
  reported: number
  currency: string
}

const processFinancialData = (data, tab: EarningTab): Result[] => {
  return _.map(data, (item) => {
    if (tab === EarningTab.earnings) {
      return {
        quarter: item?.quarter,
        year: item?.year,
        estimated: item?.epsActual,
        reported: item?.epsEstimate,
        currency: item?.currency,
      }
    } else if (tab === EarningTab.revenues) {
      return {
        quarter: item?.quarter,
        year: item?.year,
        estimated: item?.revenueActual,
        reported: item?.revenueEstimate,
        currency: item?.currency,
      }
    }
    return null
  }).filter(Boolean) as Result[]
}

const Earnings = (): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const [activeTab, setActiveTab] = useState<EarningTab>(EarningTab.earnings)
  const { t } = useTranslation()
  const { earnings } = useAppSelector((state) => state.tipRank)

  const barChartData = processFinancialData(earnings, activeTab)

  return (
    <>
      {showModal && (
        <AboutEarning
          close={() => {
            setShowModal(false)
          }}
        />
      )}
      <Spacer preset='small' />
      <div className='d-flex justify-center align-center'>
        <Text preset='bodyBig' text={t('companyDetails.earning.title')} />
        <Spacer preset='medium' />
        <BasicLink
          onClick={() => {
            setShowModal(true)
          }}
        >
          <Text preset='bodyRegular' text='Info' />
        </BasicLink>
      </div>
      <Spacer preset='tiny' />
      <Card key='account-type' width='full' aria-hidden padding='smaller'>
        <div className='w-100 position-relative'>
          <div className='w-50'>
            <ButtonedTabs<EarningTab>
              tabsStyle='buttoned-tab-bar__tabs--large'
              tabs={[
                {
                  id: EarningTab.earnings,
                  title: t('companyDetails.earning.earnings'),
                },
                {
                  id: EarningTab.revenues,
                  title: t('companyDetails.earning.revenues'),
                },
              ]}
              activeTab={activeTab}
              setActiveTab={(e) => {
                setActiveTab(e)
              }}
              loading={false}
            />
          </div>
          <Spacer preset='medium' />
          <BarChart
            data={barChartData}
            height={350}
            activeTab={activeTab}
            legendFirst={
              activeTab === EarningTab.earnings
                ? t('companyDetails.earning.estimatedEps')
                : t('companyDetails.earning.estimated')
            }
            legendSecond={
              activeTab === EarningTab.earnings
                ? t('companyDetails.earning.reportedEps')
                : t('companyDetails.earning.reported')
            }
          />
        </div>
      </Card>
    </>
  )
}

export default Earnings
