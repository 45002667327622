import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const Dollar = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 24'
      fill='none'
    >
      <path
        d='M11.4256 21V18.85C10.5423 18.65 9.77975 18.2667 9.13809 17.7C8.49642 17.1333 8.02559 16.3333 7.72559 15.3L9.57559 14.55C9.82559 15.35 10.1964 15.9583 10.6881 16.375C11.1798 16.7917 11.8256 17 12.6256 17C13.3089 17 13.8881 16.8458 14.3631 16.5375C14.8381 16.2292 15.0756 15.75 15.0756 15.1C15.0756 14.5167 14.8923 14.0542 14.5256 13.7125C14.1589 13.3708 13.3089 12.9833 11.9756 12.55C10.5423 12.1 9.55892 11.5625 9.02559 10.9375C8.49225 10.3125 8.22559 9.55 8.22559 8.65C8.22559 7.56667 8.57559 6.725 9.27559 6.125C9.97559 5.525 10.6923 5.18333 11.4256 5.1V3H13.4256V5.1C14.2589 5.23333 14.9464 5.5375 15.4881 6.0125C16.0298 6.4875 16.4256 7.06667 16.6756 7.75L14.8256 8.55C14.6256 8.01667 14.3423 7.61667 13.9756 7.35C13.6089 7.08333 13.1089 6.95 12.4756 6.95C11.7423 6.95 11.1839 7.1125 10.8006 7.4375C10.4173 7.7625 10.2256 8.16667 10.2256 8.65C10.2256 9.2 10.4756 9.63333 10.9756 9.95C11.4756 10.2667 12.3423 10.6 13.5756 10.95C14.7256 11.2833 15.5964 11.8125 16.1881 12.5375C16.7798 13.2625 17.0756 14.1 17.0756 15.05C17.0756 16.2333 16.7256 17.1333 16.0256 17.75C15.3256 18.3667 14.4589 18.75 13.4256 18.9V21H11.4256Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
