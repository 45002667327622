import {
  BuySellTransaction,
  OrderTransaction,
  TransactionType,
} from '@interfaces'

export function mapOrderTransaction(
  data: OrderTransaction,
): BuySellTransaction {
  return {
    title: data?.asset?.name,
    status: data?.status,
    statusText: data?.statusText,
    childStatus: data?.childStatus,
    childStatusText: data?.childStatusText,
    reason: data?.reason,
    reasonText: data?.reasonText,
    documentId: data?.documentId,
    ticker: data?.asset?.ticker,
    amount: data?.calculations?.totalCost,
    date: data?.creationDate,
    logo: data?.asset?.logoUrl,
    asset: data?.asset,
    orderMethod: data?.method,
    type: data?.type as unknown as TransactionType,
    shares: data?.shares,
    taxes: data?.calculations?.taxes,
    fees: data?.calculations?.fees,
    totalCost: data?.calculations?.totalCost,
    lockedCash: data?.calculations?.lockedCash,
    netAmount: data?.calculations?.netAmount,
    netAmountInPortfoliosCurrency:
      data?.calculations?.netAmountInPortfoliosCurrency,
    requiredNetAmount: data?.calculations?.requiredNetAmount,
    exchangeRateAverage: data?.exchangeRateAverage,
    expirationDate: data?.expirationDate,
    estimatedPrice: data?.estimatedPrice,
    executedPrice: data?.executedPrice,
    grossAmount: data?.calculations?.grossAmount,
    assetCurrency: data?.asset?.currency,
    contingenciesBuffer: data?.calculations?.contingenciesBuffer,
    contingenciesBufferAmount: data?.calculations?.contingenciesBufferAmount,
    isCancellable: data?.isCancellable,
    notCancellableReason: data?.notCancellableReason,
    commissions: data?.commissions,
    condition: data?.condition,
  }
}
