import { getStopLossTakeProfitType } from '@utils/getStopLossTakeProfitType'
import { formatCurrency, formatDate } from '@utils/helpers'
import { isStopLossOrder } from '@utils/isStopLossOrder'
import { isTakeProfitOrder } from '@utils/isTakeProfitOrder'
import { t } from 'i18next'
import {
  TransactionType,
  TransactionDetailType,
  BuySellTransaction,
  OrderTypeEnum,
  StopLossTakeProfitType,
  OrderTransactionStatusEnum,
  DividendTransaction,
} from 'interfaces'
import { useMemo } from 'react'

export interface FormattedValues {
  headerText: string
  isDividend: boolean
  formattedDate: string
  formattedAmount: string
  title: string
  subtitle: string
  textDetail: string
}

const buildTranslation = (prefix: string, suffix: string): string =>
  `${prefix} ${suffix}`

const getSellHeaderText = (
  isStopLoss: boolean,
  isTakeProfit: boolean,
  stopLossTakeProfitType: StopLossTakeProfitType,
  orderType: OrderTypeEnum,
): string => {
  const order = t('transactions.order')
  if (isStopLoss) {
    return buildTranslation(
      stopLossTakeProfitType === StopLossTakeProfitType.FIXED
        ? t('transactions.fixedStopLoss')
        : t('transactions.trailingStopLoss'),
      order,
    )
  }
  if (isTakeProfit) {
    return buildTranslation(
      stopLossTakeProfitType === StopLossTakeProfitType.FIXED
        ? t('transactions.fixedTakeProfit')
        : t('transactions.trailingTakeProfit'),
      order,
    )
  }

  return orderType === OrderTypeEnum.limit
    ? t('transactions.sellLimitOrder')
    : t('transactions.sell')
}

export function useFormattedValues(
  transactionType: TransactionType,
  transactionDetail?: TransactionDetailType,
  financialEvent?: string,
): FormattedValues {
  const orderType = (transactionDetail as BuySellTransaction)?.orderMethod
  const condition = (transactionDetail as BuySellTransaction)?.condition
  const isStopLoss = isStopLossOrder(condition)
  const isTakeProfit = isTakeProfitOrder(condition)
  const stopLossTakeProfitType = getStopLossTakeProfitType(condition)

  const headerText = useMemo(() => {
    switch (transactionType) {
      case TransactionType.dividendCash:
        return t('transactions.dividend')
      case TransactionType.dividendShares:
        return financialEvent || t('transactions.financialEvent')
      case TransactionType.deposit:
        return t('transactions.deposit')
      case TransactionType.withdrawal:
        return t('transactions.withdrawal')
      case TransactionType.buy:
        return orderType === OrderTypeEnum.limit
          ? t('transactions.buyLimitOrder')
          : t('transactions.buy')
      case TransactionType.sell:
        return getSellHeaderText(
          isStopLoss,
          isTakeProfit,
          stopLossTakeProfitType,
          orderType,
        )
      case TransactionType.noteCredit:
        return t('transactions.credit')
      case TransactionType.noteDebit:
        return t('transactions.debit')
      case TransactionType.safekeeping:
        return t('transactions.safekeeping')
      default:
        return ''
    }
  }, [
    transactionType,
    financialEvent,
    orderType,
    isStopLoss,
    isTakeProfit,
    stopLossTakeProfitType,
  ])

  const isDividend = useMemo(
    () =>
      transactionType === TransactionType.dividendCash ||
      transactionType === TransactionType.dividendShares,
    [transactionType],
  )

  const formattedDate = formatDate(transactionDetail?.date)

  const formattedAmount: string = useMemo(() => {
    if (!transactionDetail) {
      return null
    }

    if (
      transactionType === TransactionType.buy ||
      transactionType === TransactionType.sell
    ) {
      const buySellTransaction = transactionDetail as BuySellTransaction

      const isBuy = buySellTransaction?.type === TransactionType.buy

      const isNotCompleted =
        buySellTransaction?.status !== OrderTransactionStatusEnum.completed

      const total =
        isBuy && isNotCompleted && buySellTransaction?.lockedCash
          ? buySellTransaction?.lockedCash
          : buySellTransaction?.totalCost

      return formatCurrency(total)
    }

    return formatCurrency(transactionDetail?.amount)
  }, [transactionDetail, transactionType])

  // Shares only apply to orders and divident shares
  const shares: number = useMemo(() => {
    if (
      transactionType === TransactionType.buy ||
      transactionType === TransactionType.sell
    ) {
      return (transactionDetail as BuySellTransaction)?.shares?.requested
    }
    if (transactionType === TransactionType.dividendShares) {
      return (transactionDetail as DividendTransaction)?.sharesQuantity
    }

    return null
  }, [transactionDetail, transactionType])

  const textDetail = useMemo(() => {
    if (!transactionDetail) {
      return null
    }

    if (transactionType === TransactionType.dividendShares) {
      const { sharesQuantity } = transactionDetail as DividendTransaction
      return t(
        sharesQuantity === 1
          ? t('transactions.share_singular')
          : t('transactions.share_plural'),
        { sharesQuantity },
      )
    }
    return formattedAmount || '0'
  }, [transactionDetail, transactionType, formattedAmount])

  const title = useMemo(() => {
    if (!transactionDetail) {
      return null
    }

    if (textDetail?.includes('+') || textDetail?.includes('-')) {
      return textDetail
    }

    if (transactionType === TransactionType.buy) {
      return `-${textDetail}`
    }
    if (transactionType === TransactionType.sell) {
      return `+${textDetail}`
    }
    if (transactionType === TransactionType.dividendShares) {
      return `+${textDetail}`
    }
    if (transactionType === TransactionType.noteDebit) {
      return `-${textDetail}`
    }
    if (transactionType === TransactionType.noteCredit) {
      return `+${textDetail}`
    }
    if (transactionType === TransactionType.safekeeping) {
      return `-${textDetail}`
    }
    return textDetail
  }, [transactionDetail, transactionType, textDetail])

  const subtitle = useMemo(() => {
    if (transactionType === TransactionType.noteCredit) {
      return t('transactions.incomingPayment')
    } else if (transactionType === TransactionType.noteDebit) {
      return t('transactions.outgoingPayment')
    }

    const displayedShares = shares ? ` ${shares} ` : ''
    return transactionDetail?.ticker
      ? `${headerText}${displayedShares}${transactionDetail?.ticker}`
      : `${headerText}${displayedShares}`
  }, [headerText, transactionDetail?.ticker, shares, transactionType])

  return {
    headerText,
    isDividend,
    title,
    subtitle,
    formattedDate,
    formattedAmount,
    textDetail,
  }
}
