import { Avatar, Card, Spacer, Text } from 'components'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { SupportIcon } from '@assets/icons'
import { formatNameInTwoCharacters } from '@utils/helpers'
import { PortfolioSummary } from '@shared/components'
import { useNavigate } from 'hooks/useNavigation'
import { routes } from 'navigation'
import { useMemo } from 'react'
import LineChart from '../linearGraph/linearGraph'
import { PortfolioValuation } from 'interfaces'

export const PortfolioSection = (): JSX.Element => {
  const { userSettings, portfolioValuation } = useSelector(
    (state: RootState) => state,
  )

  const graphDataConvertor = (
    data: PortfolioValuation,
  ): { date: Date; value: number }[] => {
    const result = data.map((item) => {
      return {
        date: new Date(item.date),
        value: Number(item?.valuation?.amount),
      }
    })
    return result.sort((a, b) => a.date.getTime() - b.date.getTime())
  }

  const { profile } = useSelector((state: RootState) => state)

  const { navigate } = useNavigate()

  const initials = useMemo(() => {
    return formatNameInTwoCharacters(profile?.userProfile?.fullName)
  }, [profile?.userProfile?.fullName])

  return (
    <div className='w-100 home__widget'>
      <Spacer preset='huge' />
      <div className='w-100 home__widget__portfolio'>
        <div>
          <div className='d-flex justify-space-between align-center'>
            <div
              className='cursor-pointer'
              aria-hidden
              onClick={() => navigate('/profile')}
            >
              <Avatar
                roundness='full'
                size='large'
                image={
                  <Text preset='headline2' text={initials} color='primary' />
                }
                bgColor='tertiaryBase'
              />
            </div>
            <div className='--ml-auto d-flex align-center'>
              <div
                className='cursor-pointer'
                aria-hidden
                onClick={() => navigate(routes.auth.profileHelp)}
              >
                <SupportIcon size={24} />
              </div>
            </div>
          </div>
          <Spacer preset='mediumPlus' />

          <Card key='account-type' width='full' aria-hidden padding='smaller'>
            <div className='d-flex justify-space-between'>
              <div
                className='d-flex align-center cursor-pointer'
                aria-hidden
                onClick={() => navigate(routes.auth.portfolio)}
              >
                <PortfolioSummary
                  hideAmount={userSettings?.hideAmounts}
                  showRightArrow
                  showLast24Label={true}
                />
                {!portfolioValuation.error &&
                  portfolioValuation.valuation.length > 0 && (
                    <LineChart
                      data={graphDataConvertor(portfolioValuation.valuation)}
                      width={678}
                      height={104}
                    />
                  )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}
