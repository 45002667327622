import { ConditionType, PreorderCondition } from '@interfaces'

export function isStopLossOrTakeProfitPreorder(
  conditionInfo: PreorderCondition,
): boolean {
  return (
    conditionInfo?.stopType === ConditionType.STOP_LOSS ||
    conditionInfo?.stopType === ConditionType.TAKE_PROFIT
  )
}
