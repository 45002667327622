import React, { useEffect, useRef } from 'react'
import './tradeViewChartContainer.styles.scss'
import {
  widget,
  ChartingLibraryWidgetOptions,
  ShapePoint,
  Timezone,
  GmtTimezoneId,
} from '../../../charting_library'
import { datafeedNew } from '../datafeed/datafeed'
import { defaultProps } from './tradeViewChartContainer.constant'
import { ThemeName, CustomTimezoneId } from '../../../charting_library'
import { generateEtcGmtTimezone } from 'components/utils'
import { TVChartContainerProps } from './tradeViewChartContainer.props'
import {
  getPriceValuesSortedByValue,
  getTimeFrame,
  getTradingViewTheme,
} from '../datafeed/datafeed.helper'
import {
  customFormatter,
  getLanguageFromURL,
  priceFormatterFactory,
} from './tradeViewChartContainer.helper'

export const TradeViewChartContainer = ({
  chartData,
  priceLines,
  symbolInfo,
  disabled_features,
  candlesticksEnabled = false,
}: TVChartContainerProps): React.JSX.Element => {
  const chartContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const { mode, theme } = getTradingViewTheme()

  const drawPriceLine = (
    tvWidget,
    minPrice: number,
    maxPrice: number,
  ): void => {
    priceLines?.forEach((line) => {
      tvWidget.activeChart().createShape(
        {
          price: line.price,
        } as ShapePoint,
        {
          shape: 'horizontal_line',
          lock: true,
          disableSelection: true,
          showInObjectsTree: false,
          zOrder: 'top',
          overrides: {
            linecolor: line?.lineColor,
            linewidth: 1.5,
            linestyle: 1,
            showLabel: false,
            showPrice: false,
          },
        },
      )

      tvWidget
        .activeChart()
        .createPositionLine()
        .setPrice(line?.price)
        .setText(line?.label)
        .setBodyTextColor(line?.labelTextColor as string)
        .setBodyBackgroundColor(line?.labelBackgroundColor as string)
        .setBodyBorderColor(line?.labelBackgroundColor as string)
        .setQuantity('')
        .setExtendLeft(true)
        .setLineStyle(1)
        .setLineWidth(0.0001)
        .setLineLength(100, 'percentage')
        .setLineColor(line?.priceBackgroundColor as string)

      if (line?.price < minPrice) {
        minPrice = line?.price
      }
      if (line?.price > maxPrice) {
        maxPrice = line?.price
      }
    })

    const priceScale = tvWidget
      .activeChart()
      .getPanes()[0]
      .getRightPriceScales()[0]
    priceScale.setVisiblePriceRange({
      from: minPrice - 0.5,
      to: maxPrice + 0.5,
    })
  }

  useEffect(() => {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: defaultProps.symbol as string,
      datafeed: datafeedNew(chartData, symbolInfo),
      theme: mode as ThemeName,
      interval:
        defaultProps.interval as ChartingLibraryWidgetOptions['interval'],
      container: chartContainerRef?.current,
      library_path: defaultProps.libraryPath as string,
      timeframe: getTimeFrame(chartData),
      timezone: 'CustomTimezone' as Timezone,
      custom_timezones: [
        {
          id: 'CustomTimezone' as CustomTimezoneId,
          alias: generateEtcGmtTimezone() as GmtTimezoneId,
          title: 'London',
        },
      ],

      locale: getLanguageFromURL() || 'en',
      disabled_features: disabled_features,
      enabled_features: [
        'fix_left_edge',
        'lock_visible_time_range_when_adjusting_percentage_right_margin',
        'use_last_visible_bar_value_in_legend',
        'determine_first_data_request_size_using_visible_range',
      ],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: {
        ...defaultProps.studiesOverrides,
        'volume.volume.color.0': theme.volumeBarColor,
        'volume.volume.color.1': theme.volumeBarColor,
      },
      overrides: {
        'paneProperties.background': theme.backgroundColor,
        'paneProperties.backgroundType': 'solid',
        'mainSeriesProperties.style': candlesticksEnabled ? 1 : 2,
        'paneProperties.crossHairProperties.style': 1, // Dotted
        'paneProperties.crossHairProperties.color': theme.crossHairColor,
        'scalesProperties.crosshairLabelBgColorLight': theme.crossHairBgColor,
        'scalesProperties.crosshairLabelBgColorDark': theme.crossHairBgColor,
      },
      loading_screen: {
        backgroundColor: theme.backgroundColor,
        foregroundColor: theme.color,
      },
      custom_css_url: 'custom.css',
      custom_formatters:
        symbolInfo.symbol === 'Portfolio'
          ? { priceFormatterFactory, tickMarkFormatter: customFormatter }
          : { priceFormatterFactory },
    }

    const tvWidget = new widget(widgetOptions)

    const originalPriceRangeSortedByValue =
      getPriceValuesSortedByValue(chartData)
    const minPrice = originalPriceRangeSortedByValue[0]?.close
    const maxPrice =
      originalPriceRangeSortedByValue[
        originalPriceRangeSortedByValue?.length - 1
      ]?.close

    tvWidget.onChartReady(async () => {
      if (priceLines) {
        drawPriceLine(tvWidget, minPrice, maxPrice)
      }

      const originalRange = getTimeFrame(chartData)

      tvWidget
        .chart()
        .onVisibleRangeChanged()
        .subscribe(null, function (range) {
          if (range.to > originalRange.to) {
            tvWidget.chart().getTimeScale().setRightOffset(0)
          }
        })
      await tvWidget.chart().setVisibleRange(originalRange)
      await tvWidget.changeTheme(mode as ThemeName)
      tvWidget.applyOverrides({
        'paneProperties.background': theme.backgroundColor,
        'scalesProperties.textColor': theme.textColor,
        'paneProperties.horzGridProperties.color': theme.backgroundColor,
        'paneProperties.vertGridProperties.color': theme.backgroundColor,
        'paneProperties.backgroundType': 'solid',
        'mainSeriesProperties.style': candlesticksEnabled ? 1 : 2,
        'mainSeriesProperties.showPriceLine': true,
        'mainSeriesProperties.priceLineWidth': 1,
        'mainSeriesProperties.priceLineColor': candlesticksEnabled
          ? ''
          : theme.color,
      })

      if (candlesticksEnabled) {
        tvWidget.chart().getSeries().setChartStyleProperties(1, {
          upColor: theme.barUpColor,
          borderUpColor: theme.barUpColor,
          wickUpColor: theme.barUpColor,
          downColor: theme.barDownColor,
          borderDownColor: theme.barDownColor,
          wickDownColor: theme.barDownColor,
        })
      } else {
        tvWidget.chart().getSeries().setChartStyleProperties(2, {
          color: theme.color,
        })
      }
    })
    return () => {
      tvWidget.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme, chartData, symbolInfo, priceLines])

  return <div ref={chartContainerRef} className={'TVChartContainer'} />
}
