import { Button, GoBack, Spacer, Text } from 'components'
import './balances.style.scss'
import { useNavigate } from 'hooks/useNavigation'
import { routes } from 'navigation'
import React, { useMemo } from 'react'
import { ButtonProps } from 'components/button/button.props'
import { RemoveIcon, PlusIcon } from '@assets/icons'
import CardList from '../components/cardList'
import { useAppSelector } from 'store'
import { formatCurrency } from '@utils/helpers'
import { CurrencyCode, CurrencyName } from 'interfaces'
import { useAddFundsAndCashout } from 'hooks/useAddFundsAndCashout'
import { useTranslation } from 'hooks/useTranslation'
import { useDispatch } from 'react-redux'
import { setSelectedCurrency } from 'features/exchangeBalance/thunk'
import { CurrencyFlag } from 'components/currencyFlag/currencyFlag'

const Balances = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { cashActive, wallet } = useAppSelector((state) => state.userBalance)
  const { currenciesExchanges } = useAppSelector(
    (state) => state.currenciesExchanges,
  )
  const { navigate } = useNavigate()
  const { addFunds, cashout } = useAddFundsAndCashout({
    origin: 'Profile',
  })
  const actions = useMemo(() => {
    const commonBtnProps: ButtonProps = {
      autoWidth: true,
      size: 'medium',
      buttonType: 'secondary',
      textPreset: 'paragraph2',
    }

    const visibleActions: (typeof Button)[] = []

    const buttons = {
      addMoney: (
        <Button
          {...commonBtnProps}
          left={<PlusIcon size={24} />}
          text={t('balance.addMoney')}
          onClick={() => {
            dispatch(setSelectedCurrency(null))
            addFunds()
          }}
          buttonType='primary'
          autoWidth={false}
        />
      ),
      withdraw: (
        <Button
          {...commonBtnProps}
          left={<RemoveIcon size={24} />}
          text={t('balance.withdraw')}
          buttonType='secondary'
          onClick={() => {
            dispatch(setSelectedCurrency(null))
            cashout()
          }}
          autoWidth={false}
        />
      ),
    }

    if (wallet?.length <= 1) {
      return [...visibleActions, buttons.addMoney, buttons.withdraw]
    }

    return [...visibleActions, buttons.addMoney, buttons.withdraw]
  }, [addFunds, cashout, t, dispatch, wallet?.length])

  return (
    <>
      <div className='w-100 profile__widget payments__screen --fade-in-animation'>
        <div className='profile__widget__header'>
          <GoBack goBack={() => navigate(routes.auth.home)} />
          <div className='title mt-3'>{t('balance.balances')}</div>
        </div>
        <div className='profile__widget__content mt-5'>
          <div className='d-flex w-100 gap-24'>
            {actions?.map((item, idx) => {
              return (
                <React.Fragment key={`button-${idx}`}>{item}</React.Fragment>
              )
            })}
          </div>
        </div>
        {cashActive && (
          <div className='w-100'>
            <Spacer preset='large' />
            <Text preset='subtitle1' text={t('balance.totalBalance')} />
            <Spacer preset='tinyMedium' />

            <div className='w-100 d-flex d-flex-col gap-16'>
              <CardList
                avatar={
                  <CurrencyFlag
                    currency={cashActive.currency as CurrencyCode}
                  />
                }
                title={cashActive.currency}
                subtitle={t(CurrencyName[cashActive.currency])}
                endText={formatCurrency({
                  amount: cashActive?.amount,
                  currency: cashActive?.currency,
                })}
              />
            </div>
          </div>
        )}

        <div className='w-100'>
          <Spacer preset='large' />
          <Text preset='subtitle1' text={t('balance.allBalances')} />
          <Spacer preset='tinyMedium' />
          <div className='w-100 d-flex d-flex-col gap-16'>
            {wallet?.map((walletItem, walletIndex) => {
              const wCurrency = walletItem?.cashAvailable?.currency
              const exchanges = currenciesExchanges.exchanges.find(
                (e) => e.source === wCurrency,
              )
              return (
                <CardList
                  avatar={<CurrencyFlag currency={wCurrency as CurrencyCode} />}
                  title={wCurrency}
                  subtitle={
                    exchanges &&
                    `1 ${wCurrency} = ${Number(exchanges?.rate)} ${
                      currenciesExchanges.target
                    }`
                  }
                  endText={formatCurrency({
                    amount: walletItem?.cashAvailable?.amount,
                    currency: walletItem?.cashAvailable?.currency,
                  })}
                  endSubText={
                    exchanges?.rate &&
                    formatCurrency({
                      amount:
                        walletItem?.cashAvailable?.amount *
                        Number(exchanges?.rate),
                      currency: currenciesExchanges?.target,
                    })
                  }
                  key={walletIndex}
                />
              )
            })}
          </div>
        </div>
      </div>
      {/* {!!exchangeBalanceState?.stepsHistory?.length &&
        exchangeBalanceState?.currentStep && (
          <ExchangeBalance
            setBalanceModalValue={setBalanceModalValue}
            availableBalance={balanceModalValue?.availableBalance}
            currency={balanceModalValue?.currency}
            onHold={balanceModalValue?.onHold}
            withdrawlMoney={balanceModalValue?.withdrawlMoney}
          />
        )} */}
    </>
  )
}

export default Balances
