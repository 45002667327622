import './pieChart.styles.scss'
import { CreatePieProps, createPie } from './create-pie-d3'
import { useEffect, useRef } from 'react'

export const PieChart = ({
  data,
  colorScheme,
  innerRadius = 0.6,
  padAngle = 0.04,
  innerElem,
}: {
  data: Array<{
    label: string
    value: number
  }>
  innerElem?: JSX.Element
} & Partial<
  Pick<CreatePieProps, 'padAngle' | 'innerRadius' | 'colorScheme'>
>): JSX.Element => {
  const container = useRef()
  const defaultColors = colorScheme || [
    'var(--primary500)',
    'var(--neutral100)',
    'var(--success500)',
    'var(--warning400)',
    'var(--destructive500)',
    'var(--tertiary400)',
    'var(--tertiary300)',
  ]

  useEffect(() => {
    window.addEventListener('resize', handlePieCreation)
    handlePieCreation()
    return () => {
      window.removeEventListener('resize', handlePieCreation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerElem, padAngle, innerRadius, colorScheme])

  const handlePieCreation = (): void => {
    const node: HTMLDivElement = container?.current
    if (node) {
      createPie({
        container: node,
        data: data?.map((i) => i?.value),
        colorScheme: defaultColors,
        width: node?.getBoundingClientRect()?.width || 0,
        innerRadius,
        padAngle,
      })
    }
  }

  return (
    <div data-testid='pie-chart' className='pie-chart'>
      <div className='pie-chart__chart-cont' ref={container}>
        <div id='chart'></div>
        <div className='pie-chart__chart-inner-content'>{innerElem}</div>
      </div>
    </div>
  )
}
