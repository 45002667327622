import './chart.styles.scss'
import { ChartProps } from './chart.props'
import { PeriodEnum } from '@interfaces'
import { BigSpinner } from 'components/bigSpinner/bigSpinner'
import { TradeViewChartContainer } from 'components/tradeView/tradeViewChartContainer/tradeViewChartContainer'
import { ChartingLibraryFeatureset } from '../../charting_library/charting_library'
interface IChart extends ChartProps {
  volume?: boolean
}
export function Chart({
  priceLines,
  loading,
  selectedPeriod,
  onPeriodChange,
  data,
  symbolInfo,
  availablePeriods,
  additionalFeature = false,
  availablePeriodTabFull = false,
  volume = false,
}: IChart): JSX.Element {
  const activeStyle = (time: string): string => {
    const activeStyle = `company-details__after-widget__buttons__element--active`
    return `${selectedPeriod === (time as PeriodEnum) ? activeStyle : ''}`
  }

  const commonFeatures: ChartingLibraryFeatureset[] = [
    'legend_widget',
    'timeframes_toolbar',
    'main_series_scale_menu',
    'control_bar',
    volume ? 'control_bar' : 'create_volume_indicator_by_default',
  ]

  const additionalFeatures: ChartingLibraryFeatureset[] = additionalFeature
    ? [
        'header_compare',
        'header_quick_search',
        'header_symbol_search',
        'symbol_search_hot_key',
        'header_resolutions',
        'header_saveload',
      ]
    : ['left_toolbar', 'header_widget']

  const disabled_features: ChartingLibraryFeatureset[] = [
    ...additionalFeatures,
    ...commonFeatures,
  ]

  return (
    <div className={`chart h-100 w-100 ${loading ? '--loading' : ''}`}>
      <div className='chart__controls w-100 d-flex align-center justify-space-between pb-2'>
        <div
          className={`company-details__after-widget__buttons ${
            availablePeriodTabFull && '__Full'
          }`}
        >
          {availablePeriods?.map((p) => {
            return (
              <button
                key={`${p.label}-${p.period}`}
                className={`company-details__after-widget__buttons__element ${activeStyle(
                  p?.period,
                )}`}
                onClick={() => {
                  if (onPeriodChange) onPeriodChange(p?.period)
                }}
              >
                {p?.label}
              </button>
            )
          })}
        </div>
      </div>

      {loading ? (
        <div className='chart__loader-container'>
          <BigSpinner />
        </div>
      ) : (
        <TradeViewChartContainer
          priceLines={priceLines}
          disabled_features={disabled_features}
          chartData={data.chartData}
          symbolInfo={symbolInfo}
        />
      )}
    </div>
  )
}
