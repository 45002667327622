import { ChevronLeft } from '@assets/icons'
import CardList from '@screens/profile/components/cardList'
import { formatCurrency } from '@utils/helpers'
import { Button, CustomModal, Spacer, Text } from 'components'
import { CurrencyFlag } from 'components/currencyFlag/currencyFlag'
import { ExchangeBalanceSteps } from 'features/exchangeBalance/exchangeBalance.props'
import { setExchangeBalanceStep } from 'features/exchangeBalance/thunk'
import { CurrencyCode, CurrencyName, Money } from 'interfaces'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Fragment } from 'react/jsx-runtime'
import { useAppSelector } from 'store'

const WalletList = ({
  sourceCurrency,
  onPrevious,
  destinationWallet,
}: {
  sourceCurrency: CurrencyCode
  onPrevious: () => void
  setDestinationWallet: (value: Money) => void
  destinationWallet: Money
}): JSX.Element => {
  const { t } = useTranslation()
  const { wallet } = useAppSelector((state) => state.userBalance)
  const dispatch = useDispatch()

  useEffect(() => {
    if (destinationWallet?.amount) {
      dispatch(setExchangeBalanceStep(ExchangeBalanceSteps.reviewOrder))
    }
  }, [destinationWallet, dispatch])
  return (
    <>
      <CustomModal.Header
        text={
          <Text
            preset='subtitle2'
            text={t('exchangeBalance.destinationWalletTitle')}
          />
        }
        className='w-100 d-flex align-center justify-space-between'
      >
        <Button
          onClick={onPrevious}
          buttonType='link'
          autoWidth
          content={<ChevronLeft color='iconPrimaryInitial' size={36} />}
          shape='square'
        />
      </CustomModal.Header>
      <CustomModal.Content>
        <div className='w-100'>
          {wallet?.map((walletItem, walletIndex) => {
            return (
              <Fragment key={walletIndex}>
                {(walletItem?.cashAvailable?.currency as CurrencyCode) !==
                  sourceCurrency && (
                  <CardList
                    // onClick={() => {
                    //   setDestinationWallet({
                    //     amount: walletItem?.cashAvailable?.amount,
                    //     currency: walletItem?.cashAvailable?.currency,
                    //   })
                    //   dispatch(
                    //     setExchangeBalanceStep(
                    //       ExchangeBalanceSteps.reviewOrder,
                    //     ),
                    //   )
                    // }}
                    avatar={
                      <CurrencyFlag
                        currency={
                          walletItem?.cashAvailable?.currency as CurrencyCode
                        }
                      />
                    }
                    title={walletItem?.cashAvailable?.currency as CurrencyCode}
                    subtitle={t(
                      CurrencyName[
                        walletItem?.cashAvailable?.currency as CurrencyCode
                      ],
                    )}
                    endText={formatCurrency({
                      amount: walletItem?.cashAvailable?.amount,
                      currency: walletItem?.cashAvailable
                        ?.currency as CurrencyCode,
                    })}
                  />
                )}

                <Spacer preset='tiny' />
              </Fragment>
            )
          })}
        </div>
      </CustomModal.Content>
    </>
  )
}

export default WalletList
