import {
  CompanyPositionData,
  Investments,
  PreOrderData,
  ReducerState,
} from '@interfaces'
import { Card, Spacer, Text } from 'components'
import { OverviewSLTKCard } from './overviewSLTKCard'
import { OrderSummaryCard } from 'components/orderSummaryCard/orderSummaryCard'
import { PreorderSections } from './preorderSections'
import { useState } from 'react'
import { ChevronDown, ChevronUp } from '@assets/icons'
import { t } from 'i18next'

export const PreorderDetail = ({
  investments,
  preorder,
  togglable,
  companyPosition,
}: {
  companyPosition: CompanyPositionData & ReducerState
  investments: Investments
  preorder: PreOrderData
  togglable: boolean
}): JSX.Element => {
  const [opened, setOpened] = useState(!togglable)
  const preorderIsSLTk = (preorder: PreOrderData): boolean => {
    return !!preorder?.condition?.stopType
  }
  return (
    <>
      <Card
        bgColor='canvasSecondaryInitial'
        key={`preorder-card-${preorder?.id}`}
        width='full'
        padding='none'
      >
        {preorderIsSLTk(preorder) ? (
          <>
            <OverviewSLTKCard
              checkout={preorder}
              investments={investments}
              companyPosition={companyPosition}
            />
            <Spacer preset='smaller' />
          </>
        ) : (
          <OrderSummaryCard
            investments={investments}
            checkout={preorder}
            companyPosition={companyPosition}
          />
        )}
        {togglable && (
          <>
            <div className='d-flex justify-end w-100'>
              <button
                className='d-flex cursor-pointer'
                onClick={() => setOpened(!opened)}
              >
                <Text
                  preset='bodyRegular'
                  text={t('buySellFlow.viewDetails')}
                  color='iconPrimaryInitial'
                />
                {opened ? (
                  <ChevronUp color='iconPrimaryInitial' />
                ) : (
                  <ChevronDown color='iconPrimaryInitial' />
                )}
                <Spacer preset='smaller' />
              </button>
            </div>
            <Spacer preset='smaller' />
          </>
        )}
        {opened && (
          <Card width='full' padding='smaller'>
            <PreorderSections
              companyPosition={companyPosition}
              checkout={preorder}
            />
          </Card>
        )}
      </Card>
      <Spacer preset='smaller' />
    </>
  )
}
