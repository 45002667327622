import { createReducer } from '@reduxjs/toolkit'
import {
  clearExchangeBalanceState,
  clearExchangeRates,
  clearFromTransaction,
  clearOrders,
  clearPreOrders,
  fetchExchangeRates,
  fromTransaction,
  getTransferOrder,
  getTransferPreOrder,
  goToPrevStep,
  totalBalanceMode,
  resetIsTotalBalance,
  setExchangeBalanceStep,
  setSelectedCurrency,
  clearSelectedCurrency,
} from './thunk'
import { Forex, ReducerState, WalletTransferOrder } from 'interfaces'
import { ExchangeBalanceSteps } from './exchangeBalance.props'

export interface ExchangeBalanceState {
  prevStep: ExchangeBalanceSteps | null
  currentStep: ExchangeBalanceSteps | null
  stepsHistory: ExchangeBalanceSteps[]
}

interface MoveFundsState extends ReducerState, ExchangeBalanceState {
  preOrder?: WalletTransferOrder
  preOrderLoading?: boolean
  preOrderError?: string
  preOrderSuccess?: boolean
  order?: WalletTransferOrder
  orderLoading?: boolean
  orderError?: string
  orderSuccess?: boolean
  target?: string
  exchanges?: Array<Forex>
  cacheDurationInSeconds?: number
  totalBalanceMode?: boolean
  fromTransaction?: boolean
  selectedCurrency?: string
}

const initialState: MoveFundsState = {
  loading: true,
  error: null,
  selectedCurrency: null,
  order: null,
  orderError: null,
  orderLoading: false,
  orderSuccess: false,
  preOrder: null,
  preOrderLoading: false,
  preOrderError: null,
  preOrderSuccess: false,
  target: null,
  exchanges: [],
  cacheDurationInSeconds: null,
  stepsHistory: [],
  currentStep: null,
  prevStep: null,
  totalBalanceMode: false,
  fromTransaction: false,
}

const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(getTransferPreOrder.pending, (acc) => {
      acc.preOrderLoading = true
      acc.preOrderError = null
      acc.preOrderSuccess = false
    })
    .addCase(getTransferPreOrder.fulfilled, (acc, { payload }) => {
      acc.preOrderLoading = false
      acc.preOrderError = null
      acc.preOrder = payload
      acc.preOrderSuccess = true
    })
    .addCase(getTransferPreOrder.rejected, (acc, { error }) => {
      acc.preOrderSuccess = false
      acc.preOrderLoading = false
      acc.preOrderError = error.message
    })
    .addCase(getTransferOrder.pending, (acc) => {
      acc.orderLoading = true
      acc.orderError = null
      acc.orderSuccess = false
    })
    .addCase(getTransferOrder.fulfilled, (acc, { payload }) => {
      acc.orderLoading = false
      acc.orderError = null
      acc.orderSuccess = true
      acc.order = payload
    })
    .addCase(getTransferOrder.rejected, (acc, { error }) => {
      acc.orderLoading = false
      acc.orderSuccess = false
      acc.orderError = error.message
    })
    .addCase(fetchExchangeRates.fulfilled, (acc, { payload }): void => {
      acc.cacheDurationInSeconds = payload?.cacheDurationInSeconds
      acc.exchanges = payload?.exchanges
      acc.target = payload?.target
      acc.error = null
      acc.loading = false
    })
    .addCase(fetchExchangeRates.pending, (acc): void => {
      acc.loading = true
    })
    .addCase(fetchExchangeRates.rejected, (acc, { error }): void => {
      acc.cacheDurationInSeconds = null
      acc.target = null
      acc.exchanges = []
      acc.loading = false
      acc.error = error?.message
    })
    .addCase(clearExchangeRates, (acc): void => {
      acc.cacheDurationInSeconds = null
      acc.target = null
      acc.exchanges = []
      acc.preOrderSuccess = false
      acc.loading = false
      acc.error = null
    })
    .addCase(clearOrders, (acc): void => {
      acc.order = null
      acc.orderError = null
      acc.orderLoading = false
      acc.orderSuccess = false
    })
    .addCase(clearPreOrders, (acc): void => {
      acc.preOrder = null
      acc.preOrderError = null
      acc.preOrderLoading = false
      acc.preOrderSuccess = false
    })

    .addCase(goToPrevStep, (acc) => {
      acc.stepsHistory.pop()
      acc.prevStep = acc.stepsHistory[acc.stepsHistory?.length - 2]
      acc.currentStep = acc.stepsHistory[acc.stepsHistory?.length - 1]
    })
    .addCase(setExchangeBalanceStep, (acc, { payload }) => {
      acc.prevStep = acc.currentStep
      acc.currentStep = payload
      acc.stepsHistory.push(payload)
    })

    .addCase(clearExchangeBalanceState, (acc) => {
      acc.currentStep = initialState.currentStep
      acc.prevStep = initialState.prevStep
      acc.stepsHistory = initialState.stepsHistory
    })
    .addCase(fromTransaction, (acc): void => {
      acc.fromTransaction = true
    })
    .addCase(clearFromTransaction, (acc): void => {
      acc.fromTransaction = false
    })

    .addCase(totalBalanceMode, (acc): void => {
      acc.totalBalanceMode = true
    })
    .addCase(resetIsTotalBalance, (acc): void => {
      acc.totalBalanceMode = false
    })

    .addCase(setSelectedCurrency, (acc, { payload }) => {
      acc.selectedCurrency = payload
    })
    .addCase(clearSelectedCurrency, (acc): void => {
      acc.selectedCurrency = null
    })
})

export default reducers
