import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const ButtonIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5 6.5V5.5C19.5 3.85 18.15 2.5 16.5 2.5H5.5C3.85 2.5 2.5 3.84 2.5 5.5V9.5C2.5 10.05 2.95 10.5 3.5 10.5C4.05 10.5 4.5 10.05 4.5 9.5V8.33C4.81 8.44 5.15 8.5 5.5 8.5H19.5C20.04 8.5 20.5 8.97 20.5 9.53V19.5C20.5 20.05 20.05 20.5 19.5 20.5H5.5C4.95 20.5 4.5 20.05 4.5 19.5C4.5 18.95 4.05 18.5 3.5 18.5C2.95 18.5 2.5 18.95 2.5 19.5C2.5 21.15 3.85 22.5 5.5 22.5H19.5C21.15 22.5 22.5 21.15 22.5 19.5V9.53C22.5 7.86 21.15 6.5 19.5 6.5ZM17.5 6.5H5.5C4.95 6.5 4.5 6.05 4.5 5.5C4.5 4.95 4.95 4.5 5.5 4.5H16.5C17.05 4.5 17.5 4.95 17.5 5.5V6.5ZM3.5 15.5H9.08L7.79 16.79C7.4 17.18 7.4 17.81 7.79 18.21C8.18 18.6 8.81 18.6 9.21 18.21L12.21 15.21C12.6 14.82 12.6 14.19 12.21 13.79L9.21 10.79C9.01 10.59 8.76 10.5 8.5 10.5C8.24 10.5 7.99 10.6 7.79 10.79C7.4 11.18 7.4 11.81 7.79 12.21L9.08 13.5H3.5C2.95 13.5 2.5 13.95 2.5 14.5C2.5 15.05 2.95 15.5 3.5 15.5ZM19.5 14.5C19.5 15.0523 19.0523 15.5 18.5 15.5C17.9477 15.5 17.5 15.0523 17.5 14.5C17.5 13.9477 17.9477 13.5 18.5 13.5C19.0523 13.5 19.5 13.9477 19.5 14.5Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
