import { EurFlag, GbpFlag, UsdFlag } from '@assets/icons'
import { CurrencyCode } from 'interfaces'

export const CurrencyFlag = ({
  currency,
  size = 40,
}: {
  currency: CurrencyCode
  size?: number
}): JSX.Element => {
  switch (currency) {
    case CurrencyCode.EUR:
      return <EurFlag size={size} />
    case CurrencyCode.GBP:
      return <GbpFlag size={size} />
    default:
      return <UsdFlag size={size} />
  }
}
