import {
  CloseIcon,
  KeepIcon,
  PeaceOfMindIcon,
  SecureGainsIcon,
  StopLossIcon,
  TakeProfitIcon,
} from '@assets/icons'
import {
  Button,
  CustomModal,
  Spacer,
  Text,
  TextColorPresets,
} from '@components'
import { useNavigate, useTranslation } from '@hooks'
import { StopLossTakeProfitIllustration } from '@assets/illustrations'
import { useState } from 'react'
import { ConditionType } from 'interfaces'
import { StopLossTakeProfitFaqs } from '../stopLossTakeProfitFaqs/stopLossTakeProfitFaqs'

interface StopLossTakeProfitPromotionSection {
  icon: React.ReactNode
  title: string
  description: string
  colorTitle?: TextColorPresets
}

const firstSection: StopLossTakeProfitPromotionSection[] = [
  {
    icon: <KeepIcon color='iconNeutralStrong' />,
    title: 'promotions.stopLossTakeProfit.paragraph1.title',
    description: 'promotions.stopLossTakeProfit.paragraph1.desc',
  },
  {
    icon: <SecureGainsIcon color='iconNeutralStrong' />,
    title: 'promotions.stopLossTakeProfit.paragraph2.title',
    description: 'promotions.stopLossTakeProfit.paragraph2.desc',
  },
  {
    icon: <PeaceOfMindIcon size={42} color='iconNeutralStrong' />,
    title: 'promotions.stopLossTakeProfit.paragraph3.title',
    description: 'promotions.stopLossTakeProfit.paragraph3.desc',
  },
]

const secondSection: StopLossTakeProfitPromotionSection[] = [
  {
    icon: <StopLossIcon size={42} color='iconPrimaryInitial' />,
    title: 'buySellFlow.stopLossLiterals.title',
    colorTitle: 'negative',
    description: 'promotions.stopLossTakeProfit.howWorks.stopLoss',
  },
  {
    icon: <TakeProfitIcon size={42} color='iconPrimaryInitial' />,
    title: 'buySellFlow.takeProfitLiterals.title',
    colorTitle: 'positive',
    description: 'promotions.stopLossTakeProfit.howWorks.takeProfit',
  },
]

export const StopLossTakeProfitPromoModal = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const [faqModalProps, setFaqModalProps] = useState<{
    open: boolean
    mode?: ConditionType
  }>({ open: false, mode: ConditionType.STOP_LOSS })

  const openFAQsModal = (mode: ConditionType): void => {
    setFaqModalProps({ open: true, mode })
  }

  return (
    <>
      <CustomModal
        size='big'
        height='fullHeight'
        show
        placeOn='right'
        dismissable
        onClose={close}
      >
        <CustomModal.Header onAction={close}>
          <CloseIcon color='iconPrimaryInitial' size={24} />
        </CustomModal.Header>
        {faqModalProps.open ? (
          <StopLossTakeProfitFaqs
            mode={faqModalProps.mode}
            close={() => setFaqModalProps({ open: false })}
          />
        ) : (
          <>
            <CustomModal.Content>
              <Spacer preset='medium' />
              <div className='d-flex d-flex-col w-100'>
                <div className='d-flex justify-center w-100'>
                  <StopLossTakeProfitIllustration />
                </div>
                <Spacer preset='medium' />

                <Text align='center' preset='headline1'>
                  {t('promotions.stopLossTakeProfit.title.main')}{' '}
                  <span className='negative'>
                    {t('buySellFlow.stopLossLiterals.title').toLowerCase()}
                  </span>{' '}
                  {t('common.and')}{' '}
                  <span className='positive'>
                    {t('buySellFlow.takeProfitLiterals.title').toLowerCase()}
                  </span>
                </Text>
                <Spacer preset='medium' />

                {!!firstSection && firstSection.length > 0
                  ? firstSection.map((item) => {
                      return (
                        <div key={item?.title}>
                          <div className='d-flex align-center'>
                            {item.icon}
                            <Spacer preset='tiny' />
                            <div>
                              <Text preset='bodyBig' text={t(item.title)} />
                              <Spacer preset='tiny' />
                              <Text
                                preset='bodyRegular'
                                color='secondary'
                                text={t(item.description)}
                              />
                            </div>
                          </div>
                          <Spacer preset='medium' />
                        </div>
                      )
                    })
                  : null}

                <Text
                  align='center'
                  preset='headline2'
                  text={t('promotions.stopLossTakeProfit.howWorks.title')}
                />
                <Spacer preset='small' />

                {!!secondSection && secondSection.length > 0
                  ? secondSection.map((item) => {
                      return (
                        <div key={item?.title}>
                          <div className='d-flex align-center'>
                            {item.icon}
                            <Spacer preset='tiny' />
                            <div>
                              <Text
                                preset='bodyBig'
                                color={item.colorTitle}
                                text={t(item.title)}
                              />
                              <Spacer preset='tiny' />
                              <Text
                                preset='bodyRegular'
                                color='secondary'
                                text={t(item.description)}
                              />
                            </div>
                          </div>
                          <Spacer preset='small' />
                        </div>
                      )
                    })
                  : null}

                <Text preset='bodyRegular'>
                  {t('promotions.stopLossTakeProfit.faqLink')}{' '}
                  <span
                    onClick={() => openFAQsModal(ConditionType.STOP_LOSS)}
                    className='link'
                    role='button'
                    tabIndex={0}
                    onKeyDown={(e) =>
                      e.key === 'Enter' &&
                      openFAQsModal(ConditionType.STOP_LOSS)
                    }
                  >
                    {t('promotions.stopLossTakeProfit.stopLossFAQ')}
                  </span>{' '}
                  {t('common.and')}{' '}
                  <span
                    onClick={() => openFAQsModal(ConditionType.TAKE_PROFIT)}
                    className='link'
                    role='button'
                    tabIndex={0}
                    onKeyDown={(e) =>
                      e.key === 'Enter' &&
                      openFAQsModal(ConditionType.TAKE_PROFIT)
                    }
                  >
                    {t('promotions.stopLossTakeProfit.takeProfitFAQ')}
                  </span>
                </Text>
                <Spacer preset='huge' />
              </div>
            </CustomModal.Content>
            <CustomModal.Footer>
              <Button
                text={t('common.startInvesting')}
                onClick={() => {
                  navigate('/discover/all')
                }}
              />
            </CustomModal.Footer>
          </>
        )}
      </CustomModal>
    </>
  )
}
