import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getCurrenciesExchanges,
  getWalletTransferOrder,
  getWalletTransferPreOrder,
  WalletOrder,
  WalletPreOrder,
} from 'services'
import { CurrenciesExchanges, WalletTransferOrder } from '@interfaces'
import { ExchangeBalanceSteps } from './exchangeBalance.props'

export const getTransferPreOrder = createAsyncThunk<
  WalletTransferOrder,
  WalletPreOrder
>('getTransferPreOrder', async (data: WalletPreOrder) => {
  return getWalletTransferPreOrder(data)
})

export const getTransferOrder = createAsyncThunk<
  WalletTransferOrder,
  WalletOrder
>('getTransferOrder', async (id: WalletOrder) => {
  return getWalletTransferOrder(id)
})

export const fetchExchangeRates = createAsyncThunk<
  CurrenciesExchanges,
  { target: string }
>('fetchExchangeRates', ({ target }) => {
  return getCurrenciesExchanges(target)
})

export const clearExchangeRates = createAction('clearExchangeRates')
export const clearPreOrders = createAction('clearPreOrders')
export const clearOrders = createAction('clearOrders')

export const setExchangeBalanceStep = createAction<ExchangeBalanceSteps>(
  'setExchangeBalanceStep',
)

export const goToPrevStep = createAction<ExchangeBalanceSteps>(
  'goToPrevStepExchage',
)
export const clearExchangeBalanceState = createAction(
  'clearExchangeBalanceStates',
)
export const fromTransaction = createAction('fromTransaction')
export const clearFromTransaction = createAction('clearFromTransaction')
export const totalBalanceMode = createAction('totalBalanceMode')
export const resetIsTotalBalance = createAction('resetIsTotalBalance')

export const setSelectedCurrency = createAction<string>('setSelectedCurrency')
export const clearSelectedCurrency = createAction('clearSelectedCurrency')
