import { Button, CustomModal, Divider, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'
import { AssetType } from 'interfaces'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

export const KeyFiguresInfoModal = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const marketSelected: AssetType = useSelector(
    ({ marketReducer }: RootState) => marketReducer?.type,
  )

  const keyFiguresInfo = [
    {
      marketType: AssetType.stock,
      keyFigure: [
        {
          titleTx: 'keyFigures.marketCapTitle',
          subtitleTx: 'keyFigures.marketCapText',
        },
        {
          titleTx: 'keyFigures.perTitle',
          subtitleTx: 'keyFigures.perText',
        },
        {
          titleTx: 'keyFigures.epsTitle',
          subtitleTx: 'keyFigures.epsText',
        },
        {
          titleTx: 'keyFigures.bookValueTitle',
          subtitleTx: 'keyFigures.bookValueText',
        },
        {
          titleTx: 'keyFigures.dividendYieldTitle',
          subtitleTx: 'keyFigures.dividendYieldText',
        },
        {
          titleTx: 'keyFigures.revenueTtmTitle',
          subtitleTx: 'keyFigures.revenueTtmText',
        },
        {
          titleTx: 'keyFigures.betaTitle',
          subtitleTx: 'keyFigures.betaText',
        },
        {
          titleTx: 'keyFigures.ebitdaTitle',
          subtitleTx: 'keyFigures.ebitdaText',
        },
      ],
    },
    {
      marketType: AssetType.crypto,
      keyFigure: [
        {
          titleTx: 'keyFigures.volumeTitle',
          subtitleTx: 'keyFigures.volumeText',
        },
        {
          titleTx: 'keyFigures.maxSupplyTitle',
          subtitleTx: 'keyFigures.maxSupplyText',
        },
        {
          titleTx: 'keyFigures.circulatingSupplyTitle',
          subtitleTx: 'keyFigures.circulatingSupplyText',
        },
      ],
    },
  ]
  const keyFigure = keyFiguresInfo?.find((k) => k.marketType === marketSelected)
  return (
    <CustomModal size='big' show={true} theme='lighter' onClose={close}>
      <CustomModal.Header text={t('keyFigures.title')} />
      <CustomModal.Content>
        {keyFigure?.keyFigure?.map((k, idx) => (
          <div key={`key-figure-${k?.titleTx}`}>
            <Spacer preset='tiny' />
            <Text text={t(k?.titleTx)} preset='headline3' />
            <Spacer preset='tiny' />
            <Text
              text={t(k?.subtitleTx)}
              preset='paragraph2'
              color='secondary'
            />
            <Spacer preset='tiny' />
            {keyFigure?.keyFigure[idx + 1] ? <Divider /> : null}
          </div>
        ))}
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.ok')} onClick={() => close()} />
      </CustomModal.Footer>
    </CustomModal>
  )
}
