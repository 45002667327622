import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const SecureGainsIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 21V19L5 17V21H3ZM7 21V15L9 13V21H7ZM11 21V13L13 15.025V21H11ZM15 21V15.025L17 13.025V21H15ZM19 21V11L21 9V21H19ZM3 15.825V13L10 6L14 10L21 3V5.825L14 12.825L10 8.825L3 15.825Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
