import {
  TimeInForceOption,
  TimeInForceType,
} from '@interfaces/timeInForceOption'
import { backEndFormat } from '@utils/helpers'
import { format } from 'date-fns'

export const timeInForceTransformer = {
  toRequest: (selectedDate: TimeInForceOption<Date>) => {
    if (!selectedDate?.type) {
      console.error(selectedDate)
    }
    switch (selectedDate?.type) {
      case TimeInForceType.TIL_CANCEL:
        return null
      case TimeInForceType.TIL_DAY:
        return format(new Date(), backEndFormat)
      default:
        return format(new Date(selectedDate.value), backEndFormat)
    }
  },
}
