import { Card, Spacer, Text } from 'components'
import { cardListProps } from './cardList.props'

const CardList = ({
  avatar,
  title,
  endText,
  subtitle,
  endSubText,
  onClick,
}: cardListProps): JSX.Element => {
  return (
    <Card width='full' onClick={onClick}>
      <div className='w-100 d-flex align-center justify-space-between'>
        <div className='d-flex align-center'>
          {avatar}
          <Spacer preset='smaller' />
          <div>
            <Text preset='bodyBig' text={title} />
            <Spacer preset='tinyMedium' />
            <Text preset='bodyRegular' text={subtitle} color='secondary' />
          </div>
        </div>
        <div>
          <div className='d-flex align-center'>
            <div className='d-flex-col align-center justify-space-between'>
              <Text preset='bodyBig' text={endText} />
              <Text preset='bodyRegular' text={endSubText} />
            </div>
            {/* <Spacer preset='smallMinus' />
            <ChevronRight color='iconPrimaryInitial' size={24} /> */}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default CardList
