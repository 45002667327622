import './../buy.styles.scss'
import { useTranslation } from '@hooks'
import { BasicLink, CustomModal, Button, Card, Spacer, Text } from '@components'
import { isNil } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { fetchUserProfile } from '@screens/profile/thunk'
import { trackingService } from '@services'
import { OrderData, OrderOperationTypeEnum, OrderTypeEnum } from '@interfaces'
import { resetOrder } from '@store'
import { useNavigate } from '@hooks'
import { BuySellEvents } from '@utils/eventTracker/eventKeys'
import { clearMoneyOperationsState } from 'features/moneyOperations/thunk'
import { Cancel } from '@assets/icons'
import { routes } from 'navigation'
import {
  MarketAndLimitSummaryOrderDetail,
  SLTPSummaryOrderDetail,
} from './summaryOrderDetails'

export function AssetOperationSummary({
  onClose,
}: {
  onClose: () => void
}): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { navigate } = useNavigate()
  const ordersData = useSelector((state: RootState) => state.buySell.ordersData)
  const firstOrder = ordersData?.[0]

  const { buySell, companyStocks } = useSelector((state: RootState) => state)

  const error = !isNil(buySell?.sendOrderError)
  const errorsData = buySell?.errorsData

  const isBuy = (order: OrderData): boolean =>
    order?.type === OrderOperationTypeEnum.buy
  const isSell = (order: OrderData): boolean =>
    order?.type === OrderOperationTypeEnum.sell
  const isStopLossTakeProfitOrder = useMemo((): boolean => {
    return ordersData?.some((p) => !!p?.condition)
  }, [ordersData])

  const isMultiOrder = useMemo(() => {
    return ordersData.length > 1
  }, [ordersData])

  const successHeader = useMemo(() => {
    const isMarket = firstOrder?.method === OrderTypeEnum.market
    const isLimit = firstOrder?.method === OrderTypeEnum.limit

    if (isStopLossTakeProfitOrder) {
      if (isMultiOrder) {
        return t('buySellFlow.confirmationModal.sentStopLossTakeProfitOrders')
      }
      return t('buySellFlow.confirmationModal.sentStopLossTakeProfitOrder')
    }

    if (isBuy(firstOrder) && isMarket) {
      return t('buySellFlow.confirmationModal.sentBuyMarketOrder')
    }

    if (isSell(firstOrder) && isMarket) {
      return t('buySellFlow.confirmationModal.sentSellMarketOrder')
    }

    if (isBuy(firstOrder) && isLimit) {
      return t('buySellFlow.confirmationModal.sentBuyLimitOrder')
    }

    if (isSell(firstOrder) && isLimit) {
      return t('buySellFlow.confirmationModal.sentSellLimitOrder')
    }

    return null
  }, [firstOrder, isMultiOrder, isStopLossTakeProfitOrder, t])

  const errorHeader = t('common.somethingWentWrong')

  const header = error ? errorHeader : successHeader

  const clearOrdersInfo = (): void => {
    dispatch(resetOrder())
  }

  const handleViewInformation = useCallback(() => {
    navigate(`/transactions/${firstOrder?.id}/${firstOrder?.type}`)
  }, [firstOrder?.id, firstOrder?.type, navigate])

  const handleManageOrderNavigation = useCallback(() => {
    navigate(routes.auth.transactionsPending)
  }, [navigate])

  const onPressBasicLink = useCallback(() => {
    clearOrdersInfo()
    if (isMultiOrder) {
      handleManageOrderNavigation()
    } else {
      handleViewInformation()
    }
    dispatch(clearMoneyOperationsState())
  }, [
    clearOrdersInfo,
    dispatch,
    handleManageOrderNavigation,
    handleViewInformation,
    isMultiOrder,
  ])

  useEffect(() => {
    ordersData.forEach((orderData) => {
      trackingService.trackEvent({
        event: BuySellEvents.orderSentFE,
        props: {
          type: orderData?.type,
          method: orderData?.method,
          shares: orderData?.shares,
          ticker: orderData?.asset.ticker,
          id: orderData?.asset.id,
          assetGroup: companyStocks?.type,
          totalCost: orderData?.calculations?.totalCost?.amount,
        },
      })
    })
    dispatch(fetchUserProfile())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <CustomModal.Header linkText={t('common.close')} onClick={onClose} />
      <CustomModal.Content>
        <div className='w-100 h-100 d-flex d-flex-col justify-center'>
          {!error ? (
            <>
              {isStopLossTakeProfitOrder ? (
                <SLTPSummaryOrderDetail
                  header={header}
                  ordersData={ordersData}
                  errorsData={errorsData}
                />
              ) : (
                <MarketAndLimitSummaryOrderDetail
                  header={header}
                  ordersData={ordersData}
                />
              )}
              <Spacer preset='small' />
              <BasicLink onClick={onPressBasicLink} className='paragraph2'>
                {t(
                  `buySellFlow.confirmationModal.${
                    !isMultiOrder ? 'viewInformation' : 'manageOrders'
                  }`,
                )}
              </BasicLink>
            </>
          ) : (
            <Card padding='big'>
              <div className='w-100 d-flex d-flex-col justify-center align-center'>
                <Cancel size={64} color='negative' />
                <Spacer preset='smaller' />
                <Text preset='paragraph5' text={header} align='center' />
                <Spacer preset='small' />
                <Text
                  preset='paragraph2'
                  text={t('buySellFlow.confirmationModal.orderFailedError')}
                  align='center'
                />
              </div>
            </Card>
          )}
        </div>
      </CustomModal.Content>
      <CustomModal.Footer className='d-flex d-flex-col'>
        <Button
          text={t('common.close')}
          type='button'
          size='big'
          onClick={onClose}
        />
      </CustomModal.Footer>
    </>
  )
}
