import { UIIcon, UIIconColorProps } from 'components/utils'

export const WithdrawalIcon = ({
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x={0.25}
        y={0.25}
        width={39.5}
        height={39.5}
        rx={19.75}
        fill='white'
      />
      <rect
        x={0.25}
        y={0.25}
        width={39.5}
        height={39.5}
        rx={19.75}
        stroke='#595B67'
        strokeWidth={0.5}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27 14V13C27 11.35 25.65 10 24 10H13C11.35 10 10 11.34 10 13V17C10 17.55 10.45 18 11 18C11.55 18 12 17.55 12 17V15.83C12.31 15.94 12.65 16 13 16H27C27.54 16 28 16.47 28 17.03V27C28 27.55 27.55 28 27 28H13C12.45 28 12 27.55 12 27C12 26.45 11.55 26 11 26C10.45 26 10 26.45 10 27C10 28.65 11.35 30 13 30H27C28.65 30 30 28.65 30 27V17.03C30 15.36 28.65 14 27 14ZM25 14H13C12.45 14 12 13.55 12 13C12 12.45 12.45 12 13 12H24C24.55 12 25 12.45 25 13V14ZM19.0001 21H13.4201L14.7101 19.71C15.1001 19.32 15.1001 18.69 14.7101 18.29C14.3201 17.9 13.6901 17.9 13.2901 18.29L10.2901 21.29C9.90006 21.68 9.90006 22.31 10.2901 22.71L13.2901 25.71C13.4901 25.91 13.7401 26 14.0001 26C14.2601 26 14.5101 25.9 14.7101 25.71C15.1001 25.32 15.1001 24.69 14.7101 24.29L13.4201 23H19.0001C19.5501 23 20.0001 22.55 20.0001 22C20.0001 21.45 19.5501 21 19.0001 21ZM27 22C27 22.5523 26.5523 23 26 23C25.4477 23 25 22.5523 25 22C25 21.4477 25.4477 21 26 21C26.5523 21 27 21.4477 27 22Z'
        fill='#23282A'
      />
    </svg>
  )
}
