import { CheckIcon, MarketOrderIcon, SellLimitOrderIcon } from '@assets/icons'
import { Avatar, CustomModal, Divider, Spacer, Text } from '@components'
import { useTranslation } from '@hooks/useTranslation'
import { OrderTypeEnum } from 'interfaces'
import React from 'react'

export const ToggleTypeOfOrderModal = ({
  type,
  onChange,
}: {
  type: OrderTypeEnum
  onChange: (type: OrderTypeEnum) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const path = 'stopLossTakeProfit.orderTypes'

  const isMarket = type === OrderTypeEnum.market

  const options = [
    {
      icon: <MarketOrderIcon color='iconNeutralStrong' />,
      title: t(`${path}.market`),
      subtitle: t(`${path}.marketDescription`),
      checked: isMarket,
      value: OrderTypeEnum.market,
    },
    {
      icon: <SellLimitOrderIcon color='iconNeutralStrong' />,
      title: t(`${path}.limit`),
      subtitle: t(`${path}.limitDescription`),
      checked: !isMarket,
      value: OrderTypeEnum.limit,
    },
  ]

  return (
    <CustomModal.OverlayContent>
      <CustomModal show dismissable>
        <CustomModal.Header text={t('buySellFlow.typeOfOrder')} />
        <CustomModal.Content>
          <div>
            <Text
              preset='paragraph2'
              text={t(`stopLossTakeProfit.orderTypeDescription`)}
            />
          </div>
          <Spacer preset='smaller' />
          {options?.map((option, idx) => (
            <React.Fragment key={`orderType-${option?.value}`}>
              <button
                className='d-flex align-center w-100 align-items cursor-pointer'
                onClick={() => onChange(option?.value)}
                tabIndex={idx}
              >
                <Avatar
                  image={option?.icon}
                  bordered
                  roundness='full'
                  size='large'
                />
                <Spacer preset='smaller' />
                <div>
                  <Text
                    preset='paragraph2'
                    text={option?.title}
                    color='primary'
                  />
                  <Spacer preset='extraTiny' />
                  <Text
                    preset='paragraph2'
                    color='tertiary'
                    text={option?.subtitle}
                  />
                </div>
                <Spacer preset='small' />
                <Avatar
                  bgColor={option?.checked ? 'banner1' : 'transparent'}
                  bordered
                  image={option?.checked ? <CheckIcon size={16} /> : null}
                  roundness='full'
                  size='small'
                />
              </button>
              {idx < options?.length - 1 ? (
                <>
                  <Spacer preset='small' />
                  <Divider />
                  <Spacer preset='small' />
                </>
              ) : null}
            </React.Fragment>
          ))}
          <Spacer preset='small' />
        </CustomModal.Content>
      </CustomModal>
    </CustomModal.OverlayContent>
  )
}
