/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactNode, useMemo, useRef, useState, JSX } from 'react'
import './expandableCard.styles.scss'
import { Text, PropsText } from '../text/text'
import { ChevronDown } from '../../assets/icons'
import {
  UIBackgroundColorProps,
  UIBorderColorProps,
  getBgColorClass,
  getBorderColorClass,
  stringBuilder,
} from '../utils'
import { ChevronIconUp } from '@assets/svg'

interface ExpandableCardProps {
  header: string | ReactNode
  children?: string | ReactNode
  initialExpanded?: boolean
  bordered?: boolean
  headerTextProps?: PropsText
  childrenTextProps?: PropsText
}

export const ExpandableCard = ({
  header,
  children,
  initialExpanded = false,
  bgColor = 'secondaryBase',
  borderColor = 'strokeNeutralSoftInitial',
  bordered = true,
  headerTextProps,
  childrenTextProps,
}: ExpandableCardProps &
  UIBackgroundColorProps &
  UIBorderColorProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(initialExpanded)
  const contentRef = useRef<HTMLDivElement>(null)
  const toggleExpand = (): void => {
    setIsExpanded(!isExpanded)
  }

  const classes = useMemo(() => {
    return stringBuilder([
      ['expandable-card-component', true],
      ['--card-component-bordered', !!bordered],
      [getBgColorClass(bgColor), true],
      [getBorderColorClass(borderColor), true],
    ])
  }, [bgColor, borderColor, bordered])

  return (
    <div
      className={`${classes} card ${isExpanded ? 'expanded' : ''}`}
      onClick={toggleExpand}
    >
      <div className='d-flex justify-space-between align-center'>
        {typeof header === 'string' ? (
          <Text
            preset='headline3'
            color='secondary'
            text={header}
            {...headerTextProps}
          />
        ) : (
          header
        )}
        {isExpanded ? (
          <ChevronIconUp color='secondary' />
        ) : (
          <ChevronDown color='secondary' />
        )}
      </div>
      <div
        className='card-content'
        ref={contentRef}
        style={{
          maxHeight: isExpanded
            ? `${contentRef.current?.scrollHeight}px`
            : '0px',
        }}
      >
        {typeof children === 'string' ? (
          <Text
            preset='paragraph2'
            color='secondary'
            text={children}
            {...childrenTextProps}
          />
        ) : (
          children
        )}
      </div>
    </div>
  )
}
