import './../../moneyOperations.styles.scss'
import { useTranslation } from '@hooks'
import {
  CustomModal,
  BasicLink,
  GoBack,
  Divider,
  Spacer,
  Text,
  Notification,
  Button,
} from '@components'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { ImportantIcon, SuccessIcon } from '@assets/icons'
import { NotificationMessageType } from '@interfaces'
import { formatMoney } from '@utils/helpers'

interface TopUpTransferInfoProps {
  prevStep: () => void
  onClose?: () => void
}

export function TopUpTransferInfo({
  prevStep,
  onClose,
}: TopUpTransferInfoProps): JSX.Element {
  const { t } = useTranslation()
  const [showNotification, setShowNotification] = useState(false)

  const cancelActions = (): void => {
    prevStep()
  }

  const { destinationConcept, destinationName, destinationIban, amount } =
    useSelector((state: RootState) => state.manualBankTransfer)

  const copy = t('common.copy')
  const iban = t('bankTransfer.info.iban', {
    iban: destinationIban,
  })
  const beneficiary = t('bankTransfer.info.beneficiary', {
    beneficiary: destinationName,
  })
  const concept = t('bankTransfer.info.concept', {
    concept: destinationConcept,
  })

  const formateAmount = formatMoney(amount?.amount, amount?.currency)
  const amountText = t('bankTransfer.info.amount', {
    amount: formateAmount,
  })

  const onCopy = async (text: string): Promise<void> => {
    await navigator.clipboard.writeText(text)
    setShowNotification(true)
    setTimeout(() => {
      setShowNotification(false)
    }, 4000)
  }

  return (
    <>
      <CustomModal.Header
        text={t('bankTransfer.cashout.manualTransferShort')}
        className='subtitle3 dark--label-title'
      >
        <GoBack goBack={cancelActions} />
      </CustomModal.Header>
      <CustomModal.Content className='modalContent__content pa-3 d-flex d-flex-col'>
        <Text text={t('bankTransfer.info.title')} preset='headline1' />
        <Spacer preset='tinyMinus' />
        <div className='pr-8'>
          <Text
            text={t('bankTransfer.info.subtitle')}
            preset='bodyRegular'
            color='secondary'
          />
        </div>
        <Spacer preset='smaller' />
        <Spacer preset='smaller' />
        <div className='d-flex d-flex-row justify-space-between align-center'>
          <Text text={amountText} preset='paragraph2' />
          <div className='text-right'>
            <BasicLink
              text={copy}
              className='paragraph2'
              onClick={() => onCopy(amountText)}
            />
          </div>
        </div>
        <Spacer preset='smaller' />
        <Divider />
        <Spacer preset='smaller' />
        <div className='d-flex d-flex-row justify-space-between align-center'>
          <Text text={iban} preset='paragraph2' />
          <div className='text-right'>
            <BasicLink
              text={copy}
              className='paragraph2'
              onClick={() => onCopy(destinationIban)}
            />
          </div>
        </div>
        <Spacer preset='smaller' />
        <Divider />
        <Spacer preset='smaller' />

        <div className='d-flex d-flex-row justify-space-between align-center'>
          <Text text={beneficiary} preset='paragraph2' />
          <div className='text-right'>
            <BasicLink
              text={copy}
              className='paragraph2'
              onClick={() => onCopy(destinationName)}
            />
          </div>
        </div>

        <Spacer preset='smaller' />
        <Divider />
        <Spacer preset='smaller' />

        <div className='d-flex d-flex-row justify-space-between align-center'>
          <Text text={concept} preset='paragraph2' />
          <div className='text-right'>
            <BasicLink
              text={copy}
              className='paragraph2'
              onClick={() => onCopy(destinationConcept)}
            />
          </div>
        </div>

        <Spacer preset='medium' />
        <div className='d-flex d-flex-row align-center pa-2 positive--bg'>
          <Spacer preset='medium' />
          <SuccessIcon
            color='iconPositiveInitial'
            secondaryColor='iconNeutralSoft'
            size={24}
          />
          <Spacer preset='tiny' />
          <Text
            text={t('addMoney.orderRegistered')}
            preset='paragraph2'
            color='positive'
          />
        </div>
        <Spacer preset='medium' />
        <div className='w-100'>
          <div className='d-flex d-flex-row align-center'>
            <ImportantIcon color='secondary' />
            <Spacer preset='tiny' />
            <Text
              text={t('common.readCarefully')}
              preset='paragraph2'
              color='secondary'
            />
          </div>
          <Spacer preset='smaller' />
          <Divider />
          <Spacer preset='smaller' />
          <Text
            text={t('bankTransfer.info.mustBeOwner')}
            preset='paragraph3'
            color='tertiary'
          />
          <Spacer preset='smaller' />
          <Divider />
          <Spacer preset='smaller' />
          <Text
            text={t('bankTransfer.info.requireConcept')}
            preset='paragraph3'
            color='tertiary'
          />

          <Spacer preset='smaller' />
          <Divider />
          <Spacer preset='smaller' />
          <Text
            text={t('bankTransfer.info.fetchTime')}
            preset='paragraph3'
            color='tertiary'
          />
        </div>
        {showNotification && (
          <div className='bankTransfer__notification'>
            <Notification
              type={NotificationMessageType.positive}
              id='copied'
              message={t('common.copied')}
              onInit={() => {
                // Do nothing
              }}
              animation='bottom'
            />
          </div>
        )}
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.close')} onClick={onClose} />
      </CustomModal.Footer>
    </>
  )
}
