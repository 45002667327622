import { CurrencyCode, Money, PreOrderData } from 'interfaces'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

export const useBuyingWallet = (checkout: PreOrderData): Money => {
  const userBalanceState = useSelector((state: RootState) => state.userBalance)

  const walletCurrency: CurrencyCode = useMemo(
    () =>
      (checkout?.asset?.currency as CurrencyCode) == CurrencyCode.GBP
        ? CurrencyCode.GBP
        : (checkout?.asset?.currency as CurrencyCode) == CurrencyCode.EUR
        ? CurrencyCode.EUR
        : CurrencyCode.USD,
    [checkout],
  )

  return userBalanceState?.wallet.find(
    (w) => (w?.cashAvailable?.currency as CurrencyCode) === walletCurrency,
  )?.cashAvailable
}
