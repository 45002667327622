import './../../moneyOperations.styles.scss'
import { useTranslation } from '@hooks'
import {
  CustomModal,
  GoBack,
  Text,
  Spacer,
  BasicLink,
  Notification,
  Divider,
} from '@components'
import { NotificationMessageType, ToAmount } from '@interfaces'
import { contactSupport } from '@utils/contactSupport'
import { formatMoney } from '@utils/helpers'
import { CashoutInstructions } from './cashoutTransferInstructions'
import { useState } from 'react'
import { supportEmail } from '@utils/externalUrls'

interface CashoutTransferEmailProps {
  values: ToAmount
  prevStep: () => void
  onClose?: () => void
}

export function CashoutTransferEmail({
  prevStep,
  values,
  onClose,
}: CashoutTransferEmailProps): JSX.Element {
  const { t } = useTranslation()
  const [showNotification, setShowNotification] = useState(false)
  const cancelActions = (): void => {
    prevStep()
  }
  const copy = t('common.copy')

  const emailCopies = {
    to: {
      label: t('accountDeletion.mailConfirmation.structure.to'),
      value: supportEmail,
    },
    subject: {
      label: t('accountDeletion.mailConfirmation.structure.to'),
      value: t('bankTransfer.cashout.mail.subject'),
    },
    body: {
      label: t('accountDeletion.mailConfirmation.structure.body'),
      value: '',
    },
  }

  const computeMailBody = (): string => {
    return `${t('bankTransfer.cashout.mail.greeting')}\n${t(
      'bankTransfer.cashout.mail.explanation',
    )}\n\n${t('bankTransfer.cashout.mail.amount')}${formatMoney(
      values?.amount,
      values?.currency,
    )}
    \n${t('bankTransfer.cashout.mail.myIBAN')}\n`
  }

  const onCopy = async (text: string): Promise<void> => {
    await navigator.clipboard.writeText(text)
    setShowNotification(true)
    setTimeout(() => {
      setShowNotification(false)
    }, 4000)
  }

  return (
    <>
      <CustomModal.Header
        text={t('cashout.title')}
        linkText={t('common.close')}
        className='subtitle3 dark--label-title'
        onClick={onClose}
      >
        <GoBack goBack={cancelActions} />
      </CustomModal.Header>
      <CustomModal.Content className='d-flex d-flex-col'>
        <Text text={t('bankTransfer.cashout.sendLink')} preset='headline1' />
        <div>
          <Spacer preset='smaller' />
        </div>
        <Text
          text={t('bankTransfer.cashout.subtitle')}
          preset='paragraph2'
          color='tertiary'
        />
        <div className='text-left'>
          <Spacer preset='mediumPlus' />
          <a
            className='paragraph2 text-decoration-underline'
            href={contactSupport(
              t('bankTransfer.cashout.mail.subject'),
              computeMailBody(),
            )}
            target='_blank'
            rel='noreferrer'
          >
            {t('accountDeletion.mailConfirmation.link')}
          </a>
          <Spacer preset='mediumPlus' />
        </div>

        <div className='bankTransfer__separator'>
          <Text text={t('common.or').toLowerCase()} preset='paragraph2' />
          <Spacer preset='mediumPlus' />
        </div>
        <div>
          <Text preset='headline2' text={t('bankTransfer.cashout.sendTitle')} />
          <Spacer preset='large' />
        </div>
        <div>
          <div className='d-flex d-flex-row justify-space-between align-center'>
            <Text
              text={emailCopies.to.label}
              preset='paragraph2'
              color='tertiary'
            >
              <span className='bankTransfer bankTransfer--mailText'>
                {' '}
                {` ${emailCopies.to.value}`}
              </span>
            </Text>
            <div className='text-right'>
              <BasicLink
                text={copy}
                className='paragraph2'
                onClick={() => onCopy(emailCopies.to.value)}
              />
            </div>
          </div>
          <Spacer preset='small' />
          <Divider />
          <Spacer preset='small' />
          <div className='d-flex d-flex-row justify-space-between align-center'>
            <Text
              text={emailCopies.subject.label}
              preset='paragraph2'
              color='tertiary'
            >
              <span className='bankTransfer bankTransfer--mailText'>{` ${emailCopies.subject.value}`}</span>
            </Text>
            <div className='text-right'>
              <BasicLink
                text={copy}
                className='paragraph2'
                onClick={() => onCopy(emailCopies.subject.value)}
              />
            </div>
          </div>
          <Spacer preset='small' />
          <Divider />
          <Spacer preset='small' />
          <div className='d-flex d-flex-row justify-space-between'>
            <Text
              text={emailCopies.body.label}
              preset='paragraph2'
              color='tertiary'
            >
              <span className='d-flex d-flex-col'>
                <Spacer preset='small' />
                <span className='bankTransfer bankTransfer--mailText'>
                  {t('bankTransfer.cashout.mail.greeting')}
                </span>
                <span className='bankTransfer bankTransfer--mailText'>
                  {t('bankTransfer.cashout.mail.explanation')}
                </span>
                <Spacer preset='small' />
                <span className='bankTransfer bankTransfer--mailText'>
                  {t('bankTransfer.cashout.mail.amount')}
                </span>
                <Spacer preset='small' />
                <span className='bankTransfer bankTransfer--mailText'>
                  {t('bankTransfer.cashout.mail.myIBAN')}
                </span>
              </span>
            </Text>
            <div className='text-right'>
              <BasicLink
                text={copy}
                className='paragraph2'
                onClick={() => onCopy(computeMailBody())}
              />
            </div>
          </div>
          <Spacer preset='small' />
        </div>
        <CashoutInstructions />
        {showNotification && (
          <div className='bankTransfer__notification'>
            <Notification
              type={NotificationMessageType.positive}
              id='copied'
              message={t('common.copied')}
              onInit={() => {
                // Do nothing
              }}
              animation='bottom'
            />
          </div>
        )}
      </CustomModal.Content>
      <CustomModal.Footer />
    </>
  )
}
