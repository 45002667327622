import {
  OrderOperationTypeEnum,
  PreOrderRequest,
  StopLossTakeProfitOperation,
} from '@interfaces'
import { timeInForceTransformer } from '../cards/stopLossTakeProfitCard/utils/timeInForceTransformer'

export const SLTKOperationToPreorder = (
  operation: StopLossTakeProfitOperation,
): PreOrderRequest | null => {
  return {
    assetId: operation?.assetId,
    method: operation?.method,
    shares: operation?.sharesAmount,
    type: OrderOperationTypeEnum.sell, // SL & TK only apply for sell operations
    expirationDate: timeInForceTransformer.toRequest(operation.expirationDate), // for limit order and SL & TK
    price: operation?.limitPrice, // only for limit order and SL & TK type limit
    condition: {
      stopPercentage: operation?.stopPercentage,
      stopPrice: operation?.stopPrice,
      stopType: operation?.type,
      trailAmount: operation?.trailAmount,
      trailPercentage: operation?.trailPercentage,
      activationPrice:
        !!operation?.trailPercentage || !!operation?.trailAmount
          ? operation?.activationPrice
          : null,
    },
  }
}
