import { notDefined } from '@utils/notDefined'

export interface SLAndTPParams {
  assetPrice: number
  units: number
  stopPrice?: number
  activationPrice?: number
  trailPercentage?: number
  stopPercentage?: number
  trailAmount?: number
  limitPrice?: number
  type: 'STOP_LOSS' | 'TAKE_PROFIT'
}

export interface SLAndTPResult {
  estimatedTotal: number
  stopPrice: number
  activationPrice: number
  profitsOrLosses: number
}

export function calculateSLAndTPValues(params: SLAndTPParams): SLAndTPResult {
  const units = Number(params.units),
    assetPrice = Number(params?.assetPrice),
    type = params?.type,
    stopPercentage = Number(params?.stopPercentage),
    trailPercentage = Number(params?.trailPercentage),
    activationPrice = Number(params?.activationPrice || params?.assetPrice),
    trailAmount = Number(params?.trailAmount),
    limitPrice = Number(params?.limitPrice)

  let stopPrice: number
  if (trailAmount > 0) {
    stopPrice = activationPrice - trailAmount
  } else if (stopPercentage) {
    stopPrice =
      type === 'STOP_LOSS'
        ? activationPrice * (1 - stopPercentage / 100)
        : activationPrice * (1 + stopPercentage / 100)
  } else if (trailPercentage) {
    stopPrice = activationPrice * (1 - trailPercentage / 100)
  } else {
    stopPrice = Number(params?.stopPrice)
  }

  const invalidPercentage = notDefined(stopPercentage) || stopPercentage === 0

  const invalidAssetPrice = notDefined(assetPrice) || assetPrice === 0
  const invalidExecutionPrice = notDefined(stopPrice) || stopPrice === 0

  const noPossibleCalculation =
    invalidAssetPrice || (invalidExecutionPrice && invalidPercentage)

  if (noPossibleCalculation)
    return {
      activationPrice: null,
      estimatedTotal: null,
      stopPrice: null,
      profitsOrLosses: null,
    }

  const basePrice = limitPrice > 0 ? limitPrice : stopPrice

  const initialValue = units * assetPrice
  const estimatedTotal: number = units * basePrice // estimated proceeds || estimated profits
  const profitsOrLosses = units * basePrice - initialValue

  return {
    estimatedTotal,
    stopPrice,
    profitsOrLosses,
    activationPrice: Number(activationPrice),
  }
}
