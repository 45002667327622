import { useMemo, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BannerSlider, BannerProps } from '@components'
import { Promotion } from '@interfaces'
import { setPromotionInactive } from './thunk'
import { PromoBannerProps } from './promotions.props'
import AssessmentModal, {
  AssessmentModalMode,
} from '@screens/discover/components/assessmentModal/assessmentModal'
import { useNavigate } from '@hooks'
import { routes } from '../../../navigation/routes'
import {
  BuySellExplanation,
  OrdersWhenMarketClosedPromoModal,
  PortfolioPromotion,
  SearchImprovedModal,
  StopLossTakeProfitPromoModal,
} from '@shared/modals'

export const Promotions = ({
  promotionsData,
}: PromoBannerProps): JSX.Element => {
  const dispatch = useDispatch()
  const { navigate } = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [modal, setModal] = useState(null)

  const handleOnPress = useCallback(
    (clickRoute: string, url?: string) => {
      if (url) {
        window.open(url)
        return
      }
      setShowModal(true)
      switch (clickRoute) {
        case 'blackRockIntro':
          setModal(
            <AssessmentModal
              onClose={() => {
                setShowModal(false)
              }}
              step={AssessmentModalMode.presentation}
            />,
          )
          break

        case 'transactionsHistoryProfile':
          navigate(routes.auth.transactionsExecuted)
          break

        case 'dynamicSearchInfo':
          setModal(
            <SearchImprovedModal
              close={() => {
                setShowModal(false)
              }}
            />,
          )
          break

        case 'search':
          navigate(routes.auth.search)
          break

        case 'collections':
          navigate(routes.auth.collections)
          break
        case 'amountSelectorInfo':
          setModal(
            <BuySellExplanation
              tryProduct={() => {
                navigate(routes.auth.search)
                setShowModal(false)
              }}
              close={() => {
                setShowModal(false)
              }}
            />,
          )
          break
        case 'marketCloseInfo':
          setModal(
            <OrdersWhenMarketClosedPromoModal
              close={() => {
                setShowModal(false)
              }}
            />,
          )
          break
        case 'assetAllocationChartPromo':
          setModal(
            <PortfolioPromotion
              close={() => {
                setShowModal(false)
              }}
            />,
          )
          break
        case 'stopLossTakeProfitInfoPromo':
          setModal(
            <StopLossTakeProfitPromoModal
              close={() => {
                setShowModal(false)
              }}
            />,
          )
          break

        default:
          setShowModal(false)
          setModal(null)
      }
    },
    [navigate],
  )

  const { promotions } = promotionsData

  const formattedPromotions: BannerProps[] = useMemo(
    () =>
      promotions
        .filter((promo) => promo.active && !promo.notInterested)
        .map(
          ({
            icon,
            logo,
            highlighted,
            title,
            subtitle,
            clickRoute,
            url,
            id,
          }: Promotion) => {
            return {
              id,
              icon,
              logo,
              highlighted,
              title,
              subtitle,
              onPress: () => handleOnPress(clickRoute, url),
              menu: {
                learnMoreAction: () => handleOnPress(clickRoute, url),
                remindLaterAction: () => dispatch(setPromotionInactive(id)),
                notInterestedAction: () => dispatch(setPromotionInactive(id)),
              },
            }
          },
        ),
    [dispatch, handleOnPress, promotions],
  )

  return (
    <>
      <BannerSlider banners={formattedPromotions} />
      {showModal && modal}
    </>
  )
}
