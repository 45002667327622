import '../changePaymentMethod/changePaymentMethod.style.scss'
import { useTranslation } from 'react-i18next'
import { CustomModal, Divider, InputRadio } from '@components'
import { Balance, CurrencyCode, CurrencyName } from '@interfaces'
import { useSelector } from 'react-redux'
import { RootState } from '@store'
import { CurrencyFlag } from 'components/currencyFlag/currencyFlag'

export function ChangeCurrency(props: {
  show: boolean
  onClose: () => void
  activeCurrencyDetails: CurrencyCode
  setCurrencyDetails: (data: Balance) => void
}): JSX.Element {
  const { t } = useTranslation()
  const { userBalance } = useSelector((state: RootState) => state)
  const { wallet } = userBalance

  const handleChange = (selectedCurrencyDetails: Balance): void => {
    props.setCurrencyDetails(selectedCurrencyDetails)
    props.onClose()
  }

  return (
    <CustomModal
      show={props.show}
      theme='lighter'
      size='big'
      dismissable
      onClose={props?.onClose}
    >
      <CustomModal.Header
        linkText={t('common.cancel')}
        text={t('currency.chooseCurrency')}
        onClick={props.onClose}
      />
      <Divider />
      <CustomModal.Content className='d-flex-col changePayment'>
        <div className='d-flex d-flex-col bg-secondary-base w-100 align-self-start'>
          {wallet?.map((el, i) => {
            return (
              <InputRadio
                key={`bank_${i}`}
                containerStyle='infoCard__content__item changePayment__item paragraph2'
                defaultChecked={
                  props.activeCurrencyDetails ===
                  (el.cashActive.currency as CurrencyCode)
                }
                onChange={() => handleChange(el)}
                id={`bank_${i}`}
                value={el.cashAvailable.currency}
                name='account'
                textContainerStyle='flex d-flex-row'
              >
                <label htmlFor={`bank_${i}`} className='d-flex align-center'>
                  <div className='logo mr-2'>
                    <CurrencyFlag
                      currency={el.cashAvailable.currency as CurrencyCode}
                    />
                  </div>
                  <div className='payments__screen__item__content__titles text-left'>
                    <p>{el.cashAvailable.currency}</p>
                    <small>{t(CurrencyName[el.cashAvailable.currency])}</small>
                  </div>
                </label>
              </InputRadio>
            )
          })}
        </div>
      </CustomModal.Content>
    </CustomModal>
  )
}
