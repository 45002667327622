import { CompanyStocksState } from '@screens/company/reducers'
import { IChartData } from '../interface/tradeView.interface'
import { tradeViewColorTheme } from '../tradeViewChartContainer/tradeViewChartContainer.constant'

export const chartDataConvertor = (
  arrData: CompanyStocksState,
): IChartData[] => {
  let bars = []
  arrData.chart.forEach((element) => {
    bars = [
      ...bars,
      {
        time: Date.parse(element.dateTime.toString()) / 1000,
        low: element?.low?.amount,
        high: element?.high?.amount,
        open: element?.open?.amount,
        close: element?.close?.amount,
        volume: element?.volume,
      },
    ]
  })

  bars = bars.sort((a, b) => a.time - b.time)
  return bars
}

export const getTimeFrame = (bs) => {
  if (bs.length === 0) return null
  return {
    from: bs[0].time,
    to: bs[bs.length - 1].time,
  }
}

export const generateSymbol = (exchange, fromSymbol, toSymbol) => {
  const short = `${fromSymbol}/${toSymbol}`
  return {
    short,
    full: `${exchange}:${short}`,
  }
}

export const parseFullSymbol = (fullSymbol) => {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/)
  if (!match) {
    return null
  }

  return {
    exchange: match[1],
    fromSymbol: match[2],
    toSymbol: match[3],
  }
}

export const configurationData = {
  has_intraday: true,
  supported_resolutions: ['5'],
  intraday_multipliers: ['1', '2', '5'],
  interval: '5',
  default_resolution: '5',
  exchanges: [],
  symbolsTypes: [],
}

const chartInterval = (period: string): string => {
  return period === 'DAY'
    ? '5'
    : period === 'WEEK'
    ? '15'
    : period === 'MONTH'
    ? '60'
    : period === 'YEAR'
    ? '1D'
    : '1W'
}

export type SymbolInfo = {
  ticker?: string
  symbol: string
  companyName?: string
  type?: string
  market?: string
  period?: string
}

const supported_resolutions = (stockData) => {
  return stockData.symbol == 'Portfolio'
    ? ['1D']
    : [
        stockData.period == 'DAY'
          ? '5'
          : stockData.period == 'WEEK'
          ? '15'
          : stockData.period == 'MONTH'
          ? '60'
          : stockData.period == 'YEAR'
          ? '1D'
          : '1W',
      ]
}

export const symbolInfoMapper = (stockData: SymbolInfo) => {
  return {
    ticker: stockData.ticker,
    name: stockData.symbol,
    description: stockData.companyName,
    type: stockData.type,
    session: '24x7',
    timezone: 'Etc/UTC',
    exchange: stockData.market,
    minmov: 0.01,
    pricescale: 1,
    has_intraday: true,
    supported_resolutions: supported_resolutions(stockData),
    intraday_multipliers:
      stockData.symbol == 'Portfolio'
        ? []
        : [
            stockData.period == 'DAY'
              ? '5'
              : stockData.period == 'WEEK'
              ? '15'
              : '60',
          ],
    interval:
      stockData.symbol == 'Portfolio'
        ? '1D'
        : chartInterval(stockData.period as string),
    volume_precision: 2,
    data_status: 'streaming',
  }
}

export const getPriceValuesSortedByValue = (
  data: IChartData[],
): IChartData[] => {
  const sortedData = [...data]
  if (sortedData.length === 0) return null
  return sortedData?.sort((a, b) => a?.close - b?.close)
}

export const getTradingViewTheme = () => {
  const mode = document
    .getElementsByTagName('body')[0]
    .getAttribute('data-theme')
  const theme = tradeViewColorTheme[mode]

  return { mode, theme }
}
