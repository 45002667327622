import { ChevronUp } from '@assets/icons'
import { Card } from 'components/card/card'
import { CurrencyFlag } from 'components/currencyFlag/currencyFlag'
import { Spacer } from 'components/spacer'
import { Text } from 'components/text/text'
import { CurrencyCode, Money } from 'interfaces'
import { useBuyingWallet } from './useBuyingWallet.hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useState } from 'react'
import { Show } from 'components/show/show'
import { BalanceWalletModal } from '@shared/modals'
import { useTranslation } from 'react-i18next'

const PreOrderExchange = ({
  available,
}: {
  available: string
}): JSX.Element => {
  const { t } = useTranslation()
  const [showBalanceModal, setShowBalanceModal] = useState<boolean>(false)
  const [destinationWallet, setDestinationWallet] = useState<Money>(null)
  const { buySell, userBalance } = useSelector((state: RootState) => state)
  const checkout = buySell?.multiCheckout[0]
  const enoughCashForOrder = checkout?.enoughCashForOrder
  const buySellWallet = useBuyingWallet(checkout)

  const availableHandler = (): void => {
    setDestinationWallet(
      userBalance?.wallet?.find(
        (val) => val?.cashAvailable?.currency === checkout?.asset?.currency,
      )?.cashAvailable,
    )
    setShowBalanceModal(true)
  }

  return (
    <>
      <Card
        onClick={availableHandler}
        aria-hidden
        bgColor={
          enoughCashForOrder
            ? 'actionPrimarySubtleInitial'
            : 'actionNegetiveInitial'
        }
        padding='none'
      >
        <div className='w-100 d-flex align-center justify-between ml-1 mr-1'>
          <CurrencyFlag
            size={24}
            currency={buySellWallet?.currency as CurrencyCode}
          />
          <Spacer preset='tiny' />
          <Text
            preset='caption'
            color={enoughCashForOrder ? 'primarySubtleInitial' : 'negative'}
            text={t('buySellFlow.balanceInfoCard', {
              currency: buySellWallet?.currency,
              amount: available,
            })}
          />
          <Spacer preset='tiny' />
          <ChevronUp
            color={enoughCashForOrder ? 'iconPrimaryInitial' : 'negative'}
            size={38}
          />
        </div>
      </Card>
      <Show when={showBalanceModal}>
        <BalanceWalletModal
          destinationWallet={destinationWallet}
          close={() => setShowBalanceModal(false)}
        />
      </Show>
    </>
  )
}

export default PreOrderExchange
