import './summaryCard.styles.scss'
import { SLTPSummaryCardProps } from './sltpSummaryCard.props'
import { Spacer } from 'components/spacer'
import { Divider } from 'components/divider/divider'

export function SLTPSummaryCard({
  id,
  data,
}: SLTPSummaryCardProps): JSX.Element {
  return (
    <div className='summaryCard' key={`summaryCard-${id}`}>
      <div>
        {data?.map((row, idx) => (
          <div key={`summaryCard-${id}-${idx}`}>
            <div className='sltpSummaryRow'>
              <div className='d-flex justify-space-between'>
                <div>
                  <p className='paragraph2'>{row?.firstLabel}</p>{' '}
                  {row?.firstLabelChildren}
                </div>
                <div>
                  <p className='sltpSummaryRow__value paragraph2'>
                    {row?.firstValue}
                  </p>{' '}
                  {row?.firstValueChildren}
                </div>
              </div>

              <div className='d-flex justify-space-between'>
                <div>
                  <p className='paragraph2'>{row?.secondLabel}</p>{' '}
                  {row?.secondLabelChildren}
                </div>
                <div>
                  <p className='sltpSummaryRow__value paragraph2'>
                    {row?.secondValue}
                  </p>{' '}
                  {row?.secondValueChildren}
                </div>
              </div>
            </div>
            {idx < data.length - 1 && (
              <>
                <Spacer preset='smaller' />
                <Divider />
                <Spacer preset='smaller' />
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
