export type StyleConditionTuple = [string, boolean]

/**
 * Utility to build big strings based on conditionals. Its perfect to create class prop strings
 * @param styles
 * @returns
 */
export const stringBuilder = (styles: Array<StyleConditionTuple>): string => {
  return styles.reduce((acc: string, i: StyleConditionTuple, idx: number) => {
    const [styleClass, value] = i
    const space = idx === 0 ? '' : ' '
    return value ? `${acc}${space}${styleClass}` : acc
  }, '')
}
export const isNil = (value: unknown): boolean => {
  return value === null || value === undefined
}
export const generateEtcGmtTimezone = (): string => {
  const d = new Date()
  const offsetMinutes = d.getTimezoneOffset()

  const totalMinutes = Math.abs(offsetMinutes)
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  const sign = offsetMinutes < 0 ? '-' : '+'

  const formattedOffset =
    minutes > 0 ? `${hours}:${String(minutes).padStart(2, '0')}` : `${hours}`
  return `Etc/GMT${sign}${formattedOffset}`
}
