import { Money } from './money'
import { CommissionsData, Condition, OrderOperationTypeEnum } from './orderData'
import { OrderTypeEnum } from './orderType'
import { NotCancellableReason } from './transaction'

export enum OrderTransactionStatusEnum {
  pending = 'PENDING',
  processing = 'PROCESSING',
  placed = 'PLACED',
  completed = 'COMPLETED',
  error = 'ERROR',
  cancelled = 'CANCELLED',
  draft = 'DRAFT',
  quoted = 'QUOTED',
}

export enum OrderTransactionChildStatusEnum {
  partiallyCompleted = 'PARTIALLY_COMPLETED',
  pending = 'PENDING',
  completed = 'COMPLETED',
  error = 'ERROR',
  cancelled = 'CANCELLED',
  waitingActivation = 'ORDER_WAITING_ACTIVATION',
  waitingPreActivation = 'ORDER_WAITING_PRE_ACTIVATION',
}

export enum OrderCancellationReason {
  customerCancelled = 'CUSTOMER_CANCELLED',
  expired = 'EXPIRED',
  unknown = 'UNKNOWN',
}

export interface OrderCalculations {
  taxes: Money
  fees: Money
  totalCost: Money
  netAmount: Money
  netAmountInPortfoliosCurrency: Money
  requiredNetAmount: Money
  grossAmount: Money
  contingenciesBuffer: number
  contingenciesBufferAmount: Money
  lockedCash: Money
}

export interface OrderAsset {
  id: string
  name: string
  isin: string
  ticker: string
  market: string
  logoUrl: string
  currency: string
}

export interface OrderTransaction {
  id: string
  type: OrderOperationTypeEnum
  method: OrderTypeEnum
  status: OrderTransactionStatusEnum
  statusText: string
  childStatus: OrderTransactionChildStatusEnum
  childStatusText: string
  reason: OrderCancellationReason
  reasonText: string
  creationDate: string
  completedDate: string
  expirationDate: string
  estimatedPrice: Money
  executedPrice: Money
  shares: {
    executed: number
    requested: number
  }
  exchangeRateAverage: number
  calculations: OrderCalculations
  asset: OrderAsset
  documentId?: string
  isCancellable: boolean
  notCancellableReason: NotCancellableReason
  commissions: CommissionsData
  condition?: Condition
}

export enum TransactionFiltersStatusParam {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
}
