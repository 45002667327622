import { useTranslation } from '@hooks'
import {
  AssetOperationTab,
  OrderOperationTypeEnum,
  OrderTypeEnum,
} from '@interfaces'
import { RootState } from '@store'
import { TabItem } from 'components/tabBar/tabBar.props'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useOrderTypeTabs = ({
  type,
  onDisabledTabClick,
}: {
  type: OrderOperationTypeEnum
  onDisabledTabClick: (id: string) => void
}): TabItem[] => {
  const { t } = useTranslation()

  const { config, buySell, companyStocks } = useSelector(
    (state: RootState) => state,
  )

  return useMemo(() => {
    const tabs: TabItem[] = []

    if (buySell?.orderTypes?.includes(OrderTypeEnum.market)) {
      tabs.push({
        title: t('buySellFlow.limitOrders.market'),
        id: AssetOperationTab.MARKET,
        disabled: companyStocks?.marketStatus?.status === 'CLOSED',
        onDisabledTabClick: () => onDisabledTabClick(OrderTypeEnum.market),
      })
    }

    if (
      config?.features?.limitOrders &&
      buySell?.orderTypes?.includes(OrderTypeEnum.limit)
    ) {
      tabs.push({
        title: t('buySellFlow.limitOrders.limit'),
        id: AssetOperationTab.LIMIT,
      })
    }

    if (type === OrderOperationTypeEnum.sell) {
      tabs.push({
        title: `${t('buySellFlow.stopLossLiterals.title')}/${t(
          'buySellFlow.takeProfitLiterals.title',
        )}`,
        id: AssetOperationTab.SL_TK,
      })
    }

    return tabs
  }, [
    type,
    buySell?.orderTypes,
    companyStocks?.marketStatus?.status,
    config?.features?.limitOrders,
    onDisabledTabClick,
    t,
  ])
}
