import {
  LimitPriceRangeRequest,
  LimitPriceRangeResponse,
  OrderRequest,
  CompanyOperationsAllowed,
  PreOrderData,
  PreOrderRequest,
  UserBankInfo,
  MultipleOrdersResponse,
} from '@interfaces'
import { api } from '@utils/api/api'

export const getAllowedOperationsTypes = (
  market: string,
): Promise<CompanyOperationsAllowed> => {
  return api.get(`/api/v1/orders/markets/${market}`)
}

export const getLimitOrderPriceLimits = ({
  type,
  assetId,
}: LimitPriceRangeRequest): Promise<LimitPriceRangeResponse> => {
  return api.post('/api/v1/orders/markets/price-limit', { type, assetId })
}

export const requestOrder = (
  order: OrderRequest,
): Promise<MultipleOrdersResponse> => {
  return api.post('/api/v2/orders/multiple', order)
}

export const multiOrderCheckout = (
  preorder: PreOrderRequest[],
): Promise<PreOrderData[]> => {
  return api.post('/api/v3/pre-orders/multiple', preorder)
}

export const userBankInfo = (): Promise<UserBankInfo> =>
  api.get('api/v1/customers/me/bank-info')
