import { SuccessIcon } from '@assets/icons'
import { formatCurrency } from '@utils/helpers'
import { Card, Spacer, SummaryCard, SummaryRowProps, Text } from 'components'
import { t } from 'i18next'
import { OrderData, OrderOperationTypeEnum, OrderTypeEnum } from 'interfaces'
import { useMemo } from 'react'
import { MarketAndLimitSummaryOrderDetailProps } from './marketAndLimitSummaryOrderDetail.props'

export function MarketAndLimitSummaryOrderDetail({
  header,
  ordersData,
}: MarketAndLimitSummaryOrderDetailProps): JSX.Element {
  const firstOrder = ordersData[0]
  const isLimit = firstOrder?.method === OrderTypeEnum.limit

  const isBuy = (order: OrderData): boolean =>
    order?.type === OrderOperationTypeEnum.buy

  const marketOrderRows: SummaryRowProps[] = useMemo(() => {
    return ordersData
      .map((orderData) => [
        {
          label: t('buySellFlow.confirmationModal.marketPrice'),
          value: `1 ${orderData?.asset?.ticker} = ${formatCurrency(
            orderData?.estimatedPrice,
          )}`,
        },
        {
          labelChildren: (
            <p className='paragraph3'>{t('buySellFlow.summary.totalCost')}</p>
          ),
          valueChildren: (
            <p className='paragraph3'>
              {`${formatCurrency(
                isBuy(orderData)
                  ? orderData?.calculations?.lockedCash
                  : orderData?.calculations?.totalCost,
              )}`}
            </p>
          ),
        },
      ])
      .flat()
  }, [ordersData])

  const limitOrderRows: SummaryRowProps[] = useMemo(() => {
    return ordersData
      .map((orderData: OrderData) => [
        {
          label: t('buySellFlow.confirmationModal.limitPrice'),
          value: `1 ${orderData?.asset?.ticker} = ${formatCurrency(
            orderData?.estimatedPrice,
          )}`,
        },
        {
          labelChildren: (
            <p className='subtitle3'>
              {isBuy(orderData)
                ? t('buySellFlow.confirmationModal.totalOnHold')
                : t('buySellFlow.confirmationModal.estimatedEarnings')}
            </p>
          ),
          valueChildren: (
            <p className='subtitle3'>
              {`${formatCurrency(
                isBuy(orderData)
                  ? orderData?.calculations?.lockedCash
                  : orderData?.calculations?.totalCost,
              )}`}
            </p>
          ),
        },
      ])
      .flat()
  }, [ordersData])

  const successDetails = useMemo(() => {
    return isLimit ? (
      <>
        <SummaryCard
          id={`limitOrderData-${firstOrder?.id}`}
          data={limitOrderRows}
        />
        {isBuy && (
          <p className='text-left paragraph3 secondary--text pb-2 px-2'>
            {t('buySellFlow.totalOnHoldDisclaimer')}
          </p>
        )}
      </>
    ) : (
      <SummaryCard
        id={`marketOrderData-${firstOrder?.id}`}
        data={marketOrderRows}
      />
    )
  }, [isLimit, firstOrder?.id, limitOrderRows, marketOrderRows])

  return (
    <Card padding='tiny' width='full'>
      <div className='w-100 d-flex d-flex-col justify-center align-center'>
        <Spacer preset='medium' />
        <SuccessIcon
          size={64}
          color='positive'
          secondaryColor='iconNeutralStrongInverted'
        />
        <Spacer preset='smaller' />
        <Text preset='paragraph5' text={header} align='center' />
        <Spacer preset='tinyPlus' />
        <div className='headline1 primary--text mb-3'>
          <p className='d-inline positive--text'>{firstOrder?.shares} </p>
          {firstOrder?.asset?.ticker}
        </div>
        <Spacer preset='tinyPlus' />
      </div>
      {successDetails}
    </Card>
  )
}
