import { OrderTypeEnum, PreOrderRequest } from 'interfaces'

/**
 * Returns false when invalid preorders
 * A preorder that is null means the form is not active/toggled and the user won't used
 * @param preorders
 * @param assetShares
 * @returns
 */
export const SLTKMultiplePreorderValidation = (
  preorders: Array<PreOrderRequest | null>,
  assetShares: number,
): boolean => {
  if (assetShares === null || assetShares === undefined) return false
  const orders = preorders?.filter((p) => !!p)
  if (orders?.length === 0) return false
  return preorders?.every((p) => validatePreOrder(p, assetShares))
}

const validatePreOrder = (
  preorder: PreOrderRequest | null,
  assetShares: number,
): boolean => {
  if (preorder === null) return true

  const hasValidShares = preorder.shares > 0 && preorder.shares <= assetShares
  const hasValidCondition = validateCondition(preorder.condition)
  const hasValidLimitPrice =
    preorder.method !== OrderTypeEnum.limit || preorder.price > 0

  return (
    preorder.assetId &&
    hasValidShares &&
    hasValidCondition &&
    hasValidLimitPrice
  )
}

const validateCondition = (
  condition: PreOrderRequest['condition'],
): boolean => {
  if (!condition) return false

  const hasValidPricesOrPercentages =
    condition.stopPrice > 0 ||
    condition.stopPercentage > 0 ||
    condition.trailAmount > 0 ||
    condition.trailPercentage > 0

  const hasValidActivationPrice =
    condition.trailAmount > 0 || condition.trailPercentage > 0
      ? condition.activationPrice > 0
      : true

  return hasValidPricesOrPercentages && hasValidActivationPrice
}
