import {
  Button,
  ButtonedTabs,
  CustomModal,
  Divider,
  GoBack,
  Modal,
  Show,
  Spacer,
  TabBar,
  Text,
} from '@components'
import {
  AssetOperationTab,
  OrderOperationTypeEnum,
  OrderTypeEnum,
  PeriodEnum,
  PreOrderRequest,
  ConditionType,
} from '@interfaces'
import { useTranslation } from 'react-i18next'
import { StockOverview } from './stockOverview'
import { useDispatch, useSelector } from 'react-redux'
import {
  RootState,
  clearLimitOrderDetails,
  clearMarketOrderDetails,
  clearPreorderError,
  fetchMultiOrderCheckout,
  fetchOrderTypes,
  setLimitOrderDetails,
  setOrderOperationType,
  setOrderType,
} from '@store'
import { fetchCompanyStocks } from '@screens/company/thunk'
import { fetchCompanyInvestments } from '@screens/company/companyPosition/thunk'
import { MarketOrderCard } from './cards/marketOrderCard/marketOrderCard'
import { LimitOrderCard } from './cards/limitOrderCard/limitOrderCard'
import { SpinnerIcon } from '@assets/svg'
import { useOperationTypeTabs } from './hooks/useOperationTypeTabs'
import { useOrderTypeTabs } from './hooks/useOrderTypeTabs'
import { trackingService } from '@services'
import { BuySellEvents, LimitOrderEvents } from '@utils/eventTracker/eventKeys'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { backEndFormat, formatCurrency, numOfDaysBetween } from '@utils/helpers'
import format from 'date-fns/format'
import { BuyInfoModalForSale } from 'features/buy/steps'
import { getLimitHelpRead, setLimitHelpRead } from '@utils/limitOrderHelp'
import {
  clearLimitOrdersForm,
  fetchLimitOrderPriceLimits,
} from '@store/commonReducers/limitOrderPriceLimits/thunk'
import { useLimitOrderOperationStatus } from './hooks/useLimitOrderOperationStatus'
import {
  LimitOrderBuyInfoModal,
  LimitOrderSellInfoModal,
  StopLossTakeProfitHelpCombinedModal,
} from '@shared/modals'
import { fetchInvestments } from '@store/commonReducers/portfolio/thunk'
import { Calendar } from 'components/calendar/calendar'
import { useCalendar } from 'components/calendar/hooks/useCalendar'
import { addDays } from 'date-fns'
import { BuyInfoModal } from 'features/buy/steps/buyInfoModal'
import { StopLossCardTakeProfitCard } from './cards/stopLossTakeProfitCard/stopLossTakeProfitCard'
import { SLTKMultiplePreorderValidation } from './utils/SLTKMultiplePreorderValidation'

export const AssetOperation = ({
  onNext,
  close,
}: {
  onNext: () => void
  close: () => void
}): JSX.Element => {
  const dispatch = useDispatch()

  const {
    userBalance,
    buySell,
    companyPosition,
    companyStocks,
    limitOrderPriceLimits,
    investments,
  } = useSelector((state: RootState) => state)

  const [tab, setTab] = useState<AssetOperationTab>(AssetOperationTab.MARKET)
  const [limitBuyInfoModal, showLimitBuyInfoModal] = useState(false)
  const [stopLossTakeProfitInfoModal, showStopLossTakeProfitInfoModal] =
    useState(false)

  const [stopLossTakeProfitMode, setStopLossTakeProfitMode] =
    useState<ConditionType>(null)
  const [limitSellInfoModal, showLimitSellInfoModal] = useState(false)
  const [availableInfoModalVisible, setAvailableInfoModalVisible] =
    useState(false)
  const [timeInForceModal, setTimeInForceModal] = useState(false)

  const [limitOrderIsTooPassive, showLimitOrderIsTooPassive] = useState(false)
  const [limitOrderIsTooAggressive, showLimitOrderIsTooAggressive] =
    useState(false)

  const [calendarVisible, setCalendarVisible] = useState(false)

  const [sharesExplanationVisible, setSharesExplanationVisible] =
    useState(false)

  const orderOperationType = buySell?.orderUserInput?.orderOperationType
  const orderType = buySell?.orderUserInput?.orderType

  const [SLTKPreorders, setSLTKPreorders] = useState<PreOrderRequest[]>([])

  const { t } = useTranslation()

  const isLimitOrder = useMemo(() => {
    return orderType === OrderTypeEnum.limit && tab === AssetOperationTab.LIMIT
  }, [orderType, tab])

  const isMarketOrder = useMemo(() => {
    return (
      orderType === OrderTypeEnum.market && tab === AssetOperationTab.MARKET
    )
  }, [orderType, tab])

  const isStopLossTakeProfit = useMemo(() => {
    return orderType === OrderTypeEnum.market && tab === AssetOperationTab.SL_TK
  }, [orderType, tab])

  const stockIsInPortfolio = useMemo(() => {
    return investments?.portfolios?.some((portfolio) =>
      portfolio.positions?.some((p) => p.id === companyStocks?.id),
    )
  }, [companyStocks?.id, investments?.portfolios])

  const calendarMinDate = new Date()?.toISOString()?.substring(0, 10)
  const calendarMaxDate = (
    buySell.maxTif ? new Date(buySell.maxTif) : addDays(new Date(), 30)
  )
    ?.toISOString()
    ?.substring(0, 10)

  const [calendarSelectedDate, setCalendarSelectedDate] =
    useState(calendarMaxDate)

  const closeCalendar = (): void => setCalendarVisible(false)

  const saveCalendarDate = (date: string[]): void => {
    if (isLimitOrder) {
      dispatch(
        setLimitOrderDetails({
          shares: buySell?.orderUserInput?.limitOrder?.shares,
          price: buySell?.orderUserInput?.limitOrder?.price,
          expirationDate: format(new Date(date?.[0]), backEndFormat),
        }),
      )
    }
    closeCalendar()
  }

  useEffect(() => {
    if (isLimitOrder && buySell?.maxTif) {
      dispatch(
        setLimitOrderDetails({
          shares: buySell?.orderUserInput?.limitOrder?.shares,
          price: buySell?.orderUserInput?.limitOrder?.price,
          expirationDate: format(new Date(buySell?.maxTif), backEndFormat),
        }),
      )
    }
  }, [isLimitOrder, buySell?.maxTif])

  // Initialize order type in global state
  useEffect(() => {
    if (buySell?.orderTypes.includes(OrderTypeEnum.market)) {
      dispatch(setOrderType(OrderTypeEnum.market))
    } else {
      dispatch(setOrderType(OrderTypeEnum.limit))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buySell?.orderTypes])

  // Fetch company position and allowed order types
  useEffect(() => {
    if (companyStocks?.id) {
      dispatch(fetchInvestments())
    }
    if (companyStocks?.market) {
      dispatch(fetchOrderTypes(companyStocks?.market))
    }
  }, [companyStocks?.id, companyStocks?.market, dispatch])

  // Only fetch company investments if company is in users portfolio
  useEffect(() => {
    if (stockIsInPortfolio && companyStocks?.id) {
      dispatch(fetchCompanyInvestments({ ticker: companyStocks?.id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyStocks?.id, stockIsInPortfolio])

  // Fetch company data
  useEffect(() => {
    if (buySell?.stockId) {
      dispatch(
        fetchCompanyStocks({
          period: PeriodEnum.day,
          company: buySell?.stockId,
        }),
      )
    }
  }, [dispatch, buySell?.stockId])

  // If preorder is completed and we have a preorder id then go to next step
  useEffect(() => {
    if (!buySell.isLoading && buySell.multiCheckout?.length > 0 && onNext) {
      dispatch(clearLimitOrdersForm())
      onNext()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buySell.multiCheckout, buySell.isLoading, onNext])

  useEffect(() => {
    if (buySell?.sendPreOrderError) {
      dispatch(clearLimitOrdersForm())
      dispatch(clearLimitOrderDetails())
      dispatch(
        setLimitOrderDetails({
          price: null,
          shares: null,
          expirationDate: calendarMaxDate,
        }),
      )
      dispatch(clearPreorderError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buySell?.sendPreOrderError])

  const loading = useMemo(() => {
    return (
      buySell?.isLoading ||
      userBalance?.loading ||
      companyPosition?.loading ||
      companyStocks?.loading
    )
  }, [
    buySell?.isLoading,
    companyPosition?.loading,
    companyStocks?.loading,
    userBalance?.loading,
  ])

  const operationTypeTabs = useOperationTypeTabs()
  const orderTypeTabs = useOrderTypeTabs({
    type: orderOperationType,
    onDisabledTabClick: () => {
      // NOTHING NEED TO DO HERE
    },
  })

  const expirationDate = format(
    new Date(addDays(new Date(), 30)),
    backEndFormat,
  )

  const handleOperationTabPressed = (tab: OrderOperationTypeEnum): void => {
    dispatch(clearMarketOrderDetails())
    dispatch(clearLimitOrderDetails())
    setCalendarSelectedDate(calendarMaxDate)
    dispatch(
      setLimitOrderDetails({
        shares: null,
        price: null,
        expirationDate: buySell?.maxTif
          ? format(new Date(buySell?.maxTif), backEndFormat)
          : expirationDate,
      }),
    )
    dispatch(setOrderOperationType(tab))
  }

  const handleTabPressed = (tab: AssetOperationTab): void => {
    dispatch(clearMarketOrderDetails())
    dispatch(clearLimitOrderDetails())

    setTab(tab)
    switch (tab) {
      case AssetOperationTab.MARKET:
      case AssetOperationTab.SL_TK: // For stop loss and take profit we use MARKET type
        dispatch(setOrderType(OrderTypeEnum.market))
        break
      case AssetOperationTab.LIMIT:
        trackingService.trackEvent({ event: LimitOrderEvents.flowStarted })
        dispatch(setOrderType(OrderTypeEnum.limit))
        break
    }
  }

  const dateSelectionText = useMemo(() => {
    return numOfDaysBetween(new Date(), new Date(buySell?.maxTif))
  }, [buySell?.maxTif])

  const disabled = useMemo(() => {
    const userInput = buySell?.orderUserInput
    const marketDetails = userInput?.marketOrder
    const limitDetails = userInput?.limitOrder
    const isSell = orderOperationType === OrderOperationTypeEnum.sell
    const currentShares =
      companyPosition?.shares - companyPosition?.lockedShares

    if (isStopLossTakeProfit) {
      return !SLTKMultiplePreorderValidation(SLTKPreorders, currentShares)
    }

    // Market orderl
    if (isMarketOrder) {
      // Check if has enought shares
      const enoughtShares = isSell
        ? currentShares >= marketDetails?.shares
        : true

      // Check all fields completed and enought shares
      return !marketDetails?.shares || !enoughtShares
    }

    // Limit order
    if (isLimitOrder) {
      // Check if has enought shares
      const enoughtShares = isSell
        ? currentShares >= limitDetails?.shares
        : true

      // Check all fields completed and enought shares
      return (
        !limitDetails?.shares ||
        limitDetails?.shares == 0 ||
        !limitDetails?.expirationDate ||
        !limitDetails?.price ||
        !enoughtShares
      )
    }
  }, [
    buySell?.orderUserInput,
    companyPosition?.lockedShares,
    companyPosition?.shares,
    isLimitOrder,
    isMarketOrder,
    orderOperationType,
    isStopLossTakeProfit,
    SLTKPreorders,
  ])

  const handleMarketOrder = (): void => {
    if (disabled || loading || !isMarketOrder) return

    const userInput = buySell?.orderUserInput
    const shares = Number(userInput?.marketOrder?.shares)

    const preOrderData: PreOrderRequest = {
      type: orderOperationType,
      assetId: companyStocks?.id,
      method: orderType,
      price: Number(companyStocks?.price?.amount),
      shares,
    }

    trackingService.trackEvent({
      event: BuySellEvents.goToPreOrder,
      props: {
        type: orderOperationType?.toLowerCase(), // 'buy' or 'sell' to keep backwards compat.
        method: orderType,
        ticker: companyStocks?.symbol,
        id: companyStocks?.id,
        shares,
        assetGroup: companyStocks?.type,
      },
    })

    dispatch(fetchMultiOrderCheckout([preOrderData]))
  }

  const handleSLTKOrder = (): void => {
    if (disabled || loading) return
    dispatch(fetchMultiOrderCheckout(SLTKPreorders?.filter((o) => !!o))) // remove null forms
  }

  const { isPassive, isAggressive, shouldFetchPreorder } =
    useLimitOrderOperationStatus()

  useEffect(() => {
    if (operationTypeTabs?.length === 1) {
      dispatch(setOrderOperationType(operationTypeTabs[0]?.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationTypeTabs])

  useEffect(() => {
    const limitPrice = buySell?.orderUserInput?.limitOrder?.price

    if (isPassive) {
      trackingService.trackEvent({
        event: LimitOrderEvents.pricePassive,
        props: {
          ticker: companyStocks?.symbol,
          limitPrice,
          lowestAllowed: limitOrderPriceLimits?.lowestAllowedPrice?.amount,
        },
      })
      showLimitOrderIsTooPassive(true)
    }

    if (isAggressive) {
      trackingService.trackEvent({
        event: LimitOrderEvents.priceAggressive,
        props: {
          ticker: companyStocks?.symbol,
          limitPrice,
          highestAllowed: limitOrderPriceLimits?.highestAllowedPrice?.amount,
        },
      })
      showLimitOrderIsTooAggressive(true)
    }

    if (shouldFetchPreorder) {
      const userInput = buySell?.orderUserInput
      const shares = userInput?.limitOrder?.shares
      const price = userInput?.limitOrder?.price
      const expirationDate = userInput?.limitOrder?.expirationDate
      const preOrderData: PreOrderRequest = {
        type: orderOperationType,
        assetId: companyStocks?.id,
        method: orderType,
        price,
        shares,
        expirationDate,
      }
      trackingService.trackEvent({
        event: BuySellEvents.goToPreOrder,
        props: {
          type: orderOperationType?.toLowerCase(), // 'buy' or 'sell' to keep backwards compat.
          method: orderType,
          ticker: companyStocks?.symbol,
          id: companyStocks?.id,
          shares,
          assetGroup: companyStocks?.type,
        },
      })
      dispatch(fetchMultiOrderCheckout([preOrderData]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPassive, isAggressive, shouldFetchPreorder, limitOrderPriceLimits])

  useEffect(() => {
    if (isLimitOrder && !getLimitHelpRead(orderOperationType)) {
      orderOperationType === OrderOperationTypeEnum.buy
        ? showLimitBuyInfoModal(true)
        : showLimitSellInfoModal(true)
    }
  }, [isLimitOrder, orderOperationType])

  const {
    month,
    weekDays,
    getPreviousMonth,
    selectedYear,
    handleMonth,
    handleSelected,
    handleCancel,
    handleSubmit,
    handleRestore,
    monthsMatrix,
    submitDisabled,
  } = useCalendar({
    onChange: (_: string[], newDate: string) => {
      setCalendarSelectedDate(newDate || calendarMaxDate)
    },
    options: {
      allowSatAndSun: false,
    },
    selectedDates: [calendarSelectedDate],
    maxDate: calendarMaxDate,
    minDate: calendarMinDate,
    onCancel: closeCalendar,
    onSubmit: saveCalendarDate,
  })

  const handleReviewOrder = (): void => {
    if (disabled) return
    if (isMarketOrder) {
      handleMarketOrder()
    } else if (isLimitOrder) {
      dispatch(
        fetchLimitOrderPriceLimits({
          assetId: companyStocks?.id,
          type: orderOperationType,
        }),
      )
    } else if (isStopLossTakeProfit) {
      handleSLTKOrder()
    }
  }

  const onChangeSLTKCard = useCallback(
    (v: PreOrderRequest | null, idx: number) => {
      setSLTKPreorders((prev) => {
        const updatedPreorders = [...prev]
        updatedPreorders[idx] = v
        return updatedPreorders
      })
    },
    [],
  )

  if (loading) {
    return (
      <CustomModal.Content>
        <div className='d-flex h-100 align-center'>
          <SpinnerIcon className='spinner spinner--orange' />
        </div>
      </CustomModal.Content>
    )
  }

  return (
    <>
      <CustomModal.Header
        noBorder
        text={
          <div className='w-100 d-flex justify-center'>
            <Show
              when={operationTypeTabs?.length === 1}
              fallback={
                <ButtonedTabs<OrderOperationTypeEnum>
                  tabsStyle='buttoned-tab-bar__tabs--large'
                  tabs={operationTypeTabs}
                  activeTab={orderOperationType}
                  setActiveTab={handleOperationTabPressed}
                  loading={loading}
                />
              }
            >
              <Text preset='paragraph2' text={operationTypeTabs[0]?.title} />
            </Show>
          </div>
        }
      >
        <div className='d-flex align-center w-100'>
          <div className='w-20 d-flex align-start justify-start'>
            <GoBack goBack={close} />
          </div>
        </div>
      </CustomModal.Header>
      <Spacer preset='small' />
      <TabBar
        extraMarginOnSides
        tabs={orderTypeTabs}
        activeTab={tab}
        setActiveTab={handleTabPressed}
        loading={loading}
      />
      <Divider />
      <CustomModal.Content>
        <StockOverview
          companyPosition={companyPosition}
          companyStocks={companyStocks}
          openSharesInfoModal={() => setSharesExplanationVisible(true)}
        />
        <Spacer preset='smaller' />

        {tab === AssetOperationTab.MARKET && (
          <MarketOrderCard
            focusOnInit
            loading={loading}
            showAvailableInfoModal={() => setAvailableInfoModalVisible(true)}
          />
        )}
        {tab === AssetOperationTab.LIMIT && (
          <LimitOrderCard
            focusOnInit
            type={orderOperationType}
            openCalendar={() => setCalendarVisible(true)}
            showLimitBuyInfoModal={() => {
              showLimitBuyInfoModal(true)
            }}
            showLimitSellInfoModal={() => {
              showLimitSellInfoModal(true)
            }}
            showAvailableInfoModal={() => setAvailableInfoModalVisible(true)}
            showTimeInForceModal={() => setTimeInForceModal(true)}
          />
        )}

        <Show when={tab === AssetOperationTab.SL_TK}>
          <>
            <Spacer preset='smaller' />
            <StopLossCardTakeProfitCard
              assetId={companyPosition?.id}
              mode={ConditionType.STOP_LOSS}
              disabled={loading}
              focusOnInit
              showStopLossTakeProfitModal={() => {
                showStopLossTakeProfitInfoModal(true)
                setStopLossTakeProfitMode(ConditionType.STOP_LOSS)
              }}
              loading={loading}
              assetPrice={companyStocks?.price}
              currentShares={companyPosition?.shares}
              lockedShares={companyPosition?.lockedShares}
              symbol={companyStocks?.symbol}
              onChange={(v) => onChangeSLTKCard(v, 0)}
            />
            <Spacer preset='smaller' />
            <StopLossCardTakeProfitCard
              assetId={companyPosition?.id}
              mode={ConditionType.TAKE_PROFIT}
              disabled={loading}
              focusOnInit
              showStopLossTakeProfitModal={() => {
                showStopLossTakeProfitInfoModal(true)
                setStopLossTakeProfitMode(ConditionType.TAKE_PROFIT)
              }}
              loading={loading}
              assetPrice={companyStocks?.price}
              currentShares={companyPosition?.shares}
              lockedShares={companyPosition?.lockedShares}
              symbol={companyStocks?.symbol}
              onChange={(v) => onChangeSLTKCard(v, 1)}
            />
          </>
        </Show>
        <Spacer preset='smaller' />
      </CustomModal.Content>
      {calendarVisible && (
        <CustomModal.OverlayContent>
          <CustomModal
            show
            dismissable
            height='fitContent'
            theme='lighter'
            onClose={closeCalendar}
            size='small'
          >
            <Calendar
              monthsMatrix={monthsMatrix}
              id='asset-operation-calendar'
              headerTitle={t('limitOrders.form.expirationDate')}
              headerSubtitle={t('limitOrders.form.maxExpiration', {
                availableDay: buySell?.maxTif ? dateSelectionText : 30,
              })}
              withHeader
              withFooter
              handleCancel={handleCancel}
              handleRestore={handleRestore}
              handleSelected={handleSelected}
              handleSubmit={handleSubmit}
              handleMonth={handleMonth}
              getPreviousMonth={getPreviousMonth}
              month={month}
              selectedYear={selectedYear}
              weekDays={weekDays}
              submitDisabled={submitDisabled}
            />
          </CustomModal>
        </CustomModal.OverlayContent>
      )}
      {sharesExplanationVisible && (
        <CustomModal.OverlayContent>
          <BuyInfoModalForSale
            close={() => setSharesExplanationVisible(false)}
          />
        </CustomModal.OverlayContent>
      )}
      {limitBuyInfoModal && (
        <CustomModal.OverlayContent>
          <LimitOrderBuyInfoModal
            onClose={() => {
              setLimitHelpRead(orderOperationType)
              showLimitBuyInfoModal(false)
            }}
          />
        </CustomModal.OverlayContent>
      )}

      <Show when={limitSellInfoModal}>
        <CustomModal.OverlayContent>
          <LimitOrderSellInfoModal
            onClose={() => {
              setLimitHelpRead(orderOperationType)
              showLimitSellInfoModal(false)
            }}
          />
        </CustomModal.OverlayContent>
      </Show>

      <Show when={stopLossTakeProfitInfoModal}>
        <CustomModal.OverlayContent>
          <StopLossTakeProfitHelpCombinedModal
            mode={stopLossTakeProfitMode}
            onClose={() => {
              setLimitHelpRead(orderOperationType)
              showStopLossTakeProfitInfoModal(false)
            }}
          />
        </CustomModal.OverlayContent>
      </Show>

      {limitOrderIsTooAggressive && (
        <CustomModal.OverlayContent>
          <Modal
            className='mb-4 backdrop--none'
            show
            text={t('limitOrders.alertAggressive.title')}
            onButton={() => {
              showLimitOrderIsTooAggressive(false)
              dispatch(clearLimitOrdersForm())
            }}
            buttonText={t('common.ok')}
          >
            {t('limitOrders.alertAggressive.description', {
              price: formatCurrency(limitOrderPriceLimits?.highestAllowedPrice),
            })}
          </Modal>
        </CustomModal.OverlayContent>
      )}
      {limitOrderIsTooPassive && (
        <CustomModal.OverlayContent>
          <Modal
            className='mb-4 backdrop--none'
            show
            text={t('limitOrders.alertPassive.title')}
            onButton={() => {
              showLimitOrderIsTooPassive(false)
              dispatch(clearLimitOrdersForm())
            }}
            buttonText={t('common.ok')}
          >
            {t('limitOrders.alertPassive.description', {
              price: formatCurrency(limitOrderPriceLimits?.lowestAllowedPrice),
            })}
          </Modal>
        </CustomModal.OverlayContent>
      )}
      <Show when={availableInfoModalVisible}>
        <BuyInfoModal
          close={() => setAvailableInfoModalVisible(false)}
          title={t('buySellFlow.infoBalance.title')}
          description={t('buySellFlow.infoBalance.text')}
        />
      </Show>
      {timeInForceModal && (
        <BuyInfoModal
          close={() => setTimeInForceModal(false)}
          title={t('buySellFlow.timeInForceInfo.title')}
          description={t('buySellFlow.timeInForceInfo.text')}
        />
      )}
      <CustomModal.Footer>
        <Button
          size='big'
          text={t('buySellFlow.reviewOrder')}
          loading={loading || limitOrderPriceLimits?.isLoading}
          disabled={disabled}
          onClick={handleReviewOrder}
        />
      </CustomModal.Footer>
    </>
  )
}
