import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const PercentageIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.5 11C6.53333 11 5.70833 10.6583 5.025 9.975C4.34167 9.29167 4 8.46667 4 7.5C4 6.53333 4.34167 5.70833 5.025 5.025C5.70833 4.34167 6.53333 4 7.5 4C8.46667 4 9.29167 4.34167 9.975 5.025C10.6583 5.70833 11 6.53333 11 7.5C11 8.46667 10.6583 9.29167 9.975 9.975C9.29167 10.6583 8.46667 11 7.5 11ZM7.5 9C7.91667 9 8.27067 8.854 8.562 8.562C8.854 8.27067 9 7.91667 9 7.5C9 7.08333 8.854 6.72933 8.562 6.438C8.27067 6.146 7.91667 6 7.5 6C7.08333 6 6.72933 6.146 6.438 6.438C6.146 6.72933 6 7.08333 6 7.5C6 7.91667 6.146 8.27067 6.438 8.562C6.72933 8.854 7.08333 9 7.5 9ZM16.5 20C15.5333 20 14.7083 19.6583 14.025 18.975C13.3417 18.2917 13 17.4667 13 16.5C13 15.5333 13.3417 14.7083 14.025 14.025C14.7083 13.3417 15.5333 13 16.5 13C17.4667 13 18.2917 13.3417 18.975 14.025C19.6583 14.7083 20 15.5333 20 16.5C20 17.4667 19.6583 18.2917 18.975 18.975C18.2917 19.6583 17.4667 20 16.5 20ZM16.5 18C16.9167 18 17.2707 17.854 17.562 17.562C17.854 17.2707 18 16.9167 18 16.5C18 16.0833 17.854 15.7293 17.562 15.438C17.2707 15.146 16.9167 15 16.5 15C16.0833 15 15.7293 15.146 15.438 15.438C15.146 15.7293 15 16.0833 15 16.5C15 16.9167 15.146 17.2707 15.438 17.562C15.7293 17.854 16.0833 18 16.5 18ZM5.4 20L4 18.6L18.6 4L20 5.4L5.4 20Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
