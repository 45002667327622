import { OrderTransaction, Transaction, TransactionType } from 'interfaces'

export const mapOrderTransactionToTransaction = (
  item: OrderTransaction,
): Transaction => {
  return {
    title: item?.asset?.name,
    id: item?.id,
    ticker: item?.asset?.ticker,
    amount: item?.calculations?.totalCost,
    date: item?.creationDate,
    logo: item?.asset?.logoUrl,
    orderDetails: {
      type: item?.type,
      ticker: item?.asset?.ticker,
      status: item?.status,
      statusText: item?.statusText,
      childStatus: item?.childStatus,
      childStatusText: item?.childStatusText,
      reason: item?.reason,
      reasonText: item?.reasonText,
      method: item?.method,
      shares: item?.shares,
      sharePrice: item?.estimatedPrice,
      isCancellable: item?.isCancellable,
      notCancellableReason: item?.notCancellableReason,
      lockedCash: item?.calculations?.lockedCash,
      condition: item?.condition,
    },
    dividendSharesDetails: null,
    type: item?.type as unknown as TransactionType,
  }
}
