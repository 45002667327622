import { ChevronRight } from '@assets/icons'
import { CompanyPositionData, Money } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { Text } from 'components'
import { getSLTPOrderConfig } from '@utils/stopLossTakeProfitOrderConfig'

export const ConditionalOrders = ({
  companyPosition,
  currentValue,
  handleNavigation,
}: {
  companyPosition: CompanyPositionData
  currentValue: Money
  handleNavigation: (id: string) => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      {companyPosition?.conditionalOrders.map((order, idx) => {
        const linkedSLTPOrderConfig = getSLTPOrderConfig(
          order.condition,
          currentValue?.currency,
        )
        return (
          <div
            className='infoCard__content__item w-100'
            key={`linked-sltp-order-${order.id}-${idx}`}
            aria-hidden='true'
            role='button'
            onClick={() => handleNavigation(order.id)}
          >
            <div className='text-left w-50 mr-1'>
              <Text
                preset='bodyRegular'
                text={t(linkedSLTPOrderConfig.name)}
                color={linkedSLTPOrderConfig.color}
              />
              <div>{`${order.requestedShares} ${companyPosition?.ticker}`}</div>
            </div>
            <div className='w-50 font-weight-bold text-right'>
              {linkedSLTPOrderConfig.value}
            </div>
            <div className='infoCard__content__item--chevron'>
              <ChevronRight />
            </div>
          </div>
        )
      })}
    </>
  )
}
