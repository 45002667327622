import { formatCurrency, formatMoney } from '@utils/helpers'
import { InfoIcon, VectorDown, VectorUp } from '@assets/icons'
import { Spacer, Text } from '@components'
import { useTranslation } from 'react-i18next'
import { AssetsInfo, CompanyPositionData } from '@interfaces'

export const StockOverview = ({
  openSharesInfoModal,
  companyStocks,
  companyPosition,
}: {
  companyStocks: AssetsInfo
  companyPosition: CompanyPositionData
  openSharesInfoModal: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  const iconSize = 16

  const increaseColor =
    companyStocks?.increaseMoney?.amount >= 0 ? 'positive' : 'negative'

  const increaseMoneyIcon =
    companyStocks?.increaseMoney?.amount >= 0 ? (
      <VectorUp size={iconSize} color={increaseColor} />
    ) : (
      <VectorDown size={iconSize} color={increaseColor} />
    )

  const availableShares = companyPosition?.shares
  const positionInfo =
    availableShares > 0 &&
    `${t('common.position')}: ${availableShares} ${t(
      'buySellFlow.shares',
    )} | ${t('common.value')}: ${formatCurrency({
      amount: availableShares * companyStocks?.price?.amount,
      currency: companyStocks?.price?.currency,
    })} | ${t('common.avgAbbreviation')}: ${formatCurrency(
      companyPosition?.monetaryStatsInPortfolioBaseCurrency?.avgEntryPrice,
    )}`

  return (
    <>
      <div>
        <Text
          preset='paragraph2'
          text={`${companyStocks?.symbol} ${formatMoney(
            companyStocks?.price?.amount,
            companyStocks?.price?.currency,
            4,
          )} `}
        />
        <Spacer preset='extraTiny' />
        <div className='d-flex'>
          <div className='d-flex align-center'>{increaseMoneyIcon}</div>
          <Spacer preset='extraTiny' />
          <Text preset='paragraph3' color={increaseColor}>
            <div className='d-flex align-center'>
              {formatCurrency(companyStocks?.increaseMoney)} (
              {companyStocks?.increasePercentage}%)
            </div>
          </Text>
          <Spacer preset='tiny' />
          <Text
            preset='paragraph3'
            text={t('buySellFlow.lastDay')}
            color='secondary'
          />
        </div>
        {positionInfo && (
          <>
            <Spacer preset='extraTiny' />
            <div className='d-flex align-center'>
              <div
                className='cursor-pointer d-flex align-center'
                onClick={openSharesInfoModal}
                aria-hidden
              >
                <InfoIcon size={16} color='tertiary' />
              </div>
              <Spacer preset='tiny' />
              <Text color='secondary' preset='paragraph3' text={positionInfo} />
            </div>
          </>
        )}
      </div>
    </>
  )
}
