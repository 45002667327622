import { Button, CustomModal, Spacer, Text } from '@components'
import { useTranslation } from '@hooks'
import { ReactComponent as QRIcon } from '@assets/svg/qr_link.svg'

export const OnboardingRequiredModal = ({
  close,
}: {
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CustomModal
      show={true}
      theme='lighter'
      size='small'
      dismissable
      onClose={close}
    >
      <CustomModal.Header text={t('portfolio.complete_qr_title')} />
      <CustomModal.Content>
        <Spacer preset='tiny' />
        <Text
          preset='paragraph1'
          text={t('portfolio.complete_qr_link_text')}
          align='center'
        />
        <div className='d-flex d-flex-col align-self-start align-center justify-center'>
          <Spacer preset='medium' />
          <div className='qr__code__wrapper qr__code__wrapper--small'>
            <QRIcon className='qr__code' />
          </div>
        </div>
        <Spacer preset='tiny' />
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          text={t('common.accept')}
          onClick={close}
          buttonType='primary'
        />
      </CustomModal.Footer>
    </CustomModal>
  )
}
